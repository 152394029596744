import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { CommunicationService } from 'src/app/services/communication.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TimeByHour } from 'src/app/constants/timeList';

@Component({
  selector: 'app-user-preference',
  templateUrl: './user-preference.component.html',
  styleUrls: ['./user-preference.component.scss']
})
@Injectable({ providedIn: 'root' })
export class UserPreferenceComponent implements OnInit {
  modalReference: NgbModalRef;
  bookSeatDetailsForm: FormGroup;
  heroes$;
  seat_number;
  emp_number;
  superlatives$ = new BehaviorSubject<{ [superlativeName: string]: string }>({});
  tableDataSource$ = new BehaviorSubject<any[]>([]);
  displayedColumns$ = new BehaviorSubject<string[]>([
    'emp_name',
    'seat_number',
    'last_booking_date',
    'id'
  ]);
  start_Date: Date;
  end_Date: Date;
  currentPage$ = new BehaviorSubject<number>(1);
  pageSize$ = new BehaviorSubject<number>(10);
  dataOnPage$ = new BehaviorSubject<any[]>([]);
  sortKey$ = new BehaviorSubject<string>('emp_id');
  sortDirection$ = new BehaviorSubject<string>('asc');
  status = [];
  moreDetailsData = null;
  todayDate;
  closeResult: string;
  bookingTimeList: any;
  startTimeValue: any;
  isBookByTime = false;
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    public  translate:  TranslateService,

  ) { }

  ngOnInit() {
    this.bookSeatDetailsForm = this.formBuilder.group(
      {
        bookSeatStartDate: new FormControl(),
        bookSeatEndDate: new FormControl(),
        seat_id: new FormControl(),
        commentsRenew: [''],
        startTime : new FormControl(),
        endTime : new FormControl(),
      }
    );
    this.getBookingPreferences();
    const currentDate = new Date();
    const objCurrentDate = { year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate() };
    this.todayDate = objCurrentDate;
    this.setStartEndTime();
  }

  get f() {
    return  this.bookSeatDetailsForm.controls;
  }

  getBookingPreferences() {
      const userPreferenceData = [];
      this.communicationService.getPreferences().subscribe(res => {

        for (const user of res) {
          userPreferenceData.push(user);
        }
        this.heroes$ = new BehaviorSubject(userPreferenceData);
        combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
          .subscribe(([allSources, currentPage, pageSize]) => {
            const startingIndex = (currentPage - 1) * pageSize;
            const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
            this.dataOnPage$.next(onPage);
          });

        this.heroes$.pipe(take(1)).subscribe(heroData => {
          this.tableDataSource$.next(Object.values(heroData));
        });
      }, error => {
        console.error(error);
      });
  }
  onDateChange(event) {
    this.bookSeatDetailsForm.controls.bookSeatEndDate.setValue(this.bookSeatDetailsForm.value.bookSeatStartDate);
  }
  adjustSort(key: string) {
    if (this.sortKey$.value === key) {
      if (this.sortDirection$.value === 'asc') {
        this.sortDirection$.next('desc');
      } else {
        this.sortDirection$.next('asc');
      }
      return;
    }
    this.sortKey$.next(key);
    this.sortDirection$.next('asc');
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getMoreDetails(seat_number: any, username: any, content) {
    this.seat_number = seat_number;
    this.emp_number = username;
    this.moreDetailsData = null;
    this.bookSeatDetailsForm.controls.bookSeatStartDate.setValue(this.todayDate);
    this.bookSeatDetailsForm.controls.bookSeatEndDate.setValue(this.todayDate);
    this.modalReference = this.modalService.open(content);
    this.bookSeatDetailsForm.controls.seat_id.setValue(this.seat_number);
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  bookWorkstation() {
    const startDateVal = formatDate(this.convertDate(this.bookSeatDetailsForm.value.bookSeatStartDate), 'yyyy-MM-dd', this.locale);
    const endDateVal =  formatDate(this.convertDate(this.bookSeatDetailsForm.value.bookSeatEndDate), 'yyyy-MM-dd', this.locale);
    if (startDateVal <= endDateVal) {
       const bookSeatObj = {
         // tslint:disable-next-line:max-line-length
          start_date_time:  this.isBookByTime ? startDateVal + this.bookingTimeList[this.bookSeatDetailsForm.get('startTime').value].timeFormat :
          startDateVal + 'T00:00:00',
          end_date_time: this.isBookByTime ? endDateVal + this.bookingTimeList[this.bookSeatDetailsForm.get('endTime').value].timeFormat :
          endDateVal + 'T23:59:59',
          emp_id: this.emp_number,
          seat_id: this.seat_number,
          status: 0,
          comments: this.bookSeatDetailsForm.value.commentsRenew,
          futureReference: false,
          is_self: true
       };
       this.communicationService.bookSeat(bookSeatObj).subscribe(res => {
          this.bookSeatDetailsForm.controls.bookSeatStartDate.setValue('');
          this.bookSeatDetailsForm.controls.bookSeatEndDate.setValue('');
          this.bookSeatDetailsForm.controls.commentsRenew.setValue('');
          this.toastr.success(this.translate.instant('toasterSuccessMessage.workStationBooked'));
          this.modalReference.close();
       }, error => {
          this.bookSeatDetailsForm.controls.bookSeatStartDate.setValue('');
          this.bookSeatDetailsForm.controls.bookSeatEndDate.setValue('');
          this.bookSeatDetailsForm.controls.commentsRenew.setValue('');
          this.toastr.error(error.error.message, 'Failed to book Workstation');
          this.modalReference.close();
       });
    } else {
      this.modalReference.close();
      this.toastr.error('Invalid Booking Date Selection');
    }
  }

  convertDate(date) {
    if (date != null || date !== '') {
      return new Date(date.year, date.month - 1, date.day);
    }
  }

  setStartEndTime() {
    this.bookingTimeList = TimeByHour;
    this.f.startTime.setValue(0);
    this.f.endTime.setValue(24);
    this.bookSeatDetailsForm.get('startTime').disable();
    this.bookSeatDetailsForm.get('endTime').disable();
  }

  deselectTimeBooking(event) {
    if (event.target.checked) {
      this.bookSeatDetailsForm.get('startTime').enable();
      this.bookSeatDetailsForm.get('endTime').enable();
      this.isBookByTime = true;
    } else {
      this.isBookByTime = false;
      this.f.startTime.setValue(0);
      this.f.endTime.setValue(24);
      this.bookSeatDetailsForm.get('startTime').disable();
      this.bookSeatDetailsForm.get('endTime').disable();
    }
  }

  onStartTimeChange(value) {
    this.startTimeValue =  value.target.value;
    let val = this.startTimeValue;
    this.setEndTime(++val);
  }

  setEndTime(val) {
    this.f.endTime.setValue(val);
  }

  onEndTimeChange(value) {
    let endTimeValue =  value.target.value;
    if (this.startTimeValue <  endTimeValue) {
      this.setEndTime(endTimeValue);
    } else {
      endTimeValue = this.startTimeValue;
      this.setEndTime(++endTimeValue);
    }
  }
}
