import { Component,  OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { UserModel } from 'src/app/model/user.model';
import { OidcSecurityService} from 'angular-auth-oidc-client';

import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import {Observable} from 'rxjs';
import { jwtDecode } from 'jwt-decode';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'argon-dashboard-angular';
    errorMsg: string;
    errorSave = 'Failed to create user,Department not configured.';
    model: UserModel;
    keySize = 256;
    iterations = 1000;
    modalValue = 0;
    authenticated:boolean ;
  isAuthenticated$: Observable<boolean>;
  private loginStatus: number;
  decodedToken: { [key: string]: string };
 

    constructor(public oidcSecurityService: OidcSecurityService,
        private route: ActivatedRoute,
        private idle: Idle,
        private router: Router,
        private toastr: ToastrService,
        private modalService: NgbModal,
        private accountService: AccountService) {
      idle.setIdle(4 * 60);
      idle.setTimeout(5);
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
      idle.onTimeout.subscribe(() => {
        if (this.loginStatus === 1) {
          this.idle.stop();
          this.router.navigate(['/logout']);
        }
      });
      this.oidcSecurityService.checkAuth().subscribe((isAuthenticated) => {
        this.authenticated = isAuthenticated ;
        // console.log('app authenticated####', isAuthenticated);
         this.onAuthorizationResultComplete(this.authenticated)});
    }

   ngOnInit() {

   }

    reset() {
      this.idle.watch();
    }

    loginCall(token) {
      this.decodedToken = jwtDecode(token);
      this.accountService.login(this.model,token).subscribe(res => {
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        const seatId = JSON.parse(localStorage.getItem('qrId'));
        if (seatId) {
          this.router.navigate(['/qr-code-booking']);
        } else if (currentUser.role === 'Super User') {
          this.router.navigate(['/super-user-dashboard']);
        } else {
          this.router.navigate(['/dashboard']);
        }
        this.loginStatus = 1;
        this.reset();
      }, error => {
        // if (error.status === 406 || error.status === 401) { // Unauthorized
        //   return this.toastr.error(error.error.message);
        // }
        this.errorMsg = error.statusText;
         
        const dept =this.decodedToken.sub_department_number
        const id =this.decodedToken.preferred_username
        const emailId=this.decodedToken.email
        const userName = this.decodedToken.given_name + ' '+this.decodedToken.family_name;
        // bring content here and open the madal
        localStorage.setItem('subDept',dept);
        localStorage.setItem('userId',id);
        localStorage.setItem('emailId',emailId);
        localStorage.setItem('userName',userName);

        
        if (this.errorMsg !== undefined && this.errorMsg === this.errorSave) {
          this.errorMsg = 'User\'s Department is not configured.Kindly add your details by clicking on Register.';
          this.modalValue = 1;
          this.toastr.error(this.errorMsg);
        } else if (this.errorMsg !== undefined) {
          this.modalValue = 1;
          this.errorMsg =' User\'s Department is not configured.Kindly add your details by clicking on Register.';
          this.toastr.error(this.errorMsg);
        } else {
          this.modalValue = 1;
          this.errorMsg = 'User\'s Department is not configured.Kindly add your details by clicking on Register.';
          this.toastr.error(this.errorMsg);
        }

      });
    }

    closeModal() {
        this.modalValue = 0;
    }


    
    login() {
        this.oidcSecurityService.authorize();
        this.loginStatus = 1;
    }

    refreshSession() {
        this.oidcSecurityService.authorize();
    }

  private getSeatId(key: string): string {
    const parameters = new URLSearchParams(window.location.search);
    return parameters.get(key);
  }

    private onAuthorizationResultComplete(isAuthenticated: boolean){
      if(isAuthenticated){
        const token = this.oidcSecurityService.getToken();
        this.model = { email:"NA", first_name:"NA", last_name:"NA", role:"5", username:"NA", password:"NA", sub_department_name:"NA" }
        this.loginCall(token);
      }
    }


    private write(key: string, value: any): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    
}
