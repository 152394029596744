import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommunicationService } from 'src/app/services/communication.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { FormControl } from '@angular/forms';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-disable-floor',
  templateUrl: './disable-floor.component.html',
  styleUrls: ['./disable-floor.component.scss']
})
export class DisableFloorComponent implements OnInit {

  heroes$;
  superlatives$ = new BehaviorSubject<{ [superlativeName: string]: string }>({});
  tableDataSource$ = new BehaviorSubject<any[]>([]);
  displayedColumns$ = new BehaviorSubject<string[]>([
    'city',
    'building',
    'floor_name',
    'wing_name',
    'status',
    'id'
  ]);

  currentPage$ = new BehaviorSubject<number>(1);
  pageSize$ = new BehaviorSubject<number>(10);
  dataOnPage$ = new BehaviorSubject<any[]>([]);
  searchFormControl = new FormControl();
  sortKey$ = new BehaviorSubject<string>('floor_name');
  sortDirection$ = new BehaviorSubject<string>('asc');
  totalUserCount = 0;
  limit = 10000;
  startIndex = 0;
  status = [];
  submitted = false;
  floors = [];
  wings = [];

  constructor(
    private communicationService: CommunicationService,
    private toastr: ToastrService,  public  translate:  TranslateService, ) {

  }

  ngOnInit(): void {
    this.getAllFlooWings();

  }

  getAllFlooWings() {
    const userData = [];
    this.communicationService.getFlooWings().subscribe(res => {
      for (const user of res) {
       // if (user.status == 0) {
          userData.push(user);
        // }
      }
      this.heroes$ = new BehaviorSubject(userData);
      combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
        .subscribe(([allSources, currentPage, pageSize]) => {
          const startingIndex = (currentPage - 1) * pageSize;
          const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
          this.dataOnPage$.next(onPage);
        });

      this.heroes$.pipe(take(1)).subscribe(heroData => {
        this.tableDataSource$.next(Object.values(heroData));
      });

      combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          let filteredHeroes: any[];

          if (!searchTerm) {
            filteredHeroes = heroesArray;
          } else {
            const filteredResults = heroesArray.filter(hero => {
              return Object.values(hero)
                .reduce((prev, curr) => {
                  return prev || curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
                }, false);
            });
            filteredHeroes = filteredResults;
          }

          const sortedHeroes = filteredHeroes.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });

          this.tableDataSource$.next(sortedHeroes);
        });
    }, error => {
      console.log(error);
    });
  }

  activateFloor(floor_id, wing_id) {
    this.communicationService.activateFloor(floor_id, wing_id).subscribe(res => {
      this.wings = res;
      this.getAllFlooWings();
      this.toastr.success(this.translate.instant('toasterSuccessMessage.floorActivated'));
    }, error => {
      console.log(error);
      this.toastr.error('Unable to activate floor');

    });
  }

  disableFloor(floor_id, wing_id) {
    this.communicationService.disableFloor(floor_id, wing_id).subscribe(res => {
      this.wings = res;
      this.getAllFlooWings();
      this.toastr.success(this.translate.instant('toasterSuccessMessage.floorDisabled'));
    }, error => {
      console.log(error);
      this.toastr.error('Unable to disable floor');
    });
  }

}
