import { Component, OnInit, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {OidcClientNotification, OidcSecurityService, PublicConfiguration} from 'angular-auth-oidc-client';
import { Subscription } from 'rxjs/Subscription';
import { CommunicationService } from 'src/app/services/communication.service';
import {AccountService} from '../../services/account.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import { Router } from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import {query} from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    modalReference: NgbModalRef;

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  showErrorMessage = '';
  returnUrl: string;
  isAuthorizedSubscription: Subscription;
  isAuthorized: boolean;
  version: string;
  configuration: PublicConfiguration;
  userDataChanged$: Observable<OidcClientNotification<any>>;
  userData$: Observable<any>;
  isAuthenticated$: Observable<boolean>;
  detailsUserForm: FormGroup;
  countries = [];
  organizations = [];
  cities = [];
  buildings = [];
  floors = [];
  departments = [];
  wingId=[];
  subDepartment:String;
  countryId='0';
  cityId='0';
  buildingId='0';
  userId:String;

  constructor(
    private elementRef: ElementRef,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrService,
    private communicationService: CommunicationService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public oidcSecurityService: OidcSecurityService) {  }

  ngOnInit() {
    // this.communicationService.getVersion().subscribe(data => {
      // this.version = '1.0.0';
    // });
    this.version = '1.0.0';
    this.detailsUserForm = this.formBuilder.group(
      {
        userId: [''],
        organization: ['', Validators.required],
        department: [''],
        country: ['', Validators.required],
        countryName: ['', Validators.required],
        city: ['', Validators.required],
        cityName: ['', Validators.required],
        building: ['', Validators.required],
        buildingName: ['', Validators.required],
        floor: ['', Validators.required],
        wing: ['', Validators.required],
        floorName: ['', Validators.required],
        comment:['']
      }
    );

  }

  getRegistrationDetails(){
    this.communicationService.getRegistrationDetails(this.countryId,this.cityId,this.buildingId).subscribe(res => {
    this.countries = res.countries;
    this.cities = res.cities;
    this.buildings=  res.buildings;
    this.floors = res.floors;
    this.subDepartment=localStorage.getItem('subDept');
    this.userId =localStorage.getItem('userId');
    this.f.country.setValue(this.countries[0].country_id);
      this.f.city.setValue( this.cities[0].city_id);
      this.f.building.setValue(this.buildings[0].building_id);
      this.setFloors(this.floors);
      this.f.userId.setValue(this.userId);
      this.f.department.setValue(this.subDepartment);
      this.f.comment.setValue('');

    }, error => {
      console.error(error);
    });
  
}

  onCountryChange(value) {
    this.countryId = value.target.value;
    this.countries.forEach(country=>{
      if(country.country_id == this.countryId){
        this.f.country.setValue(country.country_id);
        this.communicationService.getRegistrationDetails(this.countryId,this.cityId,this.buildingId).subscribe(res => {
          this.cities = res.cities;
          this.buildings=  res.buildings;
          this.floors = res.floor;
          this.f.city.setValue(res.cities[0].city_id);
          this.f.building.setValue(res.buildings[0].building_id);
          this.setFloors(res.floors);
        }, error => {
          console.error(error);
        });
      }
    })
 }

 onCityChange(value) {
  this.cityId= value.target.value;
  this.cities.forEach(city=>{
    if(city.city_id == this.cityId){
      this.f.city.setValue(city.city_id);
    }
  })
  this.communicationService.getRegistrationDetails(this.countryId,this.cityId,this.buildingId).subscribe(res => {
    this.buildings=  res.buildings;
    this.floors = res.floor;
    this.f.building.setValue(res.buildings[0].building_id);
    this.setFloors(res.floors);
  }, error => {
    console.error(error);
  });
}

onBuildingChange(value) {
  this.buildingId= value.target.value;
  this.buildings.forEach(building=>{
    if(building.building_id == this.buildingId){
      this.f.building.setValue(building.building_id);
    }
  })
  this.communicationService.getRegistrationDetails(this.countryId,this.cityId,this.buildingId).subscribe(res => {
    this.floors = res.floor;
    this.setFloors(res.floors);

}, error => {
  console.error(error);
});
 
}
  setBuildings(res) {
      if (res.length > 0) {
                this.buildings = [];
                for (const building of res) {
                  if (building.status === '0') {
                    this.buildings.push(building);
                  }
                }
          this.f.building.setValue(res[0].building_id);
      } else {
        this.f.building.setValue('');
        this.floors = [];
        this.f.floor.setValue('');
      }
  }
 setFloors(res) {
    if (res.length > 0) {
       this.floors = res;
       this.f.floor.setValue(res[0].floor_id); 
    } else {
       this.floors = [];
       this.f.floor.setValue('');
    }
  }

  getUserDetails( content){
   const id =localStorage.getItem('userId');
        if(id == null || id ==''){
          this.oidcSecurityService.authorize();
        }
      this.getRegistrationDetails();
      this.modalReference = this.modalService.open(content);
    
  }
  get f() {
    return this.detailsUserForm.controls;
  }

  save() {
         const request = { 
          emp_id:this.detailsUserForm.value.userId,
          sub_department_name:this.detailsUserForm.value.department,
          username:localStorage.getItem('userName'),
          email_id:localStorage.getItem('emailId'),
          country_id:this.detailsUserForm.value.country,
           city_id:this.detailsUserForm.value.city,
           building_id:this.detailsUserForm.value.building,
           floor_id:this.detailsUserForm.value.floor,
           comment:this.detailsUserForm.value.comment,
           status: '0',
      };
            this.communicationService.userRegisteration(request).subscribe(res => {
        this.toastr.success(this.translate.instant('User Registered Successfully, Please wait for approval'));
        this.modalReference.close();
        this.getRegistrationDetails();
      },
      error => {
        this.modalReference.close();
        this.toastr.error(error.error.message, 'Failed to Register User');
      });

  }
  clearDetails() {
    this.f.country.setValue(this.countries[0].country_id);
    this.f.city.setValue( this.cities[0].city_id);
    this.f.building.setValue(this.buildings[0].building_id);
    this.setFloors(this.floors);
    this.f.userId.setValue('');
    this.f.department.setValue('');
    this.f.comment.setValue('');
  }

  onSubmit() {
    let url = new URL(window.location.href);
    let fragment = url.hash;
    let qrUrl = url.href;
    let [path, query] = fragment.substr(1).split('?');
    let params = new URLSearchParams(query);
    let qrId = params.get("qrId");
    localStorage.setItem("qrUrl",qrUrl);
    localStorage.setItem("qrId", qrId);
    this.submitted = true;
    this.showErrorMessage = '';
    this.oidcSecurityService.authorize();
  }
}

