import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommunicationService } from 'src/app/services/communication.service';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-first-aid',
  templateUrl: './add-first-aid.component.html',
  styleUrls: ['./add-first-aid.component.scss']
})
export class AddFirstAidComponent implements OnInit {
  corpId: any;
  email: any;
  fname: any;
  lname: any;
  department: any;
  detailsAddFirstAidForm: FormGroup;
  keySize = 256;
  iterations = 1000;
  searchResult = [];
  userDetails: any;
  floorId: any;
  isDisabled: boolean;

  constructor( private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    private router: Router,
    private _Activatedroute: ActivatedRoute,
    public  translate:  TranslateService, ) { }

  ngOnInit(): void {
    this.floorId = this._Activatedroute.snapshot.paramMap.get('workstation_id');
    this.detailsAddFirstAidForm = this.formBuilder.group(
      {
        empName: ['', Validators.required],
      }
    );
  }

  getSearchResult = (val) => {
    let queryData = val.target.value;
    const inp = String.fromCharCode(val.keyCode);
    if (/[a-zA-Z0-9-_ @.]/.test(inp)) {
      queryData = val.target.value + val.key;
    }
    if (queryData.length >= 4 && queryData.length <= 15) {
      this.communicationService.getSearchResult(queryData).subscribe(res => {
        if (res.employeeList.users.length === 0 && queryData.length <= 4) {
          this.toastr.error('Search User not found.');
        }
        this.searchResult = res.employeeList.users;
      }, error => {
        this.searchResult.length = 0;
        if(queryData.length <= 4){
          this.toastr.error('Search User not found.');
        }
      });
    } else {
      this.searchResult.length = 0;
    }
  }

  /*decrypt(transitmessage, pass) {
    var hexResult = this.base64ToHex(transitmessage)
    var salt = CryptoJS.enc.Hex.parse(hexResult.substr(0, 64));
    var iv = CryptoJS.enc.Hex.parse(hexResult.substr(64, 32));
    var encrypted = this.hexToBase64(hexResult.substring(96));

    var key = CryptoJS.PBKDF2(pass, salt, {
      keySize: this.keySize / 32,
      iterations: this.iterations
    });

    var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC

    })

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  hexToBase64(str) {
    return btoa(String.fromCharCode.apply(null,
      str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" "))
    );
  }

  base64ToHex(str) {
    for (var i = 0, bin = atob(str.replace(/[ \r\n]+$/, "")), hex = []; i < bin.length; ++i) {
      var tmp = bin.charCodeAt(i).toString(16);
      if (tmp.length === 1) tmp = "0" + tmp;
      hex[hex.length] = tmp;
    }
    return hex.join("");
  }*/

  getSearchUserData(data: any) {
    let myForm = this.detailsAddFirstAidForm.value.empName;
    if (typeof (data) === 'object') {
      myForm = data.displayName;
      this.corpId = data.username;
      this.fname = data.firstName;
      this.email = data.email;
      this.lname = data.lastName;
      this.department = data.department;
      this.detailsAddFirstAidForm.controls['empName'].setValue(myForm);
      this.searchResult.length = 0;
    }
  }
 async onSubmit() {
  this.isDisabled = true;
   const request = {
     username: this.corpId,
     email: this.email,
     first_name: this.fname,
     last_name: this.lname,
     department_name: this.department,
     role: null
   };
   this.communicationService.addUser(request).subscribe(res => {
      if (res != null) {
        // tslint:disable-next-line:no-shadowed-variable
        this.communicationService.updateFirstAidResponder(this.floorId, this.corpId).subscribe(res => {
           this.toastr.success(this.translate.instant('toasterSuccessMessage.workstationUpdated'));
           this.isDisabled = false;
           this.router.navigate(['/first-aid']);
        }, error => {
          console.log(error);
          this.toastr.error(error.error.message);
          this.isDisabled = false;
        });
      }
   }, error => {
      console.log(error);
      this.toastr.error(error.error.message);
      this.isDisabled = false;
   });
 }
 onCancel(event:any) {
  this.isDisabled = true;
    this.router.navigate(['/first-aid']);
 }
}


