
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { CommunicationService } from 'src/app/services/communication.service';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  corpId: any;
  email: any;
  fname: any;
  lname: any;
  subDepartment: any;
  detailsAddUserForm: FormGroup;
  keySize = 256;
  iterations = 1000;
  searchResult = [];
  submitted:boolean;

  roles = [];

  constructor(
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    private router: Router,
    public  translate:  TranslateService,
  ) { }

  ngOnInit(): void {
    /*this.getRoles()*/
    this.detailsAddUserForm = this.formBuilder.group(
      {
        role: ['', Validators.required],
        empName: ['', Validators.required],
      }
    );
  }

  onSubmit() {
    const request = {
      username: this.corpId,
      email: this.email,
      first_name: this.fname,
      last_name: this.lname,
      sub_department_name: this.subDepartment,
      role: this.detailsAddUserForm.value.role
    };
    /*this.communicationService.addUser(request).subscribe(res => {
        this.toastr.success(this.translate.instant("toasterSuccessMessage.roleUpdated"));
        this.router.navigate(['/users']);
    }, error => {
      console.log(error);
      this.toastr.error(error.error.message);
    })*/
  }

  getRoles() {
    this.communicationService.getRoles().subscribe(res => {
      this.roles = res;
    }, error => {
      console.log(error);
    });
  }

  get f() {
    return this.detailsAddUserForm.controls;
  }

  getSearchUserData(data: any) {
    let myForm = this.detailsAddUserForm.value.empName;
    if (typeof (data) === 'object') {
      myForm = data.displayName;

      this.corpId = data.corpId;
      this.fname = data.firstName;
      this.email = data.emailId;
      this.lname = data.lastName;
      this.subDepartment = data.department;
      this.detailsAddUserForm.controls['empName'].setValue(myForm);
      this.searchResult.length = 0;
    }
  }

  getSearchResult = (val) => {
    let queryData = val.target.value;
    const inp = String.fromCharCode(val.keyCode);
    if (/[a-zA-Z0-9-_ @.]/.test(inp)) {
      queryData = val.target.value + val.key;
    }
    if (queryData.length >= 4) {
      this.communicationService.getSearchResult(queryData).subscribe(res => {
        const respData = JSON.parse(this.decrypt(res, '@!5haB0tFact#~YenC*y^?1O'));
        this.searchResult = respData;
      }, error => {
        this.searchResult.length = 0;
        console.log('Getting error while searching result');
      });
    } else {
      this.searchResult.length = 0;
    }
  }

  decrypt(transitmessage, pass) {
    const hexResult = this.base64ToHex(transitmessage);
    const salt = CryptoJS.enc.Hex.parse(hexResult.substr(0, 64));
    const iv = CryptoJS.enc.Hex.parse(hexResult.substr(64, 32));
    const encrypted = this.hexToBase64(hexResult.substring(96));

    const key = CryptoJS.PBKDF2(pass, salt, {
      keySize: this.keySize / 32,
      iterations: this.iterations
    });

    const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC

    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  hexToBase64(str) {
    return btoa(String.fromCharCode.apply(null,
      str.replace(/\r|\n/g, '').replace(/([\da-fA-F]{2}) ?/g, '0x$1 ').replace(/ +$/, '').split(' '))
    );
  }

  base64ToHex(str) {
    for (let i = 0, bin = atob(str.replace(/[ \r\n]+$/, '')), hex = []; i < bin.length; ++i) {
      let tmp = bin.charCodeAt(i).toString(16);
      if (tmp.length === 1) { tmp = '0' + tmp; }
      hex[hex.length] = tmp;
    }
    // @ts-ignore
    return hex.join('');
  }

  onCancel(event) {
      this.router.navigate(['/users']);
    }

}
