import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AfterViewInit, Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import {CommunicationService} from 'src/app/services/communication.service';
import {DomSanitizer} from '@angular/platform-browser';
import * as CryptoJS from 'crypto-js';
import {Desk} from 'src/app/model/desk';
import {formatDate} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {take} from 'rxjs/operators';
import {Coordinate} from 'src/app/model/coordinate';


@Component({
  selector: 'app-search-employee-desk',
  templateUrl: './search-employee-desk.component.html',
  styleUrls: ['./search-employee-desk.component.scss']
})
export class SearchEmployeeDeskComponent implements AfterViewInit {

  @ViewChild('canvas', {static: true})
  canvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('tooltip', {static: true})
  tooltip: ElementRef<HTMLCanvasElement>;

  tooltipCtx: CanvasRenderingContext2D;


  detailsRuleConfigurationForm: FormGroup;

  hideTooltip = false;
  keySize = 256;
  iterations = 1000;
  searchResult = [];
  searchResultSeats = [];
  limit = 30;
  startIndex = 0;
  corpId: any = '';
  desk: Desk;
  deskList = [];
  todayDate;
  floorPlanCtx: CanvasRenderingContext2D;
  floorPlanImage: HTMLImageElement;
  bookingList: any[];
  tableDataSource$ = new BehaviorSubject<any[]>([]);
  displayedColumns$ = new BehaviorSubject<string[]>([
    'emp_name',
    'start_date',
    'end_date',
  ]);
  totalUserCount = 0;
  searchFormControl = new FormControl();
  currentPage$ = new BehaviorSubject<number>(1);
  pageSize$ = new BehaviorSubject<number>(10);
  dataOnPage$ = new BehaviorSubject<any[]>([]);
  sortKey$ = new BehaviorSubject<string>('start_date_time');
  sortDirection$ = new BehaviorSubject<string>('asc');
  heroes$: BehaviorSubject<any[]>;
  modalReference: any;
  isDisabled: boolean;
  submitted: boolean = false;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    public _DomSanitizationService: DomSanitizer,
    private toastr: ToastrService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit(): void {
    let currentDate = new Date();
    this.todayDate = {year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate()};

    this.detailsRuleConfigurationForm = this.formBuilder.group(
      {
        empName: [''],
        seatName: [''],
        building_name: [''],
        floor_name: [''],
        wing_name: [''],
        seat_number: [''],
        startDate: [''],
        workstationName: [''],
      }
    );
    this.detailsRuleConfigurationForm.controls.startDate.setValue(this.todayDate);
  }

  get f() {
    return this.detailsRuleConfigurationForm.controls;
  }

  ngAfterViewInit(): void {
    this.floorPlanCtx = this.canvas.nativeElement.getContext('2d');
    this.tooltipCtx = this.tooltip.nativeElement.getContext('2d');
    this.tooltipCtx.fillStyle = "#ffffff";
  }

  getSearchUserData(data: any) {
    let myForm = this.detailsRuleConfigurationForm.value.empName;
    if (typeof (data) === 'object') {
      myForm = data.displayName;
      this.corpId = data.username;
      this.detailsRuleConfigurationForm.controls['empName'].setValue(myForm);

      this.searchResult.length = 0;
    }
    this.detailsRuleConfigurationForm.controls["seatName"].setValue("");
  }

  getSearchSeatsData(data: any) {
    let myForm = this.detailsRuleConfigurationForm.value.seatName;
    if (typeof (data) === 'object') {
      myForm = data.name;
      this.detailsRuleConfigurationForm.controls['seatName'].setValue(myForm);
      this.searchResultSeats.length = 0;
    }
  }

  onSubmit() {
    this.deskList = [];
    const limit = this.limit;
    const index = this.startIndex;
    if (this.detailsRuleConfigurationForm.value.seatName != "") {
      this.corpId = '';
      this.detailsRuleConfigurationForm.controls["empName"].setValue('');
    }
    let startDateVal = formatDate(this.convertDate(this.detailsRuleConfigurationForm.value.startDate), 'yyyy-MM-dd', this.locale);

    const searchObj = {
      seat_id: this.detailsRuleConfigurationForm.value.seatName,
      emp_id: this.corpId !== '' ? this.corpId : '',
      building_id: '',
      start_date_time: startDateVal,
    }
    this.communicationService.findDesk(limit, index, searchObj).subscribe(res => {
      this.desk = res[0];
      for (let obj of res) {
        this.deskList.push(obj);
      }
      if (this.deskList) {
        const image = new Image();
        let objectURL = 'data:image/png;base64,' + this.deskList[0].image_path;
        image.src = objectURL;
        this.floorPlanImage = image;

        image.onload = (() => this.fitToCanvas(image));
      } else {
        this.toastr.error('Unable to find desk');
      }

    }, error => {
      this.searchResultSeats.length = 0;
      this.toastr.error('Unable to find desk');
    });
  }

  private fitToCanvas(image) {
    this.floorPlanCtx.canvas.width = image.width;
    this.floorPlanCtx.canvas.height = image.height;
    this.floorPlanCtx.drawImage(image, 0, 0);
    this.deskList.forEach(desk => {
      this.highlightSelected(desk);
    })
  }

  convertDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  }

  highlightSelected(desk) {
    if (desk.type == 0) {
      this.floorPlanCtx.fillStyle = '#A8ADB3';
      this.floorPlanCtx.fillRect(desk.x, desk.y, desk.w, desk.h);
      this.floorPlanCtx.strokeStyle = '#d4d6d9';
      this.floorPlanCtx.strokeRect(desk.x, desk.y, desk.w, desk.h);
      this.f.floor_name.setValue(desk.floor_name);
      this.f.wing_name.setValue(desk.wing_name);
      this.f.seat_number.setValue(desk.name);
      this.f.building_name.setValue(desk.building_name);
    } else if (desk.type === 1) {
      this.floorPlanCtx.fillStyle = '#A8ADB3';
      this.plotPolygon(this.desk);
      this.f.floor_name.setValue(desk.floor_name);
      this.f.wing_name.setValue(desk.wing_name);
      this.f.seat_number.setValue(desk.name);
      this.f.building_name.setValue(desk.building_name);
    }
  }

  private plotPolygon(element: Desk) {
    this.floorPlanCtx.beginPath();
    this.floorPlanCtx.moveTo(element.sides[0], element.sides[1]);
    this.floorPlanCtx.lineTo(element.sides[2], element.sides[3]);
    this.floorPlanCtx.lineTo(element.sides[4], element.sides[5]);
    this.floorPlanCtx.lineTo(element.sides[6], element.sides[7]);
    this.floorPlanCtx.lineTo(element.sides[8], element.sides[9]);
    this.floorPlanCtx.lineTo(element.sides[10], element.sides[11]);
    this.floorPlanCtx.closePath();
    this.floorPlanCtx.fill();
  }

  getSearchResultSeats = (val) => {
    const queryData = val.target.value;
    if (queryData.length >= 4) {
      this.communicationService.getSeatsFilter(this.limit, this.startIndex, queryData).subscribe(res => {
        this.searchResultSeats = res;
      }, error => {
        this.searchResultSeats.length = 0;
        console.log('Getting error while searching result for seats');
      });
    } else {
      this.searchResultSeats.length = 0;
    }
  }

  getSearchResult = (val) => {
    let queryData = val.target.value;
    const inp = String.fromCharCode(val.keyCode);
    if (/[a-zA-Z0-9-_ @.]/.test(inp)) {
      queryData = val.target.value + val.key;
    }
    if (queryData.length >= 4 && queryData.length <= 15) {
      this.communicationService.getSearchResult(queryData).subscribe(res => {
        if (res.employeeList.users.length === 0 && queryData.length <= 4) {
          this.toastr.error('Search User not found.');
        }
        this.searchResult = res.employeeList.users;
      }, error => {
        this.searchResult.length = 0;
        if (queryData.length <= 4) {
          this.toastr.error('Search User not found.');
        }
      });
    } else {
      this.searchResult.length = 0;
    }
  }
  // old search user code
  /*decrypt(transitmessage, pass) {

    var hexResult = this.base64ToHex(transitmessage)

    var salt = CryptoJS.enc.Hex.parse(hexResult.substr(0, 64));
    var iv = CryptoJS.enc.Hex.parse(hexResult.substr(64, 32));
    var encrypted = this.hexToBase64(hexResult.substring(96));

    var key = CryptoJS.PBKDF2(pass, salt, {
      keySize: this.keySize / 32,
      iterations: this.iterations
    });

    var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC

    })

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  hexToBase64(str) {
    return btoa(String.fromCharCode.apply(null,
      str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" "))
    );
  }


  base64ToHex(str) {
    for (var i = 0, bin = atob(str.replace(/[ \r\n]+$/, "")), hex = []; i < bin.length; ++i) {
      var tmp = bin.charCodeAt(i).toString(16);
      if (tmp.length === 1) tmp = "0" + tmp;
      hex[hex.length] = tmp;
    }
    return hex.join("");
  }*/
  // onMouseMove(event) {
  //   this.hideTooltip=false;
  //   const rB = this.floorPlanCtx.canvas.getBoundingClientRect();
  //   const x = event.pageX - (rB.left + window.scrollX);
  //   const y = event.pageY - (rB.top + window.scrollY);
  //   // Put your mousemove stuff here
  //   let prevx: number;
  //   let prevy: number;
  //   for (let i = 0; i < this.deskList.length; i++) {
  //     let section = this.deskList[i];
  //     if (section.type == 0) {
  //       if (y > section.y && y < section.y + section.h
  //         && x > section.x && x < section.x + section.w) {
  //         let lineheight = 15;
  //         this.canvas.nativeElement.style.cursor = "pointer";
  //         this.tooltip.nativeElement.style.left = (section.x) + "px";
  //         this.tooltip.nativeElement.style.top = (section.y) + "px";
  //         this.tooltipCtx.clearRect(0, 0, this.tooltip.nativeElement.width, this.tooltip.nativeElement.height);
  //         this.tooltipCtx.fillStyle="#FFFFFF";
  //          if (section.selected) {
  //           this.hideTooltip = true;
  //           this.tooltipCtx.fillText(`\nWorkstation Name : ${section.name}`, 0, lineheight);
  //           lineheight = lineheight + 20

  //           this.tooltipCtx.fillText(`\nStart Time : ${section.start_date_time}`, 0, lineheight);
  //           lineheight = lineheight + 20

  //           this.tooltipCtx.fillText(`\nEnd Time : ${section.end_date_time}`, 0, lineheight);
  //           lineheight = lineheight + 20

  //           this.tooltipCtx.textAlign = "left"
  //           this.tooltipCtx.font = "bold  15px Arial";

  //         } else {
  //           this.hideTooltip = false;
  //         }
  //       }
  //     }
  //   }
  // }
  setBookingsPopup(element, content) {
    this.f.workstationName.setValue(element.name);
    element.bookings.forEach(booking => {
      this.bookingList.push(booking);
    })
    this.sortBookings();
    this.modalReference = this.modalService.open(content);
  }

  onClick(event, content) {
    this.bookingList = [];
    const rB = this.floorPlanCtx.canvas.getBoundingClientRect();
    const x = event.pageX - (rB.left + window.scrollX);
    const y = event.pageY - (rB.top + window.scrollY);
    this.deskList.map((element) => {
      if (element.selected) {
        if (y > element.y && y < element.y + element.h
          && x > element.x && x < element.x + element.w) {
          this.setBookingsPopup(element, content);
        } else if (element.h === null || element.y === null
          || element.x === null || element.w === null) {
          const odd: any = [];
          const even: any = [];
          this.setArrays(element, even, odd);
          const {xMin, xMax, yMin, yMax} = this.getMinMax(even, odd);
          if (this.isPointInsidePoly(x, y, xMin, xMax, yMin, yMax)) {
            this.setBookingsPopup(element, content);
          }
        }
      }
    })
  }

  sortBookings() {
    const bookingData = [];
    bookingData.push(this.bookingList);
    this.totalUserCount = 10;
    this.heroes$ = new BehaviorSubject(bookingData);
    combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
      .subscribe(([allSources, currentPage, pageSize]) => {
        const startingIndex = (currentPage - 1) * pageSize;
        const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
        this.dataOnPage$.next(onPage);
      });

    this.heroes$.pipe(take(1)).subscribe(heroData => {
      this.tableDataSource$.next(Object.values(heroData));
    });
    combineLatest(this.heroes$, this.sortKey$, this.sortDirection$)
      .subscribe(([changedHeroData, sortKey, sortDirection]) => {
        const heroesArray = Object.values(changedHeroData);
        const sortedHeroes = heroesArray.sort((a, b) => {
          if (a[sortKey] > b[sortKey]) {
            return sortDirection === 'asc' ? 1 : -1;
          }
          if (a[sortKey] < b[sortKey]) {
            return sortDirection === 'asc' ? -1 : 1;
          }
          return 0;
        });

        this.tableDataSource$.next(sortedHeroes);
      });

    combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
      .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
        const heroesArray = Object.values(changedHeroData);
        let filteredHeroes: any[];

        if (!searchTerm) {
          filteredHeroes = heroesArray;
        } else {
          const filteredResults = heroesArray.filter(hero => {
            return Object.values(hero)
              .reduce((prev, curr) => {
                return prev || curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
              }, false);
          });
          filteredHeroes = filteredResults;
        }

        const sortedHeroes = filteredHeroes.sort((a, b) => {
          if (a[sortKey] > b[sortKey]) {
            return sortDirection === 'asc' ? 1 : -1;
          }
          if (a[sortKey] < b[sortKey]) {
            return sortDirection === 'asc' ? -1 : 1;
          }
          return 0;
        });
        this.tableDataSource$.next(sortedHeroes);
      });
  }

  private setArrays(element: Coordinate, even: any, odd: any) {
    let i = 0;
    let j = 0;
    element.sides.forEach((entry, index) => {
      if (index % 2 === 0) {
        even[i] = entry;
        i++;
      } else {
        odd[j] = entry;
        j++;
      }
    });
  }

  isPointInsidePoly(x, y, xmin, xmax, ymin, ymax) {
    if (x < xmin || x > xmax || y < ymin || y > ymax) {
      return false;
    } else {
      return true;
    }
  }

  private getMinMax(even: any, odd: any) {
    const xMin = even.reduce((a, b) => Math.min(a, b));
    const xMax = even.reduce((a, b) => Math.max(a, b));
    const yMin = odd.reduce((a, b) => Math.min(a, b));
    const yMax = odd.reduce((a, b) => Math.max(a, b));
    return {xMin, xMax, yMin, yMax};
  }
}
