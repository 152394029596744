import { Component, OnInit, LOCALE_ID, Inject, ViewChild, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { CommunicationService } from 'src/app/services/communication.service';
import { ViewOnMapComponent } from 'src/app/pages/view-on-map/view-on-map.component';
import * as XLSX from 'xlsx';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, ModalDismissReasons, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../../environments/environment';
import {SuperUserFilterRequest} from '../../model/superUserFilter';
import {SuperUserDashboardService} from '../../services/super-user-dashboard.service';
import { headings } from '@sdc-wob-type-3/group-ui';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  departmentIndex: number;
  buildingCount: any;
  allFloors: any;
  floorCount: any;
  allFloorCount: any;
  allWings: any;
  allWingsCount: any;
  allData: any[];
  departmentName: any;
  buildings: any[];
  floors: any[];
  wings: any[];
  selectedWing: string;
  selectedFloor: string;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private communicationService: CommunicationService,
    public  translate:  TranslateService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private superUserService: SuperUserDashboardService) {
    let today = new Date();
    this.start_Date = {'year':today.getFullYear(),'month': today.getMonth()+1,'day' : today.getUTCDate()};
    this.end_Date = {'year':today.getFullYear(),'month': today.getMonth()+1,day : today.getUTCDate()};
    this.currentUserRole = this.communicationService.currentUser.role;
  }
  allDepartments: any;
  countries:any= [];
  modalReference: NgbModalRef;
  currentUserRole: string;
  cardsList: any;
  private dashboardDetails: any;
  filterRequest: SuperUserFilterRequest ;

  detailsSuperDashboardForm: FormGroup;
  detailsSuperDashboardFormRenew: FormGroup;
  heroes$;
  seat_number;
  selectedDepartmentObj:any;
  emp_number;
  superlatives$ = new BehaviorSubject<{ [superlativeName: string]: string }>({});
  tableDataSource$ = new BehaviorSubject<any[]>([]);
  displayedColumns$ = new BehaviorSubject<string[]>([
    'srno',
    'country_name',
    'employeeName',
    'organizationName',
    'departmentName',
    'cityName',
    'buildingName',
    'floorName',
    'wingName',
    'seatName',
    'status',
    'comments',
    'startDate',
    'endDate'
  ]);

  start_Date: any;
  end_Date: any;
  cities = [];
  currentPage$ = new BehaviorSubject<number>(1);
  pageSize$ = new BehaviorSubject<number>(10);
  dataOnPage$ = new BehaviorSubject<any[]>([]);
  searchFormControl = new FormControl();
  sortKey$ = new BehaviorSubject<string>('emp_id');
  sortDirection$ = new BehaviorSubject<string>('asc');
  totalUserCount = 0;
  limit = 10;
  startIndex = 0;
  status = [];
  moreDetailsData = null;
  closeResult: string;
  todayDate;
  downloadAll = false;
  isSearch = false;
  private departments: any;
  selectedDepartment: any = 'all';
  selectedCountry = 'all';
  selectedBuilding = 'all';
  fileName = 'report.xlsx';
  selectedTab:string ='master';//master,booking
  allBuildings: any;


  ngOnInit(): void {
    localStorage.removeItem('qrId');
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.dashboardDetails = this.getDashBoardDetails();
    this.departmentName = currentUser.department ;
    this.findFilterData();
    this.detailsSuperDashboardForm = this.formBuilder.group(
      {
        startDate: new FormControl(),
        endDate: new FormControl(),
        status: [''],
        city: [''],
        department: [''],
        downloadAll: ['']
      }
    );

    const currentDate = new Date();
    const objCurrentDate = { year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate() };
    this.todayDate = objCurrentDate;

    this.detailsSuperDashboardForm.controls.startDate.setValue(this.todayDate);
    this.detailsSuperDashboardForm.controls.endDate.setValue(this.todayDate);
  }

  findFilterData() {

    this.filterRequest = {
      start_date_time: `${this.start_Date['year']}-${this.start_Date['month']>=10 ? this.start_Date['month'] : '0'+this.start_Date['month']}-${this.start_Date['day']>=10?this.start_Date['day']:'0'+this.start_Date['day']}T00:00:00`,
      end_date_time: `${this.end_Date['year']}-${this.end_Date['month']>=10? this.end_Date['month']:'0'+this.end_Date['month']}-${this.end_Date['day']>=10 ? this.end_Date['day'] : '0'+ this.end_Date['day']}T23:59:59`
    };

    this.superUserService.findFilterData(this.filterRequest).subscribe(res => {
      let filteredDepartments = res['departments'];
      this.allDepartments = filteredDepartments.filter(department => department.departmentName === this.departmentName);
      this.selectedDepartmentChange();
      this.findSelectedDepartment();
    });
  }

  getDashBoardDetails() {
    this.communicationService.getDashboardDetails().subscribe(res => {
      this.dashboardDetails = res;
        this.loadCardsDetails();
    }, error => {
      console.log(error);
    });
  }

  loadCardsDetails() {
    this.cardsList = [

      { title: this.translate.instant('superUserDashBoard.card.department'), value: this.dashboardDetails.department_count, bottomValue: '', class: 'fas fa-chart-bar', bottomClass: '', textClass: 'text-success' , icon: 'network-24' },
      { title: this.translate.instant('superUserDashBoard.card.subdepartment'), value: this.dashboardDetails.sub_department_count, bottomValue: '', class: 'fas fa-chart-pie', bottomClass: '', textClass: 'text-danger' , icon: 'alarm-24'},
      { title: this.translate.instant('superUserDashBoard.card.building'), value: this.dashboardDetails.building_count, bottomValue: '', class: 'fas fa-percent', bottomClass: '', textClass: 'text-success' , icon: 'diagram-curve-24'},
      { title: this.translate.instant('superUserDashBoard.card.floor'), value: this.dashboardDetails.floors_count, bottomValue: '', class: 'fas fa-chart-bar', bottomClass: '', textClass: 'text-warning' , icon: 'list-view-24'},
      { title: this.translate.instant('superUserDashBoard.card.wing'), value: this.dashboardDetails.wing_count, bottomValue: '', class: 'fas fa-users', bottomClass: '', textClass: 'text-warning' , icon: 'calendar-24'},
      { title: this.translate.instant('superUserDashBoard.card.totalWorkstation'), value: this.dashboardDetails.total_seats, bottomValue: '', class: 'fas fa-chart-bar', bottomClass: '', textClass: 'text-warning' , icon: 'list-view-24'},
    ];
  }

  onStartDateChange(event) {
    this.detailsSuperDashboardForm.controls.endDate.setValue(this.detailsSuperDashboardForm.value.startDate);
  }

  selectDownloadAll(event) {
    if (event.target.checked) {
      this.downloadAll = true;
    } else {
      this.downloadAll = false;
    }
  }


  exportexcel() {
    this.prepareExcel(this.allData);
  }

  prepareExcel(data: any) {
    const arr = [];
    const Heading = [
      ['DEPARTMENT NAME' , 'COUNTRY','CITY','ORGANIZATION NAME','FLOOR NAME',
      'WING NAME','BUILDING NAME','SEAT NAME' , 'STATUS'],
    ];
    if(this.selectedTab == 'booking'){
      const cityIndex = Heading[0].indexOf('CITY');
      Heading[0].splice(cityIndex + 1, 0, 'EMPLOYEE-NAME');
      Heading[0].push('START DATE', 'END DATE', 'COMMENTS');
    }
    for (const obj of data) {
      let wsDetails;

      if(this.selectedTab == 'booking'){
        wsDetails = {
          'department_name': obj.departmentName,
          'country_name': obj.countryName,
          'city': obj.cityName,
          'employee': obj.employeeName,
          'organization_name': obj.organizationName,
          'floor_name': obj.floorName,
          'wing_name': obj.wingName,
          'building_name': obj.buildingName,
          'seat_name': obj.seatName,
          'status': obj.status,
          'start_date': obj.startDate,
          'end_date': obj.endDate,
          'comments': obj.comments,
        };
      } else {
        wsDetails = {
          'department_name': obj.departmentName,
          'country_name': obj.countryName,
          'city': obj.cityName,
          'organization_name': obj.organizationName,
          'floor_name': obj.floorName,
          'wing_name': obj.wingName,
          'building_name': obj.buildingName,
          'seat_name': obj.seatName,
          'status': obj.status,
        };
      }

      arr.push(wsDetails);
    }
    // Had to create a new workbook and then add the header
    const wb = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, Heading);
    // Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(ws, arr, { origin: 'A2', skipHeader: true });

    XLSX.utils.book_append_sheet(wb, ws, 'Report');

    XLSX.writeFile(wb, this.fileName);
    this.toastr.success('File Downloaded succesfully');
  }



  convertDate(date) {
    if (date != null || date != '') {
      const converdate = new Date(date.year, date.month - 1, date.day);
      return converdate;
    }
  }

  selectedDepartmentChange() {
    this.countries = [];
    this.buildings = [];
    this.floors = [];
    this.wings = [];
    this.selectedCountry='all';
    this.selectedBuilding='all';
    this.selectedFloor = 'all';
    this.selectedWing='all';
    this.createRawData();
  }

  findSelectedDepartment() {
    for(let i=0;i<this.allDepartments.length;i++){
      if(this.allDepartments[i]['departmentName']==this.departmentName){
        this.selectedDepartmentObj = this.allDepartments[i];
        return this.allDepartments[i];
      }
    }
    return null;
  }

  selectedCountryChange(){
    this.selectedBuilding ='all';
    this.selectedFloor ='all';
    this.selectedWing ='all';
    this.createRawData();
  }

  selectedBuildingChange(){
    this.selectedFloor = 'all';
    this.selectedWing = 'all';
    this.createRawData();
  }

  selectedFloorChange(){
    this.selectedWing = 'all';
    this.createRawData();
  }

  selectedWingChange(){
    this.createRawData();
  }

  count = 0;
  createRawData(){
    this.count = 0;
    let arr = this.selectedDepartment=='all'?this.allDepartments:[this.findSelectedDepartment()];
    this.allData =[];
    this.allBuildings = [];
    this.allFloors  = [];
    this.allWings = [];
    for( this.departmentIndex=0;this.departmentIndex<arr.length;this.departmentIndex++){
      let department =  arr[this.departmentIndex];
      if(department['buildings'] && department['buildings'].length>0){
        for( let buildingIndex=0; buildingIndex< (department['buildings']).length;buildingIndex++){
          let  building = department['buildings'][buildingIndex];
              this.setBuildingData(building,this.allData,department);
        }
      }
    }

    this.totalUserCount = this.allData.length/10;
      this.heroes$ = new BehaviorSubject(this.allData);
      combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
        .subscribe(([allSources, currentPage, pageSize]) => {
          const startingIndex = (currentPage - 1) * pageSize;
          const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
          this.dataOnPage$.next(onPage);
        });

      this.heroes$.pipe(take(1)).subscribe(heroData => {
        this.tableDataSource$.next(Object.values(heroData));
      });
      combineLatest(this.heroes$, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          const sortedHeroes = heroesArray.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });

          this.tableDataSource$.next(sortedHeroes);
        });

      combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          let filteredHeroes: any[];

          if (!searchTerm) {
            filteredHeroes = heroesArray;
          } else {
            const filteredResults = heroesArray.filter(hero => {
              return Object.values(hero)
                .reduce((prev, curr) => {
                  return prev || curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
                }, false);
            });
            filteredHeroes = filteredResults;
          }

          const sortedHeroes = filteredHeroes.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });
          this.tableDataSource$.next(sortedHeroes);
        });
}

setBuildingData(building, allData, department) {
  if (!this.countries.includes(building['country']))
    this.countries.push(building['country']);

  if (this.selectedCountry == 'all' || this.selectedCountry == building['country']) {
    this.allBuildings.push(building);
    this.buildingCount = this.allBuildings.length;
    if (this.selectedBuilding == 'all' || this.selectedBuilding == building['buildingName']) {
      if (building['floors'] && building['floors'].length > 0) {
        for (let floorIndex = 0; floorIndex < (building['floors']).length; floorIndex++) {
          let floor = building['floors'][floorIndex];
          this.allFloors.push(floor);
          this.allFloorCount = this.allFloors.length;
          if (this.selectedFloor == 'all' || this.selectedFloor == floor['floorName']) {
            this.setFloorData(floor, allData, department, building);
          }
        }
      }
    }
  }
}

setFloorData(floor: any, allData: any, department: any, building: any) {
  if (this.selectedFloor == 'all' || this.selectedFloor == floor['floorName']) {
    if (floor['wings'] && floor['wings'].length > 0) {
      for (let wingIndex = 0; wingIndex < (floor['wings']).length; wingIndex++) {
        let wing = floor['wings'][wingIndex];
        this.allWings.push(wing);
        this.allWingsCount = this.allWings.length;
        if (this.selectedWing == 'all' || this.selectedWing == wing['wingName']) {
          this.setWingData(wing, allData, department, building, floor);
        }
      }
    }
  }
}

setWingData(wing: any, allData: any, department: any, building: any, floor: any) {
  if (this.selectedFloor == 'all' || this.selectedFloor == floor['floorName']) {
    let seats = this.selectedTab == 'master' ? wing['seats'] : wing['bookedSeats'];
    if (seats && seats.length > 0) {
      for (let index = 0; index < (seats).length; index++) {
        let seat = seats[index];
        let obj = {
          'departmentName': department['departmentName'],
          'employeeName': seat.employeeName,
          'organizationName': department?.organization?.organizationName,
          'cityName': building['cityName'], srno: ++this.count,
          'countryName': building['country'],
          'buildingName': building['buildingName'],
          'floorName': floor['floorName'],
          'wingName': wing['wingName'],
          'seatName': seat['seatName'],
          'comments':seat.comments,
          'status': this.getStatusText(seat.status),
          'startDate': seat['startDate'],
          'endDate': seat['endDate']
        }
        allData.push(obj);
      }
    }
  }
}

statusMap = {
  0: 'OPEN',
  1: 'APPROVED',
  2: 'REJECTED',
  3: 'COMPLETED',
  4: 'CANCELLED',
  5: 'CANNOT_BOOK'
}

getStatusText(code: number): string {
  return this.statusMap[code];
}

}
