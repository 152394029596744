import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from 'src/app/services/communication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-floor',
  templateUrl: './view-floor.component.html',
  styleUrls: ['./view-floor.component.scss']
})
export class ViewFloorComponent implements OnInit {

  heroes$;
  superlatives$ = new BehaviorSubject<{ [superlativeName: string]: string }>({});
  tableDataSource$ = new BehaviorSubject<any[]>([]);
  displayedColumns$ = new BehaviorSubject<string[]>([
    'country_name',
    'city_name',
    'building_name',
    'floor_name',
    'status',
    'action'
  ]);
  currentPage$ = new BehaviorSubject<number>(1);
  pageSize$ = new BehaviorSubject<number>(10);
  dataOnPage$ = new BehaviorSubject<any[]>([]);
  searchFormControl = new FormControl();
  sortKey$ = new BehaviorSubject<string>('floor_name');
  sortDirection$ = new BehaviorSubject<string>('asc');
  totalUserCount = 0;
  limit = 10000;
  startIndex = 0;
   constructor(
      private communicationService: CommunicationService,
      private toastr: ToastrService,
      private router: Router,
      public  translate:  TranslateService) { }

  ngOnInit(): void {
    this.getAllFloors(this.limit, this.startIndex);
    this.searchFormControl.setValue('');
  }

  adjustSort(key: string) {
    if (this.sortKey$.value === key) {
      if (this.sortDirection$.value === 'asc') {
        this.sortDirection$.next('desc');
      } else {
        this.sortDirection$.next('asc');
      }
      return;
    }

    this.sortKey$.next(key);
    this.sortDirection$.next('asc');
  }
  updateFloor(floor_id: string) {
    this.router.navigate(['add-edit-floor', floor_id]);
  }

  getAllFloors(limit: number, index: number) {
    const userData = [];
    this.communicationService.getFloorsFilter(limit, index).subscribe(res => {
      for (const obj of res) {
        userData.push(obj);
      }

      this.totalUserCount = userData.length;
      this.heroes$ = new BehaviorSubject(userData);
      combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
        .subscribe(([allSources, currentPage, pageSize]) => {
          const startingIndex = (currentPage - 1) * pageSize;
          const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
          this.dataOnPage$.next(onPage);
        });

      this.heroes$.pipe(take(1)).subscribe(heroData => {
        this.tableDataSource$.next(Object.values(heroData));
      });

      combineLatest(this.heroes$, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);

          const sortedHeroes = heroesArray.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });

          this.tableDataSource$.next(sortedHeroes);
        });

      combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          let filteredHeroes: any[];

          if (!searchTerm) {
            filteredHeroes = heroesArray;
            this.totalUserCount = userData.length;
          } else {
            const filteredResults = heroesArray.filter(hero => {
              return Object.values(hero)
                .reduce((prev, curr) => {
                  if (curr != null) {
                    curr = curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
                  }
                  return prev || curr;
                }, false);
            });
            filteredHeroes = filteredResults;
            if(filteredHeroes.length >= 0){
              this.totalUserCount = filteredHeroes.length;
            }
          }

          const sortedHeroes = filteredHeroes.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });

          this.tableDataSource$.next(sortedHeroes);
        });
    }, error => {
      console.log(error);
    });
  }


  activateFloor(floorId) {
    this.communicationService.activateFloors(floorId).subscribe(() => {
      this.getAllFloors(this.limit, this.startIndex);
      this.toastr.success(this.translate.instant('toasterSuccessMessage.floorActivated'));    }, error => {
      console.log(error);
      this.toastr.error('Unable to activate floor');
    });
  }

  disableFloor(floorId) {
    this.communicationService.disableFloors(floorId).subscribe(() => {
      this.getAllFloors(this.limit, this.startIndex);
      this.toastr.success(this.translate.instant('toasterSuccessMessage.floorDisabled'));
    }, error => {
      console.error('error', error);
      this.toastr.error('Unable to disable floor');
    });
  }

}
