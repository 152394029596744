import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { CommunicationService } from 'src/app/services/communication.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'audit-logs',
  templateUrl: './audit-logs.component.html',
  styleUrls: ['./audit-logs.component.scss']
})
export class AuditLogsComponent implements OnInit {
  heroes$;
  superlatives$ = new BehaviorSubject<{ [superlativeName: string]: string }>({});
  tableDataSource$ = new BehaviorSubject<any[]>([]);
  displayedColumns$ = new BehaviorSubject<string[]>([
    'audit_log_id',
    'category',
    'message_level',
    'summary',
    'created_on',
    'owner_name',
    'status',
    'details'
  ]);
  currentPage$ = new BehaviorSubject<number>(1);
  pageSize$ = new BehaviorSubject<number>(10);
  dataOnPage$ = new BehaviorSubject<any[]>([]);
  searchFormControl = new FormControl();
  sortKey$ = new BehaviorSubject<string>('audit_log_id');
  sortDirection$ = new BehaviorSubject<string>('desc');
  moreDetailsData = null;
  closeResult: string;
  totalLogsCount = 0;
  startIndex = 0;
  constructor(
    private communicationService: CommunicationService, private modalService: NgbModal, ) { }

  ngOnInit() {
    this.getAllAuditLogs(this.pageSize$.value, this.startIndex);
    this.searchFormControl.setValue('');
  }

  adjustSort(key: string) {
    if (this.sortKey$.value === key) {
      if (this.sortDirection$.value === 'asc') {
        this.sortDirection$.next('desc');
      } else {
        this.sortDirection$.next('asc');
      }
      return;
    }

    this.sortKey$.next(key);
    this.sortDirection$.next('asc');
  }

  handlePageChange(event): void {
    this.startIndex = event - 1;
    this.getAllAuditLogs(this.pageSize$.value, this.startIndex);
  }

  getAllAuditLogs(size: number, index: number) {
    this.communicationService.getAllAuditLogs(size, index).subscribe(res => {
      this.totalLogsCount = res.totalPageCount;
      this.heroes$ = new BehaviorSubject(res.auditLogList);
      combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
        .subscribe(([allSources, currentPage, pageSize]) => {
          const startingIndex = (currentPage - 1) * pageSize;
          const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
          this.dataOnPage$.next(onPage);
        });

      this.heroes$.pipe(take(1)).subscribe(heroData => {
        this.tableDataSource$.next(Object.values(heroData));
      });
      combineLatest(this.heroes$, this.sortKey$, this.sortDirection$)
      .subscribe(([changedHeroData,  sortKey, sortDirection]) => {
        const heroesArray = Object.values(changedHeroData);
        const sortedHeroes = heroesArray.sort((a, b) => {
          if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
          if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
          return 0;
        });

        this.tableDataSource$.next(sortedHeroes);
      });

      combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          let filteredHeroes: any[];

          if (!searchTerm) {
            filteredHeroes = heroesArray;
          } else {
            const filteredResults = heroesArray.filter(hero => {
              return Object.values(hero)
                .reduce((prev, curr) => {
                  return prev || curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
                }, false);
            });
            filteredHeroes = filteredResults;
          }

          const sortedHeroes = filteredHeroes.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });

          this.tableDataSource$.next(sortedHeroes);
        });
    }, error => {
      console.log(error);
    });
  }
  getMoreDetails(id: any, content) {
    this.moreDetailsData = null;
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.communicationService.getAuditLogDetails(id).subscribe(res => {
      this.moreDetailsData = res;
     // this.getAllAuditLogs(this.pageSize$.value,this.startIndex);
    }, error => {
      console.error('AuditLogsComponent : Getting error while getting more details');
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
