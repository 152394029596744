import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunicationService } from 'src/app/services/communication.service';
import { ConfirmationDialogService } from 'src/app/pages/confirmation-dialog/confirmation-dialog.service';
import {  Component,  OnInit, LOCALE_ID, Inject } from '@angular/core';
import { formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import * as CryptoJS from 'crypto-js';
import { User } from 'src/app/model/user';
import {TranslateService} from '@ngx-translate/core';
import {ValidationService} from 'src/app/services/validation.service';
import * as QRCode from 'qrcode';

@Component({
  selector: 'app-create-qr-code',
  templateUrl: './create-qr-code.component.html',
  styleUrls: ['./create-qr-code.component.css']
})
export class CreateQrCodeComponent implements OnInit {
  countries: any[];
  cities: any[];
  buildings: any[];
  wings: any[];
  floors: any[];
  seats:any;
  generateQrCode:any = false;
  detailsCreateQRCodeForm: FormGroup;
  qrCodeUrl: any;
  qrUrl: any;
  submitted = false;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private confirmationDialogService: ConfirmationDialogService,
    private validationService: ValidationService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.qrUrl = localStorage.getItem('qrUrl');
    this.getCountries();
    this.detailsCreateQRCodeForm = this.formBuilder.group({
      city: ['', Validators.required],
      country: ['', Validators.required],
      floor: ['', Validators.required],
      wing: ['', Validators.required],
      building: ['', Validators.required],
      seat_id: ['', Validators.required]
    });
  }

  get f() {
    return this.detailsCreateQRCodeForm.controls;
  }

  onFloorChange(value) {
    this.getWings(value.target.value);
  }

  onCityChange(value) {
    this.getBuildings(value.target.value);
  }

  onCountryChange(value) {
    this.getCitiesByCountry(value.target.value);
  }

  onBuildingChange(value) {
    this.f.floor.setValue('');
    this.getFloors(value.target.value);
  }

  onWingChange(value){
   this.getSeatsList(this.detailsCreateQRCodeForm.get('floor').value,value.target.value);
  }

  getCountries() {
    this.communicationService.getCountries().subscribe(res => {
      this.countries = [];
      for (const country of res) {
        if (country.status === '0') {
          this.countries.push(country);
        }
      }
      if (this.countries.length > 0) {
        this.f.country.setValue(this.countries[0].country_id);
        this.getCitiesByCountry(this.countries[0].country_id);
      } else {
        this.countries = [];
        this.f.country.setValue('');
        this.cities = [];
        this.f.city.setValue('');
        this.buildings = [];
        this.f.building.setValue('');
        this.floors = [];
        this.f.floor.setValue('');
        this.wings = [];
        this.f.wing.setValue('');
      }
    }, error => {
      if (error.error.message) {
        this.toastr.error(error.error.message);
      }
    });
  }

  getCitiesByCountry(country_id: string) {
    this.communicationService.getCitiesByCountry(country_id).subscribe(res => {
      if (res.length > 0) {
        this.cities = [];
        for (const city of res) {
          if (city.status === '0') {
            this.cities.push(city);
          }
        }
        if (this.cities.length > 0) {
          for (const city of this.cities) {
            if (city.city_name == 'Sydney') {
              this.f.city.setValue(city.city_id);
              this.getBuildings(city.city_id);
              return 0 ;
            }
          }
          this.f.city.setValue(this.cities[0].city_id);
          this.getBuildings(this.cities[0].city_id);
        } else {
          this.cities = [];
          this.f.city.setValue('');
          this.buildings = [];
          this.f.building.setValue('');
          this.floors = [];
          this.f.floor.setValue('');
          this.wings = [];
          this.f.wing.setValue('');
        }
      } else {
        this.cities = [];
        this.f.city.setValue('');
        this.buildings = [];
        this.f.building.setValue('');
        this.floors = [];
        this.f.floor.setValue('');
        this.wings = [];
        this.f.wing.setValue('');
      }
    }, error => {
      console.error(error);
    });
  }

  preQrCodeButton() {
    const seatId = this.detailsCreateQRCodeForm.get('seat_id').value;
    const url = this.qrUrl + `?qrId=${seatId}`;
    this.generateQrCode = true;
    this.generateQRCode(url);
  }

  async generateQRCode(text: string) {
    try {
      this.qrCodeUrl = await QRCode.toDataURL(text);
    } catch (err) {

      console.error(err);
    }
  }

  async downloadQRCode() {
    const seatId = this.detailsCreateQRCodeForm.get('seat_id').value;
    const seat = this.seats.find(s => s.seat_id === seatId);
    const seatName = seat ? seat.name : 'unknown';

    // Load the QR code and the other image
    const qrCodeImg     = await this.loadImage(this.qrCodeUrl) as unknown as HTMLImageElement;
    const otherImg      = await this.loadImage('./assets/img/theme/QRCodeLayout.svg') as unknown as HTMLImageElement;

    // Create a canvas and draw the other image and the QR code on it
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = otherImg.width;
    canvas.height = otherImg.height;
    ctx.drawImage(otherImg, 0, 0);

    // Calculate the position to center the QR code
    const x = (otherImg.width - qrCodeImg.width) / 2;
    const y = (otherImg.height - qrCodeImg.height) / 2;

    // Draw the QR code at the calculated position and increased the size
    const qrCodeSize = 450;
    ctx.drawImage(qrCodeImg, 0, 0, qrCodeImg.width, qrCodeImg.height, x-150, y, qrCodeSize, qrCodeSize);

    //write the name of the seat at the bottom of the image
    ctx.font = '80px VWITS-Regular';
    ctx.fillStyle = '#583c5e';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'bottom';
    const textX = canvas.width / 2;
    const textY = canvas.height - 80;
    ctx.fillText(seatName, textX, textY+20);

    // Create a link and trigger the download
    const link = document.createElement('a');
    link.href = canvas.toDataURL('image/png');
    link.download = `seat_${seatName}.png`;
    link.click();
  }


  loadImage(url: string): Promise<HTMLElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });
  }

  getBuildings(value) {
    this.communicationService.getBuildings(value).subscribe(res => {
      if (res.length > 0) {
        this.buildings = [];
        for (const building of res) {
          if (building.status === '0') {
            this.buildings.push(building);
          }
        }
        if (this.buildings.length > 0) {
          this.buildings.sort( (a, b) => {
            if ( a.building_name < b.building_name) { return -1; }
            if (a.building_name > b.building_name) { return 1 ; }
            return 0;
          });
          this.buildings.sort( (a, b) => a.building_name - b.building_name);
          this.f.building.setValue(this.buildings[0].building_id);
          this.getFloors(this.buildings[0].building_id);
        } else {
          this.f.building.setValue('');
          this.floors = [];
          this.f.floor.setValue('');
          this.wings = [];
          this.f.wing.setValue('');
        }
      } else {
        this.buildings = [];
        this.f.building.setValue('');
        this.floors = [];
        this.f.floor.setValue('');
        this.wings = [];
        this.f.wing.setValue('');
      }
    }, error => {
      console.error(error);
    });
  }

  getFloors(building_id: string) {
    this.communicationService.getFloorsByBuilding(building_id).subscribe(res => {
      this.floors = [];
      for (const floor of res) {
        if (floor.status === '0') {
          this.floors.push(floor);
        }
      }
      if (this.floors.length > 0) {
        this.floors.sort( (a, b) => {
          if ( a.floor_name < b.floor_name) { return -1; }
          if (a.floor_name > b.floor_name) { return 1 ; }
          return 0;
        });
        this.floors.sort( (a, b) => a.floor_name - b.floor_name);
        this.f.floor.setValue(this.floors[0].floor_id);
        this.getWings(this.floors[0].floor_id);
      } else {
        this.f.floor.setValue('');
        this.wings = [];
        this.f.wing.setValue('');
      }
    }, error => {
      console.error(error);
    });
  }

  getWings(floor_id: string) {
    this.communicationService.getWings(floor_id).subscribe(res => {
      this.wings = [];
      for (const wing of res) {
        if (wing.status === '0') {
          this.wings.push(wing);
        }
      }
      if (this.wings.length > 0) {
        this.wings.sort( (a, b) => {
          if ( a.wing_name < b.wing_name) { return -1; }
          if (a.wing_name > b.wing_name) { return 1 ; }
          return 0;
        });
        this.wings.sort( (a, b) => a.wing_name - b.wing_name);
        this.f.wing.setValue(this.wings[0].wing_id);
        this.getSeatsList(floor_id,this.wings[0].wing_id);
      } else {
        this.f.wing.setValue('');
      }
    }, error => {
      console.error(error);
    });
  }

  getSeatsList(floor_id: string, wing_id: string) {
    this.communicationService.getSeatsList(floor_id, wing_id).subscribe(res => {
     this.seats = res;

     this.f.seat_id.setValue(this.seats[0].seat_id);
    }, error => {
      console.error(error);
    });
  }

}


