import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CommunicationService } from 'src/app/services/communication.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-book-food',
  templateUrl: './book-food.component.html',
  styleUrls: ['./book-food.component.scss']
})
export class BookFoodComponent implements OnInit {
  modalReference: NgbModalRef;
  searchResultSeats = [];
  detailsBookFoodForm: FormGroup;
  todayDate;
  employeeId: any;
  limit = 20;
  cities = [];
  foods = [];
  snackSlotList = [];
  foodSlotList = [];
  startIndex = 0;
  isMorningSnack = false;
  isEveningSnack = false;
  isSnackSelected = true;
  snackSlotData: Array<any> = [];
  submitted:boolean=false;
  isDisabled: boolean;


   constructor(
    @Inject(LOCALE_ID) public locale: string,
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    public _DomSanitizationService: DomSanitizer,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService) {
  }

  ngOnInit(): void {
    this.detailsBookFoodForm = this.formBuilder.group(
      {
        employee_id: ['', Validators.required],
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
        food:'',
        snack:'',
        Morning:'',
        Evening:''
      }
    )

    let currentDate = new Date();
    const objCurrentDate = { year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate()+1 };
    this.todayDate = objCurrentDate;

    let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser.id) {
      this.communicationService.getUserDetails(currentUser.id).subscribe(res => {
        this.detailsBookFoodForm.controls.employee_id.setValue(res.firstName + " " + res.lastName);
        this.employeeId = res.username;
      }, error => {
        console.log("error", error)
      })
    }
    this.detailsBookFoodForm.controls.startDate.setValue(this.todayDate);
    this.detailsBookFoodForm.controls.endDate.setValue(this.todayDate);
    this.detailsBookFoodForm.patchValue({food:'0'});
    this.getFoods();
  }

  getFoods() {
    console.log("getFoods");
    this.communicationService.getFood().subscribe(res => {
      this.foods = res;
      for (let i = 0; i < res.length; i++) {
        if(res[i].type === 'Snacks'){
          this.snackSlotList.push(res[i]);
        }else{
          this.foodSlotList.push(res[i]);
        }
      }
    }, error => {
      console.error(error);
    });
  }

  onChangeSnack(e) {
    if (e.target.checked) {
      if(e.target.value === "Morning") {
        this.isMorningSnack = true;
        this.isSnackSelected = false;
        this.snackSlotData.push(1);
      } else if(e.target.value === "Evening"){
        this.isEveningSnack = true;
        this.isSnackSelected = false;
        this.snackSlotData.push(2);
      } else if(e.target.value === "None"){
        this.snackSlotData = [];
        this.isEveningSnack = false;
        this.isMorningSnack = false;
        this.detailsBookFoodForm.controls.Morning.setValue('');
        this.detailsBookFoodForm.controls.Evening.setValue('');
        this.isSnackSelected = true;
      }
    } else {
      if(e.target.value === "Morning") {
        this.isMorningSnack = false;
         this.snackSlotData.splice(this.snackSlotData.indexOf(e.target.value), 1);
      } else if(e.target.value === "Evening"){
        this.isEveningSnack = false;
        this.snackSlotData.splice(this.snackSlotData.indexOf(e.target.value), 1);
      } else if(e.target.value === "None"){
        this.isSnackSelected = false;
      }
    }
  }

  onDateChange(event){
    this.detailsBookFoodForm.controls.endDate.setValue(this.detailsBookFoodForm.value.startDate);
  }

  get f() {
    return this.detailsBookFoodForm.controls;
  }

  ngAfterViewInit(): void {
  }

  onSubmitBook(confirmed: any,content) {
    this.isDisabled = true;
    if (confirmed) {
      let snack_ids = null;
      let food_id = null;
      for (let i = 0; i < this.snackSlotData.length; i++) {
        if(snack_ids != null){
          snack_ids = snack_ids + "," + this.snackSlotData[i];
        } else{
          snack_ids = this.snackSlotData[i];
        }
      }
      if(!this.isMorningSnack && !this.isEveningSnack){
        snack_ids = null;
      }
      if(this.detailsBookFoodForm.controls.food.value){
        food_id = this.detailsBookFoodForm.controls.food.value;
      }
      if(this.detailsBookFoodForm.controls.food.value && this.detailsBookFoodForm.controls.food.value === '0'){
        food_id = null;
      }

      const startDateVal =
      formatDate(this.convertDate(this.detailsBookFoodForm.value.startDate), 'yyyy-MM-dd', this.locale);
    const endDateVal = formatDate(this.convertDate(this.detailsBookFoodForm.value.endDate), 'yyyy-MM-dd', this.locale);
      // let startDateVal = formatDate(this.convertDate(this.detailsBookFoodForm.value.startDate), 'yyyy-MM-dd', this.locale);
      // let endDateVal =  formatDate(this.convertDate(this.detailsBookFoodForm.value.endDate), 'yyyy-MM-dd', this.locale);
      let ngbModalOptions: NgbModalOptions = {
        backdrop : 'static',
        keyboard : false
      };
      this.modalReference = this.modalService.open(content,ngbModalOptions);
      if(startDateVal <= endDateVal){
        let bookFoodObj = {
          start_date_time: startDateVal+ 'T00:00:00',
          end_date_time: endDateVal+ 'T23:59:59',
            // start_date: startDateVal,
            // end_date: endDateVal,
            emp_id: this.employeeId,
            status: 0,
            is_self: true,
            snack_id: snack_ids,
            food_id: food_id
        }
        this.communicationService.bookFood(bookFoodObj).subscribe(res => {
          this.modalReference.close();
          console.log(res);
          this.toastr.success("Food booked successfully");
          this.isDisabled = false;
          this.router.navigate(['/view-bookings']);
        },
        error => {
          this.modalReference.close();
          console.error(error);
          this.toastr.error(error.error.message, "Failed to book food");
          this.isDisabled = false;
        })
      } else {
        this.modalReference.close();
        this.toastr.error("Invalid Booking Date Selection");
        this.isDisabled = false;
      }
    }
  }

  public openConfirmationDialog(content) {
    if (this.detailsBookFoodForm.value.employee_id) {
      this.confirmationDialogService.confirm('Book Food Confirmation',
        'Are you sure you want to book food ?')
        .then((confirmed) => this.onSubmitBook(confirmed,content))
    } else {
      this.toastr.error("Seat is not selected")
    }
  }

  convertDate(date) {
    let converdate = new Date(date.year, date.month - 1, date.day);
    return converdate;
  }

}
