import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CommunicationService } from 'src/app/services/communication.service';
import { userRoles } from '../../helper/userRoles';
import { TranslateService } from '@ngx-translate/core';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  group?: {
    path: string;
    title: string;
    icon: string;
    class: string;
  }[];
}

export const ROUTES: RouteInfo[] = [
  {path: '/dashboard', title: 'Dashboard', icon: 'ni-tv-2 text-primary', class: ''},
  {
    path: '/book-seat', title: 'Booking', icon: 'ni-settings text-blue', class: '',
    group: [{path: '/book-seat', title: 'Book a Workstation', icon: 'ni-planet text-blue', class: ''},
      {path: '/auto-assign-booking', title: 'Auto Assign Booking', icon: 'ni-curved-next text-blue', class: ''},
      {path: '/express-booking', title: 'Express Booking', icon: 'ni-delivery-fast text-blue', class: ''},
      { path: '/book-food', title: 'Book a Food',  icon:'ni-delivery-fast text-blue', class: '' },
      /* { path: '/qr-code-booking', title: 'QR Code Booking',  icon:'ni-glasses-2 text-blue', class: '' }, */
      {path: '/view-bookings', title: 'Bookings List', icon: 'ni-pin-3 text-blue', class: ''}]
  },
  {path: '/search-employee-desk', title: 'Locate', icon: 'ni-compass-04 text-blue', class: ''},
  {path: '/booking-by-date', title: 'Check Availability', icon: 'ni-check-bold text-blue', class: ''},
  {path: '/users', title: 'Users', icon: 'ni-single-02 text-blue', class: ''},
  {path: '/approv-reject-booking', title: 'My Tasks', icon: 'ni-notification-70 text-blue', class: ''},
  {
    path: '/disable-floor', title: 'Space Management', icon: 'ni-settings text-blue', class: '',
    group: [{path: '/first-aid', title: 'First Aid Responders', icon: 'ni-active-40 text-blue', class: ''},
      {path: '/disable-floor', title: 'Enable/Disable Floor', icon: 'ni-building text-blue', class: ''},
      // { path: '/enable-disable-seat', title: 'Enable/Disable Workstation',  icon:'ni-active-40 text-blue', class: '' },
      {path: '/view-workstations', title: 'Workstations', icon: 'ni-active-40 text-blue', class: ''},
      {path: '/rule-configuration', title: 'Capacity Management', icon: 'ni-scissors text-blue', class: ''}]
  },
  {
    path: '/view-country', title: 'Configuration', icon: 'ni-settings-gear-65 text-blue', class: '',
    group: [{path: '/view-country', title: 'View Country', icon: 'ni-world text-blue', class: ''},
      {path: '/view-organisation', title: 'View Organisation', icon: 'ni-planet text-blue', class: ''},
      {path: '/view-department', title: 'View Department', icon: 'ni-badge text-blue', class: ''},
      {path: '/view-sub-department', title: 'View Sub-Department', icon: 'ni-badge text-blue', class: ''},
      {path: '/view-city', title: 'View City', icon: 'ni-world-2 text-blue', class: ''},
      {path: '/view-building', title: 'View Building', icon: 'ni-building text-blue', class: ''},
      {path: '/view-floor', title: 'View Floor', icon: 'ni-map-big text-blue', class: ''},
      {path: '/view-wing', title: 'View Wing', icon: 'ni-active-40 text-blue', class: ''},
      {path: '/add-workstation', title: 'Add Workstation', icon: 'ni-active-40 text-blue', class: ''},
    ]
  },
  {path: '/audit-logs', title: 'Audit logs', icon: 'ni-bullet-list-67 text-blue', class: ''},
  {path: '/help', title: 'Help', icon: 'ni-album-2 text-blue', class: ''},
  {path: '/logout', title: 'Logout', icon: 'ni ni-user-run text-blue', class: ''}

];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Input() navPosition: boolean;

  public menuItems: any[];
  public isCollapsed = true;

  adminMenu   = this.translate.instant('sidebar.spaceManagement');
  bookingMenu = this.translate.instant('sidebar.booking') ;
  configMenu   = this.translate.instant('sidebar.configuration');

  showAdminMenus   = false;
  showBookingMenus = false;
  showConfigMenus = false;

  collapseNav = false;
  currentUser: any;
  nestedMenuItems: any[];

  constructor(private router: Router,
              public translate: TranslateService,
              public communicationService: CommunicationService) {
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser') || '{}');

    if ([userRoles.ADMIN, userRoles.SUPERADMIN].includes(this.currentUser.role) === false) {
      this.menuItems = this.menuItems.filter((route) => route.title !== 'Configuration');
    }

    if ([userRoles.SUPERADMIN, userRoles.ADMIN, userRoles.DEPARTMENTHEAD, userRoles.MANAGER, userRoles.HR].includes(this.currentUser.role) == false) {
      this.menuItems = this.menuItems.filter((route) => route.title !== 'Users');
      this.menuItems = this.menuItems.filter((route) => route.title !== 'My Tasks');
      this.menuItems = this.menuItems.filter((route) => route.title !== 'Space Management');
    }

    if ([userRoles.ADMIN, userRoles.DEPARTMENTHEAD, userRoles.MANAGER, userRoles.HR, userRoles.EMPLOYEE].includes(this.currentUser.role) === false) {
      this.menuItems = this.menuItems.filter((route) => route.title !== 'Dashboard');
    }

    if (this.menuItems.length >= 8 && [userRoles.SUPERADMIN].includes(this.currentUser.role) === false) {
      this.menuItems[7].group = this.menuItems[7].group.filter((route) => route.title !== 'View Organisation');
      this.menuItems[7].group = this.menuItems[7].group.filter((route) => route.title !== 'View Department');
      this.menuItems[7].group = this.menuItems[7].group.filter((route) => route.title !== 'View Sub-Department');
      this.menuItems[7].group = this.menuItems[7].group.filter((route) => route.title !== 'View Country');
      this.menuItems[7].group = this.menuItems[7].group.filter((route) => route.title !== 'View City');
    }

    this.translateMenuList();
  }

  translateMenuList() {
    this.menuItems.forEach(item => {
        switch (item.title) {
          case 'Dashboard': item.title = this.translate.instant('sidebar.dashBoard'); break;
          case 'Booking': item.title = this.translate.instant('sidebar.booking');
            item.group.forEach((res: { title: any; }) => {
              switch (res.title) {
                case 'Book a Workstation': res.title = this.translate.instant('sidebar.bookingOptions.bookWorkstation'); break;
                case 'Express Booking': res.title = this.translate.instant('sidebar.bookingOptions.expressBooking'); break;
                case 'Auto Assign Booking': res.title = this.translate.instant('sidebar.bookingOptions.autoAssignBooking'); break;
                case 'Book a Food': res.title = this.translate.instant('sidebar.bookingOptions.bookFood'); break;
                case 'Bookings List': res.title = this.translate.instant('sidebar.bookingOptions.bookingList'); break;
              } });
            break;
          case 'Locate': item.title = this.translate.instant('sidebar.locate'); break;
          case 'Check Availability': item.title = this.translate.instant('sidebar.availability'); break;
          case 'Users': item.title = this.translate.instant('sidebar.users'); break;
          case 'My Tasks': item.title = this.translate.instant('sidebar.task'); break;
          case 'Space Management': item.title = this.translate.instant('sidebar.spaceManagement');
            item.group.forEach((res: { title: any; }) => {
              switch (res.title) {
                case 'Enable/Disable Floor': res.title = this.translate.instant('sidebar.spaceManagementOptions.enableDisableFloor'); break;
                case 'Enable/Disable Workstation': res.title = this.translate.instant('sidebar.spaceManagementOptions.enableDisableWorkstation'); break;
                case 'Capacity Management': res.title = this.translate.instant('sidebar.spaceManagementOptions.capacityManagement'); break;
                case 'Workstations': res.title = this.translate.instant('sidebar.spaceManagementOptions.viewWorkstation'); break;
                case 'First Aid Responders': res.title = this.translate.instant('sidebar.spaceManagementOptions.firstAidResponder'); break;
              } });
            break;
          case 'Configuration':
            item.title = this.translate.instant('sidebar.configuration');
            item.group.forEach((res: { title: any; }) => {
              switch (res.title) {
                case 'View Department': res.title = this.translate.instant('sidebar.configurationOptions.viewDepartment'); break;
                case 'View Sub-Department': res.title = this.translate.instant('sidebar.configurationOptions.viewSubDepartment'); break;
                case 'View Country': res.title = this.translate.instant('sidebar.configurationOptions.viewcountry'); break;
                case 'View City': res.title = this.translate.instant('sidebar.configurationOptions.viewcity'); break;
                case 'View Organisation': res.title = this.translate.instant('sidebar.configurationOptions.viewOrganisation'); break;
                case 'View Building': res.title = this.translate.instant('sidebar.configurationOptions.viewbuilding'); break;
                case 'View Floor': res.title = this.translate.instant('sidebar.configurationOptions.viewFloor'); break;
                case 'View Wing': res.title = this.translate.instant('sidebar.configurationOptions.viewWing'); break;
                case 'Add Workstation': res.title = this.translate.instant('sidebar.configurationOptions.addWorkstation'); break;
              } });
            break;
          case 'Audit logs': item.title = this.translate.instant('sidebar.logs'); break;
          case 'Help': item.title = this.translate.instant('sidebar.help'); break;
          case 'Logout': item.title = this.translate.instant('sidebar.logout'); break;
        }

      }
    );
  }
  showHideMenu() {
    this.communicationService.showHideMenuBar = false;
  }
}
