import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpClient
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry, retryWhen, switchMap, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AccountService } from '../services/account.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private router: Router,
              private service: AccountService,
              private http: HttpClient) { }
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const authToken = sessionStorage.getItem('token');
    let authReq = req;
    if (currentUser) {
      authReq = authReq.url.startsWith(environment.apiUrl) ? authReq.clone(
        {
          withCredentials: true,
          headers: req.headers.set('Authorization', 'Bearer ' + authToken)
        },

      ) : authReq.clone();
    }
    return next.handle(authReq).pipe(catchError(x => this.handleAuthError(authReq, x, next)));
  }

  private handleAuthError(request: HttpRequest<any>, err: HttpErrorResponse, next: HttpHandler): Observable<any> {
    if (err.status === 401 || err.status === 403) {
 /*      if (!this.isRefreshing) {
        this.isRefreshing = true;
        this.refreshTokenSubject.next(null);
        if(this.service.refreshToken()){
          this.isRefreshing = false;
          return next.handle(request);
        }
      } */
      this.router.navigate(['/logout']);
      return throwError(err);
    }
    return throwError(err);
  }
}

