
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Desk } from 'src/app/model/desk';
import { CommunicationService } from 'src/app/services/communication.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-on-map',
  templateUrl: './view-on-map.component.html',
  styleUrls: ['./view-on-map.component.scss']
})
export class ViewOnMapComponent implements AfterViewInit {

  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('loadingModal', { static: true }) loadingModal: any;

  @ViewChild('tooltip', { static: true })
  tooltip: ElementRef<HTMLCanvasElement>;
  modalReference: NgbModalRef;
  detailsViewMapForm: FormGroup;

  hideTooltip = false;
  booking_id;
  searchResult = [];
  searchResultSeats = [];
  /*limit = 20;
  startIndex = 0;*/
  desk: Desk;
  floorPlanCtx: CanvasRenderingContext2D;
  tooltipCtx: CanvasRenderingContext2D;
  floorPlanImage: HTMLImageElement;
  content: any;
  isModalLoaded = false;
  seat_name: string;
  displayAttributeTable = false;
  viewAttribute: string;
  metaJsonObject = {};
  displayMap = new Map<string, string>();
  cityName: string;
  private buildingName: any;

  constructor(
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    public _DomSanitizationService: DomSanitizer,
    private modalService: NgbModal,
    private router: Router) {
    this.booking_id = history.state.id;
  }

  // tslint:disable-next-line:use-lifecycle-interface use-life-cycle-interface
  ngOnInit(): void {
    this.detailsViewMapForm = this.formBuilder.group(
      {
        city_name: [''],
        building_name: [''],
        floor_name: [''],
        wing_name: [''],
        attributes: ['']
      }
    );
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    this.modalReference = this.modalService.open(this.loadingModal, ngbModalOptions);
  }

  get f() {
    return this.detailsViewMapForm.controls;
  }

  ngAfterViewInit(): void {
    this.floorPlanCtx = this.canvas.nativeElement.getContext('2d');
    this.tooltipCtx = this.tooltip.nativeElement.getContext('2d');
    this.onSubmit();
  }

  onSubmit() {
    const searchObj = {
      booking_id: this.booking_id
    };
    this.communicationService.findBooking(searchObj).subscribe(res => {
      this.desk = res[0];
      this.f.city_name.setValue(this.desk .city_name);
      this.f.building_name.setValue(this.desk .building_name);
      const image = new Image();
      this.modalReference.close();
      const objectURL = 'data:image/png;base64,' + this.desk.image_path;
      image.src = objectURL;
      image.onload = (() => this.fitToCanvas(image));
    }, () => {
      this.searchResultSeats.length = 0;
      this.modalReference.close();
      console.log('Getting error while searching map for seat or employee');
    });
  }

  private fitToCanvas(image) {
    this.floorPlanCtx.canvas.width = image.width;
    this.floorPlanCtx.canvas.height = image.height;
    this.floorPlanCtx.drawImage(image, 0, 0);
    this.highlightSelected();
    this.displayAttributes(this.desk.name);
  }

  highlightSelected() {
    if (this.desk.type === 0) {
      this.floorPlanCtx.fillStyle = '#A8ADB3';
      this.floorPlanCtx.fillRect(this.desk.x, this.desk.y, this.desk.w, this.desk.h);
      this.floorPlanCtx.strokeStyle = '#d4d6d9';
      this.floorPlanCtx.strokeRect(this.desk.x, this.desk.y, this.desk.w, this.desk.h);
      this.f.floor_name.setValue(this.desk.floor_name);
      this.f.wing_name.setValue(this.desk.wing_name);
      this.f.attributes.setValue(this.desk.attributes);
      this.onMouseMoveRect();
    } else {
      this.floorPlanCtx.fillStyle = '#A8ADB3';
      this.floorPlanCtx.strokeStyle = '#d4d6d9';
      this.floorPlanCtx.beginPath();
      this.floorPlanCtx.moveTo(this.desk.sides[0], this.desk.sides[1]);
      this.floorPlanCtx.lineTo(this.desk.sides[2], this.desk.sides[3]);
      this.floorPlanCtx.lineTo(this.desk.sides[4], this.desk.sides[5]);
      this.floorPlanCtx.lineTo(this.desk.sides[6], this.desk.sides[7]);
      this.floorPlanCtx.lineTo(this.desk.sides[8], this.desk.sides[9]);
      this.floorPlanCtx.lineTo(this.desk.sides[10], this.desk.sides[11]);
      this.floorPlanCtx.closePath();
      this.floorPlanCtx.fill();
      this.f.floor_name.setValue(this.desk.floor_name);
      this.f.wing_name.setValue(this.desk.wing_name);
      this.f.attributes.setValue(this.desk.attributes);

      this.onMouseMovePoly();
    }
  }

  onMouseMoveRect() {
    this.hideTooltip = true;
    const lineheight = 15;
    this.canvas.nativeElement.style.cursor = 'pointer';
    this.tooltip.nativeElement.style.left = (this.desk.x) + 'px';
    this.tooltip.nativeElement.style.top = (this.desk.y) + 'px';
    this.tooltipCtx.fillStyle = '#ffffff';
    this.tooltipCtx.fillText(`\nWorkstation Name : ${this.desk.name}`, 0, lineheight);
    this.tooltipCtx.textAlign = 'center';
    this.tooltipCtx.font = 'bold 35px Arial';
  }

  onMouseMovePoly() {
    this.hideTooltip = true;
    const lineheight = 15;
    this.canvas.nativeElement.style.cursor = 'pointer';
    this.tooltip.nativeElement.style.left = (this.desk.sides[0] - 25) + 'px';
    this.tooltip.nativeElement.style.top = (this.desk.sides[1] - 5) + 'px';
    this.tooltipCtx.fillStyle = '#ffffff';
    this.tooltipCtx.fillText(`\nWorkstation Name : ${this.desk.name}`, 0, lineheight);
    this.tooltipCtx.textAlign = 'center';
    this.tooltipCtx.font = 'bold 35px Arial';
  }

  back() {
    this.router.navigate(['/view-bookings']);
  }

  displayAttributes(value: string) {
    this.communicationService.getSeat(value).subscribe(res => {
      this.seat_name = value;
        if (res.attributes !== 'null') {
          this.displayAttributeTable = true;
          this.viewAttribute = res.attributes;
          this.metaJsonObject = JSON.parse(this.viewAttribute);
          const keys = Object.keys(this.metaJsonObject);
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < keys.length; i++) {
            this.displayMap.set(keys[i], this.metaJsonObject[keys[i]]);
          }
        }
    }, error => {
        console.error(error);
      }
    );

  }

}


