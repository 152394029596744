import {Component, Inject, LOCALE_ID, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {CommunicationService} from 'src/app/services/communication.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TimeByHour} from 'src/app/constants/timeList';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-auto-assign-booking',
  templateUrl: './auto-assign-booking.component.html',
  styleUrls: ['./auto-assign-booking.component.scss']
})
export class AutoAssignBookingComponent implements OnInit {
  modalReference: NgbModalRef;
  detailsAutoAssignForm: FormGroup;
  countries = [];
  cities = [];
  buildings = [];
  isDisabled: boolean;
  todayDate;
  bookingTimeList: any;
  startTimeValue = 0;
  isBookByTime: Boolean = false;
  submitted:boolean;
  emailNotification:boolean=false;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    public translate: TranslateService) {
  }

  @ViewChild('mymodal')
  private defaultTabButtonsTpl: TemplateRef<any>;

  ngOnInit(): void {
    const currentDate = new Date();
    this.todayDate = {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate()
    };

    this.detailsAutoAssignForm = this.formBuilder.group(
      {
        country: ['', Validators.required],
        city: ['', Validators.required],
        building: ['', Validators.required],
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
        startTime: ['', Validators.required],
        endTime: ['', Validators.required],
        email_notification: ['', Validators.required],
      }
    );
    this.detailsAutoAssignForm.controls.startDate.setValue(this.todayDate);
    this.detailsAutoAssignForm.controls.endDate.setValue(this.todayDate);
    this.getCountries();
    this.setStartEndTime();
  }

  onDateChange() {
    this.detailsAutoAssignForm.controls.endDate.setValue(this.detailsAutoAssignForm.value.startDate);
  }

  get f() {
    return this.detailsAutoAssignForm.controls;
  }

  onCityChange(value) {
    this.getBuildings(value.target.value);
  }

  onBuildingChange(value) {
    this.f.building.setValue(value.target.value);
  }

  onCountryChange(value) {
    this.getCitiesByCountry(value.target.value);
  }

  setStartEndTime() {
    this.bookingTimeList = TimeByHour;
    this.f.startTime.setValue(0);
    this.f.endTime.setValue(24);
    this.detailsAutoAssignForm.get('startTime').disable();
    this.detailsAutoAssignForm.get('endTime').disable();
  }

  getCitiesByCountry(country_id: string) {
    this.communicationService.getCitiesByCountry(country_id).subscribe(res => {
      this.cities = [];
      for (const city of res) {
        if (city.status === '0') {
          this.cities.push(city);
        }
      }
      if (this.cities.length > 0) {
        this.f.city.setValue(this.cities[0].city_id);
        this.getBuildings(this.cities[0].city_id);
      } else {
        this.f.city.setValue('');
        this.f.building.setValue('');
      }
      this.modalReference.close();
    }, error => {
      console.error(error);
      this.modalReference.close();
    });
  }

  /*getCities() {
    this.communicationService.getCities().subscribe(res => {
      this.cities = res;
      this.modalReference.close();
    }, error => {
      console.error(error);
      this.modalReference.close();
    })
  }*/

  getCountries() {
    this.communicationService.getCountries().subscribe(res => {
      for (const country of res) {
        if (country.status === '0') {
          this.countries.push(country);
        }
      }
      if (this.countries.length > 0) {
        this.f.country.setValue(this.countries[0].country_id);
        this.getCitiesByCountry(this.countries[0].country_id);
      } else {
        this.f.country.setValue('');
        this.f.city.setValue('');
        this.f.building.setValue('');
      }
      this.modalReference.close();
    }, error => {
      this.modalReference.close();
      console.error(error);
    });
  }

  onEmailChange(event) {
        if (event.target.checked) {
          this.emailNotification = true;
        } else {
          this.emailNotification = false;
        }
  }

  getBuildings(value) {
    this.communicationService.getBuildings(value).subscribe(res => {
      if (res.length > 0) {
        this.buildings = [];
        for (const building of res) {
          if (building.status === '0') {
            this.buildings.push(building);
          }
        }
        if (this.buildings.length > 0) {
          this.f.building.setValue(this.buildings[0].building_id);
        } else {
          this.f.building.setValue('');
          this.modalReference.close();
        }
      }
    }, error => {
      this.modalReference.close();
      console.error(error);
    });
  }

  isInputSelected() {
    if (this.f.country.value === ''
      || this.f.city.value === '' || this.f.building.value === '') {
      return false;
    }
    return true;
  }

  deselectTimeBooking(event) {
    if (event.target.checked) {
      this.detailsAutoAssignForm.get('startTime').enable();
      this.detailsAutoAssignForm.get('endTime').enable();
      this.isBookByTime = true;
    } else {
      this.isBookByTime = false;
      this.f.startTime.setValue(0);
      this.f.endTime.setValue(24);
      this.detailsAutoAssignForm.get('startTime').disable();
      this.detailsAutoAssignForm.get('endTime').disable();
    }
  }

  onStartTimeChange(value) {
    this.startTimeValue = value.target.value;
    let val = this.startTimeValue;
    this.setEndTime(++val);
  }

  setEndTime(val) {
    this.f.endTime.setValue(val);
  }

  onEndTimeChange(value) {
    let endTimeValue = value.target.value;
    // tslint:disable-next-line:radix
    if (parseInt(endTimeValue) > this.startTimeValue) {
      this.setEndTime(endTimeValue);
    } else {
      endTimeValue = this.startTimeValue;
      this.setEndTime(++endTimeValue);
    }
  }

  convertDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  }

  autoAssign(content) {
    this.isDisabled = true;
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    this.modalReference = this.modalService.open(content, ngbModalOptions);
    const startDateVal = formatDate(this.convertDate(this.detailsAutoAssignForm.value.startDate), 'yyyy-MM-dd', this.locale);
    const endDateVal = formatDate(this.convertDate(this.detailsAutoAssignForm.value.endDate), 'yyyy-MM-dd', this.locale);
    if (startDateVal <= endDateVal) {
      const bookingRequest = {
        building_id: this.detailsAutoAssignForm.value.building,
        is_self: true,
        start_date_time: this.isBookByTime ? startDateVal + this.bookingTimeList[this.detailsAutoAssignForm.get('startTime').value].timeFormat :
          startDateVal + 'T00:00:00',
        end_date_time: this.isBookByTime ? endDateVal + this.bookingTimeList[this.detailsAutoAssignForm.get('endTime').value].timeFormat :
          endDateVal + 'T23:59:59',
          email_notification:this.emailNotification
      };
      this.communicationService.autoAssign(bookingRequest).subscribe(() => {
          this.modalReference.close();
          this.toastr.success(this.translate.instant('toasterSuccessMessage.workStationBooked'));
          this.router.navigate(['/view-bookings']);
        },
        error => {
          this.modalReference.close();
          console.log('error', error);
          this.toastr.error(error.error.message, 'Failed to book workstation');
          this.isDisabled = false;
        });
    } else {
      this.modalReference.close();
      this.toastr.error('Invalid Booking Date Selection');
    }
  }

  // tslint:disable-next-line:use-lifecycle-interface use-life-cycle-interface
  ngAfterViewInit(): void {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    this.modalReference = this.modalService.open(this.defaultTabButtonsTpl, ngbModalOptions);
  }
}
