
import { FormBuilder, FormControl,FormGroup } from '@angular/forms';
import { AfterViewInit, Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild,TemplateRef } from '@angular/core';
import { CommunicationService } from 'src/app/services/communication.service';
import {NgbModal, ModalDismissReasons, NgbModalRef, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import * as CryptoJS from 'crypto-js';
import { Desk } from 'src/app/model/desk';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-search-employee-desk-au',
  templateUrl: './search-employee-desk-au.component.html',
  styleUrls: ['./search-employee-desk-au.component.scss']
})
export class SearchEmployeeDeskAuComponent implements AfterViewInit {

  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('tooltip', { static: true })
  tooltip: ElementRef<HTMLCanvasElement>;

  tooltipCtx: CanvasRenderingContext2D;

  @ViewChild('mymodal')
  private defaultTabButtonsTpl: TemplateRef<any>;
  modalReference: NgbModalRef;
  detailsEmployeeDeskAUForm: FormGroup;
  hideTooltip: boolean = false;
  keySize = 256;
  iterations = 1000;
  searchResult = [];
  searchResultSeats = [];
  buildings = [];
  limit = 300;
  startIndex = 0;
  todayDate;
  corpId: any;
  desk: Desk;
  deskList=[];
  floorPlanCtx: CanvasRenderingContext2D;
  floorPlanImage: HTMLImageElement;
  //buildings=[{"status":"0","building_id":"11","building_name":"MX336","country_id":null,"country_name":null,"city_id":null,"city_name":null,"department_name":null},{"status":"0","building_id":"12","building_name":"GVZ Halle N","country_id":null,"country_name":null,"city_id":null,"city_name":null,"department_name":null},{"status":"0","building_id":"13","building_name":"Brentanostr.","country_id":null,"country_name":null,"city_id":null,"city_name":null,"department_name":null}];
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    public _DomSanitizationService: DomSanitizer,
    private toastr: ToastrService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.getCountries();
    let currentDate = new Date();
    this.todayDate = {year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate()};
    this.detailsEmployeeDeskAUForm = this.formBuilder.group(
      {
        empName: [''],
        seatName: [''],
        floor_name: [''],
        wing_name: [''],
        seat_number: [''],
        date: [''],
        building: [''],
        city:[''],
        country:[''],
       }
      )
    }
    get f() {
      return this.detailsEmployeeDeskAUForm.controls;
    }

    countries = [];
    getCountries() {
      this.communicationService.getCountries().subscribe(res => {
       this.countries = [];
             for (const country of res) {
               if (country.status === '0' && country.country_name === 'Australia') {
                 this.countries.push(country);
                 this.getCitiesByCountry(country.country_id);
               }
             }
             if (this.countries.length > 0) {
               this.f.country.setValue(this.countries[0].country_id);
             } else {
               this.countries = [];
               this.f.country.setValue('');
               this.cities = [];
               this.f.city.setValue('');
               this.buildings = [];
               this.f.building.setValue('');
               this.modalReference.close();
             }
//         this.getCitiesByCountry(res[0].country_id);
      }, error => {
        console.error(error);
      })
    }
  cities= [];
    getCitiesByCountry(country_id: String) {
      this.communicationService.getCitiesByCountry(country_id).subscribe(res => {
      if (res.length > 0) {
              this.cities = [];
              for (const city of res) {
                if (city.status === '0') {
                  this.cities.push(city);
                }
              }
              if (this.cities.length > 0) {
                for (const city of this.cities) {
                  if (city.city_name == 'Sydney') {
                    this.f.city.setValue(city.city_id);
                    this.getBuildings(city.city_id);
                    return 0 ;
                  }
                }
                this.f.city.setValue(this.cities[0].city_id);
                this.getBuildings(this.cities[0].city_id);
              } else {
                this.cities = [];
                this.f.city.setValue('');
                this.buildings = [];
                this.f.building.setValue('');
                this.modalReference.close();
              }
            } else {
              this.cities = [];
              this.f.city.setValue('');
              this.buildings = [];
              this.f.building.setValue('');
              this.modalReference.close();
            }
      }, error => {
        console.error(error);
      })
    }

    onCityChange(value) {
        this.getBuildings(value.target.value);
      }

    getBuildings(value) {
      this.communicationService.getBuildings(value).subscribe(res => {
        this.buildings = [];
        for (const building of res) {
          if (building.status === '0') {
            this.buildings.push(building);
          }
        }
        this.f.building.setValue(res[0].building_id);
      }, error => {
        console.error(error);
      })
    }

    onBuildingChange(value) {
      this.f.building.setValue(value);
    }

  ngAfterViewInit(): void {
    this.floorPlanCtx = this.canvas.nativeElement.getContext('2d');
    this.tooltipCtx = this.tooltip.nativeElement.getContext('2d');

    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    this.modalReference = this.modalService.open(this.defaultTabButtonsTpl, ngbModalOptions);
  }

  getSearchResult = (val) => {
    let queryData = val.target.value;
    const inp = String.fromCharCode(val.keyCode);
    if (/[a-zA-Z0-9-_ @.]/.test(inp)) {
      queryData = val.target.value + val.key;
    }
    if (queryData.length >= 4 && queryData.length <= 15) {
      this.communicationService.getSearchResult(queryData).subscribe(res => {
        if (res.employeeList.users.length === 0 && queryData.length <= 4) {
          this.toastr.error('Search User not found.');
        }
        this.searchResult = res.employeeList.users;
      }, error => {
        this.searchResult.length = 0;
        if(queryData.length <= 4){
          this.toastr.error('Search User not found.');
        }

      });
    } else {
      this.searchResult.length = 0;
    }
  }


  getSearchResultSeats = (val) => {
    const queryData = val.target.value;
    if (queryData.length >= 0) {
      this.communicationService.getSeatsFilter(this.limit, this.startIndex, queryData).subscribe(res => {
        this.searchResultSeats = res;
      }, error => {
        this.searchResultSeats.length = 0;
        console.log('Getting error while searching result for seats');
      });
    } else {
      this.searchResultSeats.length = 0;
    }
  }

  getSearchUserData(data: any) {
    let myForm = this.detailsEmployeeDeskAUForm.value.empName;
    if (typeof (data) === 'object') {
      myForm = data.displayName;
      this.corpId = data.username;
      this.detailsEmployeeDeskAUForm.controls['empName'].setValue(myForm);

      this.searchResult.length = 0;
    }
    this.detailsEmployeeDeskAUForm.controls["seatName"].setValue("");
  }

  getSearchSeatsData(data: any) {
    let myForm = this.detailsEmployeeDeskAUForm.value.seatName;
    if (typeof (data) === 'object') {
      myForm = data.name;
      this.detailsEmployeeDeskAUForm.controls['seatName'].setValue(myForm);
      this.searchResultSeats.length = 0;
    }
  }

  onSubmit() {
    this.deskList=[];
    const limit = this.limit;
    const index = this.startIndex;
    if(this.detailsEmployeeDeskAUForm.value.seatName!=""){
      this.corpId='';
      this.detailsEmployeeDeskAUForm.controls["empName"].setValue('');
    }
    let startDateVal = formatDate(this.convertDate(this.todayDate), 'yyyy-MM-dd', this.locale);

    const searchObj = {
      seat_id: this.detailsEmployeeDeskAUForm.value.seatName,
      emp_id:  this.corpId !==''?this.corpId:'',
      building_id: this.detailsEmployeeDeskAUForm.value.building,
      start_date_time: startDateVal,
    }
    this.communicationService.findDesk(limit, index, searchObj).subscribe(res => {
      this.desk = res[0];
      for (let obj of res) {
        this.deskList.push(obj);
      }
      if (this.deskList) {
        const image = new Image();
        let objectURL = 'data:image/png;base64,' + this.deskList[0].image_path;
        image.src = objectURL;
        this.floorPlanImage = image;

        image.onload = (() => this.fitToCanvas(image));
      } else {
        this.toastr.error('Unable to find desk');
      }

    }, error => {
      this.searchResultSeats.length = 0;
      this.toastr.error('Unable to find desk');
    });
  }

  private fitToCanvas(image) {
    this.floorPlanCtx.canvas.width = image.width;
    this.floorPlanCtx.canvas.height = image.height;
    this.floorPlanCtx.drawImage(image, 0, 0);
     this.deskList.forEach(desk=>{
      this.highlightSelected(desk);
     })
  }
  convertDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  }
  highlightSelected(desk: Desk) {
    if (desk.type == 0) {
      this.floorPlanCtx.fillStyle = '#A8ADB3';
      this.floorPlanCtx.fillRect(desk.x,desk.y,desk.w,desk.h);
      this.floorPlanCtx.strokeStyle = '#d4d6d9';
      this.floorPlanCtx.strokeRect(desk.x,desk.y,desk.w,desk.h);
      this.f.floor_name.setValue(desk.floor_name);
      this.f.wing_name.setValue(desk.wing_name);
      this.f.seat_number.setValue(desk.name);
      this.f.date.setValue(desk.start_date_time +" to "+desk.end_date_time );
    } else if (desk.type === 1) {
      this.floorPlanCtx.fillStyle = '#A8ADB3';
      this.plotPolygon(this.desk);
      this.f.floor_name.setValue(desk.floor_name);
      this.f.wing_name.setValue(desk.wing_name);
      this.f.seat_number.setValue(desk.name);
      this.f.date.setValue(desk.start_date_time +" to "+desk.end_date_time );
    }
  }

  private plotPolygon(element: Desk) {
    this.floorPlanCtx.beginPath();
    this.floorPlanCtx.moveTo(element.sides[0], element.sides[1]);
    this.floorPlanCtx.lineTo(element.sides[2], element.sides[3]);
    this.floorPlanCtx.lineTo(element.sides[4], element.sides[5]);
    this.floorPlanCtx.lineTo(element.sides[6], element.sides[7]);
    this.floorPlanCtx.lineTo(element.sides[8], element.sides[9]);
    this.floorPlanCtx.lineTo(element.sides[10], element.sides[11]);
    this.floorPlanCtx.closePath();
    this.floorPlanCtx.fill();
  }
  onClick(event,content) {

  }
}
