import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from 'src/app/services/communication.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  heroes$;
  superlatives$ = new BehaviorSubject<{ [superlativeName: string]: string }>({});
  tableDataSource$ = new BehaviorSubject<any[]>([]);
  displayedColumns$ = new BehaviorSubject<string[]>([
    'firstName',
    'lastName',
    'email',
    'department',
    'role',
    'status',
    'username'
  ]);
  currentPage$ = new BehaviorSubject<number>(1);
  pageSize$ = new BehaviorSubject<number>(10);
  dataOnPage$ = new BehaviorSubject<any[]>([]);
  searchFormControl = new FormControl();
  sortKey$ = new BehaviorSubject<string>('email');
  sortDirection$ = new BehaviorSubject<string>('asc');
  totalUserCount = 0;
  limit = 10;
  startIndex = 0;
  validated: boolean;
  constructor(
    private communicationService: CommunicationService,
    private validationService: ValidationService,
    private toastr: ToastrService,
    private router: Router,
    public  translate:  TranslateService) { }

  ngOnInit() {
    this.getAllUsers(this.limit, this.startIndex);
    this.searchFormControl.setValue('');
  }

  updateUserRole(username: string) {
    this.router.navigate(['update-user-role', username]);
  }

  adjustSort(key: string) {
    if (this.sortKey$.value === key) {
      if (this.sortDirection$.value === 'asc') {
        this.sortDirection$.next('desc');
      } else {
        this.sortDirection$.next('asc');
      }
      return;
    }

    this.sortKey$.next(key);
    this.sortDirection$.next('asc');
  }

  handlePageChange(event): void {
    this.startIndex = event - 1;
    this.getAllUsers(this.limit, this.startIndex);
  }

  getValidSearchResult() {
    this.validated = this.validationService.validationSearchResult(this.searchFormControl.value);
    if (this.validated === false) {
      this.getSearchResult();
    }
  }

  getSearchResult() {
    let queryData = this.searchFormControl.value;
    let userData = [];
    if (queryData.length >= 4) {
      this.communicationService.getAllUsersFilter(queryData).subscribe(res => {
        for (const user of res.employeeList.users) {
          if (user.status === "0" || user.status === "-1") {
            userData.push(user);
          }
        }
        this.heroes$ = new BehaviorSubject(userData);
        this.totalUserCount = userData.length;
         combineLatest(this.tableDataSource$)
                 .subscribe(([allSources]) => {
                   const onPage = allSources;
                   this.dataOnPage$.next(onPage);
                 });

               this.heroes$.pipe(take(1)).subscribe(heroData => {
                 this.tableDataSource$.next(Object.values(heroData));
               });
      }, error => {
        console.log('Getting error while searching result');
      });
    }
  }

  getAllUsers(limit: number, index: number) {
    const userData = [];
    this.searchFormControl.setValue('');
    this.communicationService.getAllUsersDetails(limit, index).subscribe(res => {
      for (const user of res.employeeList.users) {
        if (user.status === "0" || user.status === "1") {
          userData.push(user);
        }
      }
      this.totalUserCount = res.totalPageCount;
      this.heroes$ = new BehaviorSubject(userData);
      combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
        .subscribe(([allSources, currentPage, pageSize]) => {
          const startingIndex = (currentPage - 1) * pageSize;
          const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
          this.dataOnPage$.next(onPage);
        });

      this.heroes$.pipe(take(1)).subscribe(heroData => {
        this.tableDataSource$.next(Object.values(heroData));
      });

      combineLatest(this.heroes$, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          this.searchFormControl.setValue('');
          const sortedHeroes = heroesArray.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });

          this.tableDataSource$.next(sortedHeroes);
        });
    }, error => {
      console.log(error);
    });
  }
  updateUserDetails(id: any) {
    this.router.navigate(['/user-profile', id]);
  }

  activateUser(username, role) {
    this.communicationService.activateUser(username, role).subscribe(() => {
      this.getAllUsers(this.limit, this.startIndex);
      this.toastr.success(this.translate.instant('toasterSuccessMessage.userActivated'));    }, error => {
      console.log(error.error.message);
       this.toastr.error(error.error.message);
    });
  }

  disableUser(username, role) {
    this.communicationService.disableUser(username, role).subscribe(() => {
      this.getAllUsers(this.limit, this.startIndex);
      this.toastr.success(this.translate.instant('toasterSuccessMessage.userDisabled'));
    }, error => {
      console.error('error', error.error.message);
      this.toastr.error(error.error.message);
    });
  }
}

