import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'; import { RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ClipboardModule } from 'ngx-clipboard';
import { RouteGuardService } from '../../services/route-guard.service ';
import { CommunicationService } from 'src/app/services/communication.service';
import { AdminLayoutRoutes } from './admin-layout.routing';
import { DashboardComponent } from '../../pages/dashboard/dashboard.component';
import { UserProfileComponent } from '../../pages/user-profile/user-profile.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JwtInterceptor } from 'src/app/helper/jwt.interceptor';
import { CdkTableModule } from '@angular/cdk/table';
import { CardsComponent } from '../../pages/dashboard/cards/cards.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { GroupUiModule } from '@sdc-wob-type-3/group-ui-angular';
import { OidcSecurityService } from "angular-auth-oidc-client";
import { environment } from "../../../environments/environment";
import { ComponentsModule } from "../../components/components.module";
import { AppInitService } from 'src/app/helper/app-init.service';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        CommonModule,
        RouterModule.forChild(AdminLayoutRoutes),
        FormsModule,
        HttpClientModule,
        ComponentsModule,
        NgxSliderModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgbModule,
        ClipboardModule,
        CdkTableModule,
        GroupUiModule
    ],
    declarations: [
        DashboardComponent,
        UserProfileComponent,
        CardsComponent
    ],
    exports: [
        CardsComponent
    ],
    providers: [

        RouteGuardService, CommunicationService, { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }, DatePipe]

})

export class AdminLayoutModule {
    constructor() { }
}

export function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.init();
  }
}
