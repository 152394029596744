import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

declare var require: any;
// tslint:disable-next-line:no-var-requires
  const FileSaver = require('file-saver');

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})


export class HelpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }
   downloadPdf() {
      const pdfUrl = './assets/pdf/usage_guide.pdf';
      const pdfName = 'usage_guide';
      FileSaver.saveAs(pdfUrl, pdfName);
    }
}
