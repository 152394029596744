import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunicationService } from 'src/app/services/communication.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-edit-floor',
  templateUrl: './add-edit-floor.component.html',
  styleUrls: ['./add-edit-floor.component.scss']
})
export class AddEditFloorComponent implements OnInit {

  countries = [];
  cities = [];
  buildings = [];
  floorId: any;
  detailsAddEditFloorForm: FormGroup;
  floorDetails: any;
  floorName: any;
  isDisabled: boolean;
  submitted:boolean;
  constructor(
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    private _Activatedroute: ActivatedRoute,
    private router: Router,
    public  translate:  TranslateService,
  ) { }

  ngOnInit(): void {
    this.floorId = this._Activatedroute.snapshot.paramMap.get('floorid');
    this.getCountries();
    this.detailsAddEditFloorForm = this.formBuilder.group(
      {
        country: ['', Validators.required],
        countryName: ['', Validators.required],
        cityName: ['', Validators.required],
        buildingName: ['', Validators.required],
        city: ['', Validators.required],
        building: ['', Validators.required],
        floor: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9\-\/\(\)\,\. ]*')]),
      }
    );
    if (this.floorId != null) {
      this.getFloorDetails(this.floorId);
    }
  }

  get floor() {
    return this.detailsAddEditFloorForm.get('floor');
  }

  getFloorDetails(floorId) {
    this.communicationService.getFloorById(floorId).subscribe(res => {
       this.floorDetails = res;
       this.floorName = res.floor_name;
       this.f.country.setValue(res.country_id);
       this.f.countryName.patchValue(res.country_name);
       this.f.cityName.patchValue(res.city_name);
       this.f.buildingName.patchValue(res.building_name);
       this.getCitiesByCountry(res.country_id);
       this.f.city.setValue(res.city_id);
       this.getBuildings(res.city_id);
       this.f.building.setValue(res.building_id);
  }, error => {
    console.log(error);
  });
  }
  getBuildings(value) {
    this.communicationService.getBuildings(value).subscribe(res => {
      if (res.length > 0) {
             this.buildings = [];
             for (const building of res) {
               if (building.status === '0') {
                 this.buildings.push(building);
               }
             }
        if (this.floorId == null) {
        this.f.building.setValue(res[0].building_id); }
      } else {
        this.buildings = [];
        this.f.building.setValue('');
      }
    }, error => {
      console.error(error);
    });
  }

  getCitiesByCountry(country_id: string) {
    this.communicationService.getCitiesByCountry(country_id).subscribe(res => {
       this.cities = res;
       if (this.floorId == null) {
       this.f.city.setValue(res[0].city_id);
       this.getBuildings(res[0].city_id); }
    }, error => {
      console.error(error);
    });
  }

  getCountries() {
    this.communicationService.getCountries().subscribe(res => {
      this.countries = res;
      if (this.floorId == null) {
      this.f.country.setValue(res[0].country_id);
      this.getCitiesByCountry(res[0].country_id); }
    }, error => {
      console.error(error);
    });
  }

  onCountryChange(value) {
    this.getCitiesByCountry(value.target.value);
  }

  onCityChange(value) {
    this.getBuildings(value.target.value);
  }

  onSubmit(event:any) {
    this.isDisabled = true;
    if (this.detailsAddEditFloorForm.value.floor) {
      const request = {
        floor_id: this.floorId != null ? this.floorId : null,
        building_id: this.detailsAddEditFloorForm.value.building,
        floor_name: this.detailsAddEditFloorForm.value.floor,
        status: '0'
      };
      this.communicationService.addFloor(request).subscribe(res => {
          this.toastr.success(this.translate.instant('toasterSuccessMessage.floorAdded'));
          this.router.navigate(['/view-floor']);
      }, error => {
        this.isDisabled = false;
        this.toastr.error(error.error.message);
        console.log(error);
      });
    } else {
      this.isDisabled = false;
      this.toastr.error(this.translate.instant('addeditfloor.floorNameErrorMsg'));
    }
  }

  get f() {
    return this.detailsAddEditFloorForm.controls;
  }

  onCancel(event:any) {
    this.isDisabled = true;
    this.router.navigate(['/view-floor']);
  }
}
