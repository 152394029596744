import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class RouteGuardService implements CanActivate {

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUserRole: any = JSON.parse( sessionStorage.getItem('currentUser') );
        if ( route.data.role.includes( currentUserRole.role ) ) {
            return true;
        }
        return false;
    }
}
