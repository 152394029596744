import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunicationService } from 'src/app/services/communication.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-edit-organisation',
  templateUrl: './add-edit-organisation.component.html',
  styleUrls: ['./add-edit-organisation.component.scss']
})
export class AddEditOrganisationComponent implements OnInit {
    countries = [];
    organizationId: any;
    detailsAddEditOrganisationForm: FormGroup;
    organizationDetails: any;
    organizationName: any;
  isDisabled: boolean;
  submitted:boolean;

  constructor(
     private formBuilder: FormBuilder,
     private communicationService: CommunicationService,
     private toastr: ToastrService,
     private _Activatedroute: ActivatedRoute,
     private router: Router,
     public  translate:  TranslateService,
  ) { }

  ngOnInit(): void {
    this.organizationId = this._Activatedroute.snapshot.paramMap.get('organizationid');
    this.getCountries();
    this.detailsAddEditOrganisationForm = this.formBuilder.group(
      {
        country: ['', Validators.required],
        organization: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9\-\/\(\)\,\. ]*')]),
      }
    );
      if (this.organizationId != null) {
        this.getOrganizationDetails(this.organizationId);
      }

  }

  get organization() {
    return this.detailsAddEditOrganisationForm.get('organization');
  }

  getCountries() {
      this.communicationService.getCountries().subscribe(res => {
        this.countries = res;
        if (this.organizationId == null) {
          this.f.country.setValue(res[0].country_id);
        }
      }, error => {
        console.error(error);
      });
  }

  getOrganizationDetails(organizationId) {
      this.communicationService.getOrganizationById(organizationId).subscribe(res => {
        this.organizationDetails = res;
        this.organizationName = res.organization_name;
        this.f.country.setValue(res.country_id);
    }, error => {
      console.log(error);
    });
  }

  onSubmit(event:any) {
    this.isDisabled = true;
      if (this.detailsAddEditOrganisationForm.value.organization) {
        const request = {
          country_id: this.detailsAddEditOrganisationForm.value.country,
          organization_name: this.detailsAddEditOrganisationForm.value.organization === '' ? this.organizationName : this.detailsAddEditOrganisationForm.value.organization,
          status: '0',
          organization_id: this.organizationId != null ? this.organizationId : null,
        };
        this.communicationService.addOrganization(request).subscribe(res => {
            this.toastr.success(this.translate.instant('toasterSuccessMessage.organizationAdded'));
            this.router.navigate(['/view-organisation']);
        }, error => {
          this.isDisabled = false;
          this.toastr.error(error.error.message);
        });
      } else {
        this.isDisabled = false;
        this.toastr.error(this.translate.instant('addeditorganization.organizationNameErrorMsg'));
      }
  }

  get f() {
       return this.detailsAddEditOrganisationForm.controls;
  }

  onCancel(event) {
      this.isDisabled = true;
      this.router.navigate(['/view-organisation']);
  }
}
