import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'app-add-parking',
  templateUrl: './add-parking.component.html',
  styleUrls: ['./add-parking.component.css']
})
export class AddParkingComponent implements OnInit {
  detailsAddParkingForm: FormGroup;
  parkings = [];
  parkingId: any;
  organizationDetails: any = null;
  parking_Name: any;
  parking_Count: number;
  parkingStatus: any;
  isDisabled: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    private _Activatedroute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.parkingId = this._Activatedroute.snapshot.paramMap.get('parkingid');
    this.detailsAddParkingForm = this.formBuilder.group(
      {
        parkingName: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9 ]*'), Validators.maxLength(50)]),
        parkingCount: new FormControl('', [Validators.required, Validators.pattern('[0-9 ]*'), Validators.maxLength(8)]),
      }
    );
    this.getParkingList();
    if (this.parkingId != null) {
      this.getParkingDetails(this.parkingId);
    }

  }

  get parkingName() {
    return this.detailsAddParkingForm.get('parkingName');
  }

  get parkingCount() {
    return this.detailsAddParkingForm.get('parkingCount');
  }

  getParkingList() {
    this.communicationService.getAllParkings().subscribe(res => {
      this.parkings = res;
    }, error => {
      console.error(error);
    });
  }

  getParkingDetails(parkingId: any) {
    this.communicationService.getParkingById(parkingId).subscribe(res => {
      this.f.parkingName.setValue(res.name);
      this.f.parkingCount.setValue(res.count);
      this.parkingStatus = res.status;
    }, error => {
      console.log(error);
    });
  }

  get f() {
    return this.detailsAddParkingForm.controls;
  }

  onCancel(event:any) {
    this.isDisabled = true;
    this.router.navigate(['/view-parkings']);
  }

  onSubmit(event:any) {
    this.isDisabled = true;
    if (this.detailsAddParkingForm.value.parkingCount > 0) {
      const request = {
        id: this.parkingId != null ? this.parkingId : null,
        parking_name: this.detailsAddParkingForm.value.parkingName == '' ? this.parking_Name : this.detailsAddParkingForm.value.parkingName,
        total_slots: this.detailsAddParkingForm.value.parkingCount,
        status: this.parkingStatus != null ? this.parkingStatus : '0'
      };
      this.communicationService.updateParking(request).subscribe(res => {
        this.toastr.success('Parking Updated Successfully');
        this.isDisabled = false;
        this.router.navigate(['/view-parkings']);
      }, error => {
        this.toastr.error(error.error.message);
        this.isDisabled = false;

      });
    } else {
      this.toastr.error('Parking Updatation Failed');
      this.isDisabled = false;
    }
  }

}

