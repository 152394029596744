import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
//import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommunicationService } from '../services/communication.service';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GroupSidebarComponent } from './group-sidebar/group-sidebar.component';
import {GroupUiModule} from "@sdc-wob-type-3/group-ui-angular";
import {CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
export  function  HttpLoaderFactory(http:  HttpClient) {
  return  new  TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    HttpClientModule,
    NgxSliderModule, TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }), GroupUiModule
  ],
  declarations: [
    FooterComponent,
   // NavbarComponent,
    SidebarComponent,
    GroupSidebarComponent
  ],
  exports: [
    FooterComponent,
   // NavbarComponent,
    SidebarComponent,
    GroupSidebarComponent
  ],
  providers: [CommunicationService]
})
export class ComponentsModule { }
