import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunicationService } from 'src/app/services/communication.service';
import { ConfirmationDialogService } from 'src/app/pages/confirmation-dialog/confirmation-dialog.service';
import {  Component,  OnInit, LOCALE_ID, Inject } from '@angular/core';
import { formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { User } from 'src/app/model/user';

@Component({
  selector: 'app-book-zoopass',
  templateUrl: './book-zoopass.component.html',
  styleUrls: ['./book-zoopass.component.css']
})
export class BookZoopassComponent implements OnInit {
  detailsBookZooPassForm: FormGroup;
  startDate;
  endDate;
  corpId: any;
  todayDate;
  user: User;
  searchResult = [];
  searchResultSeats = [];
  keySize = 256;
  /* iterations = 1000;
   limit = 30;
   startIndex = 0;*/
  hideElement = true;
  userName: any;
  passCount: number;
  zooPasses = [];
  isDisabled = false;
  mymodal: any;
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private confirmationDialogService: ConfirmationDialogService,
    private toastr: ToastrService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.detailsBookZooPassForm = this.formBuilder.group({
      zooPass: ['', Validators.required],
      employee_id: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      comments: new FormControl('', [Validators.pattern('[a-zA-Z0-9 ]*'), Validators.maxLength(250)]),
      selfBooking: ['', Validators.required],
      type: ['', Validators.required],
      empName: [''],
    });
    const currentDate = new Date();
    this.todayDate = {year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate()};
    const objCurrentDate = { year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate() };
    this.startDate = objCurrentDate;
    this.endDate =  objCurrentDate;
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser.id) {
      this.communicationService.getUserDetails(currentUser.id).subscribe(res => {
         this.detailsBookZooPassForm.controls.employee_id.setValue(res.firstName + ' ' + res.lastName);
         this.userName = res.username;
      }, error => {
        console.log('error', error);
      });
    }
    this.detailsBookZooPassForm.controls.startDate.setValue(this.startDate);
    this.detailsBookZooPassForm.controls.endDate.setValue(this.endDate);
    this.getZooPass(this.startDate, this.endDate);
  }

  get comments() {
    return this.detailsBookZooPassForm.get('comments');
  }

  convertDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  }

  onDateChange(event) {
    this.detailsBookZooPassForm.controls.endDate.setValue(this.detailsBookZooPassForm.value.startDate);
  }

  checkAvailability(){
    this.getZooPass(this.detailsBookZooPassForm.value.endDate , this.detailsBookZooPassForm.value.startDate);
  }

  getZooPass(startDate, endDate) {
    let sDate;
    let eDate;
    if (startDate && endDate) {
      sDate = this.convertDate(startDate);
      eDate = this.convertDate(endDate);
    }
    let sDateFormatted, eDateFormatted;
    if (sDate && eDate) {
      sDateFormatted = formatDate(sDate, 'yyyy-MM-dd', this.locale);
      eDateFormatted = formatDate(eDate, 'yyyy-MM-dd', this.locale);
    }
    this.communicationService.getZooPass(sDateFormatted , eDateFormatted).subscribe(res => {
      this.zooPasses = res;
      //this.f.zooPass.setValue(res[0].zoo_pass_id);
      this.passCount = res[0].count;
    }, error => {
      console.error(error);
    });
  }

  get f() {
    return this.detailsBookZooPassForm.controls;
  }

  public openConfirmationDialog(content) {
    if (this.detailsBookZooPassForm.value.zooPass) {
      const sDate = formatDate(this.convertDate(this.detailsBookZooPassForm.value.startDate),
        'yyyy-MM-dd', this.locale);
      this.confirmationDialogService.confirm('Book Zoo Pass Confirmation',
        'Are you sure you want to book Zoo Pass  from ' + sDate + '?')
        .then((confirmed) => this.onSubmitBook(confirmed, content));
    } else {
      this.toastr.error('Zoo Pass is not selected');
    }
  }

  onSubmitBook(confirmed: any, content) {
    let is_self = true;
    let zoo_pass;
    if (confirmed && this.passCount > 0) {
      zoo_pass =  this.detailsBookZooPassForm.value.zooPass;

      let userRequestObj = {};
      if (this.user) {
        userRequestObj = {
          username: this.user.username,
          first_name: this.user.firstName,
          last_name: this.user.lastName,
          role: '5',
          email: this.user.email,
        };
        is_self = false;
      }
      const startDateVal = formatDate(this.convertDate(this.detailsBookZooPassForm.value.startDate), 'yyyy-MM-dd', this.locale);
      const endDateVal = formatDate(this.convertDate(this.detailsBookZooPassForm.value.endDate), 'yyyy-MM-dd', this.locale);
      if (startDateVal <= endDateVal) {
        const bookZooPassObj = {
          start_date_time: startDateVal + 'T00:00:00',
          end_date_time: endDateVal + 'T23:59:59',
          emp_id: this.userName,
          comments: this.detailsBookZooPassForm.value.comments,
          user_request: userRequestObj,
          is_self,
          zoo_pass_id : zoo_pass,
          email_notification : true
        };
        this.communicationService.bookZooPass(bookZooPassObj).subscribe(res => {
          this.toastr.success('Zoo Pass Booked Successfully');
          this.router.navigate(['/view-bookings']);
        },
          error => {
            console.log('error', error);
            this.toastr.error(error.error.message, 'Failed to Book Zoo Pass');
          });
      } else {
        this.toastr.error('Invalid Booking Date Selection');
      }
    } else if (this.passCount <= 0) {
      this.toastr.error('Zoo Pass full for the selected date.');
    }
  }
}
