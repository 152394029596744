import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunicationService } from 'src/app/services/communication.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-edit-sub-department',
  templateUrl: './add-edit-sub-department.component.html',
  styleUrls: ['./add-edit-sub-department.component.css']
})
export class AddEditSubDepartmentComponent implements OnInit {
    departments = [];
    subDepartmentId: any;
    detailsAddEditSubDepartmentForm: FormGroup;
    subDepartmentDetails: any;
    subDepartmentName: any;
    isDisabled: boolean;
    submitted:boolean=false;
    userRole:any;
    deptDict:any;
    departmentName:any;
    departmentId:any;
    departmentMap:any;
    adminSubdepartment:boolean = true;
    modalReference: NgbModalRef;
    detailsForm: FormGroup;
    userData =new Map<string,string>();

  constructor(
     private formBuilder: FormBuilder,
     private communicationService: CommunicationService,
     private toastr: ToastrService,
     private _Activatedroute: ActivatedRoute,
     private router: Router,
     public  translate:  TranslateService,
     private modalService: NgbModal

  ) { }

  ngOnInit(): void {
    this.getDepartments();
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.userRole = currentUser.role ;
    this.userRole == "Admin" ? this.adminSubdepartment = false : this.adminSubdepartment = true ;
    this.departmentName = currentUser.department;
    this.subDepartmentId = this._Activatedroute.snapshot.paramMap.get('subdepartmentid');
    this.detailsAddEditSubDepartmentForm = this.formBuilder.group({
        department: ['', Validators.required],
        subDepartment: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9\-\/\(\)\,\. ]*')]),
        organization: ['', Validators.required],
        country: ['', Validators.required],
    });
    if (this.subDepartmentId != null) {
        this.getSubDepartmentDetails(this.subDepartmentId);
    }

    this.detailsForm = this.formBuilder.group(
      {
        userId: ['', Validators.required],
        subDepartment:['', Validators.required],
      }
  );
  }

  get subDepartment() {
    return this.detailsAddEditSubDepartmentForm.get('subDepartment');
  }
  get f() {
      return this.detailsAddEditSubDepartmentForm.controls;
  }

  getSubDepartmentDetails(subDepartmentId) {
    this.communicationService.getSubDepartmentById(subDepartmentId).subscribe(res => {
      this.subDepartmentDetails = res;
      this.subDepartmentName = res.sub_department_name;
      // this.f.department.setValue(res.department_id);
      this.detailsAddEditSubDepartmentForm.controls.department.setValue(res.department_id);
      this.f.organization.setValue(res.organization_name);
      this.f.country.setValue(res.country_name);
    }, error => {
    console.log(error);
    });
  }

  getDepartments() {
      this.communicationService.getDepartments().subscribe(res => {
        this.departments = res;
        this.departmentMap = new Map(this.departments.map(dept => [dept.department_name, dept.department_id]));
        if (this.subDepartmentId == null && this.adminSubdepartment == true) {
              this.detailsAddEditSubDepartmentForm.controls.department.setValue(res[0].department_id);
        } else if (this.adminSubdepartment == false) {
              this.detailsAddEditSubDepartmentForm.controls.department.setValue(this.departmentName);
        }
      }, error => {
        console.error(error);
      });
  }

  onSubmit(event:any) {
    this.isDisabled = true;
    if (this.detailsAddEditSubDepartmentForm.value.subDepartment) {
      const request = {
        department_id: this.detailsAddEditSubDepartmentForm.value.department,
        sub_department_name: this.detailsAddEditSubDepartmentForm.value.subDepartment === '' ? this.subDepartmentName : this.detailsAddEditSubDepartmentForm.value.subDepartment,
        status: '0',
        sub_department_id: this.subDepartmentId != null ? this.subDepartmentId : null,
      };
      this.communicationService.addSubdepartment(request).subscribe(res => {
          this.toastr.success(this.translate.instant('toasterSuccessMessage.subDepartmentAdded'));
          this.router.navigate(['/view-sub-department']);
      }, error => {
        this.isDisabled = false;
        this.toastr.error(error.error.message);
      });
    } else {
      this.isDisabled = false;
      this.toastr.error(this.translate.instant('addeditsubdepartment.subDepartmentNameErrorMsg'));
    }
  }

  getDepartmentId(departmentName) {
      return this.departmentMap.get(departmentName);
  }

  onAdminSubmit(event:any){
     this.isDisabled = true;
     this.departmentId = this.getDepartmentId(this.departmentName);
     if (this.detailsAddEditSubDepartmentForm.value.subDepartment) {
     const request = {
             department_id: this.departmentId,
             sub_department_name: this.detailsAddEditSubDepartmentForm.value.subDepartment === '' ? this.subDepartmentName : this.detailsAddEditSubDepartmentForm.value.subDepartment,
             status: '0',
             sub_department_id: this.subDepartmentId != null ? this.subDepartmentId : null,
           };
     this.communicationService.addSubdepartment(request).subscribe(res => {
               this.toastr.success(this.translate.instant('toasterSuccessMessage.subDepartmentAdded'));
               this.router.navigate(['/add-edit-sub-department']);
     }, error => {
             this.isDisabled = false;
             this.toastr.error(error.error.message);
     });
     } else {
        this.isDisabled = false;
        this.toastr.error(this.translate.instant('addeditsubdepartment.subDepartmentNameErrorMsg'));
     }
  }

  onCancel(event:any) {
    this.isDisabled = true;
    this.router.navigate(['/view-sub-department']);
  }
  getUserRequestList(){
    this.communicationService.getUserRequest().subscribe(res => {   
      for (const user of res) {
       if(this.userData.size >0 && this.userData.has(user.emp_id) && user.status =='1'){
            this.userData.delete(user.emp_id);
       } else if( user.status =='0'){
        this.userData.set(user.emp_id,user.sub_department);}
    }
    })
  }

  approveUserRequest(addUserModal){
  this.getUserRequestList();
   this.modalReference = this.modalService.open(addUserModal);
  }

  addEmployee(empId,subDept){
    const userDetails ={
      emp_id:empId,
      sub_department_name:subDept,
      username:localStorage.getItem('userName'),
      email_id:localStorage.getItem('emailId'),
      country_id:'',
      building_id:'',
      floor_id:'',
      comment:'',
      status: '0',
    };
  this.communicationService.addUserDepartment(userDetails).subscribe(res => {
    this.toastr.success(this.translate.instant('toasterSuccessMessage.subDepartmentAdded'));
    this.getUserRequestList();

}, error => {
  this.toastr.error(error.error.message, 'Failed to add user department');
  this.modalReference.close();
});
} 
cancelEmployeeRequest(empId,subDept){
  const userDetails ={
    emp_id:empId,
    sub_department_name:subDept,
    username:localStorage.getItem('userName'),
    email_id:localStorage.getItem('emailId'),
  };
  this.communicationService.rejectUserRequest(userDetails).subscribe(res => {

    this.toastr.success(this.translate.instant('toasterSuccessMessage.subDepartmentRejected'));
    this.userData.delete(empId);
    this.getUserRequestList();

  }, error => {
    this.toastr.error(error.error.message, 'Failed to cancel the the request');
    this.modalReference.close();
  });
  } 

}
