import {Component, Inject, LOCALE_ID, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {User} from '../../model/user';
import {CommunicationService} from '../../services/communication.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import * as XLSX from 'xlsx';
import {take} from 'rxjs/operators';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-fire-warden-bookings',
  templateUrl: './fire-warden-bookings.component.html',
  styleUrls: ['./fire-warden-bookings.component.css']
})
export class FireWardenBookingsComponent implements OnInit {
  private currentUser: any;
  private features: any;
  submitted: boolean = false;
  isDisabled:boolean = false;
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal
  ) {
  }

  get f() {
    return this.detailsFireWardenBookingsForm.controls;
  }

  get commentsRenew() {
    return this.detailsFireWardenBookingsFormRenew.get('commentsRenew');
  }

  // @ViewChild("viewOnMapComp") viewOnMapComponent: ViewOnMapComponent;
  modalReference: NgbModalRef;
  detailsFireWardenBookingsForm: FormGroup;
  detailsFireWardenBookingsFormRenew: FormGroup;
  heroes$;
  seat_number;
  emp_number;
  superlatives$ = new BehaviorSubject<{ [superlativeName: string]: string }>({});
  tableDataSource$ = new BehaviorSubject<any[]>([]);
  displayedColumns$ = new BehaviorSubject<string[]>([
    'emp_name',
    'seat_number',
    'building',
    'floor',
    'start_date',
    'end_date',
    'status_msg',
    /*'parking',
    'zoo_pass',
    'id'*/
  ]);
  start_Date: Date;
  end_Date: Date;
  cities = [];
  user: User;
  hideElement = true;
  currentPage$ = new BehaviorSubject<number>(1);
  pageSize$ = new BehaviorSubject<number>(10);
  dataOnPage$ = new BehaviorSubject<any[]>([]);
  searchFormControl = new FormControl();
  sortKey$ = new BehaviorSubject<string>('emp_id');
  sortDirection$ = new BehaviorSubject<string>('asc');
  totalBookingCount = 0;
  limit = 10;
  startIndex = 0;
  status = [];
  bookingTypes = [];
  moreDetailsData = null;
  closeResult: string;
  todayDate;
  downloadAll = true;
  isSearch = false;
  countries = [];
  floor_name;
  floors = [];
  buildings = [];
  wings = [];
  isReportDownload = false;
  floorId: any;

  @ViewChild('spinnermodal')
  private defaultTabButtonsTpl: TemplateRef<any>;

  fileName = 'report.xlsx';

  ngOnInit() {
    this.detailsFireWardenBookingsForm = this.formBuilder.group(
      {
        country: ['', Validators.required],
        floor: ['', Validators.required],
        wing: ['', Validators.required],
        building: ['', Validators.required],

        startDate: new FormControl(),
        endDate: new FormControl(),
        status: [''],
        city: [''],
        employee_id: [''],
        type: [''],
      }
    );
    this.detailsFireWardenBookingsFormRenew = this.formBuilder.group(
      {
        startDateRenew: new FormControl(),
        endDateRenew: new FormControl(),
        seat_id: new FormControl(),
        floor_id: new FormControl(),
        wing_id: new FormControl(),
        commentsRenew: new FormControl('', [Validators.pattern('[a-zA-Z0-9 ]*'), Validators.maxLength(250)]),

      }
    );
    this.getCountries();
    this.getAllBookings(this.limit, this.startIndex);
    this.searchFormControl.setValue('');
    this.getBookingStatus();
    const currentDate = new Date();
    const objCurrentDate = {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate()
    };
    this.todayDate = objCurrentDate;
    this.start_Date = this.todayDate;
    this.end_Date = this.todayDate;

    this.detailsFireWardenBookingsForm.controls.startDate.setValue(this.todayDate);
    this.detailsFireWardenBookingsForm.controls.endDate.setValue(this.todayDate);

    this.detailsFireWardenBookingsFormRenew.controls.startDateRenew.setValue(this.todayDate);
    this.detailsFireWardenBookingsFormRenew.controls.endDateRenew.setValue(this.todayDate);

    this.bookingTypes = [{booking_type_id: 'All', booking_type_name: 'All'},
      {booking_type_id: 'Workstation', booking_type_name: 'Workstation'},
      {booking_type_id: 'Parking', booking_type_name: 'Parking'},
      {booking_type_id: 'ZooPass', booking_type_name: 'Zoo Pass'}
    ];
    this.detailsFireWardenBookingsForm.controls.type.setValue(this.bookingTypes[0].booking_type_id);
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser.id) {
      this.communicationService.getUserDetails(currentUser.id).subscribe(res => {
        this.detailsFireWardenBookingsForm.controls.employee_id.setValue(res.username);
      }, error => {
        console.log('error', error);
      });
    }
  }

  getBuildings(value) {
    this.communicationService.getBuildings(value).subscribe(res => {
       this.buildings = [];
              for (const building of res) {
                if (building.status === '0') {
                  this.buildings.push(building);
                }
              }
      this.f.building.setValue(res[0].building_id);
      this.getFloors(res[0].building_id);
    }, error => {
      console.error(error);
     // this.modalReference.close();
    });
  }

  onBuildingChange(value) {
    this.getFloors(value.target.value);
  }

  getFloors(building_id: string) {
    this.communicationService.getFloorsByBuilding(building_id).subscribe(res => {
      this.floors = res;
      this.f.floor.setValue(res[0].floor_id);
      this.floor_name = res[0].floor_name;
      this.getWings(res[0].floor_id);
    }, error => {
      console.error(error);
     // this.modalReference.close();
    });
  }

  getWings(floor_id: string) {
    this.communicationService.getWings(floor_id).subscribe(res => {
      this.wings = res;
      this.f.wing.setValue(res[0].wing_id);
     // this.modalReference.close();
    }, error => {
      console.error(error);
     // this.modalReference.close();
    });
  }

  getBookingStatus() {
    this.communicationService.getBookingStatus().subscribe(res => {
      this.status = res;
    }, error => {
      console.error(error);
    });
  }

  onDateChange(event) {
    this.detailsFireWardenBookingsFormRenew.controls.endDateRenew.setValue(this.detailsFireWardenBookingsFormRenew.value.startDateRenew);
  }

  onNewDateChange(event) {
    this.detailsFireWardenBookingsForm.controls.endDate.setValue(this.detailsFireWardenBookingsForm.value.startDate);
  }

  adjustSort(key: string) {
    if (this.sortKey$.value === key) {
      if (this.sortDirection$.value === 'asc') {
        this.sortDirection$.next('desc');
      } else {
        this.sortDirection$.next('asc');
      }
      return;
    }
    this.sortKey$.next(key);
    this.sortDirection$.next('asc');
  }

  exportexcel() {
    let data;
    const responderType = 'Fire Warden';
    if (this.downloadAll) {
      this.isReportDownload = true;
      this.communicationService.getAllResponderBookings(this.limit, this.startIndex, this.isReportDownload, responderType)
        .subscribe(res => {
        data = res.bookingResponderList;
        this.prepareExcel(data);
      }, error => {
        console.error(error);
      });
    } else {
      data = this.dataOnPage$.value;
      this.prepareExcel(data);
    }
  }

  prepareExcel(data: any) {
    const arr = [];
    for (const obj of data) {
      const wsDetails = {
        'emp_name': obj.emp_name,
        'seat_number': obj.seat_number,
        'building': obj.building,
        'floor': obj.floor_name,
        'start_date': obj.start_date,
        'end_date': obj.end_date,
        'status': obj.status_msg,
        'responder_type': obj.responder_type
      };
      if (wsDetails.responder_type != null && wsDetails.responder_type !== 'First Aid Responder') {
        arr.push(wsDetails);
      }
    }

    const Heading = [
      ['EMPLOYEE NAME', 'WORKSTATION NUMBER', 'LOCATION', 'LEVEL', 'START DATE', 'END DATE', 'STATUS', 'RESPONDER TYPE'],
    ];

    // Had to create a new workbook and then add the header
    const wb = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, Heading);

    // Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(ws, arr, {origin: 'A2', skipHeader: true});

    XLSX.utils.book_append_sheet(wb, ws, 'Report');

    XLSX.writeFile(wb, this.fileName);
    this.toastr.success('File Downloaded succesfully');
  }

  onFloorChange(value) {
    if (value) {
      this.floorId = this.detailsFireWardenBookingsForm.value.floor;
      this.floor_name = value.target.options[value.target.selectedIndex].text;
    }
  }

  getCountries() {
    this.communicationService.getCountries().subscribe(res => {
      this.countries = [];
      for (const country of res) {
        if (country.status === '0' && country.country_name === 'Australia') {
          this.countries.push(country);
        }
      }
      if (this.countries.length > 0) {
        this.f.country.setValue(this.countries[0].country_id);
        this.getCitiesByCountry(this.countries[0].country_id);
      } else {
        this.countries = [];
        this.f.country.setValue('');
        this.cities = [];
        this.f.city.setValue('');
        this.buildings = [];
        this.f.building.setValue('');
        this.floors = [];
        this.f.floor.setValue('');
      }
    }, error => {
     // this.modalReference.close();
      this.toastr.error(error.error.message);
    });
  }

  getCitiesByCountry(country_id: string) {
    this.communicationService.getCitiesByCountry(country_id).subscribe(res => {
      if (res.length > 0) {
        this.cities = [];
        for (const city of res) {
          if (city.status === '0') {
            this.cities.push(city);
          }
        }
        if (this.cities.length > 0) {
          this.f.city.setValue(this.cities[0].city_id);
          this.getBuildings(this.cities[0].city_id);
        } else {
          this.cities = [];
          this.f.city.setValue('');
          this.buildings = [];
          this.f.building.setValue('');
          this.floors = [];
          this.f.floor.setValue('');
        }
      } else {
        this.cities = [];
        this.f.city.setValue('');
        this.buildings = [];
        this.f.building.setValue('');
        this.floors = [];
        this.f.floor.setValue('');
      }
    }, error => {
     // this.modalReference.close();
      console.error(error);
    });
  }

  handlePageChange(event, content): void {
    this.startIndex = event - 1;
    if (this.isSearch) {
      this.search(content);
    } else {
      this.getAllBookings(this.limit, this.startIndex);
    }
  }

  onCityChange(value) {
    this.getBuildings(value.target.value);
  }

  onCountryChange(value) {
    this.getCitiesByCountry(value.target.value);
  }

  getAllBookings(limit: number, index: number) {
    const bookingData = [];
    const responderType = 'Fire Warden';
    this.isReportDownload = false;
    let n = 0;
    this.communicationService.getAllResponderBookings(limit, index, this.isReportDownload, responderType).subscribe(res => {
      for (const obj of res.bookingResponderList) {
        // tslint:disable-next-line:max-line-length
        if (res.bookingResponderList[n].responder_type === 'Fire Warden' || res.bookingResponderList[n].responder_type === 'First Aid Responder & Fire Warden' ) {
          bookingData.push(obj);
        }
        n = n + 1;
      }

      this.totalBookingCount = res.totalPageCount;
      this.heroes$ = new BehaviorSubject(bookingData);
      combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
        .subscribe(([allSources, currentPage, pageSize]) => {
          const startingIndex = (currentPage - 1) * pageSize;
          const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
          this.dataOnPage$.next(onPage);
        });

      this.heroes$.pipe(take(1)).subscribe(heroData => {
        this.tableDataSource$.next(Object.values(heroData));
      });
      combineLatest(this.heroes$, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, sortKey, sortDirection]) => {
            if (this.sortKey$.value === 'start_date') {
              const heroesArray = Object.values(changedHeroData);
              const sortedHeroes = heroesArray.sort((a, b) => {
                if (this.getDateFormat(a.start_date) > this.getDateFormat(b.start_date)) { return sortDirection === 'asc' ? 1 : -1; }
                if (this.getDateFormat(a.start_date) < this.getDateFormat(b.start_date)) { return sortDirection === 'asc' ? -1 : 1; }
                return 0;
              });
              this.tableDataSource$.next(sortedHeroes);
            } else if (this.sortKey$.value === 'end_date') {
              const heroesArray = Object.values(changedHeroData);
              const sortedHeroes = heroesArray.sort((a, b) => {
                if (this.getDateFormat(a.end_date) > this.getDateFormat(b.end_date)) { return sortDirection === 'asc' ? 1 : -1; }
                if (this.getDateFormat(a.end_date) < this.getDateFormat(b.end_date)) { return sortDirection === 'asc' ? -1 : 1; }
                return 0;
              });
              this.tableDataSource$.next(sortedHeroes);
            } else {
              const heroesArray = Object.values(changedHeroData);
              const sortedHeroes = heroesArray.sort((a, b) => {
                if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
                if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
                return 0;
              });
              this.tableDataSource$.next(sortedHeroes);
            }
          }
        );

      combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          let filteredHeroes: any[];
          if (!searchTerm) {
            filteredHeroes = heroesArray;
            this.totalBookingCount = res.bookingResponderList.length;
          } else {
            const filteredResults = heroesArray.filter(hero => {
              return Object.values(hero)
                .reduce((prev, curr) => {
                  return prev || curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
                }, false);
            });
            filteredHeroes = filteredResults;
            if(filteredHeroes.length >= 0){
              this.totalBookingCount = filteredHeroes.length;
            }
          }
          const sortedHeroes = filteredHeroes.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });

          this.tableDataSource$.next(sortedHeroes);
        });
    }, error => {
      this.modalReference.close();
    });
  }

  getDateFormat(date) {
    const str = date;
    const [days, months, years] = str.split('-');
    const dateFormatted = new Date(+years, +months - 1, +days);
    return dateFormatted;
  }

  /*getMoreDetails(seat_number: any, username: any, content, floor_id: any, wing_id: any) {
    this.seat_number = seat_number;
    this.emp_number = username;
    this.detailsFireWardenBookingsFormRenew.controls.startDateRenew.setValue(this.todayDate)
    this.detailsFireWardenBookingsFormRenew.controls.endDateRenew.setValue(this.todayDate);

    this.detailsFireWardenBookingsFormRenew.controls.floor_id.setValue(floor_id);
    this.detailsFireWardenBookingsFormRenew.controls.wing_id.setValue(wing_id);
    this.moreDetailsData = null;
    this.detailsFireWardenBookingsFormRenew.controls.seat_id.setValue(this.seat_number);

    this.modalReference = this.modalService.open(content);
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }*/



  convertDate(date) {
    if (date != null || date !== '') {
      const converdate = new Date(date.year, date.month - 1, date.day);
      return converdate;
    }
  }

  search(content) {
    const limit = this.limit;
    const index = this.startIndex;
    const bookingData = [];
    // tslint:disable-next-line:one-variable-per-declaration
    let sDate, eDate;
    this.isSearch = true;

    this.modalReference = this.modalService.open(content);
    if (this.detailsFireWardenBookingsForm.value.startDate) {
      sDate = this.convertDate(this.detailsFireWardenBookingsForm.value.startDate);
    }
    if (this.detailsFireWardenBookingsForm.value.endDate) {
      eDate = this.convertDate(this.detailsFireWardenBookingsForm.value.endDate);
    }
    // tslint:disable-next-line:one-variable-per-declaration
    let sDateFormatted, eDateFormatted;

    if (sDate) {
      sDateFormatted = formatDate(sDate, 'yyyy-MM-dd', this.locale);
    }
    if (eDate) {
     // eDate.setDate(eDate.getDate() + 1);
      eDateFormatted = formatDate(eDate, 'yyyy-MM-dd', this.locale);
    }
    const searchObj = {
      start_date: sDateFormatted,
      end_date: eDateFormatted,
      status: this.detailsFireWardenBookingsForm.value.status,
      building_id: this.detailsFireWardenBookingsForm.value.building,
      floor_id: this.detailsFireWardenBookingsForm.value.floor,
      city_id: this.detailsFireWardenBookingsForm.value.city,
      isReportDownload: false,
      responder: 'Fire Warden'
    };
    this.communicationService.getAllResponderBookingsFilter(limit, index, searchObj).subscribe(res => {
      for (const obj of res.bookingResponderList) {
        // if (obj.status == 0) {
        bookingData.push(obj);
        // }
      }
      this.totalBookingCount = res.totalPageCount;
      this.heroes$ = new BehaviorSubject(bookingData);
      this.modalReference.close();
      combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
        .subscribe(([allSources, currentPage, pageSize]) => {
          const startingIndex = (currentPage - 1) * pageSize;
          const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
          this.dataOnPage$.next(onPage);
        });

      this.heroes$.pipe(take(1)).subscribe(heroData => {
        this.tableDataSource$.next(Object.values(heroData));
      });

      combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          let filteredHeroes: any[];

          if (!searchTerm) {
            filteredHeroes = heroesArray;
          } else {
            const filteredResults = heroesArray.filter(hero => {
              return Object.values(hero)
                .reduce((prev, curr) => {
                  return prev || curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
                }, false);
            });
            filteredHeroes = filteredResults;
          }

          const sortedHeroes = filteredHeroes.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });
          this.tableDataSource$.next(sortedHeroes);
        });
    }, error => {
      this.modalReference.close();
      console.log(error);
    });
  }

}
