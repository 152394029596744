import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunicationService } from 'src/app/services/communication.service';
import { formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AfterViewInit, Component, LOCALE_ID, Inject, TemplateRef, ViewChild } from '@angular/core';
import { ConfirmationDialogService } from 'src/app/pages/confirmation-dialog/confirmation-dialog.service';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ValidationService } from 'src/app/services/validation.service';
import { TimeByHour } from 'src/app/constants/timeList';

@Component({
  selector: 'app-qr-code-booking',
  templateUrl: './qr-code-booking.component.html',
  styleUrls: ['./qr-code-booking.component.scss']
})
export class QrCodeBookingComponent implements  AfterViewInit {
  modalReference: NgbModalRef;
  searchResultSeats = [];
  detailsQRCodeBookingForm: FormGroup;
  thumbnail: any;
  todayDate;
  employeeId: any;
  loading = false;
  limit = 20;
  startIndex = 0;
  countries = [];
  cities = [];
  buildings = [];
  loader = false;
  bookingTimeList: any;
  startTimeValue = 0;
  isBookByTime = false;
  submitted:boolean;
  isDisabled:boolean;
  floorId:any;
  wingId:any;

  @ViewChild('mymodal')
  private defaultTabButtonsTpl: TemplateRef<any>;
  validatated: boolean;
  private seatName: string;
  private type: boolean;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    public _DomSanitizationService: DomSanitizer,
    private toastr: ToastrService,
    private router: Router,
    private validationService: ValidationService,
    private modalService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService,
    public  translate:  TranslateService) {
  }

  ngOnInit(): void {
    this.detailsQRCodeBookingForm = this.formBuilder.group(
      {
        seatName: ['', Validators.required],
        employee_id: ['', Validators.required],
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
        comments: new FormControl('', [Validators.pattern('[a-zA-Z0-9 ]*'), Validators.maxLength(250)]),
        country: ['', Validators.required],
        city: ['', Validators.required],
        building: ['', Validators.required],
        startTime : ['', Validators.required],
        endTime : ['', Validators.required],
      }
    );

    const currentDate = new Date();
    const objCurrentDate = { year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate() };
    this.todayDate = objCurrentDate;

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    const seatId = JSON.parse(localStorage.getItem('qrId'));
    localStorage.removeItem('qrId');
    if (currentUser.id) {
      this.communicationService.getUserDetails(currentUser.id).subscribe(res => {
        this.detailsQRCodeBookingForm.controls.employee_id.setValue(res.firstName + ' ' + res.lastName);
        this.employeeId = res.username;
      }, error => {
        console.log('error', error);
      });
    }
    this.detailsQRCodeBookingForm.controls.startDate.setValue(this.todayDate);
    this.detailsQRCodeBookingForm.controls.endDate.setValue(this.todayDate);

    this.getSeatDetails(seatId);
    this.setStartEndTime();

  }

  getSeatDetails(seatId) {
    this.communicationService.getSeatById(seatId).subscribe(res => {
      this.seatName = res.name;
      this.floorId = res.floor_id;
      this.wingId = res.wing_id;
      this.f.seatName.setValue(res.name);
      this.modalReference.close();
    }, error => {
      console.error(error);
      this.router.navigate(['/dashboard']);
    });
  }

  logout(){
    this.router.navigate(['/logout'])
  }

  onDateChange(event) {
    this.detailsQRCodeBookingForm.controls.endDate.patchValue(this.detailsQRCodeBookingForm.value.startDate);
  }

  get f() {
    return this.detailsQRCodeBookingForm.controls;
  }

  getSearchResultSeats = (val) => {
    const queryData = val.target.value;
    if (queryData.length >= 2) {
      this.communicationService.getSeatsFilter(this.limit, this.startIndex, queryData).subscribe(res => {
        this.searchResultSeats = res;
      }, error => {
        this.searchResultSeats.length = 0;
        console.log('Getting error while searching result for seats');
      });
    } else {
      this.searchResultSeats.length = 0;
    }
  }

  getSearchSeatsData(data: any) {
    let myForm = this.detailsQRCodeBookingForm.value.seatName;
    if (typeof (data) === 'object') {
      myForm = data.name;
      this.detailsQRCodeBookingForm.controls['seatName'].setValue(myForm);
      this.searchResultSeats.length = 0;
    }
  }

  setStartEndTime() {
    this.bookingTimeList = TimeByHour;
    this.f.startTime.setValue(0);
    this.f.endTime.setValue(24);
    this.detailsQRCodeBookingForm.get('startTime').disable();
    this.detailsQRCodeBookingForm.get('endTime').disable();
  }

  ngAfterViewInit(): void {

    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.modalReference = this.modalService.open(this.defaultTabButtonsTpl, ngbModalOptions);

  }

  deselectTimeBooking(event) {
    if (event.target.checked) {
      this.detailsQRCodeBookingForm.get('startTime').enable();
      this.detailsQRCodeBookingForm.get('endTime').enable();
      this.isBookByTime = true;
    } else {
      this.isBookByTime = false;
      this.f.startTime.setValue(0);
      this.f.endTime.setValue(24);
      this.detailsQRCodeBookingForm.get('startTime').disable();
      this.detailsQRCodeBookingForm.get('endTime').disable();
    }
  }

  onStartTimeChange(value) {
    this.startTimeValue =  value.target.value;
    let val = this.startTimeValue;
    this.setEndTime(++val);
  }

  setEndTime(val) {
    this.f.endTime.setValue(val);
  }

  onEndTimeChange(value) {
    let endTimeValue =  value.target.value;
    // tslint:disable-next-line:radix
    if (parseInt(endTimeValue) > this.startTimeValue) {
      this.setEndTime(endTimeValue);
    } else {
      endTimeValue = this.startTimeValue;
      this.setEndTime(++endTimeValue);
    }
  }

  onSubmitBook(confirmed: any, content) {
    if (confirmed) {
      const startDateVal = formatDate(this.convertDate(this.detailsQRCodeBookingForm.value.startDate), 'yyyy-MM-dd', this.locale);
      const endDateVal = formatDate(this.convertDate(this.detailsQRCodeBookingForm.value.endDate), 'yyyy-MM-dd', this.locale);
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false
      };
      this.modalReference = this.modalService.open(content, ngbModalOptions);
      if (startDateVal <= endDateVal) {
        const bookSeatObj = {
          // start_date: startDateVal,
          // end_date: endDateVal,
          start_date_time: this.isBookByTime ? startDateVal + this.bookingTimeList[this.detailsQRCodeBookingForm.get('startTime').value].timeFormat :
            startDateVal + 'T00:00:00'   ,
          end_date_time: this.isBookByTime ? endDateVal + this.bookingTimeList[this.detailsQRCodeBookingForm.get('endTime').value].timeFormat :
            endDateVal + 'T23:59:59',
          emp_id: this.employeeId,
          seat_id: this.detailsQRCodeBookingForm.value.seatName,
          status: 0,
          comments: this.detailsQRCodeBookingForm.value.comments,
          futureReference: false,
          is_self: true,
          floor_id: this.floorId,
          wing_id: this.wingId
        };
        this.communicationService.bookSeat(bookSeatObj).subscribe(res => {
            this.modalReference.close();
            this.toastr.success(this.translate.instant('toasterSuccessMessage.workStationBooked'));
          },
          error => {
            this.modalReference.close();
            console.error(error);
            this.toastr.error(error.error.message, 'Failed to book workstation');
          });
      } else {
        this.modalReference.close();
        this.toastr.error('Invalid Booking Date Selection');
      }
    }
  }

  get comments() {
    return this.detailsQRCodeBookingForm.get('comments');
  }

  public openConfirmationDialog(content) {
    const startDate = formatDate(this.convertDate(this.detailsQRCodeBookingForm.value.startDate),
      'yyyy-MM-dd', this.locale);
    const endDate = formatDate(this.convertDate(this.detailsQRCodeBookingForm.value.endDate),
      'yyyy-MM-dd', this.locale);
    if (this.detailsQRCodeBookingForm.value.seatName ) {
      const sDate = this.isBookByTime ? startDate + this.bookingTimeList[this.detailsQRCodeBookingForm.get('startTime').value].timeFormat :
        startDate + 'T00:00:00';
      const eDate = this.isBookByTime ? endDate + this.bookingTimeList[this.detailsQRCodeBookingForm.get('endTime').value].timeFormat :
        endDate + 'T23:59:59';
      this.confirmationDialogService.confirm('Book Workstation Confirmation',
        'Are you sure you want to book Workstation ' + this.detailsQRCodeBookingForm.value.seatName + ' from ' + sDate + ' to ' + eDate + '?')
        .then((confirmed) => this.onSubmitBook(confirmed, content));
    } else {
      this.toastr.error('Workstation is not selected');
    }
  }

  isInputSelected() {
    if (this.f.wing.value === undefined
      || this.f.wing.value === '' || this.f.floor.value === undefined || this.f.floor.value === '') {
      return false;
    }
    return true;
  }

  convertDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  }
}
