import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CommunicationService} from 'src/app/services/communication.service';
import {ConfirmationDialogService} from 'src/app/pages/confirmation-dialog/confirmation-dialog.service';
import {Coordinate} from 'src/app/model/coordinate';
import {AfterViewInit, Component, ElementRef, Inject, LOCALE_ID, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {formatDate} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {FloorPath} from 'src/app/model/floorPath';
import {User} from 'src/app/model/user';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {ValidationService} from 'src/app/services/validation.service';
import {TimeByHour} from 'src/app/constants/timeList';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-book-seat-au',
  templateUrl: './book-seat-au.component.html',
  styleUrls: ['./book-seat-au.component.scss']
})
export class BookSeatAuComponent implements OnInit {
  modalReference: NgbModalRef;

  @ViewChild('canvas', {static: true})
  canvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('tooltip', {static: true})
  tooltip: ElementRef<HTMLCanvasElement>;

  @ViewChild('mymodal')
  private defaultTabButtonsTpl: TemplateRef<any>;
  hideTooltip = false;
  selectedSeat: any;
  floorPlanCtx: CanvasRenderingContext2D;
  tooltipCtx: CanvasRenderingContext2D;
  workstationNameForm: FormGroup;
  floorPlanImage: HTMLImageElement;
  detailsBookSeatAUForm: FormGroup;
  cities = [];
  countries = [];
  floors = [];
  buildings = [];
  wings = [];
  coordinates: Coordinate[] = [];
  floor_id: any;
  wing_id: any;
  todayDate;
  loading = false;
  is_selected = false;
  is_map_shown = false;
  selectedSeats: any;
  corpId: any;
  user: User;
  searchResult = [];
  searchResultSeats = [];
  zones = [];
  isSelfBooking = true;
  keySize = 256;
  iterations = 1000;
  limit = 10000;
  startIndex = 0;
  showAttribute = 0;
  metaJsonObject = {};
  viewAttribute: string;
  seat_name: string;
  displayMap = new Map<string, string>();
  seat_name2: string;
  viewAttribute2: string;
  metaJsonObject2 = {};
  displayMap2 = new Map<string, string>();
  viewAttribute3: string;
  seat_name3: string;
  metaJsonObject3 = {};
  displayMap3 = new Map<string, string>();
  popoverAttribute: string;
  totalBookingCount: any;
  validatated: any;
  bookingTimeList: any;
  selectedZoneMessage: any;
  startTimeValue = 0;
  isBookByTime = false;
  isCarParking = false;
  hideParking = false;
  emp_name;
  tableDataSource$ = new BehaviorSubject<any[]>([]);
  displayedColumns$ = new BehaviorSubject<string[]>([
    'emp_name',
    'seat_number',
    'building',
    'floor',
    'start_date',
    'end_date',
    'status_msg',
  ]);
  totalUserCount = 0;
  bookingResponderList: Coordinate[] = [];
  searchFormControl = new FormControl();
  currentPage$ = new BehaviorSubject<number>(1);
  pageSize$ = new BehaviorSubject<number>(10);
  dataOnPage$ = new BehaviorSubject<any[]>([]);
  sortKey$ = new BehaviorSubject<string>('start_date_time');
  sortDirection$ = new BehaviorSubject<string>('asc');
  heroes$: BehaviorSubject<any[]>;
  private firewardenResponder: boolean;
  private firstAidResponder: boolean;
  submitted = false;
  isDisabled = false;
  emailNotification = true ;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private confirmationDialogService: ConfirmationDialogService,
    private validationService: ValidationService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    public translate: TranslateService) {
  }

  // tslint:disable-next-line:use-lifecycle-interface use-life-cycle-interface

  ngOnInit(): void {
    this.getCountries();
    this.detailsBookSeatAUForm = this.formBuilder.group({
      city: ['', Validators.required],
      country: ['', Validators.required],
      floor: ['', Validators.required],
      wing: ['', Validators.required],
      building: ['', Validators.required],
      seat_id: ['', Validators.required],
      employee_id: ['', Validators.required],
      employee_name: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      comments: new FormControl('', [Validators.pattern('[a-zA-Z0-9 ]*'), Validators.maxLength(250)]),
      selfBooking: ['', Validators.required],
      email_notification: [ true, Validators.required],
      empName: [''],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      workstationName: [''],
      zone: ['', Validators.required],
      isCarParking: ['', Validators.required]
    });
    this.workstationNameForm = this.formBuilder.group({
      name: ['', Validators.required],
      name2: ['', Validators.required],
      name3: ['', Validators.required],
    });

    const currentDate = new Date();
    this.todayDate = {year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate()};

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser.id) {
      this.communicationService.getUserDetails(currentUser.id).subscribe(res => {
        this.detailsBookSeatAUForm.controls.employee_name.setValue(res.firstName + ' ' + res.lastName);
        this.detailsBookSeatAUForm.controls.employee_id.patchValue(res.username);
      }, error => {
        console.log('error', error);
      });
    }
    this.detailsBookSeatAUForm.controls.startDate.setValue(this.todayDate);
    this.detailsBookSeatAUForm.controls.endDate.setValue(this.todayDate);
    this.setStartEndTime();
    this.getZones(this.detailsBookSeatAUForm.value.startDate, this.detailsBookSeatAUForm.value.endDate);
  }

  get f() {
    return this.detailsBookSeatAUForm.controls;
  }

  get comments() {
    return this.detailsBookSeatAUForm.get('comments');
  }

  onDateChange() {
    this.detailsBookSeatAUForm.controls.endDate.setValue(this.detailsBookSeatAUForm.value.startDate);
    this.onSubmit();
    this.getZones(this.detailsBookSeatAUForm.value.startDate, this.detailsBookSeatAUForm.value.endDate);
  }

  onEndDateChange() {
    this.onSubmit();
    this.getZones(this.detailsBookSeatAUForm.value.startDate, this.detailsBookSeatAUForm.value.endDate);
  }

  validatingComment() {
    this.validatated = this.validationService.validationComment(this.detailsBookSeatAUForm.value.comments);
  }

  onCarParkingChange(event) {
    if (event.target.checked) {
      this.isCarParking = true;
      this.getZones(this.detailsBookSeatAUForm.value.startDate, this.detailsBookSeatAUForm.value.endDate);
    } else {
      this.isCarParking = false;
    }
  }

  setStartEndTime() {
    this.bookingTimeList = TimeByHour;
    this.f.startTime.setValue(0);
    this.f.endTime.setValue(24);
    this.detailsBookSeatAUForm.get('startTime').disable();
    this.detailsBookSeatAUForm.get('endTime').disable();
  }

  getZones(date, endDate) {
    let sDate;
    if (date) {
      sDate = this.convertDate(date);
    }
    let sDateFormatted;
    if (sDate) {
      sDateFormatted = formatDate(sDate, 'yyyy-MM-dd', this.locale);
    }

    let eDate;
    if (endDate) {
      eDate = this.convertDate(endDate);
    }
    let eDateFormatted;
    if (eDate) {
      eDateFormatted = formatDate(sDate, 'yyyy-MM-dd', this.locale);
    }

    this.communicationService.getZones(sDateFormatted, eDateFormatted).subscribe(res => {
      this.zones = res;
      this.f.zone.setValue(res[0].zone_id);
      this.selectedZoneMessage = 'Please only park in the White colour coded spaces';
      this.onSubmit();
    }, error => {
      console.error(error);
    });
  }

  getSearchUserData(data: any) {
    let myForm = this.detailsBookSeatAUForm.value.empName;
    if (typeof (data) === 'object') {
      myForm = data.displayName;
      this.corpId = data.corpId;
      this.detailsBookSeatAUForm.controls['empName'].setValue(myForm);
      this.searchResult.length = 0;
      this.user = new User();
      this.user.email = data.email;
      this.user.firstName = data.firstName;
      this.user.lastName = data.lastName;
      this.user.username = data.username;
      this.user.department = data.department;
    }
  }

  deselectSelfBooking(event) {
    if (event.target.checked) {
      this.isSelfBooking = false;
    } else {
      this.isSelfBooking = true;
    }
  }

  /*deselectTimeBooking(event) {
    if (event.target.checked) {
      this.detailsBookSeatAUForm.get('startTime').enable();
      this.detailsBookSeatAUForm.get('endTime').enable();
      this.isBookByTime = true;
    } else {
      this.isBookByTime = false;
      this.f.startTime.setValue(0);
      this.f.endTime.setValue(24);
      this.detailsBookSeatAUForm.get('startTime').disable();
      this.detailsBookSeatAUForm.get('endTime').disable();
    }
  } */

  onFloorChange(value) {
    if (value) {
      this.f.wing.setValue('');
      this.getWings(value.target.value);
    }
  }

  onWingChange(value) {
    if (value) {
      this.onSubmit();
    }
  }

  onCityChange(value) {
    this.getBuildings(value.target.value);
  }

  onBuildingChange(value) {
    this.getFloors(value.target.value);
  }

  onCountryChange(value) {
    this.getCitiesByCountry(value.target.value);
  }

  onStartTimeChange(value) {
    this.startTimeValue = value.target.value;
    let val = this.startTimeValue;
    this.setEndTime(++val);
    this.onSubmit();
  }

  setEndTime(val) {
    this.f.endTime.setValue(val);
  }

  onEndTimeChange(value) {
    let endTimeValue = value.target.value;
    if (parseInt(endTimeValue) > this.startTimeValue) {
      this.f.endTime.setValue(endTimeValue);
    } else {
      endTimeValue = this.startTimeValue;
      this.setEndTime(++endTimeValue);
    }
    this.onSubmit();
  }

  getCities() {
    this.communicationService.getCities().subscribe(res => {
      this.cities = res;
    }, error => {
      this.modalReference.close();
      console.error(error);
    });
  }

  getCountries() {
    this.communicationService.getCountries().subscribe(res => {
      this.countries = [];
      for (const country of res) {
        if (country.status === '0') {
          this.countries.push(country);
        }
      }
      if (this.countries.length > 0) {
        this.f.country.setValue(this.countries[0].country_id);
        this.getCitiesByCountry(this.countries[0].country_id);
      } else {
        this.countries = [];
        this.f.country.setValue('');
        this.cities = [];
        this.f.city.setValue('');
        this.buildings = [];
        this.f.building.setValue('');
        this.floors = [];
        this.f.floor.setValue('');
        this.wings = [];
        this.f.wing.setValue('');
        this.modalReference.close();
      }
    }, error => {
      this.modalReference.close();
      if (error.error.message) {
        this.toastr.error(error.error.message);
      }
    });
  }

  getBuildings(value) {
    this.communicationService.getBuildings(value).subscribe(res => {
      if (res.length > 0) {
        this.buildings = [];
        for (const building of res) {
          if (building.status === '0') {
            this.buildings.push(building);
          }
        }
        if (this.buildings.length > 0) {
          this.buildings.sort( (a, b) => {
            if ( a.building_name < b.building_name) { return -1; }
            if (a.building_name > b.building_name) { return 1 ; }
            return 0;
          });
          this.buildings.sort( (a, b) => a.building_name - b.building_name);
          this.f.building.setValue(this.buildings[0].building_id);
          if ('Esseondon Fields' === this.buildings[0].building_name && this.buildings[0].parking === '1') {
            this.hideParking = true;
            this.isCarParking = false;
          } else {
            this.hideParking = false;
          }
          this.getFloors(this.buildings[0].building_id);
        } else {
          this.f.building.setValue('');
          this.floors = [];
          this.f.floor.setValue('');
          this.wings = [];
          this.f.wing.setValue('');
          this.modalReference.close();
        }
      } else {
        this.buildings = [];
        this.f.building.setValue('');
        this.floors = [];
        this.f.floor.setValue('');
        this.wings = [];
        this.f.wing.setValue('');
        this.modalReference.close();
      }
    }, error => {
      this.modalReference.close();
      console.error(error);
    });
  }

  async getCoords() {
    const startDateVal = formatDate(this.convertDate(this.detailsBookSeatAUForm.value.startDate),
      'yyyy-MM-dd', this.locale);
    const endDateVal = formatDate(this.convertDate(this.detailsBookSeatAUForm.value.endDate),
      'yyyy-MM-dd', this.locale);
    const coordinates = {
      start_date_time: this.isBookByTime ? startDateVal + this.bookingTimeList[this.detailsBookSeatAUForm.get('startTime').value].timeFormat :
        startDateVal + 'T00:00:00',
      end_date_time: this.isBookByTime ? endDateVal + this.bookingTimeList[this.detailsBookSeatAUForm.get('endTime').value].timeFormat :
        endDateVal + 'T23:59:59',
      floor_id: this.detailsBookSeatAUForm.value.floor,
      wing_id: this.detailsBookSeatAUForm.value.wing
    };
    this.communicationService.getSeats(coordinates).subscribe(res => {
      res.sort((a, b) => {
        if (b.name < a.name) { return 1; }
        if (b.name > a.name) { return -1; }
        return 0;
      });
      let workstations = [];
      for (const obj of res) {
        if (obj.status === '0' && !obj.selected) {
          workstations.push(obj);
        }
      }
      workstations = workstations.sort((a, b) => {
        return a.name - b.name;
      }) ;
      this.coordinates = [];
      for (const obj of workstations) {
        if (!obj.is_common) {
          this.coordinates.push(obj);
        }
      }
     // this.coordinates = workstations;
      this.showFloorPlan();
      this.validateBooking();
    }, error => {
      this.modalReference.close();
      console.error(error);
    });
  }

  private validateBooking() {
    if (this.coordinates.length == 0) {
      this.modalReference.close();
      this.toastr.error('All workstations are booked for given date range.');
    } else {
      this.f.seat_id.setValue(this.coordinates[0].name);
      this.loadMapBorder();
    }
  }

  /*getWorkstations() {
    let startDateVal = formatDate(this.convertDate(this.detailsBookSeatAUForm.value.startDate), 'yyyy-MM-dd', this.locale);
    let endDateVal = formatDate(this.convertDate(this.detailsBookSeatAUForm.value.endDate), 'yyyy-MM-dd', this.locale);

    let request = {
      floor_id: this.detailsBookSeatAUForm.value.floor,
      wing_id: this.detailsBookSeatAUForm.value.wing,
      is_reserved: 1,
      start_date: startDateVal,
      end_date: endDateVal
    }
    this.communicationService.getSeats(request).subscribe(res => {
      //this.seats = res;
      this.showFloorPlan();
      if (res.length == 0) {
        this.modalReference.close();
        this.toastr.error("All workstations are booked for given date range.");
      } else {
        this.f.seat.setValue(res[0].name);
      }
    }, error => {
      console.error(error);
      this.modalReference.close();
    })
  }*/

  ngAfterViewInit(): void {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    this.modalReference = this.modalService.open(this.defaultTabButtonsTpl, ngbModalOptions);
    this.floorPlanCtx = this.canvas.nativeElement.getContext('2d');
    this.tooltipCtx = this.tooltip.nativeElement.getContext('2d');
    this.tooltipCtx.fillStyle = '#ffffff';
  }

  getFloors(building_id: string) {
    this.communicationService.getFloorsByBuilding(building_id).subscribe(res => {
      this.floors = [];
      for (const floor of res) {
        if (floor.status === '0') {
          this.floors.push(floor);
        }
      }
      if (this.floors.length > 0) {
        this.floors.sort( (a, b) => {
          if ( a.floor_name < b.floor_name) { return -1; }
          if (a.floor_name > b.floor_name) { return 1 ; }
          return 0;
        });
        this.floors.sort( (a, b) => a.floor_name - b.floor_name);
        this.f.floor.setValue(this.floors[0].floor_id);
        this.getWings(this.floors[0].floor_id);
      } else {
        this.f.floor.setValue('');
        this.wings = [];
        this.f.wing.setValue('');
        this.modalReference.close();
      }
    }, error => {
      this.modalReference.close();
      console.error(error);
    });
  }

  getCitiesByCountry(country_id: string) {
    this.communicationService.getCitiesByCountry(country_id).subscribe(res => {
      if (res.length > 0) {
        this.cities = [];
        for (const city of res) {
          if (city.status === '0') {
            this.cities.push(city);
          }
        }
        if (this.cities.length > 0) {
          for (const city of this.cities) {
            if (city.city_name == 'Sydney') {
              this.f.city.setValue(city.city_id);
              this.getBuildings(city.city_id);
              return 0 ;
            }
          }
          this.f.city.setValue(this.cities[0].city_id);
          this.getBuildings(this.cities[0].city_id);
        } else {
          this.cities = [];
          this.f.city.setValue('');
          this.buildings = [];
          this.f.building.setValue('');
          this.floors = [];
          this.f.floor.setValue('');
          this.wings = [];
          this.f.wing.setValue('');
          this.modalReference.close();
        }
      } else {
        this.cities = [];
        this.f.city.setValue('');
        this.buildings = [];
        this.f.building.setValue('');
        this.floors = [];
        this.f.floor.setValue('');
        this.wings = [];
        this.f.wing.setValue('');
        this.modalReference.close();
      }
    }, error => {
      this.modalReference.close();
      console.error(error);
    });
  }

  getWings(floor_id: string) {
    this.communicationService.getWings(floor_id).subscribe(res => {
      this.wings = [];
      for (const wing of res) {
        if (wing.status === '0' && wing.is_parking == '1') {
          this.wings.push(wing);
        }
      }
      if (this.wings.length > 0) {
        this.wings.sort( (a, b) => {
          if ( a.wing_name < b.wing_name) { return -1; }
          if (a.wing_name > b.wing_name) { return 1 ; }
          return 0;
        });
        this.wings.sort( (a, b) => a.wing_name - b.wing_name);
        this.f.wing.setValue(this.wings[0].wing_id);
        this.onSubmit();
      } else {
        this.f.wing.setValue('');
      }
      this.modalReference.close();
    }, error => {
      console.error(error);
      this.modalReference.close();
    });
  }

  onSubmit() {
    this.is_selected = false;
    this.selectedSeats = null;
    this.is_map_shown = true;
    this.coordinates = [];
    this.getCoords();
    //  this.clearAttributes();
  }

  /*onClearMap() {
    this.is_selected = false;
    this.selectedSeats = null;
    this.clearAttributes();
    if (this.is_map_shown) {
      this.showFloorPlan();
    } else {
      this.toastr.error('Select floor and wing');
    }
  }*/

  /* clearAttributes() {
     this.showAttribute = 0;
     this.viewAttribute = '';
     this.viewAttribute2 = '';
     this.viewAttribute3 = '';
     this.metaJsonObject = {};
     this.metaJsonObject2 = {};
     this.metaJsonObject3 = {};
     this.seat_name = '';
     this.seat_name2 = '';
     this.seat_name3 = '';
     this.displayMap.clear();
     this.displayMap2.clear();
     this.displayMap3.clear();
   }*/

  getFloorWings(floor_id: string, wing_id: string) {
    this.communicationService.getFloorWings(floor_id, wing_id).subscribe(res => {
      const objectURL = 'data:image/png;base64,' + res.image_path;
      const image = new Image();
      image.src = objectURL;
      this.floorPlanImage = image;
      image.onload = (() => this.fitToCanvas(image));
    }, error => {
      console.error(error);
    });
  }

  showFloorPlan() {
    this.is_selected = false;
    this.loading = true;
    // this.clearAttributes();
    this.f.seat_id.setValue('');
    const wing_id = this.f.wing.value;
    const floor_id = this.f.floor.value;
    this.getFloorWings(floor_id, wing_id);
  }

  setBookingsPopup(element, content) {
    this.f.workstationName.setValue(element.name);
    element.bookings.forEach(booking => {
      this.bookingResponderList.push(booking);
    });
    this.sortBookings();
    this.modalReference = this.modalService.open(content);
  }

  loadMapBorder() {
    this.bookingResponderList = [];
    const rB = this.floorPlanCtx.canvas.getBoundingClientRect();
    this.coordinates.map((element) => {
      this.floorPlanCtx.strokeStyle = '#006400';
      this.floorPlanCtx.strokeRect(element.x, element.y, element.w, element.h);
      this.selectedSeat = element;
      if (element.h === null || element.w === null) {
        this.floorPlanCtx.fillStyle = '#006400';
        this.plotPolygonBorder(element);
      }
    });
  }

  onClick(event, content) {
    this.bookingResponderList = [];
    const rB = this.floorPlanCtx.canvas.getBoundingClientRect();
    const x = event.pageX - rB.left;
    const y = event.pageY - (rB.top + window.scrollY);
    this.coordinates.map((element) => {
      if (element.selected) {
        if (y > element.y && y < element.y + element.h
          && x > element.x && x < element.x + element.w) {
          this.setBookingsPopup(element, content);
        } else if (element.h === null || element.y === null
          || element.x === null || element.w === null) {
          const odd: any = [];
          const even: any = [];
          this.setArrays(element, even, odd);
          const {xMin, xMax, yMin, yMax} = this.getMinMax(even, odd);
          if (this.isPointInsidePoly(x, y, xMin, xMax, yMin, yMax)) {
            this.setBookingsPopup(element, content);
          }
        }
      } else if (!element.is_common && !element.selected) {
        if (element.type === 0) {
          let noOfSeats: any = [];
          if (y > element.y && y < element.y + element.h
            && x > element.x && x < element.x + element.w) {
            if (!this.is_selected) {
              this.floorPlanCtx.fillStyle = '#64a844';
              this.floorPlanCtx.fillRect(element.x, element.y, element.w, element.h);
              this.floorPlanCtx.strokeStyle = '#c2cca6';
              this.floorPlanCtx.strokeRect(element.x, element.y, element.w, element.h);
              this.selectedSeat = element;
              if (this.seat_name !== element.name && this.seat_name2 !== element.name && this.seat_name3 !== element.name) {
                if (this.showAttribute === 0) {
                  this.showAttribute = 1;
                  this.getDisplayAttributes1(element.name);
                } else if (this.showAttribute === 1) {
                  this.showAttribute = 2;
                  this.getDisplayAttributes2(element.name);
                } else {
                  this.showAttribute = 3;
                  this.getAttributeDetails3(element.name);
                }
              }
              if (this.selectedSeats != null) {
                const array = this.selectedSeats.split(',');
                if (array.indexOf(element.name) > -1) {
                } else {
                  this.selectedSeats = this.selectedSeats + ',' + element.name;
                }
              } else {
                this.selectedSeats = element.name;
              }
              this.f.seat_id.setValue(this.selectedSeats);
              noOfSeats = this.selectedSeats.split(',');
              if (noOfSeats.length > 2) {
                this.is_selected = true;
              }
            }

          }
        } else {
          const odd: any = [];
          const even: any = [];
          let noOfSeats: any = [];
          this.setArrays(element, even, odd);
          const {xMin, xMax, yMin, yMax} = this.getMinMax(even, odd);
          if (this.isPointInsidePoly(x, y, xMin, xMax, yMin, yMax)
            && !element.is_common && !element.selected) {
            if (!this.is_selected) {
              this.floorPlanCtx.fillStyle = '#64a844';
              this.plotPolygon(element);
              this.selectedSeat = element;
              if (this.seat_name !== element.name && this.seat_name2 !== element.name && this.seat_name3 !== element.name) {
                if (this.showAttribute === 0) {
                  this.showAttribute = 1;
                  this.getDisplayAttributes1(element.name);
                } else if (this.showAttribute === 1) {
                  this.showAttribute = 2;
                  this.getDisplayAttributes2(element.name);
                } else {
                  this.showAttribute = 3;
                  this.getAttributeDetails3(element.name);
                }
              }
              if (this.selectedSeats != null) {
                const array = this.selectedSeats.split(',');
                if (array.indexOf(element.name) > -1) {
                } else {
                  this.selectedSeats = this.selectedSeats + ',' + element.name;
                }
              } else {
                this.selectedSeats = element.name;
              }
              this.f.seat_id.setValue(this.selectedSeats);
              noOfSeats = this.selectedSeats.split(',');
              if (noOfSeats.length > 2) {
                this.is_selected = true;
              }
            }

          }
        }
      }
    });
  }

  public openFireWardenDialog(content) {
    if (this.firstAidResponder == true) {
      this.firstAidResponder = false;
    }
    this.firewardenResponder = true;
    this.getFireWardenBookings(this.limit, this.startIndex);
    this.modalReference = this.modalService.open(content);
  }

  public openFirstAidDialog(content) {
    if (this.firewardenResponder == true) {
      this.firewardenResponder = false;
    }
    this.firstAidResponder = true;
    this.getFirstAidBookings(this.limit, this.startIndex);
    this.modalReference = this.modalService.open(content);
  }

  getFirstAidBookings(limit: number, index: number) {
    const bookingData = [];
    let sDate, eDate;

    if (this.detailsBookSeatAUForm.value.startDate) {
      sDate = this.convertDate(this.detailsBookSeatAUForm.value.startDate);
    }
    if (this.detailsBookSeatAUForm.value.endDate) {
      eDate = this.convertDate(this.detailsBookSeatAUForm.value.endDate);
    }
    let sDateFormatted, eDateFormatted;

    if (sDate) {
      sDateFormatted = formatDate(sDate, 'yyyy-MM-dd', this.locale);
    }
    if (eDate) {
      eDate.setDate(eDate.getDate());
      eDateFormatted = formatDate(eDate, 'yyyy-MM-dd', this.locale);
    }
    const searchObj = {
      start_date: sDateFormatted,
      end_date: eDateFormatted,
      status: this.detailsBookSeatAUForm.value.status,
      building_id: this.detailsBookSeatAUForm.value.building,
      floor_id: this.detailsBookSeatAUForm.value.floor,
      city_id: this.detailsBookSeatAUForm.value.city,
      isReportDownload: false,
      responder: 'First Aid Responder'
    };
    let n = 0;
    this.communicationService.getAllResponderBookingsFilter(limit, index, searchObj).subscribe(res => {
      for (const obj of res.bookingResponderList) {
        if (res.bookingResponderList[n].responder_type == 'First Aid Responder' || res.bookingResponderList[n].responder_type == 'First Aid Responder & Fire Warden') {
          bookingData.push(obj);
        }
        n = n + 1;
      }
      this.totalBookingCount = res.totalPageCount;
      this.heroes$ = new BehaviorSubject(bookingData);
      combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
        .subscribe(([allSources, currentPage, pageSize]) => {
          const startingIndex = (currentPage - 1) * pageSize;
          const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
          this.dataOnPage$.next(onPage);
        });

      this.heroes$.pipe(take(1)).subscribe(heroData => {
        this.tableDataSource$.next(Object.values(heroData));
      });
      combineLatest(this.heroes$, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, sortKey, sortDirection]) => {
            if (this.sortKey$.value == 'start_date') {
              const heroesArray = Object.values(changedHeroData);
              const sortedHeroes = heroesArray.sort((a, b) => {
                if (this.getDateFormat(a.start_date) > this.getDateFormat(b.start_date)) { return sortDirection === 'asc' ? 1 : -1; }
                if (this.getDateFormat(a.start_date) < this.getDateFormat(b.start_date)) { return sortDirection === 'asc' ? -1 : 1; }
                return 0;
              });  this.tableDataSource$.next(sortedHeroes);
            } else  if ( this.sortKey$.value == 'end_date') {
              const heroesArray = Object.values(changedHeroData);
              const sortedHeroes = heroesArray.sort((a, b) => {
                if (this.getDateFormat(a.end_date) > this.getDateFormat(b.end_date)) { return sortDirection === 'asc' ? 1 : -1; }
                if (this.getDateFormat(a.end_date) < this.getDateFormat(b.end_date)) { return sortDirection === 'asc' ? -1 : 1; }
                return 0;
              }); this.tableDataSource$.next(sortedHeroes);
            } else {
              const heroesArray = Object.values(changedHeroData);
              const sortedHeroes = heroesArray.sort((a, b) => {
                if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
                if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
                return 0;
              });
              this.tableDataSource$.next(sortedHeroes); }
          }
        );

      combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          let filteredHeroes: any[];
          if (!searchTerm) {
            filteredHeroes = heroesArray;
          } else {
            const filteredResults = heroesArray.filter(hero => {
              return Object.values(hero)
                .reduce((prev, curr) => {
                  return prev || curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
                }, false);
            });
            filteredHeroes = filteredResults;
          }
          const sortedHeroes = filteredHeroes.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });

          this.tableDataSource$.next(sortedHeroes);
        });
    }, error => {
      /*this.modalReference.close();*/
    });
  }

  getDateFormat(date) {
    const str = date;
    const [days, months, years] = str.split('-');
    const dateFormatted = new Date(+years, +months - 1, +days);
    return dateFormatted;
  }

  getFireWardenBookings(limit: number, index: number) {
    const bookingData = [];
    let sDate, eDate;

    if (this.detailsBookSeatAUForm.value.startDate) {
      sDate = this.convertDate(this.detailsBookSeatAUForm.value.startDate);
    }
    if (this.detailsBookSeatAUForm.value.endDate) {
      eDate = this.convertDate(this.detailsBookSeatAUForm.value.endDate);
    }
    let sDateFormatted, eDateFormatted;

    if (sDate) {
      sDateFormatted = formatDate(sDate, 'yyyy-MM-dd', this.locale);
    }
    if (eDate) {
      eDate.setDate(eDate.getDate());
      eDateFormatted = formatDate(eDate, 'yyyy-MM-dd', this.locale);
    }
    const searchObj = {
      start_date: sDateFormatted,
      end_date: eDateFormatted,
      status: this.detailsBookSeatAUForm.value.status,
      building_id: this.detailsBookSeatAUForm.value.building,
      floor_id: this.detailsBookSeatAUForm.value.floor,
      city_id: this.detailsBookSeatAUForm.value.city,
      isReportDownload: false,
      responder: 'Fire Warden'
    };
    let n = 0;
    this.communicationService.getAllResponderBookingsFilter(limit, index, searchObj).subscribe(res => {
      for (const obj of res.bookingResponderList) {
        // tslint:disable-next-line:max-line-length
        if (res.bookingResponderList[n].responder_type == 'Fire Warden' || res.bookingResponderList[n].responder_type == 'First Aid Responder & Fire Warden') {
          bookingData.push(obj);
        }
        n = n + 1;
      }
      this.totalBookingCount = res.totalPageCount;
      this.heroes$ = new BehaviorSubject(bookingData);
      combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
        .subscribe(([allSources, currentPage, pageSize]) => {
          const startingIndex = (currentPage - 1) * pageSize;
          const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
          this.dataOnPage$.next(onPage);
        });

      this.heroes$.pipe(take(1)).subscribe(heroData => {
        this.tableDataSource$.next(Object.values(heroData));
      });
      combineLatest(this.heroes$, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, sortKey, sortDirection]) => {
            if (this.sortKey$.value == 'start_date') {
              const heroesArray = Object.values(changedHeroData);
              const sortedHeroes = heroesArray.sort((a, b) => {
                if (this.getDateFormat(a.start_date) > this.getDateFormat(b.start_date)) { return sortDirection === 'asc' ? 1 : -1; }
                if (this.getDateFormat(a.start_date) < this.getDateFormat(b.start_date)) { return sortDirection === 'asc' ? -1 : 1; }
                return 0;
              });  this.tableDataSource$.next(sortedHeroes);
            } else  if ( this.sortKey$.value == 'end_date') {
              const heroesArray = Object.values(changedHeroData);
              const sortedHeroes = heroesArray.sort((a, b) => {
                if (this.getDateFormat(a.end_date) > this.getDateFormat(b.end_date)) { return sortDirection === 'asc' ? 1 : -1; }
                if (this.getDateFormat(a.end_date) < this.getDateFormat(b.end_date)) { return sortDirection === 'asc' ? -1 : 1; }
                return 0;
              }); this.tableDataSource$.next(sortedHeroes);
            } else {
              const heroesArray = Object.values(changedHeroData);
              const sortedHeroes = heroesArray.sort((a, b) => {
                if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
                if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
                return 0;
              });
              this.tableDataSource$.next(sortedHeroes); }
          }
        );

      combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          let filteredHeroes: any[];
          if (!searchTerm) {
            filteredHeroes = heroesArray;
          } else {
            const filteredResults = heroesArray.filter(hero => {
              return Object.values(hero)
                .reduce((prev, curr) => {
                  return prev || curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
                }, false);
            });
            filteredHeroes = filteredResults;
          }
          const sortedHeroes = filteredHeroes.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });

          this.tableDataSource$.next(sortedHeroes);
        });
    }, error => {
    });
  }

  sortBookings() {
    const bookingData = [];
    bookingData.push(this.bookingResponderList);
    this.totalUserCount = 10;
    this.heroes$ = new BehaviorSubject(bookingData);
    combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
      .subscribe(([allSources, currentPage, pageSize]) => {
        const startingIndex = (currentPage - 1) * pageSize;
        const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
        this.dataOnPage$.next(onPage);
      });

    this.heroes$.pipe(take(1)).subscribe(heroData => {
      this.tableDataSource$.next(Object.values(heroData));
    });
    combineLatest(this.heroes$, this.sortKey$, this.sortDirection$)
      .subscribe(([changedHeroData, sortKey, sortDirection]) => {
        const heroesArray = Object.values(changedHeroData);
        const sortedHeroes = heroesArray.sort((a, b) => {
          if (a[sortKey] > b[sortKey]) {
            return sortDirection === 'asc' ? 1 : -1;
          }
          if (a[sortKey] < b[sortKey]) {
            return sortDirection === 'asc' ? -1 : 1;
          }
          return 0;
        });

        this.tableDataSource$.next(sortedHeroes);
      });

    combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
      .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
        const heroesArray = Object.values(changedHeroData);
        let filteredHeroes: any[];

        if (!searchTerm) {
          filteredHeroes = heroesArray;
        } else {
          const filteredResults = heroesArray.filter(hero => {
            return Object.values(hero)
              .reduce((prev, curr) => {
                return prev || curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
              }, false);
          });
          filteredHeroes = filteredResults;
        }

        const sortedHeroes = filteredHeroes.sort((a, b) => {
          if (a[sortKey] > b[sortKey]) {
            return sortDirection === 'asc' ? 1 : -1;
          }
          if (a[sortKey] < b[sortKey]) {
            return sortDirection === 'asc' ? -1 : 1;
          }
          return 0;
        });
        this.tableDataSource$.next(sortedHeroes);
      });
  }

  getAttributeDetails3(value: string) {
    this.communicationService.getSeat(value).subscribe(res => {
        this.seat_name3 = value;
        this.workstationNameForm.controls.name3.patchValue(this.seat_name3);
        if (res.attributes !== 'null') {
          this.viewAttribute3 = res.attributes;
          this.metaJsonObject3 = JSON.parse(this.viewAttribute3);
          const keys = Object.keys(this.metaJsonObject3);
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < keys.length; i++) {
            this.displayMap3.set(keys[i], this.metaJsonObject3[keys[i]]);
          }
        }
      }, error => {
        console.error(error);
      }
    );
  }

  getDisplayAttributes2(value: string) {
    this.communicationService.getSeat(value).subscribe(res => {
        this.seat_name2 = value;
        this.workstationNameForm.controls.name2.patchValue(this.seat_name2);
        if (res.attributes !== 'null') {
          this.viewAttribute2 = res.attributes;
          this.metaJsonObject2 = JSON.parse(this.viewAttribute2);
          const keys = Object.keys(this.metaJsonObject2);
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < keys.length; i++) {
            this.displayMap2.set(keys[i], this.metaJsonObject2[keys[i]]);
          }
        }

      }, error => {
        console.error(error);
      }
    );
  }

  getDisplayAttributes1(value: string) {
    this.communicationService.getSeat(value).subscribe(res => {
      this.seat_name = value;
      this.workstationNameForm.controls.name.patchValue(this.seat_name);
      if (res.attributes !== 'null') {
        this.viewAttribute = res.attributes;
        this.metaJsonObject = JSON.parse(this.viewAttribute);
        const keys = Object.keys(this.metaJsonObject);
        for (let i = 0; i < keys.length; i++) {
          this.displayMap.set(keys[i], this.metaJsonObject[keys[i]]);
        }
      }
    }, error => {
      console.error(error);
    });
  }

  /*getAttributeDetails(content) {
    if (this.showAttribute > 0) {
      this.popoverAttribute = '';
      this.modalReference = this.modalService.open(content);
    } else {
      this.popoverAttribute = 'Please select a workstation to get it\'s attributes';
    }
  }*/

  // onMouseMove(event) {
  //   this.hideTooltip = false;
  //   const rB = this.floorPlanCtx.canvas.getBoundingClientRect();
  //   const x = event.pageX - (rB.left + window.scrollX);
  //   const y = event.pageY - (rB.top + window.scrollY);
  //   // Put your mousemove stuff here
  //   // tslint:disable-next-line:prefer-for-of
  //   for (let i = 0; i < this.coordinates.length; i++) {
  //     const section = this.coordinates[i];
  //     if (section.type === 0) {
  //       if (y > section.y && y < section.y + section.h
  //         && x > section.x && x < section.x + section.w) {
  //         let lineheight = 15;
  //         this.canvas.nativeElement.style.cursor = 'pointer';
  //         this.tooltip.nativeElement.style.left = (section.x) + 'px';
  //         this.tooltip.nativeElement.style.top = (section.y) + 'px';
  //         this.tooltipCtx.clearRect(0, 0, this.tooltip.nativeElement.width, this.tooltip.nativeElement.height);

  //         if (section.is_common) {
  //           this.hideTooltip = true;

  //           this.tooltipCtx.fillText(`\nWorkstation Name : ${section.name}`, 0, lineheight);
  //           lineheight = lineheight + 25;

  //           this.tooltipCtx.fillText(`\nOccupied By : NA`, 0, lineheight);
  //           lineheight = lineheight + 25;

  //           this.tooltipCtx.fillText(`\nBooking Dates : NA`, 0, lineheight);
  //           lineheight = lineheight + 25;

  //           this.tooltipCtx.textAlign = 'left';
  //           this.tooltipCtx.font = 'bold  15px Arial';

  //         } else if (section.selected) {
  //           this.hideTooltip = true;
  //           this.tooltipCtx.fillText(`\nWorkstation Name : ${section.name}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nOccupied By : ${section.emp_name}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nStart Date & Time : ${section.start_date_time}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nEnd Date & Time : ${section.end_date_time}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nStatus : ${section.status}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.textAlign = 'left';
  //           this.tooltipCtx.font = 'bold  15px Arial';

  //         } else {
  //           this.hideTooltip = false;
  //         }
  //       }
  //     } else {
  //       const odd: any = [];
  //       const even: any = [];
  //       this.setArrays(section, even, odd);
  //       const {xMin, xMax, yMin, yMax} = this.getMinMax(even, odd);
  //       if (section.is_common) {
  //         // tslint:disable-next-line:no-shadowed-variable
  //         const odd: any = [];
  //         // tslint:disable-next-line:no-shadowed-variable
  //         const even: any = [];
  //         this.setArrays(section, even, odd);
  //         // tslint:disable-next-line:no-shadowed-variable
  //         const {xMin, xMax, yMin, yMax} = this.getMinMax(even, odd);

  //         if (this.isPointInsidePoly(x, y, xMin, xMax, yMin, yMax)) {
  //           this.hideTooltip = true;
  //           let lineheight = 15;
  //           this.canvas.nativeElement.style.cursor = 'pointer';
  //           this.tooltip.nativeElement.style.left = (section.sides[0] + 15) + 'px';
  //           this.tooltip.nativeElement.style.top = (section.sides[1] + 15) + 'px';

  //           this.tooltipCtx.clearRect(0, 0, section.sides[0] + 15, section.sides[1] + 15);

  //           this.tooltipCtx.fillText(`\nWorkstation Name : ${section.name}`, 0, lineheight);
  //           lineheight = lineheight + 25;

  //           this.tooltipCtx.fillText(`\nOccupied By : NA`, 0, lineheight);
  //           lineheight = lineheight + 25;

  //           this.tooltipCtx.fillText(`\nBooking Dates : NA`, 0, lineheight);
  //           lineheight = lineheight + 25;

  //           this.tooltipCtx.textAlign = 'left';
  //           this.tooltipCtx.font = 'bold  15px Arial';
  //         }
  //       } else if (section.selected) {
  //         // tslint:disable-next-line:no-shadowed-variable
  //         const odd: any = [];
  //         // tslint:disable-next-line:no-shadowed-variable
  //         const even: any = [];
  //         this.setArrays(section, even, odd);
  //         // tslint:disable-next-line:no-shadowed-variable
  //         const {xMin, xMax, yMin, yMax} = this.getMinMax(even, odd);

  //         if (this.isPointInsidePoly(x, y, xMin, xMax, yMin, yMax)) {
  //           this.hideTooltip = true;
  //           let lineheight = 15;
  //           this.canvas.nativeElement.style.cursor = 'pointer';
  //           this.tooltip.nativeElement.style.left = (section.sides[0] + 15) + 'px';
  //           this.tooltip.nativeElement.style.top = (section.sides[1] + 15) + 'px';

  //           this.tooltipCtx.clearRect(0, 0, section.sides[0] + 15, section.sides[1] + 15);
  //           this.tooltipCtx.fillText(`\nWorkstation Name : ${section.name}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nOccupied By : ${section.emp_name}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nStart Date & Time : ${section.start_date_time}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nEnd Date & Time : ${section.end_date_time}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nStatus : ${section.status}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.textAlign = 'left';
  //           this.tooltipCtx.font = 'bold  15px Arial';

  //         }
  //       }
  //     }
  //   }
  // }

  /*deselectCurrentAndReinitializeCanvas(element: Coordinate, event) {
    this.coordinates.map(section => {
      if (element.name === section.name && !section.selected && !section.is_common) {
        this.floorPlanCtx.fillStyle = 'rgba(255,255,255,1)';
        this.floorPlanCtx.fillRect(section.x, section.y, section.w, section.h);
        this.floorPlanCtx.strokeStyle = '#FFFFFF';
        this.floorPlanCtx.strokeRect(section.x, section.y, section.w, section.h);
      }
    });
  }*/

  onSubmitBook(confirmed: any, content) {
    let is_self = true;
    let zone_id_value;
    if (confirmed) {
      if (this.isCarParking) {
        zone_id_value = this.detailsBookSeatAUForm.value.zone;
      }
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false
      };
      this.modalReference = this.modalService.open(content, ngbModalOptions);
      let userRequestObj = {};
      if (this.user) {
        userRequestObj = {
          username: this.user.username,
          first_name: this.user.firstName,
          last_name: this.user.lastName,
          role: '5',
          email: this.user.email,
          sub_department_name: this.user.department
        };
        is_self = false;
      }
      const startDateVal =
        formatDate(this.convertDate(this.detailsBookSeatAUForm.value.startDate), 'yyyy-MM-dd', this.locale);
      const endDateVal = formatDate(this.convertDate(this.detailsBookSeatAUForm.value.endDate), 'yyyy-MM-dd', this.locale);
      if (startDateVal <= endDateVal) {
        const bookSeatObj = {
          // start_date: startDateVal,
          // end_date: endDateVal,
          start_date_time: this.isBookByTime ? startDateVal + this.bookingTimeList[this.detailsBookSeatAUForm.get('startTime').value].timeFormat :
            startDateVal + 'T00:00:00',
          end_date_time: this.isBookByTime ? endDateVal + this.bookingTimeList[this.detailsBookSeatAUForm.get('endTime').value].timeFormat :
            endDateVal + 'T23:59:59',
          emp_id: this.detailsBookSeatAUForm.value.employee_id,
          seat_id: this.detailsBookSeatAUForm.value.seat_id,
          status: 0,
          comments: this.detailsBookSeatAUForm.value.comments,
          floor_id: this.detailsBookSeatAUForm.value.floor,
          wing_id: this.detailsBookSeatAUForm.value.wing,
          futureReference: false,
          user_request: userRequestObj,
          is_self,
          zone_id: zone_id_value,
          email_notification : this.detailsBookSeatAUForm.value.email_notification,
        };
        this.communicationService.bookSeat(bookSeatObj).subscribe(res => {
            this.modalReference.close();
            this.toastr.success(this.translate.instant('toasterSuccessMessage.workStationBooked'));
            this.router.navigate(['/view-bookings']);
          },
          error => {
            this.modalReference.close();
            console.log('error', error);
            this.toastr.error(error.error.message, 'Failed to book Workstation');
          });
      } else {
        this.modalReference.close();
        this.toastr.error('Invalid Booking Date Selection');
      }
    }
  }

  public openConfirmationDialog(content) {
    const startDate = formatDate(this.convertDate(this.detailsBookSeatAUForm.value.startDate),
      'yyyy-MM-dd', this.locale);
    const endDate = formatDate(this.convertDate(this.detailsBookSeatAUForm.value.endDate),
      'yyyy-MM-dd', this.locale);

    if (this.detailsBookSeatAUForm.value.seat_id) {
      const sDate = this.isBookByTime ? startDate + this.bookingTimeList[this.detailsBookSeatAUForm.get('startTime').value].timeFormat :
        startDate + 'T00:00:00';

      const eDate = this.isBookByTime ? endDate + this.bookingTimeList[this.detailsBookSeatAUForm.get('endTime').value].timeFormat :
        endDate + 'T23:59:59';
      this.confirmationDialogService.confirm('Book Workstation Confirmation',
        'Are you sure you want to book Workstation ' + this.detailsBookSeatAUForm.value.seat_id + ' from ' + sDate + ' to ' + eDate + '?')
        .then((confirmed) => this.onSubmitBook(confirmed, content));
    } else {
      this.toastr.error('Workstation is not selected');
    }
  }

  /* public openAttributesModal() {
     if (this.detailsBookSeatAUForm.value.seat_id) {
       const workstation_ids = this.detailsBookSeatAUForm.value.seat_id.split(',');
       const attributesObject = {
         workstations: workstation_ids
       };

       this.communicationService.getSeatAttributes(attributesObject);
     } else {
       this.toastr.error('Workstation is not selected');

     }
   }*/

  isInputSelected() {
    if (this.f.wing.value === undefined
      || this.f.wing.value === '' || this.f.floor.value === undefined || this.f.floor.value === '') {
      return false;
    }
    return true;
  }

  convertDate(date) {
    const converdate = new Date(date.year, date.month - 1, date.day);
    return converdate;
  }

  private fitToCanvas(image) {
    this.floorPlanCtx.canvas.width = image.width;
    this.floorPlanCtx.canvas.height = image.height;
    this.floorPlanCtx.drawImage(image, 0, 0);
    this.highlightSelected();
    this.loadMapBorder();
  }

  highlightSelected() {
    this.coordinates.map((element) => {
      if (element.is_common) {
        if (element.type === 0) {
          this.floorPlanCtx.fillStyle = 'rgba(232,232,232, 0.8)';
          this.floorPlanCtx.fillRect(element.x, element.y, element.w, element.h);
          this.floorPlanCtx.strokeStyle = '#000000';
          this.floorPlanCtx.strokeRect(element.x, element.y, element.w, element.h);
        } else {
          this.floorPlanCtx.fillStyle = 'rgba(232,232,232, 0.8)';
          this.floorPlanCtx.strokeStyle = '#000000';
          this.plotPolygon(element);
        }

      } else if (element.selected) {
        if (element.type === 0) {
          this.floorPlanCtx.fillStyle = '#A8ADB3';
          this.floorPlanCtx.fillRect(element.x, element.y, element.w, element.h);
          this.floorPlanCtx.strokeStyle = '#d4d6d9';
          this.floorPlanCtx.strokeRect(element.x, element.y, element.w, element.h);
        } else {
          this.floorPlanCtx.fillStyle = '#A8ADB3';
          this.floorPlanCtx.strokeStyle = '#d4d6d9';
          this.plotPolygon(element);
        }
      }
    });
    this.loading = false;
  }

  private setArrays(element: Coordinate, even: any, odd: any) {
    let i = 0;
    let j = 0;
    element.sides.forEach((entry, index) => {
      if (index % 2 === 0) {
        even[i] = entry;
        i++;
      } else {
        odd[j] = entry;
        j++;
      }
    });
  }

  private plotPolygon(element: Coordinate) {
    this.floorPlanCtx.beginPath();
    this.floorPlanCtx.moveTo(element.sides[0], element.sides[1]);
    this.floorPlanCtx.lineTo(element.sides[2], element.sides[3]);
    this.floorPlanCtx.lineTo(element.sides[4], element.sides[5]);
    this.floorPlanCtx.lineTo(element.sides[6], element.sides[7]);
    this.floorPlanCtx.lineTo(element.sides[8], element.sides[9]);
    this.floorPlanCtx.lineTo(element.sides[10], element.sides[11]);
    this.floorPlanCtx.closePath();
    this.floorPlanCtx.fill();
  }

  private plotPolygonBorder(element: Coordinate) {
    this.floorPlanCtx.beginPath();
    this.floorPlanCtx.moveTo(element.sides[0], element.sides[1]);
    this.floorPlanCtx.lineTo(element.sides[2], element.sides[3]);
    this.floorPlanCtx.lineTo(element.sides[4], element.sides[5]);
    this.floorPlanCtx.lineTo(element.sides[6], element.sides[7]);
    this.floorPlanCtx.lineTo(element.sides[8], element.sides[9]);
    this.floorPlanCtx.lineTo(element.sides[10], element.sides[11]);
    this.floorPlanCtx.closePath();
    this.floorPlanCtx.strokeStyle = '#006400';
    this.floorPlanCtx.stroke();
  }

  isPointInsidePoly(x, y, xmin, xmax, ymin, ymax) {
    if (x < xmin || x > xmax || y < ymin || y > ymax) {
      return false;
    } else {
      return true;
    }
  }

  private getMinMax(even: any, odd: any) {
    const xMin = even.reduce((a, b) => Math.min(a, b));
    const xMax = even.reduce((a, b) => Math.max(a, b));
    const yMin = odd.reduce((a, b) => Math.min(a, b));
    const yMax = odd.reduce((a, b) => Math.max(a, b));
    return {xMin, xMax, yMin, yMax};
  }

  getSearchResult = (val) => {
    let queryData = val.target.value;
    const inp = String.fromCharCode(val.keyCode);
    if (/[a-zA-Z0-9-_ @.]/.test(inp)) {
      queryData = val.target.value + val.key;
    }
    if (queryData.length >= 4 && queryData.length <= 15) {
      this.communicationService.getSearchResult(queryData).subscribe(res => {
        if (res.employeeList.users.length === 0 && queryData.length <= 4) {
          this.toastr.error('Search User not found.');
        }
        this.searchResult = res.employeeList.users;
      }, error => {
        this.searchResult.length = 0;
        if (queryData.length <= 4) {
          this.toastr.error('Search User not found.');
        }
      });
    } else {
      this.searchResult.length = 0;
    }
  }

  /*getSearchResultSeats = (val) => {
    const queryData = val.target.value;
    if (queryData.length >= 4) {
      this.communicationService.getSeatsFilter(this.limit, this.startIndex, queryData).subscribe(res => {
        this.searchResultSeats = res;
      }, error => {
        this.searchResultSeats.length = 0;
        console.log('Getting error while searching result for seats');
      });
    } else {
      this.searchResultSeats.length = 0;
    }
  }*/

  onZoneChange(event) {
    switch (event.target.value) {
      case '1':
        this.selectedZoneMessage = 'Please only park in the White color coded spaces.';
        break;
      case '2' :
        this.selectedZoneMessage = 'Please only park in the Blue color coded spaces.';
        break;
      case '4':
        this.selectedZoneMessage = 'Please only park on level (P2)';
        break;
      default:
        this.selectedZoneMessage = '';
    }
  }

}
