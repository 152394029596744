import { Component, OnInit } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommunicationService } from 'src/app/services/communication.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-rule-configuration',
  templateUrl: './rule-configuration.component.html',
  styleUrls: ['./rule-configuration.component.scss']
})
export class RuleConfigurationComponent implements OnInit {

  constructor(public formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    private router: Router,  public  translate:  TranslateService) { }
  detailsRuleConfigurationForm: FormGroup;

  floors;
  floorMap;
  group = {};
  cities = [];
  countries = [];
  buildings = [];
  floorDetails;
  submitted:boolean=false;

  allocations: any = [
    { id: 1, name: 'Allow All', value: 1 },
    { id: 2, name: 'Alternate Seat Allocation', value: 0 }
  ];

  ngOnInit(): void {
    this.group['city'] = new FormControl('');
    this.group['building'] = new FormControl('');
    this.group['country'] = new FormControl('');
    this.detailsRuleConfigurationForm = this.formBuilder.group(this.group);
    this.getCountries();
    this.communicationService.getConfiguration().subscribe(res => {
      this.floorMap = res.floor_map;
    });
  }

  getFloors(building_id: string) {
    this.floorDetails = [];
    this.communicationService.getFloorsByBuilding(building_id).subscribe(res => {
      this.floors = res;
      for (const floor of this.floors) {
        const floorName = 'floor' + floor.floor_name;
        const id = floor.floor_id;
        this.floorDetails.push({
          key: id,
          value: floorName
        });
        floor.options = { floor: 0, ceil: 100 };
        this.group[floorName] = new FormControl(this.floorMap[floor.floor_id]);
      }
      this.detailsRuleConfigurationForm = this.formBuilder.group(this.group);
    }, error => {
      console.error(error);
    });
  }

  getCities(value) {
    this.communicationService.getCitiesByCountry(value).subscribe(res => {
      if (res.length > 0) {
        this.cities = [];
        for (const city of res) {
            if (city.status === '0') {
               this.cities.push(city);
            }
        }
        this.group['city'] = new FormControl(this.cities[0].city_id);
        this.detailsRuleConfigurationForm = this.formBuilder.group(this.group);
        this.getBuildings(this.cities[0].city_id);
      } else {
        this.cities = [];
        this.buildings = [];
      }
    }, error => {
      console.error(error);
    });
  }

  getCountries() {
    this.communicationService.getCountries().subscribe(res => {
      for (const country of res) {
          if (country.status === '0') {
             this.countries.push(country);
          }
      }
      this.group['country'] = new FormControl(this.countries[0].country_id);
      this.getCities(this.countries[0].country_id);
    }, error => {
      console.error(error);
    });
  }

  getBuildings(value) {
    this.communicationService.getBuildings(value).subscribe(res => {
      if (res.length > 0) {
        this.buildings = [];
        for (const building of res) {
            if (building.status === '0') {
               this.buildings.push(building);
            }
        }
        this.group['building'] = new FormControl(this.buildings[0].building_id);
        this.getFloors(this.buildings[0].building_id);
      } else {
        this.buildings = [];
        this.floors = [];
      }
    }, error => {
      console.error(error);
    });
  }

  onCheckboxChange(e) {

  }

  onCountryChange(value) {
    this.group['country'] = new FormControl(this.detailsRuleConfigurationForm.value.country);
    this.getCities(value);
  }


  onCityChange(value) {
    this.group['city'] = new FormControl(this.detailsRuleConfigurationForm.value.city);
    this.getBuildings(value);
  }

  onBuildingChange(value) {
    this.getFloors(value);
  }

  saveConfiguration() {
    const object = {};
    for (const floorDetail of this.floorDetails) {
      const floorName = floorDetail.value;
      object[floorDetail.key] = this.detailsRuleConfigurationForm.get(floorName)?.value;
    }
    const saveConfig = {
      configuration_id: 1,
      allocation_method: 1,
      floor_map: object
    };

    this.communicationService.saveConfiguration(saveConfig).subscribe(res => {
      this.toastr.success(this.translate.instant('toasterSuccessMessage.configurationSaved'));
      this.router.navigate(['/dashboard']);
    },
      error => {
        console.log('error', error);
        this.toastr.error(error.error.message, 'Failed to save configuration');
      });
  }
  get f() {
    return this.detailsRuleConfigurationForm.controls;
  }
}
