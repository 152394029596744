import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { CommunicationService } from 'src/app/services/communication.service';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  constructor(
    public communicationService: CommunicationService) {
  }

  userDetails: any;
  userRole: any;
  ngOnInit() {

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser.id) {
      this.communicationService.getUserDetails(currentUser.id).subscribe(res => {
        this.userDetails = res;
        this.userRole = res.roleName;
      }, error => {
        console.error('error', error);
      });

    }

  }
}
