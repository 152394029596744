import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModalRef, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'app-responders',
  templateUrl: './responders.component.html',
  styleUrls: ['./responders.component.css']
})
export class RespondersComponent implements OnInit {
  modalReference: NgbModalRef;
  heroes$;
  superlatives$ = new BehaviorSubject<{ [superlativeName: string]: string }>({});
  tableDataSource$ = new BehaviorSubject<any[]>([]);
  displayedColumns$ = new BehaviorSubject<string[]>([
    'firstName',
    'lastName',
    'email',
    'role',
    'building_name',
    'floor_name',
    'responder'
    /*'status'*/

  ]);
  currentPage$ = new BehaviorSubject<number>(1);
  pageSize$ = new BehaviorSubject<number>(10);
  dataOnPage$ = new BehaviorSubject<any[]>([]);
  searchFormControl = new FormControl();
  sortKey$ = new BehaviorSubject<string>('email');
  sortDirection$ = new BehaviorSubject<string>('asc');
  totalUserCount = 0;
  limit = 10;
  startIndex = 0;
  @ViewChild('spinnermodal')
  private defaultTabButtonsTpl: TemplateRef<any>;

  constructor(
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.getAllUsers(this.limit, this.startIndex);
    this.searchFormControl.setValue('');
  }
  updateUserRole(username: string) {
    this.router.navigate(['update-user-role', username]);
  }

  adjustSort(key: string) {
    if (this.sortKey$.value === key) {
      if (this.sortDirection$.value === 'asc') {
        this.sortDirection$.next('desc');
      } else {
        this.sortDirection$.next('asc');
      }
      return;
    }

    this.sortKey$.next(key);
    this.sortDirection$.next('asc');
  }

  handlePageChange(event): void {
    this.startIndex = event - 1;
    this.getAllUsers(this.limit, this.startIndex);
  }

  getAllUsers(limit: number, index: number) {
    const userData = [];
    this.searchFormControl.setValue('');
    this.communicationService.getFloorResponderUsers(limit, index).subscribe(res => {
      for (const user of res.employeeDaosList) {
        if (user.status == 0 || user.status == -1) {
          userData.push(user);
        }
      }
      this.totalUserCount = res.totalPageCount;
      this.heroes$ = new BehaviorSubject(userData);
      combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
        .subscribe(([allSources, currentPage, pageSize]) => {
          const startingIndex = (currentPage - 1) * pageSize;
          const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
          this.dataOnPage$.next(onPage);
        });

      this.heroes$.pipe(take(1)).subscribe(heroData => {
        this.tableDataSource$.next(Object.values(heroData));
      });

      combineLatest(this.heroes$, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          this.searchFormControl.setValue('');

          const sortedHeroes = heroesArray.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });

          this.tableDataSource$.next(sortedHeroes);
        });

        combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          let filteredHeroes: any[];

          if (!searchTerm) {
            filteredHeroes = heroesArray;
            this.totalUserCount = res.totalPageCount;
          } else {
            const filteredResults = heroesArray.filter(hero => {
              return Object.values(hero)
                .reduce((prev, curr) => {
                  if (curr != null) {
                    curr = curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
                  }
                  return prev || curr;
                }, false);
            });
            filteredHeroes = filteredResults;
            if(filteredHeroes.length >= 0){
              this.totalUserCount = filteredHeroes.length;
            }
          }

          const sortedHeroes = filteredHeroes.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) return sortDirection === 'asc' ? 1 : -1;
            if (a[sortKey] < b[sortKey]) return sortDirection === 'asc' ? -1 : 1;
            return 0;
          });

          this.tableDataSource$.next(sortedHeroes);
        });
    }, error => {
      console.log(error);
    });
  }
}



