import {Component, OnInit, LOCALE_ID, Inject, ViewChild, TemplateRef} from '@angular/core';
import {FormControl} from '@angular/forms';
import {formatDate} from '@angular/common';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {take} from 'rxjs/operators';
import {CommunicationService} from 'src/app/services/communication.service';
import {ViewOnMapComponent} from 'src/app/pages/view-on-map/view-on-map.component';
import * as XLSX from 'xlsx';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal, ModalDismissReasons, NgbModalRef, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {TimeByHour} from 'src/app/constants/timeList';
import {entityFeatures} from 'src/app/helper/entityFeatures';
import {userDeparment} from '../../helper/userDeparment';
import { ValidationService } from 'src/app/services/validation.service';


@Component({
  selector: 'app-view-bookings',
  templateUrl: './view-bookings.component.html',
  styleUrls: ['./view-bookings.component.scss']
})
@Injectable({providedIn: 'root'})
export class ViewBookingsComponent implements OnInit {
  showZooPass = false;
  showParking = false;
  showFood = false;
  showRenewBooking = true;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    public translate: TranslateService,
    private validationService: ValidationService,
  ) {

    this.router.config;
  }

  get f() {
    return this.detailsViewBookingFormRenew.controls;
  }

  @ViewChild('viewOnMapComp') viewOnMapComponent: ViewOnMapComponent;
  modalReference: NgbModalRef;
  detailsViewBookingForm: FormGroup;
  detailsViewBookingFormRenew: FormGroup;
  heroes$;
  seat_number;
  emp_number;
  superlatives$ = new BehaviorSubject<{ [superlativeName: string]: string }>({});
  tableDataSource$ = new BehaviorSubject<any[]>([]);
  displayedColumns$ = new BehaviorSubject<string[]>(this.getColumns());
  start_Date: Date;
  end_Date: Date;
  cities = [];
  currentPage$ = new BehaviorSubject<number>(1);
  pageSize$ = new BehaviorSubject<number>(10);
  dataOnPage$ = new BehaviorSubject<any[]>([]);
  searchFormControl = new FormControl();
  sortKey$ = new BehaviorSubject<string>('emp_id');
  sortDirection$ = new BehaviorSubject<string>('asc');
  totalUserCount = 0;
  limit = 10;
  buildings = [];
  startIndex = 0;
  status = [];
  moreDetailsData = null;
  closeResult: string;
  todayDate;
  downloadAll = false;
  isSearch = false;
  bookingTimeList: any;
  startTimeValue: any;
  isBookByTime = false;
  bookingTypes = [];
  currentUserDepartment;
  floors = [];
  validated: boolean;

  @ViewChild('pleaseWaitModal')
  private defaultTabButtonsTpl: TemplateRef<any>;

  fileName = 'report.xlsx';


  handleFlags() {
    const features = this.communicationService.getData('features') ? this.communicationService.getData('features') : [];
    features.forEach(element => {
      if ([entityFeatures.ZOOPASS].includes(element.feature_name)) {
        this.showZooPass = true;
      } else if (entityFeatures.CARPARKING.includes(element.feature_name)) {
        this.showParking = true;
      } else if (entityFeatures.FOODBOOKING.includes(element.feature_name)) {
        this.showFood = true;
      }
    });
  }


  ngOnInit() {
    // this.handleFlags();
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.currentUserDepartment = currentUser.department;
    this.getCities();
    this.detailsViewBookingForm = this.formBuilder.group(
      {
        startDate: new FormControl(),
        endDate: new FormControl(),
        status: [''],
        city: [''],
        building: [''],
        floor: [''],
        downloadAll: [''],
        type: [''],
        employee:[''],

      }
    );
    this.detailsViewBookingFormRenew = this.formBuilder.group(
      {
        startDateRenew: new FormControl(),
        endDateRenew: new FormControl(),
        seat_id: new FormControl(),
        commentsRenew: [''],
        startTime: new FormControl(),
        endTime: new FormControl(),
        floor_id: [''],
        wing_id: [''],
      }
    );
    this.getAllBookings(this.limit, this.startIndex);
    this.searchFormControl.setValue('');
    this.getBookingStatus();
    const currentDate = new Date();
    const objCurrentDate = {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate()
    };
    this.todayDate = objCurrentDate;

    this.detailsViewBookingForm.controls.startDate.setValue(this.todayDate);
    this.detailsViewBookingForm.controls.endDate.setValue(this.todayDate);

    this.detailsViewBookingFormRenew.controls.startDateRenew.setValue(this.todayDate);
    this.detailsViewBookingFormRenew.controls.endDateRenew.setValue(this.todayDate);
    this.setStartEndTime();
    this.bookingTypes = [{booking_type_id: 'All', booking_type_name: 'All'},
      {booking_type_id: 'Workstation', booking_type_name: 'Workstation'},
      {booking_type_id: 'Parking', booking_type_name: 'Parking'},
      {booking_type_id: 'ZooPass', booking_type_name: 'Zoo Pass'}
    ];
    this.detailsViewBookingForm.controls.type.setValue(this.bookingTypes[0].booking_type_id);
  }

  onStartDateChange(event) {
    this.detailsViewBookingForm.controls.endDate.setValue(this.detailsViewBookingForm.value.startDate);
  }

  getColumns() {
    let features = this.communicationService.getData('features') ? this.communicationService.getData('features') : [];
    const arr = [
      'emp_name',
      'city',
      'building',
      'floor',
      'wing',
      'seat_number',
      'start_date',
      'end_date',

    ];
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser) {
      const dept = currentUser.department;
      if (dept === userDeparment.AUSTRALIA) {
        this.showRenewBooking = false;
      }
      this.communicationService.getFeatureByDept(dept).subscribe(data => {
        features = data;
        features.forEach(element => {
          if (entityFeatures.CARPARKING.includes(element.feature_name)) {
            this.showParking = true;
            arr.push('parking');
          } else if ([entityFeatures.ZOOPASS].includes(element.feature_name)) {
          this.showZooPass = true;
          arr.push('zoo_pass');
          } else if (entityFeatures.FOODBOOKING.includes(element.feature_name)) {
            this.showFood = true;
            arr.push('is_meals_opted');
          }
        });
        arr.push('status_msg', 'comments', 'id');
      });
    } else {
      arr.push('status_msg', 'comments', 'id');
    }
    return arr;
  }

  ngAfterViewInit(): void {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    this.modalReference = this.modalService.open(this.defaultTabButtonsTpl, ngbModalOptions);
  }

  setStartEndTime() {
    this.bookingTimeList = TimeByHour;
    this.f.startTime.setValue(0);
    this.f.endTime.setValue(24);
    this.detailsViewBookingFormRenew.get('startTime').disable();
    this.detailsViewBookingFormRenew.get('endTime').disable();
  }

  getCities() {
    this.communicationService.getCities().subscribe(res => {
      this.cities = res;
    }, error => {
      console.error(error);
      this.modalReference.close();
      this.toastr.error(error.error.message);
    });
  }

  getBookingStatus() {
    this.communicationService.getBookingStatus().subscribe(res => {
      this.status = res;
    }, error => {
      console.error(error);
    });
  }

  onDateChange(event) {
    this.detailsViewBookingFormRenew.controls.endDateRenew.setValue(this.detailsViewBookingFormRenew.value.startDateRenew);
  }

  deselectTimeBooking(event) {
    if (event.target.checked) {
      this.detailsViewBookingFormRenew.get('startTime').enable();
      this.detailsViewBookingFormRenew.get('endTime').enable();
      this.isBookByTime = true;
    } else {
      this.isBookByTime = false;
      this.f.startTime.setValue(0);
      this.f.endTime.setValue(24);
      this.detailsViewBookingFormRenew.get('startTime').disable();
      this.detailsViewBookingFormRenew.get('endTime').disable();
    }
  }

  onStartTimeChange(value) {
    this.startTimeValue = value.target.value;
    let val = this.startTimeValue;
    this.setEndTime(++val);
  }

  setEndTime(val) {
    this.f.endTime.setValue(val);
  }

  onEndTimeChange(value) {
    let endTimeValue = value.target.value;
    if (this.startTimeValue < endTimeValue) {
      this.setEndTime(endTimeValue);
    } else {
      endTimeValue = this.startTimeValue;
      this.setEndTime(++endTimeValue);
    }
  }

  viewOnMap(booking_id: any) {
    this.router.navigate(['/view-on-map'], {state: {id: booking_id}});
  }

  adjustSort(key: string) {
    if (this.sortKey$.value === key) {
      if (this.sortDirection$.value === 'asc') {
        this.sortDirection$.next('desc');
      } else {
        this.sortDirection$.next('asc');
      }
      return;
    }
    this.sortKey$.next(key);
    this.sortDirection$.next('asc');
  }

  exportexcel() {
    let data;
    if (userDeparment.AUSTRALIA === this.currentUserDepartment) {
      this.downloadAll = true;
    }
    if (this.downloadAll) {
      let bookingType = this.detailsViewBookingForm.value.type;
      this.communicationService.downloadAllBookings(bookingType).subscribe(res => {
        data = res;
        this.prepareExcel(data);
      }, error => {
        console.error(error);
      });
    } else {
      data = this.dataOnPage$.value;
      this.prepareExcel(data);
    }
  }

  onCityChange(value) {
      this.getBuildings(value.target.value);
  }

  onBuildingChange(value) {
    this.getFloors(value.target.value);
  }

  getBuildings(value) {
      this.communicationService.getBuildings(value).subscribe(res => {
        if (res.length > 0) {
          this.buildings = [];
          for (const building of res) {
            if (building.status === '0') {
              this.buildings.push(building);
            }
          }
          if (this.buildings.length > 0) {
            this.buildings.sort( (a, b) => {
              if ( a.building_name < b.building_name) { return -1; }
              if (a.building_name > b.building_name) { return 1 ; }
              return 0;
            });
            this.buildings.sort( (a, b) => a.building_name - b.building_name);
            // this.f.building.setValue(this.buildings[0].building_id);
          } else {
            this.f.building.setValue('');
            this.modalReference.close();
          }
        } else {
          this.buildings = [];
          this.modalReference.close();
        }
      }, error => {
        this.modalReference.close();
        console.error(error);
      });
    }

    getFloors(building_id: string) {
      this.communicationService.getFloorsByBuilding(building_id).subscribe(res => {
        this.floors = [];
        for (const floor of res) {
          if (floor.status === '0') {
            this.floors.push(floor);
          }
        }
        if (this.floors.length > 0) {
          this.floors.sort( (a, b) => {
            if ( a.floor_name < b.floor_name) { return -1; }
            if (a.floor_name > b.floor_name) { return 1 ; }
            return 0;
          });
          this.floors.sort( (a, b) => a.floor_name - b.floor_name);
          // this.f.floor.setValue(this.floors[0].floor_id);
        } else {
          this.f.floor.setValue('');
          this.modalReference.close();
        }
      }, error => {
        this.modalReference.close();
        console.error(error);
      });
    }

    getValidSearchResult() {
      this.validated = this.validationService.validationSearchResult(this.searchFormControl.value);
      if (this.validated === false) {
        this.search();
      }
    }

  

  prepareExcel(data: any) {
    const arr = [];
    for (const obj of data) {
      const wsDetails = {
        'emp_name': obj.emp_name,
        'city': obj.city,
        'seat_number': obj.seat_number,
        'start_date': obj.start_date,
        'end_date': obj.end_date,
        'status': obj.status_msg,
        'comments': obj.comments,
        'type': obj.type,
        'parking': obj.parking,
        'zoo_pass': obj.zoo_pass,
        'building': obj.building,
      };
      arr.push(wsDetails);
    }

    const Heading = [
      ['EMPLOYEE NAME', 'CITY', 'WORKSTATION NUMBER', 'START DATE', 'END DATE', 'STATUS', 'COMMENTS', 'BOOKING TYPE', "PARKING", "ZOO PASS", "LOCATION"],
    ];

    // Had to create a new workbook and then add the header
    const wb = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, Heading);

    // Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(ws, arr, {origin: 'A2', skipHeader: true});

    XLSX.utils.book_append_sheet(wb, ws, 'Report');

    XLSX.writeFile(wb, this.fileName);
    this.toastr.success('File Downloaded succesfully');
  }

  selectDownloadAll(event) {
    if (event.target.checked) {
      this.downloadAll = true;
    } else {
      this.downloadAll = false;
    }
  }

  cancelBooking(id: any) {
    if (id.zoo_pass === "Zoo Pass") {
      this.communicationService.cancelSeatBooking(id.booking_id).subscribe(res => {
        this.toastr.success(this.translate.instant('toasterSuccessMessage.zooPassCancel'), '');
        this.getAllBookings(this.limit, this.startIndex);
      }, error => {
        this.toastr.error('Unable to cancel booking', '');
        console.log('Getting error while cancel booking');
      });
    } else if (id.parking != null && id.seat_number == null) {
      this.communicationService.cancelSeatBooking(id.booking_id).subscribe(res => {
        this.toastr.success(this.translate.instant('toasterSuccessMessage.carParkingCancel'), '');
        this.getAllBookings(this.limit, this.startIndex);
      }, error => {
        this.toastr.error('Unable to cancel booking', '');
        console.log('Getting error while cancel booking');
      });
    } else if (id.parking != null && id.seat_number != null) {
      this.communicationService.cancelSeatBooking(id.booking_id).subscribe(res => {
        this.toastr.success(this.translate.instant('toasterSuccessMessage.bookingWithCarParkingCancel'), '');
        this.getAllBookings(this.limit, this.startIndex);
      }, error => {
        this.toastr.error('Unable to cancel booking', '');
        console.log('Getting error while cancel booking');
      });
    } else {
      this.communicationService.cancelSeatBooking(id.booking_id).subscribe(res => {
        this.toastr.success(this.translate.instant('toasterSuccessMessage.bookingCancelled'), '');
        this.getAllBookings(this.limit, this.startIndex);
      }, error => {
        this.toastr.error('Unable to cancel booking', '');
        console.log('Getting error while cancel booking');
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  handlePageChange(event): void {
    this.startIndex = event - 1;
    if (this.isSearch) {
      this.search();
    } else {
      this.getAllBookings(this.limit, this.startIndex);
    }
  }

  getAllBookings(limit: number, index: number) {
    const bookingData = [];
    this.communicationService.getAllBookings(limit, index).subscribe(res => {
      for (const obj of res.bookingResponseList) {
        bookingData.push(obj);
      }
      this.modalReference.close();
      this.totalUserCount = res.totalPageCount;
      this.heroes$ = new BehaviorSubject(bookingData);
      combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
        .subscribe(([allSources, currentPage, pageSize]) => {
          const startingIndex = (currentPage - 1) * pageSize;
          const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
          this.dataOnPage$.next(onPage);
        });

      this.heroes$.pipe(take(1)).subscribe(heroData => {
        this.tableDataSource$.next(Object.values(heroData));
      });
      combineLatest(this.heroes$, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          const sortedHeroes = heroesArray.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) {
              return sortDirection === 'asc' ? 1 : -1;
            }
            if (a[sortKey] < b[sortKey]) {
              return sortDirection === 'asc' ? -1 : 1;
            }
            return 0;
          });

          this.tableDataSource$.next(sortedHeroes);
        });

      combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          let filteredHeroes: any[];

          if (!searchTerm) {
            filteredHeroes = heroesArray;
          } else {
            const filteredResults = heroesArray.filter(hero => {
              return Object.values(hero)
                .reduce((prev, curr) => {
                  return prev || curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
                }, false);
            });
            filteredHeroes = filteredResults;
          }

          const sortedHeroes = filteredHeroes.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) {
              return sortDirection === 'asc' ? 1 : -1;
            }
            if (a[sortKey] < b[sortKey]) {
              return sortDirection === 'asc' ? -1 : 1;
            }
            return 0;
          });
          this.tableDataSource$.next(sortedHeroes);
        });
    }, error => {
      this.modalReference.close();
      console.log(error);
    });
  }

  floor_id;
  wing_id;

  getMoreDetails(seat_number: any, username: any, row: any, content) {
    this.seat_number = seat_number;
    this.emp_number = username;
    this.floor_id = row.floor;
    this.wing_id = row.wing;
    this.detailsViewBookingFormRenew.controls.floor_id.setValue(this.floor_id);
    this.detailsViewBookingFormRenew.controls.wing_id.setValue(this.wing_id);
    this.detailsViewBookingFormRenew.controls.startDateRenew.setValue(this.todayDate);
    this.detailsViewBookingFormRenew.controls.endDateRenew.setValue(this.todayDate);
    this.moreDetailsData = null;
    this.detailsViewBookingFormRenew.controls.seat_id.setValue(this.seat_number);
    this.modalReference = this.modalService.open(content);
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  renew() {
    const startDateVal = formatDate(this.convertDate(this.detailsViewBookingFormRenew.value.startDateRenew), 'yyyy-MM-dd', this.locale);
    const endDateVal = formatDate(this.convertDate(this.detailsViewBookingFormRenew.value.endDateRenew), 'yyyy-MM-dd', this.locale);
    if (startDateVal <= endDateVal) {
      const bookSeatObj = {
        start_date_time: this.isBookByTime ? startDateVal + this.bookingTimeList[this.detailsViewBookingFormRenew.get('startTime').value].timeFormat :
          startDateVal + 'T00:00:00',
        end_date_time: this.isBookByTime ? endDateVal + this.bookingTimeList[this.detailsViewBookingFormRenew.get('endTime').value].timeFormat :
          endDateVal + 'T23:59:59',
        emp_id: this.emp_number,
        seat_id: this.seat_number,
        status: 0,
        comments: this.detailsViewBookingFormRenew.value.commentsRenew,
        futureReference: false,
        is_self: true,
        floor_id: this.floor_id,
        wing_id: this.wing_id
      };
      this.communicationService.bookSeat(bookSeatObj).subscribe(res => {
        this.detailsViewBookingFormRenew.controls.startDateRenew.setValue('');
        this.detailsViewBookingFormRenew.controls.endDateRenew.setValue('');
        this.detailsViewBookingFormRenew.controls.commentsRenew.setValue('');
        this.toastr.success(this.translate.instant('toasterSuccessMessage.workStationBooked'));
        this.modalReference.close();
        this.getAllBookings(this.limit, this.startIndex);
      }, error => {
        this.detailsViewBookingFormRenew.controls.startDateRenew.setValue('');
        this.detailsViewBookingFormRenew.controls.endDateRenew.setValue('');
        this.detailsViewBookingFormRenew.controls.commentsRenew.setValue('');
        this.toastr.error(error.error.message, 'Failed to book Workstation');
        this.modalReference.close();
      });
    } else {
      this.toastr.error('Invalid Booking Date Selection');
    }
  }

  convertDate(date) {
    if (date != null || date !== '') {
      const converdate = new Date(date.year, date.month - 1, date.day);
      return converdate;
    }
  }

  search() {
    const limit = this.limit;
    const index = this.startIndex;
    const bookingData = [];
    let sDate;
    let eDate;
    this.isSearch = true;

    if (this.detailsViewBookingForm.value.startDate) {
      sDate = this.convertDate(this.detailsViewBookingForm.value.startDate);
    }
    if (this.detailsViewBookingForm.value.endDate) {
      eDate = this.convertDate(this.detailsViewBookingForm.value.endDate);
    }
    let sDateFormatted;
    let eDateFormatted;

    if (sDate) {
      sDateFormatted = formatDate(sDate, 'yyyy-MM-dd', this.locale);
      sDateFormatted = sDateFormatted + 'T00:00:00';
    }
    if (eDate) {
      eDateFormatted = formatDate(eDate, 'yyyy-MM-dd', this.locale);
      eDateFormatted = eDateFormatted + 'T23:59:59';
    }
    if (sDateFormatted <= eDateFormatted) {
      const searchObj = {
        emp_id:this.searchFormControl.value,
        start_date: sDateFormatted,
        end_date: eDateFormatted,
        status: this.detailsViewBookingForm.value.status,
        city_id: this.detailsViewBookingForm.value.city ,
        building_id: this.detailsViewBookingForm.value.building,
        floor_id:this.detailsViewBookingForm.value.floor,
      };

      let flag:boolean = false;
      this.communicationService.getAllBookingsFilter(limit, index, searchObj).subscribe(res => {
        for (const obj of res.bookingResponseList) {
          bookingData.push(obj);
        }
        this.totalUserCount = res.totalPageCount;
        this.heroes$ = new BehaviorSubject(bookingData);
        combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
          .subscribe(([allSources, currentPage, pageSize]) => {
            const startingIndex = (currentPage - 1) * pageSize;
            const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
            this.dataOnPage$.next(onPage);
          });

        this.heroes$.pipe(take(1)).subscribe(heroData => {
          this.tableDataSource$.next(Object.values(heroData));
        });

        combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
          .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
            const heroesArray = Object.values(changedHeroData);
            let filteredHeroes: any[];

            if (!searchTerm) {
              filteredHeroes = heroesArray;
            } else {
              const filteredResults = heroesArray.filter(hero => {
                return Object.values(hero)
                  .reduce((prev, curr) => {
                    return prev || curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
                  }, false);
              });
              filteredHeroes = filteredResults;
            }

            const sortedHeroes = filteredHeroes.sort((a, b) => {
              if (a[sortKey] > b[sortKey]) {
                return sortDirection === 'asc' ? 1 : -1;
              }
              if (a[sortKey] < b[sortKey]) {
                return sortDirection === 'asc' ? -1 : 1;
              }
              return 0;
            });

            this.tableDataSource$.next(sortedHeroes);
          });
      }, error => {
        console.log(error);
      });
    } else {
      this.modalReference.close();
      this.toastr.error('Invalid Booking Date Selection');
    }
  }

}
