import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CommunicationService} from 'src/app/services/communication.service';
import {ConfirmationDialogService} from 'src/app/pages/confirmation-dialog/confirmation-dialog.service';
import {Coordinate} from 'src/app/model/coordinate';
import {AfterViewInit, Component, ElementRef, Inject, LOCALE_ID, TemplateRef, ViewChild} from '@angular/core';
import {formatDate} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {FloorPath} from 'src/app/model/floorPath';
import {User} from 'src/app/model/user';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {ValidationService} from 'src/app/services/validation.service';
import {TimeByHour} from 'src/app/constants/timeList';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {take} from 'rxjs/operators';
import { userRoles } from 'src/app/helper/userRoles';
import { SeatAttributes } from 'src/app/model/seatattributes';
import { element } from 'protractor';


@Component({
  selector: 'app-book-seat',
  templateUrl: './book-seat.component.html',
  styleUrls: ['./book-seat.component.scss'],
})
export class BookSeatComponent implements AfterViewInit {
  detailsViewComment: FormGroup;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private confirmationDialogService: ConfirmationDialogService,
    private validationService: ValidationService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    public translate: TranslateService) {
  }

  get f() {
    return this.detailsBookSeatForm.controls;
  }

  get comments() {
    return this.detailsBookSeatForm.get('comments');
  }
  modalReference: NgbModalRef;
  showFloorPlanFlag = false;

  @ViewChild('canvas', {static: true})
  canvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('tooltip', {static: true})
  tooltip: ElementRef<HTMLCanvasElement>;

  @ViewChild('mymodal')
  private defaultTabButtonsTpl: TemplateRef<any>;

  @ViewChild('modalComment')
  private modalComment: TemplateRef<any>;


  hideTooltip = false;
  selectedSeat: any;
  imageCanvas: HTMLImageElement;

  floorPlanCtx: CanvasRenderingContext2D;
  tooltipCtx: CanvasRenderingContext2D;

  workstationNameForm: FormGroup;
  floorPlanImage: HTMLImageElement;
  detailsBookSeatForm: FormGroup;
  cities = [];
  countries = [];
  floors = [];
  buildings = [];
  wings = [];
  thumbnail: any;
  coordinates: Coordinate[] = [];
  floorPath: FloorPath;
  x_value: any;
  y_value: any;
  floor_id: any;
  wing_id: any;
  todayDate;
  employeeNo: any;
  loading = false;
  is_selected = false;
  is_map_shown = false;
  isFuturePreference = false;
  selectedSeats: any;
  corpId: any;
  user: User;
  searchResult = [];
  searchResultSeats = [];
  isSelfBooking = true;
  keySize = 256;
  iterations = 1000;
  limit = 30;
  startIndex = 0;
  showAttribute = 0;
  metaJsonObject = {};
  viewAttribute: string;
  seat_name: string;
  displayMap = new Map<string, string>();
  seat_name2: string;
  viewAttribute2: string;
  metaJsonObject2 = {};
  displayMap2 = new Map<string, string>();
  viewAttribute3: string;
  seat_name3: string;
  metaJsonObject3 = {};
  displayMap3 = new Map<string, string>();
  popoverAttribute: string;
  validatated: any;
  bookingTimeList: any;
  startTimeValue = 0;
  isBookByTime = false;
  emp_name;
  dropdown:boolean = false;
  userIdList=[];
  tableDataSource$ = new BehaviorSubject<any[]>([]);
  displayedColumns$ = new BehaviorSubject<string[]>([
    'emp_name',
    'start_date',
    'end_date',
    'comments',
    'details',
    'id',
  ]);
  totalUserCount = 0;
  bookingList: Coordinate[] = [];
  searchFormControl = new FormControl();
  currentPage$ = new BehaviorSubject<number>(1);
  pageSize$ = new BehaviorSubject<number>(10);
  dataOnPage$ = new BehaviorSubject<any[]>([]);
  sortKey$ = new BehaviorSubject<string>('start_date_time');
  sortDirection$ = new BehaviorSubject<string>('asc');
  heroes$: BehaviorSubject<any[]>;
  submitted: boolean;
  isDisabled: boolean;
  activeBookings : number;
  detailsFormNew: FormGroup;
  isOnSubmit = false;
  userRole:any;
  emailNotification:boolean = false;
    isManager=false;
      validated: boolean;
  selectedUser:any;
  isMeetingRoom:boolean;
  isMeetingRoomBook:boolean;
  userData =new Map<string,string>();
  isUserDataEmpty : boolean;

  // tslint:disable-next-line:use-lifecycle-interface use-life-cycle-interface
  ngOnInit(): void {
    this.getCountries();
    this.detailsBookSeatForm = this.formBuilder.group({
      city: ['', Validators.required],
      country: ['', Validators.required],
      floor: ['', Validators.required],
      wing: ['', Validators.required],
      building: ['', Validators.required],
      seat_id: ['', Validators.required],
      employee_id: ['', Validators.required],
      employee_name: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      comments: new FormControl('', [Validators.pattern('[a-zA-Z0-9 ]*'), Validators.maxLength(250)]),
      futureReference: ['', Validators.required],
      email_notification: ['', Validators.required],
      selfBooking: ['', Validators.required],
      type: ['', Validators.required],
      empName: [''],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      workstationName: [''],
    });
    this.workstationNameForm = this.formBuilder.group({
      name: ['', Validators.required],
      name2: ['', Validators.required],
      name3: ['', Validators.required],
    });
    this.detailsViewComment = this.formBuilder.group({
      name: ['', Validators.required],
      comments: ['', Validators.required]
    });
      this.detailsFormNew = this.formBuilder.group(
      {
        name: ['', Validators.required],
        capacity:['', Validators.required],
        empName: [''],
      }
  );
    const currentDate = new Date();
    this.todayDate = {year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate()};
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.userRole = currentUser.role;
    this.isManager= this.userRole ==userRoles.MANAGER?true:false;
    if (currentUser.id) {
      this.communicationService.getUserDetails(currentUser.id).subscribe(res => {
        this.detailsBookSeatForm.controls.employee_name.setValue(res.firstName + ' ' + res.lastName);
        this.detailsBookSeatForm.controls.employee_id.patchValue(res.username);
      }, error => {
        console.log('error', error);
      });
    }
    this.activeBookings = currentUser.activeBookingCount ;
    this.detailsBookSeatForm.controls.startDate.setValue(this.todayDate);
    this.detailsBookSeatForm.controls.endDate.setValue(this.todayDate);
    this.setStartEndTime();
  }

  onDateChange() {
    const startDate = this.detailsBookSeatForm.value.startDate;
    this.f.endDate.setValue(startDate);
    this.onSubmit();
  }

  validatingComment() {
    this.validatated = this.validationService.validationComment(this.detailsBookSeatForm.value.comments);
  }

  onChange(event) {
    if (event.target.checked) {
      this.isFuturePreference = true;
    } else {
      this.isFuturePreference = false;
    }
  }

  onEmailChange(event) {
      if (event.target.checked) {
        this.emailNotification = true;
      } else {
        this.emailNotification = false;
      }
    }

  setStartEndTime() {
    this.bookingTimeList = TimeByHour;
    this.f.startTime.setValue(0);
    this.f.endTime.setValue(24);
    this.detailsBookSeatForm.get('startTime').disable();
    this.detailsBookSeatForm.get('endTime').disable();
  }

  getSearchUserData(data: any) {
    let myForm = this.detailsBookSeatForm.value.empName;
    if (typeof (data) === 'object') {
      myForm = data.displayName;
      this.corpId = data.corpId;
        this.detailsBookSeatForm.controls['empName'].setValue(myForm);
      this.searchResult.length = 0;
      this.user = new User();
      this.user.email = data.email;
      this.user.firstName = data.firstName;
      this.user.lastName = data.lastName;
      this.user.username = data.username;
      this.user.department = data.department;
    }
  }

  getSearchUser(data: any) {
    let myForm = this.detailsFormNew.value.empName;
    if (typeof (data) === 'object') {
      myForm = data.displayName;
      this.corpId = data.username;
      this.detailsFormNew.controls['empName'].setValue(myForm);
      this.searchResult.length = 0;
    }
  }
  get getUserData(){
    return Array.from(this.userData.keys());
  }
  addEmployee(){
    this.userIdList =[];
   let capacity = this.detailsFormNew.get('capacity').value;
   let val = this.detailsFormNew.get('empName').value
   this.communicationService.getSearchResult(val).subscribe(res => {
    for (const user of res.employeeList.users) {
      if(user.displayName === (val)){
          if( !this.userData.has(val) && capacity>this.userData.size){
        this.userData.set(user.id,user.displayName);
        this.isUserDataEmpty =false;
      }}
    }
    if (res.employeeList.users.length === 0 ) {
      this.toastr.error('Search User not found.');
    }
  }, error => {
    this.searchResult.length = 0;
      this.toastr.error('Search User not found.');
  });
  }

  removeEmployee(key){
    if(this.userData.has(key)){
      this.userData.delete(key);
    }
    this.isUserDataEmpty = this.userData.size>0?false:true;
  }

  save(){
    this.userIdList =[];
    this.userData.forEach((value: string, key: string) => {
      this.userIdList.push(key);
    });
    this.isUserDataEmpty = (this.userIdList.toString()=='')?true:false;
    this.isDisabled = true;
    let is_self = true;
    const startDateVal =
     formatDate(this.convertDate(this.detailsBookSeatForm.value.startDate), 'yyyy-MM-dd', this.locale);
   const endDateVal = formatDate(this.convertDate(this.detailsBookSeatForm.value.endDate), 'yyyy-MM-dd', this.locale);
 if (startDateVal <= endDateVal) {
        const bookSeatObj = {
          start_date_time: this.isBookByTime ? startDateVal + this.bookingTimeList[this.detailsBookSeatForm.get('startTime').value].timeFormat :
            startDateVal + 'T00:00:00',
          end_date_time: this.isBookByTime ? endDateVal + this.bookingTimeList[this.detailsBookSeatForm.get('endTime').value].timeFormat :
            endDateVal + 'T23:59:59',
          emp_id: this.detailsBookSeatForm.value.employee_id,
          seat_id: this.detailsBookSeatForm.value.seat_id,
          status: 0,
          email_notification: this.emailNotification,
          comments: this.detailsBookSeatForm.value.comments,
          floor_id: this.detailsBookSeatForm.value.floor,
          wing_id: this.detailsBookSeatForm.value.wing,
          futureReference: this.isFuturePreference,
          is_self,
          users_id:this.userIdList.toString(),
        };
        this.communicationService.bookSeat(bookSeatObj).subscribe(res => {
          this.modalReference.close();
          this.toastr.success(this.translate.instant('toasterSuccessMessage.workStationBooked'));
          this.router.navigate(['/view-bookings']);
        },
        error => {
          console.log('error', error);
          this.toastr.error(error.error.message, 'Failed to book Workstation');
        });
      } else {
        this.toastr.error('Invalid Booking Date Selection');
      }
  }

  deselectSelfBooking(event) {
    if (event.target.checked) {
      this.isSelfBooking = false;
    } else {
      this.isSelfBooking = true;
    }
  }

  deselectTimeBooking(event) {
    if (event.target.checked) {
      this.detailsBookSeatForm.get('startTime').enable();
      this.detailsBookSeatForm.get('endTime').enable();
      this.isBookByTime = true;
    } else {
      this.isBookByTime = false;
      this.f.startTime.setValue(0);
      this.f.endTime.setValue(24);
      this.detailsBookSeatForm.get('startTime').disable();
      this.detailsBookSeatForm.get('endTime').disable();
    }
  }

  onFloorChange(value) {
    if (value) {
      this.f.wing.setValue('');
      this.getWings(value.target.value);
    }
  }

  onCityChange(value) {
    this.getBuildings(value.target.value);
  }

  onBuildingChange(value) {
    this.getFloors(value.target.value);
  }

  onCountryChange(value) {
    this.getCitiesByCountry(value.target.value);
  }

  onStartTimeChange(value) {
    this.startTimeValue = value.target.value;
    let val = this.startTimeValue;
    this.setEndTime(++val);
    this.onSubmit();
  }

  setEndTime(val) {
    this.f.endTime.setValue(val);
  }

  onEndTimeChange(value) {
    let endTimeValue = value.target.value;
    // tslint:disable-next-line:radix
    if (parseInt(endTimeValue) > this.startTimeValue) {
      this.f.endTime.setValue(endTimeValue);
    } else {
      endTimeValue = this.startTimeValue;
      this.setEndTime(++endTimeValue);
    }
    this.onSubmit();
  }

  getCities() {
    this.communicationService.getCities().subscribe(res => {
      this.cities = res;
    }, error => {
      this.modalReference.close();
      console.error(error);
    });
  }

  getCountries() {
    this.communicationService.getCountries().subscribe(res => {
      this.countries = [];
      for (const country of res) {
        if (country.status === '0') {
          this.countries.push(country);
        }
      }
      if (this.countries.length > 0) {
        this.f.country.setValue(this.countries[0].country_id);
        this.getCitiesByCountry(this.countries[0].country_id);
      } else {
        this.countries = [];
        this.f.country.setValue('');
        this.cities = [];
        this.f.city.setValue('');
        this.buildings = [];
        this.f.building.setValue('');
        this.floors = [];
        this.f.floor.setValue('');
        this.wings = [];
        this.f.wing.setValue('');
        this.modalReference.close();
      }
    }, error => {
      this.modalReference.close();
      if (error.error.message) {
        this.toastr.error(error.error.message);
      }
    });
  }

  getBuildings(value) {
    this.communicationService.getBuildings(value).subscribe(res => {
      if (res.length > 0) {
        this.buildings = [];
        for (const building of res) {
          if (building.status === '0') {
            this.buildings.push(building);
          }
        }
        if (this.buildings.length > 0) {
          this.buildings.sort( (a, b) => {
            if ( a.building_name < b.building_name) { return -1; }
            if (a.building_name > b.building_name) { return 1 ; }
            return 0;
          });
          this.buildings.sort( (a, b) => a.building_name - b.building_name);
          this.f.building.setValue(this.buildings[0].building_id);
          this.getFloors(this.buildings[0].building_id);
        } else {
          this.f.building.setValue('');
          this.floors = [];
          this.f.floor.setValue('');
          this.wings = [];
          this.f.wing.setValue('');
          this.modalReference.close();
        }
      } else {
        this.buildings = [];
        this.f.building.setValue('');
        this.floors = [];
        this.f.floor.setValue('');
        this.wings = [];
        this.f.wing.setValue('');
        this.modalReference.close();
      }
    }, error => {
      this.modalReference.close();
      console.error(error);
    });
  }

  async getCoords() {
    const startDateVal = formatDate(this.convertDate(this.detailsBookSeatForm.value.startDate),
      'yyyy-MM-dd', this.locale);
    const endDateVal = formatDate(this.convertDate(this.detailsBookSeatForm.value.endDate),
      'yyyy-MM-dd', this.locale);
    const coordinates = {
      start_date_time: this.isBookByTime ? startDateVal + this.bookingTimeList[this.detailsBookSeatForm.get('startTime').value].timeFormat :
        startDateVal + 'T00:00:00',
      end_date_time: this.isBookByTime ? endDateVal + this.bookingTimeList[this.detailsBookSeatForm.get('endTime').value].timeFormat :
        endDateVal + 'T23:59:59',
      floor_id: this.detailsBookSeatForm.value.floor,
      wing_id: this.detailsBookSeatForm.value.wing
    };
    this.communicationService.getSeats(coordinates).subscribe(res => {
      // let workstations = [];
      // for (let obj of res) {
      //   // if (obj.status === '0') {
      //   //   workstations.push(obj);
      //   // }
      // }
      this.coordinates = res;
      if (res != null) {
        this.loadMapBorder();
      }
      this.showFloorPlan();
    }, error => {
      console.error(error);
    });
  }

  ngAfterViewInit(): void {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    this.modalReference = this.modalService.open(this.defaultTabButtonsTpl, ngbModalOptions);
    this.floorPlanCtx = this.canvas.nativeElement.getContext('2d');
    this.tooltipCtx = this.tooltip.nativeElement.getContext('2d');
    this.tooltipCtx.fillStyle = '#ffffff';
  }

  getFloors(building_id: string) {
    this.communicationService.getFloorsByBuilding(building_id).subscribe(res => {
      this.floors = [];
      for (const floor of res) {
        if (floor.status === '0') {
          this.floors.push(floor);
        }
      }
      if (this.floors.length > 0) {
        this.floors.sort( (a, b) => {
          if ( a.floor_name < b.floor_name) { return -1; }
          if (a.floor_name > b.floor_name) { return 1 ; }
          return 0;
        });
        this.floors.sort( (a, b) => a.floor_name - b.floor_name);
        this.f.floor.setValue(this.floors[0].floor_id);
        this.getWings(this.floors[0].floor_id);
      } else {
        this.f.floor.setValue('');
        this.wings = [];
        this.f.wing.setValue('');
        this.modalReference.close();
      }
    }, error => {
      this.modalReference.close();
      console.error(error);
    });
  }

  getCitiesByCountry(country_id: string) {
    this.communicationService.getCitiesByCountry(country_id).subscribe(res => {
      if (res.length > 0) {
        this.cities = [];
        for (const city of res) {
          if (city.status === '0') {
            this.cities.push(city);
          }
        }
        if (this.cities.length > 0) {
          this.cities.sort( (a, b) => {
            if ( a.city_name < b.city_name) { return -1; }
            if (a.city_name > b.city_name) { return 1 ; }
            return 0;
          });
          this.cities.sort( (a, b) => a.city_name - b.city_name);
          this.f.city.setValue(this.cities[0].city_id);
          this.getBuildings(this.cities[0].city_id);
        } else {
          this.cities = [];
          this.f.city.setValue('');
          this.buildings = [];
          this.f.building.setValue('');
          this.floors = [];
          this.f.floor.setValue('');
          this.wings = [];
          this.f.wing.setValue('');
          this.modalReference.close();
        }
      } else {
        this.cities = [];
        this.f.city.setValue('');
        this.buildings = [];
        this.f.building.setValue('');
        this.floors = [];
        this.f.floor.setValue('');
        this.wings = [];
        this.f.wing.setValue('');
        this.modalReference.close();
      }
    }, error => {
      this.modalReference.close();
      console.error(error);
    });
  }

  getWings(floor_id: string) {
    this.communicationService.getWings(floor_id).subscribe(res => {
      this.wings = [];
      for (const wing of res) {
        if (wing.status === '0') {
          this.wings.push(wing);
        }
      }
      if (this.wings.length > 0) {
        this.wings.sort( (a, b) => {
          if ( a.wing_name < b.wing_name) { return -1; }
          if (a.wing_name > b.wing_name) { return 1 ; }
          return 0;
        });
        this.wings.sort( (a, b) => a.wing_name - b.wing_name);
        this.f.wing.setValue(this.wings[0].wing_id);
      } else {
        this.f.wing.setValue('');
      }
      this.modalReference.close();
    }, error => {
      this.modalReference.close();
    });
  }

  onSubmit() {
    this.isOnSubmit = true;
    this.is_selected = false;
    this.selectedSeats = null;
    this.is_map_shown = true;
    this.getCoords();
    this.clearAttributes();
  }

  onClearMap() {
    this.isOnSubmit = false;
    this.is_selected = false;
    this.selectedSeats = null;
    this.isMeetingRoom = false;
    this.isMeetingRoomBook =false;
    this.clearAttributes();
    if (this.is_map_shown) {
      this.showFloorPlan();
    } else {
      this.toastr.error('Select floor and wing');
    }
  }

  clearAttributes() {
    this.showAttribute = 0;
    this.viewAttribute = '';
    this.viewAttribute2 = '';
    this.viewAttribute3 = '';
    this.metaJsonObject = {};
    this.metaJsonObject2 = {};
    this.metaJsonObject3 = {};
    this.seat_name = '';
    this.seat_name2 = '';
    this.seat_name3 = '';
    this.displayMap.clear();
    this.displayMap2.clear();
    this.displayMap3.clear();
  }

  getFloorWings(floor_id: string, wing_id: string) {
    this.communicationService.getFloorWings(floor_id, wing_id).subscribe(res => {
      const objectURL = 'data:image/png;base64,' + res.image_path;
      const image = new Image();
      image.src = objectURL;
      this.floorPlanImage = image;
      image.onload = (() => this.fitToCanvas(image));
    }, error => {
      this.loading = false;
      if (this.isOnSubmit == true) {
        this.toastr.error(error.error.message, 'Failed to load map');
      }
    });
  }

  showFloorPlan() {
    this.is_selected = false;
    this.loading = true;
    this.clearAttributes();
    this.f.seat_id.setValue('');
    const wing_id = this.f.wing.value;
    const floor_id = this.f.floor.value;
    this.getFloorWings(floor_id, wing_id);
  }

  setBookingsPopup(element, content) {
    this.userIdList =[];
    this.bookingList =[];
    this.selectedSeat = element;
    if(this.selectedSeat.type == '1'){
      this.isMeetingRoom = this.selectedSeat.selected? false:true;
      this.isMeetingRoomBook =true;
    }
    this.f.workstationName.setValue(element.name);
    element.bookings.forEach(booking => {
      this.bookingList.push(booking);
      if(booking.users_id != null){
        this.userIdList.push(booking.users_id)
      }
    });
    this.isUserDataEmpty = this.userIdList.toString()==''?true:false;
    this.sortBookings();
    this.modalReference = this.modalService.open(content);
  }

  setMeetingRoomsPopup(element, content) {
    this.userData.clear();
    this.detailsFormNew.controls.empName.setValue('');
    this.modalReference = this.modalService.open(content);
    this.detailsFormNew.controls.name.setValue(element.name);
    this.seat_name = element.name;
    this.communicationService.getSeatById(element.seat_id).subscribe(res => {
    this.detailsFormNew.controls.capacity.setValue(res.capacity);
    }, error => {
        console.error(error);
      }
    );
  }


  loadMapBorder() {
    this.bookingList = [];
    const rB = this.floorPlanCtx.canvas.getBoundingClientRect();
    this.coordinates.map((element) => {
      this.floorPlanCtx.strokeStyle = '#006400';
      this.floorPlanCtx.strokeRect(element.x, element.y, element.w, element.h);
      this.selectedSeat = element;
      if (element.h === null || element.w === null) {
        this.floorPlanCtx.fillStyle ='#006400';
        this.plotPolygonBorder(element);
      }
    });
  }

  onClick(event, content) {
    this.isMeetingRoom =  false;
    this.isMeetingRoomBook =false;
    this.userIdList =[];
    const rB = this.floorPlanCtx.canvas.getBoundingClientRect();
    const x = event.pageX - rB.left;
    const y = event.pageY - (rB.top + window.scrollY);
    this.coordinates.map((element) => {
      if (element.selected && element.status=='0' ){
        if (y > element.y && y < element.y + element.h
          && x > element.x && x < element.x + element.w) {
          this.setBookingsPopup(element, content);
        } else if (element.h === null || element.y === null
          || element.x === null || element.w === null) {
          const odd: any = [];
          const even: any = [];
          this.setArrays(element, even, odd);
          const {xMin, xMax, yMin, yMax} = this.getMinMax(even, odd);
          if (this.isPointInsidePoly(x, y, xMin, xMax, yMin, yMax)) {
            this.setBookingsPopup(element, content);
          }
        }
      } else if ( !element.selected && element.reserved=='Yes' && element.status == '0' ){
        if (y > element.y && y < element.y + element.h
          && x > element.x && x < element.x + element.w) {
            this.setReservedComment(element , content);
        } else if (element.h === null || element.y === null
          || element.x === null || element.w === null) {
          const odd: any = [];
          const even: any = [];
          this.setArrays(element, even, odd);
          const {xMin, xMax, yMin, yMax} = this.getMinMax(even, odd);
          if (this.isPointInsidePoly(x, y, xMin, xMax, yMin, yMax)) {
            this.setReservedComment(element , content);
          }
        }
      } else if (!element.is_common && !element.selected && element.status ==='0') {
        if (element.shape === 0) {
          let noOfSeats: any = [];
          if (y > element.y && y < element.y + element.h
            && x > element.x && x < element.x + element.w) {
            if (!this.is_selected) {
              this.floorPlanCtx.fillStyle = '#64a844';
              this.floorPlanCtx.fillRect(element.x, element.y, element.w, element.h);
              this.floorPlanCtx.strokeStyle = '#c2cca6';
              this.floorPlanCtx.strokeRect(element.x, element.y, element.w, element.h);
              if (this.seat_name !== element.name && this.seat_name2 !== element.name && this.seat_name3 !== element.name) {
                if (this.showAttribute === 0) {
                  this.showAttribute = 1;
                  this.getDisplayAttributes1(element.name);
                } else if (this.showAttribute === 1) {
                  this.showAttribute = 2;
                  this.getDisplayAttributes2(element.name);
                } else {
                  this.showAttribute = 3;
                  this.getAttributeDetails3(element.name);
                }
              }
              if (this.selectedSeats != null) {
                const array = this.selectedSeats.split(',');
                if (array.indexOf(element.name) > -1) {
                } else {
                  this.selectedSeats = this.selectedSeats + ',' + element.name;
                }
              } else {
                this.selectedSeats = element.name;
              }
              this.f.seat_id.setValue(this.selectedSeats);
              noOfSeats = this.selectedSeats.split(',');
              if ((noOfSeats.length > 60 && this.userRole == "Manager")) {
                this.is_selected = true;
                } else if (noOfSeats.length > 2 && this.userRole != "Manager") {
                  this.is_selected = true;
                }
            }

          }
        } else {
          const odd: any = [];
          const even: any = [];
          let noOfSeats: any = [];
          this.setArrays(element, even, odd);
          const {xMin, xMax, yMin, yMax} = this.getMinMax(even, odd);
          if (this.isPointInsidePoly(x, y, xMin, xMax, yMin, yMax)
            && !element.is_common && !element.selected) {
            if (!this.is_selected) {
              this.floorPlanCtx.fillStyle = '#64a844';
              this.plotPolygon(element);
              this.selectedSeat = element;
              this.isMeetingRoom = this.selectedSeat.type == '1'? true:false;
              if (this.seat_name !== element.name && this.seat_name2 !== element.name && this.seat_name3 !== element.name) {
                if (this.showAttribute === 0) {
                  this.showAttribute = 1;
                  this.getDisplayAttributes1(element.name);
                } else if (this.showAttribute === 1) {
                  this.showAttribute = 2;
                  this.getDisplayAttributes2(element.name);
                } else {
                  this.showAttribute = 3;
                  this.getAttributeDetails3(element.name);
                }
              }
              if (this.selectedSeats != null) {
                const array = this.selectedSeats.split(',');
                if (array.indexOf(element.name) > -1) {
                } else {
                  this.selectedSeats = this.selectedSeats + ',' + element.name;
                }
              } else {
                this.selectedSeats = element.name;
              }
              this.f.seat_id.setValue(this.selectedSeats);
              noOfSeats = this.selectedSeats.split(',');
              // if (noOfSeats.length > 2) {
              //   this.is_selected = true;
              // }
              if (noOfSeats.length > 60 && this.userRole == "Manager") {
                this.is_selected = true;
                } else if (noOfSeats.length > 2 && this.userRole != "Manager") {
                  this.is_selected = true;
                }
            }

          }
        }
      }
    });
  }

  meetingRoomDetails(meetingRoomDetails,id){
    this.userIdList=[];
    if(this.selectedSeat.type===1) {
      this.setMeetingRoomsPopup(this.selectedSeat,meetingRoomDetails);}
    if(this.selectedSeat.bookings != null){
    for(const booking of this.selectedSeat.bookings){
      if(booking.booking_id === id){
      this.userIdList.push(booking.users_id);
     this.communicationService.getUserByIds(this.userIdList).subscribe(res => {
       if(res != null){
         const users = res;
           users.forEach(user=>{
            var name = user.firstName + " "+ user.lastName;
            this.userData.set(user.id,name);
           })
       }
    });}
  }
}
  }

  setReservedComment(element , content){
      this.detailsViewComment.controls.name.setValue(element.name);
      this.communicationService.getSeat(element.name).subscribe(res => {
        this.detailsViewComment.controls.comments.setValue(res.comments);
      }, error => {
          console.error(error);
        }
        );
      this.modalReference = this.modalService.open(this.modalComment , content);
  }

  sortBookings() {
    const bookingData = [];
    bookingData.push(this.bookingList);
    this.totalUserCount = 10;
    this.heroes$ = new BehaviorSubject(bookingData);
    combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
      .subscribe(([allSources, currentPage, pageSize]) => {
        const startingIndex = (currentPage - 1) * pageSize;
        const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
        this.dataOnPage$.next(onPage);
      });

    this.heroes$.pipe(take(1)).subscribe(heroData => {
      this.tableDataSource$.next(Object.values(heroData));
    });
    combineLatest(this.heroes$, this.sortKey$, this.sortDirection$)
      .subscribe(([changedHeroData, sortKey, sortDirection]) => {
        const heroesArray = Object.values(changedHeroData);
        const sortedHeroes = heroesArray.sort((a, b) => {
          if (a[sortKey] > b[sortKey]) {
            return sortDirection === 'asc' ? 1 : -1;
          }
          if (a[sortKey] < b[sortKey]) {
            return sortDirection === 'asc' ? -1 : 1;
          }
          return 0;
        });

        this.tableDataSource$.next(sortedHeroes);
      });

    combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
      .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
        const heroesArray = Object.values(changedHeroData);
        let filteredHeroes: any[];

        if (!searchTerm) {
          filteredHeroes = heroesArray;
        } else {
          const filteredResults = heroesArray.filter(hero => {
            return Object.values(hero)
              .reduce((prev, curr) => {
                return prev || curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
              }, false);
          });
          filteredHeroes = filteredResults;
        }

        const sortedHeroes = filteredHeroes.sort((a, b) => {
          if (a[sortKey] > b[sortKey]) {
            return sortDirection === 'asc' ? 1 : -1;
          }
          if (a[sortKey] < b[sortKey]) {
            return sortDirection === 'asc' ? -1 : 1;
          }
          return 0;
        });
        this.tableDataSource$.next(sortedHeroes);
      });
  }

  getAttributeDetails3(value: string) {
    this.communicationService.getSeat(value).subscribe(res => {
        this.seat_name3 = value;
        this.workstationNameForm.controls.name3.patchValue(this.seat_name3);
        if (res.attributes !== 'null') {
          this.viewAttribute3 = res.attributes;
          this.metaJsonObject3 = JSON.parse(this.viewAttribute3);
          const keys = Object.keys(this.metaJsonObject3);
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < keys.length; i++) {
            this.displayMap3.set(keys[i], this.metaJsonObject3[keys[i]]);
          }
        }
      }, error => {
        console.error(error);
      }
    );
  }

  getDisplayAttributes2(value: string) {
    this.communicationService.getSeat(value).subscribe(res => {
        this.seat_name2 = value;
        this.workstationNameForm.controls.name2.patchValue(this.seat_name2);
        if (res.attributes !== 'null') {
          this.viewAttribute2 = res.attributes;
          this.metaJsonObject2 = JSON.parse(this.viewAttribute2);
          const keys = Object.keys(this.metaJsonObject2);
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < keys.length; i++) {
            this.displayMap2.set(keys[i], this.metaJsonObject2[keys[i]]);
          }
        }

      }, error => {
        console.error(error);
      }
    );
  }

  getDisplayAttributes1(value: string) {
    this.communicationService.getSeat(value).subscribe(res => {
      this.seat_name = value;
      this.workstationNameForm.controls.name.patchValue(this.seat_name);
      if (res.attributes !== 'null') {
        this.viewAttribute = res.attributes;
        this.metaJsonObject = JSON.parse(this.viewAttribute);
        const keys = Object.keys(this.metaJsonObject);
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < keys.length; i++) {
          this.displayMap.set(keys[i], this.metaJsonObject[keys[i]]);
        }
      }
    }, error => {
      console.error(error);
    });
  }

  getAttributeDetails(content) {
    if (this.showAttribute > 0) {
      this.popoverAttribute = '';
      this.modalReference = this.modalService.open(content);
    } else {
      this.popoverAttribute = 'Please select a workstation to get it\'s attributes';
    }
  }

  // onMouseMove(event) {
  //   this.hideTooltip = false;
  //   const rB = this.floorPlanCtx.canvas.getBoundingClientRect();
  //   const x = event.pageX - (rB.left + window.scrollX);
  //   const y = event.pageY - (rB.top + window.scrollY);
  //   // Put your mousemove stuff here
  //   // tslint:disable-next-line:prefer-for-of
  //   for (let i = 0; i < this.coordinates.length; i++) {
  //     const section = this.coordinates[i];
  //     if (section.type === 0) {
  //       if (y > section.y && y < section.y + section.h
  //         && x > section.x && x < section.x + section.w) {
  //         let lineheight = 15;
  //         this.canvas.nativeElement.style.cursor = 'pointer';
  //         this.tooltip.nativeElement.style.left = (section.x) + 'px';
  //         this.tooltip.nativeElement.style.top = (section.y) + 'px';
  //         this.tooltipCtx.clearRect(0, 0, this.tooltip.nativeElement.width, this.tooltip.nativeElement.height);

  //         if (section.is_common) {
  //           this.hideTooltip = true;

  //           this.tooltipCtx.fillText(`\nWorkstation Name : ${section.name}`, 0, lineheight);
  //           lineheight = lineheight + 25;

  //           this.tooltipCtx.fillText(`\nOccupied By : NA`, 0, lineheight);
  //           lineheight = lineheight + 25;

  //           this.tooltipCtx.fillText(`\nBooking Dates : NA`, 0, lineheight);
  //           lineheight = lineheight + 25;

  //           this.tooltipCtx.textAlign = 'left';
  //           this.tooltipCtx.font = 'bold  15px Arial';

  //         } else if (section.selected) {
  //           this.hideTooltip = true;
  //           this.tooltipCtx.fillText(`\nWorkstation Name : ${section.name}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nOccupied By : ${section.emp_name}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nStart Date & Time : ${section.start_date_time}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nEnd Date & Time : ${section.end_date_time}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nStatus : ${section.status}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.textAlign = 'left';
  //           this.tooltipCtx.font = 'bold  15px Arial';

  //         } else {
  //           this.hideTooltip = false;
  //         }
  //       }
  //     } else {
  //       const odd: any = [];
  //       const even: any = [];
  //       this.setArrays(section, even, odd);
  //       const {xMin, xMax, yMin, yMax} = this.getMinMax(even, odd);
  //       if (section.is_common) {
  //         // tslint:disable-next-line:no-shadowed-variable
  //         const odd: any = [];
  //         // tslint:disable-next-line:no-shadowed-variable
  //         const even: any = [];
  //         this.setArrays(section, even, odd);
  //         // tslint:disable-next-line:no-shadowed-variable
  //         const {xMin, xMax, yMin, yMax} = this.getMinMax(even, odd);

  //         if (this.isPointInsidePoly(x, y, xMin, xMax, yMin, yMax)) {
  //           this.hideTooltip = true;
  //           let lineheight = 15;
  //           this.canvas.nativeElement.style.cursor = 'pointer';
  //           this.tooltip.nativeElement.style.left = (section.sides[0] + 15) + 'px';
  //           this.tooltip.nativeElement.style.top = (section.sides[1] + 15) + 'px';

  //           this.tooltipCtx.clearRect(0, 0, section.sides[0] + 15, section.sides[1] + 15);

  //           this.tooltipCtx.fillText(`\nWorkstation Name : ${section.name}`, 0, lineheight);
  //           lineheight = lineheight + 25;

  //           this.tooltipCtx.fillText(`\nOccupied By : NA`, 0, lineheight);
  //           lineheight = lineheight + 25;

  //           this.tooltipCtx.fillText(`\nBooking Dates : NA`, 0, lineheight);
  //           lineheight = lineheight + 25;

  //           this.tooltipCtx.textAlign = 'left';
  //           this.tooltipCtx.font = 'bold  15px Arial';
  //         }
  //       } else if (section.selected) {
  //         // tslint:disable-next-line:no-shadowed-variable
  //         const odd: any = [];
  //         // tslint:disable-next-line:no-shadowed-variable
  //         const even: any = [];
  //         this.setArrays(section, even, odd);
  //         // tslint:disable-next-line:no-shadowed-variable
  //         const {xMin, xMax, yMin, yMax} = this.getMinMax(even, odd);

  //         if (this.isPointInsidePoly(x, y, xMin, xMax, yMin, yMax)) {
  //           this.hideTooltip = true;
  //           let lineheight = 15;
  //           this.canvas.nativeElement.style.cursor = 'pointer';
  //           this.tooltip.nativeElement.style.left = (section.sides[0] + 15) + 'px';
  //           this.tooltip.nativeElement.style.top = (section.sides[1] + 15) + 'px';

  //           this.tooltipCtx.clearRect(0, 0, section.sides[0] + 15, section.sides[1] + 15);
  //           this.tooltipCtx.fillText(`\nWorkstation Name : ${section.name}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nOccupied By : ${section.emp_name}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nStart Date & Time : ${section.start_date_time}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nEnd Date & Time : ${section.end_date_time}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nStatus : ${section.status}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.textAlign = 'left';
  //           this.tooltipCtx.font = 'bold  15px Arial';

  //         }
  //       }
  //     }
  //   }
  // }

  cancelBooking(id: any) {
      this.communicationService.cancelSeatBooking(id.booking_id).subscribe(res => {
        this.toastr.success(this.translate.instant('toasterSuccessMessage.bookingCancelled'), '');
        this.getCoords();
        this.modalReference.close();
      }, error => {
        this.toastr.error('Unable to cancel booking', '');
        console.log('Getting error while cancel booking');
      });
  }

  deselectCurrentAndReinitializeCanvas(element: Coordinate, event) {
    this.coordinates.map(section => {
      if (element.name === section.name && !section.selected && !section.is_common) {
        this.floorPlanCtx.fillStyle = 'rgba(255,255,255,1)';
        this.floorPlanCtx.fillRect(section.x, section.y, section.w, section.h);
        this.floorPlanCtx.strokeStyle = '#FFFFFF';
        this.floorPlanCtx.strokeRect(section.x, section.y, section.w, section.h);
      }
    });
  }

  onSubmitBook(confirmed: any, content) {
    this.isDisabled = true;
    let is_self = true;
    if (confirmed) {
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false
      };
      this.modalReference = this.modalService.open(content, ngbModalOptions);
      let userRequestObj = {};
      if (this.user) {
        userRequestObj = {
          username: this.user.username,
          first_name: this.user.firstName,
          last_name: this.user.lastName,
          role: '5',
          email: this.user.email,
          sub_department_name: this.user.department,
        };
        is_self = false;
      }
      const startDateVal =
        formatDate(this.convertDate(this.detailsBookSeatForm.value.startDate), 'yyyy-MM-dd', this.locale);
      const endDateVal = formatDate(this.convertDate(this.detailsBookSeatForm.value.endDate), 'yyyy-MM-dd', this.locale);
      if (startDateVal <= endDateVal) {
        const bookSeatObj = {
          // start_date: startDateVal,
          // end_date: endDateVal,
          start_date_time: this.isBookByTime ? startDateVal + this.bookingTimeList[this.detailsBookSeatForm.get('startTime').value].timeFormat :
            startDateVal + 'T00:00:00',
          end_date_time: this.isBookByTime ? endDateVal + this.bookingTimeList[this.detailsBookSeatForm.get('endTime').value].timeFormat :
            endDateVal + 'T23:59:59',
          emp_id: this.detailsBookSeatForm.value.employee_id,
          seat_id: this.detailsBookSeatForm.value.seat_id,
          status: 0,
          email_notification: this.emailNotification,
          comments: this.detailsBookSeatForm.value.comments,
          floor_id: this.detailsBookSeatForm.value.floor,
          wing_id: this.detailsBookSeatForm.value.wing,
          futureReference: this.isFuturePreference,
          user_request: userRequestObj,
          is_self,
        };
        this.communicationService.bookSeat(bookSeatObj).subscribe(res => {
            this.modalReference.close();
            this.toastr.success(this.translate.instant('toasterSuccessMessage.workStationBooked'));
            this.isDisabled = false;
            this.router.navigate(['/view-bookings']);
          },
          error => {
            this.modalReference.close();
            console.log('error', error);
            this.toastr.error(error.error.message, 'Failed to book Workstation');
            this.isDisabled = false;
          });
      } else {
        this.modalReference.close();
        this.toastr.error('Invalid Booking Date Selection');
        this.isDisabled = false;
      }
    }
  }

  public openConfirmationDialog(content) {
    const startDate = formatDate(this.convertDate(this.detailsBookSeatForm.value.startDate),
      'yyyy-MM-dd', this.locale);
    const endDate = formatDate(this.convertDate(this.detailsBookSeatForm.value.endDate),
      'yyyy-MM-dd', this.locale);

    if (this.detailsBookSeatForm.value.seat_id) {
      const sDate = this.isBookByTime ? startDate + this.bookingTimeList[this.detailsBookSeatForm.get('startTime').value].timeFormat :
        startDate + 'T00:00:00';

      const eDate = this.isBookByTime ? endDate + this.bookingTimeList[this.detailsBookSeatForm.get('endTime').value].timeFormat :
        endDate + 'T23:59:59';
      this.confirmationDialogService.confirm('Book Workstation Confirmation',
        'Are you sure you want to book Workstation ' + this.detailsBookSeatForm.value.seat_id + ' from ' + sDate + ' to ' + eDate + '?')
        .then((confirmed) => this.onSubmitBook(confirmed, content));
    } else {
      this.toastr.error('Workstation is not selected');
    }
  }

  public openAttributesModal() {
    if (this.detailsBookSeatForm.value.seat_id) {
      const workstation_ids = this.detailsBookSeatForm.value.seat_id.split(',');
      const attributesObject = {
        workstations: workstation_ids
      };

      this.communicationService.getSeatAttributes(attributesObject);
    } else {
      this.toastr.error('Workstation is not selected');

    }
  }

  isInputSelected() {
    if (this.f.wing.value === undefined
      || this.f.wing.value === '' || this.f.floor.value === undefined || this.f.floor.value === '') {
      return false;
    }
    return true;
  }

  convertDate(date) {
    const converdate = new Date(date.year, date.month - 1, date.day);
    return converdate;
  }

  private fitToCanvas(image) {
    this.floorPlanCtx.canvas.width = image.width;
    this.floorPlanCtx.canvas.height = image.height;
    this.floorPlanCtx.drawImage(image, 0, 0);
    this.highlightSelected();
    this.loadMapBorder();
  }

  highlightSelected() {
    this.coordinates.map((element) => {
      if (element.is_common) {
        if (element.shape === 0) {
          this.floorPlanCtx.fillStyle = 'rgba(232,232,232, 0.8)';
          this.floorPlanCtx.fillRect(element.x, element.y, element.w, element.h);
          this.floorPlanCtx.strokeStyle = '#000000';
          this.floorPlanCtx.strokeRect(element.x, element.y, element.w, element.h);
        } else {
          this.floorPlanCtx.fillStyle = 'rgba(232,232,232, 0.8)';
          this.floorPlanCtx.strokeStyle = '#000000';
          this.plotPolygon(element);
        }

      } else if (element.selected) {
        if (element.shape === 0) {
          this.floorPlanCtx.fillStyle = '#A8ADB3';
          this.floorPlanCtx.fillRect(element.x, element.y, element.w, element.h);
          this.floorPlanCtx.strokeStyle = '#d4d6d9';
          this.floorPlanCtx.strokeRect(element.x, element.y, element.w, element.h);
        } else {
          this.floorPlanCtx.fillStyle = '#A8ADB3';
          this.floorPlanCtx.strokeStyle = '#d4d6d9';
          this.plotPolygon(element);
        }
      }else if(element.status=='1'){
        if (element.shape === 0) {
          this.floorPlanCtx.fillStyle = '#e5e3e7';
          this.floorPlanCtx.fillRect(element.x, element.y, element.w, element.h);
          this.floorPlanCtx.strokeStyle = '#e5e3e7';
          this.floorPlanCtx.strokeRect(element.x, element.y, element.w, element.h);
        } else {
          this.floorPlanCtx.fillStyle = '#e5e3e7';
          this.floorPlanCtx.strokeStyle = '#e5e3e7';
          this.plotPolygon(element);
        }
      }
    });
    this.loading = false;
  }

  private setArrays(element: Coordinate, even: any, odd: any) {
    let i = 0;
    let j = 0;
    element.sides.forEach((entry, index) => {
      if (index % 2 === 0) {
        even[i] = entry;
        i++;
      } else {
        odd[j] = entry;
        j++;
      }
    });
  }

  private plotPolygon(element: Coordinate) {
    this.floorPlanCtx.beginPath();
    this.floorPlanCtx.moveTo(element.sides[0], element.sides[1]);
    this.floorPlanCtx.lineTo(element.sides[2], element.sides[3]);
    this.floorPlanCtx.lineTo(element.sides[4], element.sides[5]);
    this.floorPlanCtx.lineTo(element.sides[6], element.sides[7]);
    this.floorPlanCtx.lineTo(element.sides[8], element.sides[9]);
    this.floorPlanCtx.lineTo(element.sides[10], element.sides[11]);
    this.floorPlanCtx.closePath();
    this.floorPlanCtx.fill();
  }

  private plotPolygonBorder(element: Coordinate) {
    this.floorPlanCtx.beginPath();
    this.floorPlanCtx.moveTo(element.sides[0], element.sides[1]);
    this.floorPlanCtx.lineTo(element.sides[2], element.sides[3]);
    this.floorPlanCtx.lineTo(element.sides[4], element.sides[5]);
    this.floorPlanCtx.lineTo(element.sides[6], element.sides[7]);
    this.floorPlanCtx.lineTo(element.sides[8], element.sides[9]);
    this.floorPlanCtx.lineTo(element.sides[10], element.sides[11]);
    this.floorPlanCtx.closePath();
    this.floorPlanCtx.strokeStyle = element.type===1?'#641f00':'#006400';
    this.floorPlanCtx.stroke();
  }

  isPointInsidePoly(x, y, xmin, xmax, ymin, ymax) {
    if (x < xmin || x > xmax || y < ymin || y > ymax) {
      return false;
    } else {
      return true;
    }
  }

  private getMinMax(even: any, odd: any) {
    const xMin = even.reduce((a, b) => Math.min(a, b));
    const xMax = even.reduce((a, b) => Math.max(a, b));
    const yMin = odd.reduce((a, b) => Math.min(a, b));
    const yMax = odd.reduce((a, b) => Math.max(a, b));
    return {xMin, xMax, yMin, yMax};
  }

  getSearchResult = (val) => {
    let queryData = val.target.value;
    const inp = String.fromCharCode(val.keyCode);
    if (/[a-zA-Z0-9-_ @.]/.test(inp)) {
      queryData = val.target.value + val.key;
    }
    if (queryData.length >= 4 && queryData.length <= 15) {
      this.communicationService.getSearchResult(queryData).subscribe(res => {
        if (res.employeeList.users.length === 0 && queryData.length <= 4) {
          this.toastr.error('Search User not found.');
        }
        this.searchResult = res.employeeList.users;
      }, error => {
        this.searchResult.length = 0;
        if (queryData.length <= 4) {
          this.toastr.error('Search User not found.');
        }
      });
    } else {
      this.searchResult.length = 0;
    }
  }

  getSearchResultSeats = (val) => {
    const queryData = val.target.value;
    if (queryData.length >= 4) {
      this.communicationService.getSeatsFilter(this.limit, this.startIndex, queryData).subscribe(res => {
        this.searchResultSeats = res;
      }, error => {
        this.searchResultSeats.length = 0;
        console.log('Getting error while searching result for seats');
      });
    } else {
      this.searchResultSeats.length = 0;
    }
  }

  /*decrypt(transitmessage, pass) {

    var hexResult = this.base64ToHex(transitmessage)

    var salt = CryptoJS.enc.Hex.parse(hexResult.substr(0, 64));
    var iv = CryptoJS.enc.Hex.parse(hexResult.substr(64, 32));
    var encrypted = this.hexToBase64(hexResult.substring(96));

    var key = CryptoJS.PBKDF2(pass, salt, {
      keySize: this.keySize / 32,
      iterations: this.iterations
    });

    var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC

    })

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  hexToBase64(str) {
    return btoa(String.fromCharCode.apply(null,
      str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" "))
    );
  }

  base64ToHex(str) {
    for (var i = 0, bin = atob(str.replace(/[ \r\n]+$/, "")), hex = []; i < bin.length; ++i) {
      var tmp = bin.charCodeAt(i).toString(16);
      if (tmp.length === 1) tmp = "0" + tmp;
      hex[hex.length] = tmp;
    }
    return hex.join("");
  }*/
  onEndDateChange() {
    this.onSubmit();
  }
}

function enableRipple(arg0: boolean) {
  throw new Error('Function not implemented.');
}
