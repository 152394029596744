import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SuperUserFilterRequest } from '../model/superUserFilter';

@Injectable({
  providedIn: 'root'
})
export class SuperUserDashboardService {

  constructor(private http: HttpClient) { }

  public findFilterData(request: SuperUserFilterRequest) {
    return this.http.post(`${environment.apiUrl}v1/superUserDashboard/fetchBookingDataBasedOnDate`, request);
  }

}
