import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private accountService: AccountService,
              private router: Router) {

  }

  ngOnInit(): void {
    this.accountService.logout();
    this.router.navigate(['login']);
      // .subscribe((response)=>{
      //   this.router.navigate(['login']);
      // });
  }
}
