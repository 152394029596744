import { formatDate } from '@angular/common';
import { Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TimeByHour } from 'src/app/constants/timeList';
import { Coordinate } from 'src/app/model/coordinate';
import { FloorPath } from 'src/app/model/floorPath';
import { SeatsResponse } from 'src/app/model/seatsresponse';
import { Statistics } from 'src/app/model/statistics';
import { CommunicationService } from 'src/app/services/communication.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-booking-by-date-au',
  templateUrl: './booking-by-date-au.component.html',
  styleUrls: ['./booking-by-date-au.component.scss']
})
export class BookingByDateAuComponent implements OnInit {
  modalReference: NgbModalRef;
  showFloorPlanFlag = false;

  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('tooltip', { static: true })
  tooltip: ElementRef<HTMLCanvasElement>;

  hideTooltip = false;
  selectedSeat: any;
  imageCanvas: HTMLImageElement;

  floorPlanCtx: CanvasRenderingContext2D;
  tooltipCtx: CanvasRenderingContext2D;

  floorPlanImage: HTMLImageElement;
  detailsBookByDateAUForm: FormGroup;
  cities = [];
  floors = [];
  buildings = [];
  countries = [];
  wings = [];
  thumbnail: any;
  coordinates: Coordinate[] = [];
  seatsResponse: SeatsResponse;
  statistics: Statistics;
  floorPath: FloorPath;
  x_value: any;
  y_value: any;
  floor_id: any;
  wing_id: any;
  todayDate;
  employeeNo: any;
  loading = false;
  is_selected = false;
  is_map_shown = false;
  isFuturePreference = false;
  selectedSeats: any;
  bookingTimeList: any;
  hideElement = true;
  submitted = false;
  isDisabled = false;
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService) {
  }

  ngOnInit(): void {
    // this.getFloors();
    this.getCountries();
    this.detailsBookByDateAUForm = this.formBuilder.group({
      city: ['', Validators.required],
      country: ['', Validators.required],
      floor: ['', Validators.required],
      wing: ['', Validators.required],
      building: ['', Validators.required],
      startDate: ['', Validators.required],
      startTime: ['', Validators.required],
      booked_workstations: ['', Validators.required],
      vacant_workstations: ['', Validators.required],
      zones: ['', ''],


    });

    const currentDate = new Date();
    const objCurrentDate = { year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate() };
    this.todayDate = objCurrentDate;
    this.detailsBookByDateAUForm.controls.startDate.setValue(this.todayDate);
    this.bookingTimeList = TimeByHour;
    this.f.startTime.setValue(0);
  }

  get f() {
    return this.detailsBookByDateAUForm.controls;
  }

  onDateChange(event) {
    this.detailsBookByDateAUForm.controls.endDate.setValue(this.detailsBookByDateAUForm.value.startDate);
  }

  onChange(event) {
    if (event.target.checked) {
      this.isFuturePreference = true;
    } else {
      this.isFuturePreference = false;
    }

  }
  onFloorChange(value) {
    this.getWings(value.target.value);
  }

  onCityChange(value) {
    this.getBuildings(value.target.value);
  }

  onCountryChange(value) {
    this.getCities(value.target.value);
  }

  onBuildingChange(value) {
    this.f.floor.setValue('');
    this.getFloors(value.target.value);
  }

  getCountries() {
    this.communicationService.getCountries().subscribe(res => {
      this.countries = res;
      if (this.countries.length > 0) {
        this.f.country.setValue(res[0].country_id);
        this.getCities(res[0].country_id);
      } else {
        this.countries = [];
        this.f.country.setValue('');
        this.cities = [];
        this.f.city.setValue('');
        this.buildings = [];
        this.f.building.setValue('');
        this.floors = [];
        this.f.floor.setValue('');
        this.wings = [];
        this.f.wing.setValue('');
        this.modalReference.close();
      }
    }, error => {
      console.error(error);
      this.modalReference.close();
    });
  }

  getCities(value) {
    this.communicationService.getCitiesByCountry(value).subscribe(res => {
      this.cities = res;
      if (this.cities.length > 0) {
        for (const city of this.cities) {
          if (city.city_name == 'Sydney') {
            this.f.city.setValue(city.city_id);
            this.getBuildings(city.city_id);
            return 0 ;
          }
        }
        this.f.city.setValue(res[0].city_id);
        this.getBuildings(res[0].city_id);
      } else {
          this.cities = [];
          this.f.city.setValue('');
          this.buildings = [];
          this.f.building.setValue('');
          this.floors = [];
          this.f.floor.setValue('');
          this.wings = [];
          this.f.wing.setValue('');
          this.modalReference.close();
      }
    }, error => {
      console.error(error);
      this.modalReference.close();
    });
  }

  getBuildings(value) {
    this.communicationService.getBuildings(value).subscribe(res => {
      this.buildings = [];
      for (const building of res) {
        if (building.status === '0') {
          this.buildings.push(building);
        }
      }
      if (this.buildings.length > 0) {
        this.f.building.setValue(res[0].building_id);
        this.getFloors(res[0].building_id);
      } else {
        this.f.building.setValue('');
        this.floors = [];
        this.f.floor.setValue('');
        this.wings = [];
        this.f.wing.setValue('');
        this.modalReference.close();
      }
    }, error => {
      console.error(error);
      this.modalReference.close();
    });
  }

  // async getCoords(event) {
  //   this.detailsBookByDateAUForm.controls.zones.setValue('');
  //   var wing_id = this.f.wing.value;
  //   var floor_id = this.f.floor.value;
  //   const start_date = formatDate(this.convertDate(this.detailsBookByDateAUForm.value.startDate),
  //     'yyyy-MM-dd', this.locale);
  //   this.communicationService.getSeatsByDate(floor_id, wing_id, start_date).subscribe(res => {
  //     this.coordinates = res.seats;
  //     this.statistics = res.details;
  //     if (this.statistics.booked_workstations.length > 0) {
  //       this.detailsBookByDateAUForm.controls.booked_workstations.setValue(this.statistics.booked_workstations.join("\n"));
  //     } else {
  //       this.detailsBookByDateAUForm.controls.booked_workstations.setValue('');
  //     }
  //     // if (this.statistics.vacant_workstations.length > 0) {
  //     //    this.detailsBookByDateAUForm.controls.vacant_workstations.setValue(this.statistics.vacant_workstations.join("\n"));
  //     // } else {
  //     //    this.detailsBookByDateAUForm.controls.vacant_workstations.setValue('');
  //     // }
  //     // if (this.statistics.zones != null) {
  //     //    this.detailsBookByDateAUForm.controls.zones.setValue(this.statistics.zones.join("\n"));
  //     // }
  //     this.showFloorPlan(event);
  //   }, error => {
  //     console.error(error);
  //   })
  // }

  ngAfterViewInit(): void {
    this.floorPlanCtx = this.canvas.nativeElement.getContext('2d');
    this.tooltipCtx = this.tooltip.nativeElement.getContext('2d');
  }

  getFloors(building_id: string) {
    this.communicationService.getFloorsByBuilding(building_id).subscribe(res => {
      this.floors = res;
      if (this.floors.length > 0) {
        this.f.floor.setValue(res[0].floor_id);
        this.getWings(res[0].floor_id);
      } else {
        this.f.floor.setValue('');
        this.wings = [];
        this.f.wing.setValue('');
        this.modalReference.close();
      }
    }, error => {
      console.error(error);
      this.modalReference.close();
    });
  }

  getWings(floor_id: string) {
    this.communicationService.getWings(floor_id).subscribe(res => {
      this.wings = res;
      /*this.modalReference.close();*/
      if (this.wings.length > 0) {
        this.f.wing.setValue(res[0].wing_id);
      } else {
        this.f.wing.setValue('');
      }
    }, error => {
      console.error(error);
      this.modalReference.close();
    });
  }

  // onSubmit(event) {
  //   this.is_map_shown = true
  //   this.getCoords(event);
  // }

  // getFloorWings(floor_id: String, wing_id: String, date: String) {
  //   const start_date = formatDate(this.convertDate(this.detailsBookByDateAUForm.value.startDate),
  //     'yyyy-MM-dd', this.locale);
  //   this.communicationService.getFloorWings(floor_id, wing_id).subscribe(res => {
  //     this.floorPath = res;
  //     const imagePath = '../assets/img/map/';
  //     const image = new Image();
  //     // image.src = imagePath + this.floorPath.floor_path;
  //     this.floorPlanImage = image;
  //     image.onload = (() => this.fitToCanvas(image));
  //   }, error => {
  //     console.error(error);
  //   })
  // }

  // showFloorPlan(event) {
  //   this.is_selected = false;
  //   this.loading = true;
  //   var wing_id = this.f.wing.value;
  //   var floor_id = this.f.floor.value;
  //   var start_date = this.f.startDate.value;
  //   this.getFloorWings(floor_id, wing_id, start_date);
  // }

  onSubmit() {
    this.is_map_shown = true;
    this.getCoords();
  }

  convertDateTime(end_date_time: string) {
    const date = end_date_time.split(' ');
    const newdate = date[0].split('-').reverse().join('-');
    end_date_time = newdate + 'T' + date[1];
    return end_date_time;
  }

  async getCoords() {
    const wing_id = this.f.wing.value;
    const floor_id = this.f.floor.value;
    const startDateVal = formatDate(this.convertDate(this.detailsBookByDateAUForm.value.startDate),
      'yyyy-MM-dd', this.locale);
    const start_date = this.detailsBookByDateAUForm.value.startDate !== '0' ?
      startDateVal + this.bookingTimeList[this.detailsBookByDateAUForm.get('startTime').value].timeFormat : startDateVal + 'T00:00:00';
    this.communicationService.getSeatsByDate(floor_id, wing_id, start_date).subscribe(res => {
      this.coordinates = res.seats;
      this.statistics = res.details;
      this.showFloorPlan();
      let n = 0 ;
      const booked_workspaces = [];
      const vacant_workspaces = [];
      for ( const coordinate of this.coordinates ) {
        if (this.coordinates[n].end_date_time) {
          const endDateTime = this.convertDateTime(this.coordinates[n].end_date_time) ;
          const endDate = new Date(endDateTime).getTime();
          const startDate = new  Date(start_date).getTime();
          startDate < endDate ? booked_workspaces.push(this.coordinates[n].name)
            : vacant_workspaces.push(this.coordinates[n].name);
        } else {
          vacant_workspaces.push(this.coordinates[n].name);
        }
        n += 1;
      }
      booked_workspaces.length > 0 ? this.detailsBookByDateAUForm.controls.booked_workstations.setValue(booked_workspaces.join('\n')) :
        this.detailsBookByDateAUForm.controls.booked_workstations.setValue('');
      vacant_workspaces.length > 0 ? this.detailsBookByDateAUForm.controls.vacant_workstations.setValue(vacant_workspaces.join('\n')) :
        this.detailsBookByDateAUForm.controls.vacant_workstations.setValue('');
      this.statistics.zones.length > 0 ? this.detailsBookByDateAUForm.controls.zones.setValue(this.statistics.zones.join('\n')) :
        this.detailsBookByDateAUForm.controls.zones.setValue('');
    }, error => {
      console.error(error);
    });
  }

  showFloorPlan() {
    this.is_selected = false;
    this.loading = true;
    const wing_id = this.f.wing.value;
    const floor_id = this.f.floor.value;
    const start_date = this.f.startDate.value;
    this.getFloorWings(floor_id, wing_id, start_date);
  }

   getFloorWings(floor_id: string, wing_id: string, date: string) {
    this.communicationService.getFloorWings(floor_id, wing_id).subscribe(res => {
      const objectURL = 'data:image/png;base64,' + res.image_path;
      const image = new Image();
      image.src = objectURL;
      this.floorPlanImage = image;
      image.onload = (() => this.fitToCanvas(image));
    }, error => {
      console.error(error);
    });
  }

  onMouseMove(event) {
    const rB = this.floorPlanCtx.canvas.getBoundingClientRect();
    const x = event.pageX - (rB.left + window.scrollX);
    const y = event.pageY - (rB.top + window.scrollY);
    // Put your mousemove stuff here
    let prevx: number;
    let prevy: number;
    for (let i = 0; i < this.coordinates.length; i++) {
      const section = this.coordinates[i];
      if (section.type === 0) {
        if (y > section.y && y < section.y + section.h
          && x > section.x && x < section.x + section.w) {
          let lineheight = 15;
          this.canvas.nativeElement.style.cursor = 'pointer';
          this.tooltip.nativeElement.style.left = (section.x) + 'px';
          this.tooltip.nativeElement.style.top = (section.y) + 'px';
          this.tooltipCtx.clearRect(0, 0, this.tooltip.nativeElement.width, this.tooltip.nativeElement.height);

          if (section.is_common) {
            this.hideTooltip = true;

            this.tooltipCtx.fillText(`\nWorkstation Name : ${section.name}`, 0, lineheight);
            lineheight = lineheight + 25;

            this.tooltipCtx.fillText(`\nOccupied By : NA`, 0, lineheight);
            lineheight = lineheight + 25;

            this.tooltipCtx.fillText(`\nBooking Dates : NA`, 0, lineheight);
            lineheight = lineheight + 25;

            this.tooltipCtx.textAlign = 'left';
            this.tooltipCtx.font = 'bold  15px Arial';

          } else if (section.selected) {
            this.hideTooltip = true;
            this.tooltipCtx.fillText(`\nWorkstation Name : ${section.name}`, 0, lineheight);
            lineheight = lineheight + 25;

            this.tooltipCtx.fillText(`\nOccupied By : ${section.emp_name}`, 0, lineheight);
            lineheight = lineheight + 25;

            // this.tooltipCtx.fillText(`\nBooking Dates : ${section.date_range}`, 0, lineheight);
            // lineheight = lineheight + 25

            this.tooltipCtx.fillText(`\Status : ${section.status}`, 0, lineheight);
            lineheight = lineheight + 25;

            this.tooltipCtx.textAlign = 'left';
            this.tooltipCtx.font = 'bold  15px Arial';

          } else {
            this.hideTooltip = false;
          }
        }
      } else {
        const odd: any = [];
        const even: any = [];
        this.setArrays(section, even, odd);
        const { xMin, xMax, yMin, yMax } = this.getMinMax(even, odd);
        if (section.is_common) {
          const odd: any = [];
          const even: any = [];
          this.setArrays(section, even, odd);
          const { xMin, xMax, yMin, yMax } = this.getMinMax(even, odd);

          if (this.isPointInsidePoly(x, y, xMin, xMax, yMin, yMax)) {
            this.hideTooltip = true;
            let lineheight = 15;
            this.canvas.nativeElement.style.cursor = 'pointer';
            this.tooltip.nativeElement.style.left = (section.sides[0] + 15) + 'px';
            this.tooltip.nativeElement.style.top = (section.sides[1] + 15) + 'px';

            this.tooltipCtx.clearRect(0, 0, section.sides[0] + 15, section.sides[1] + 15);

            this.tooltipCtx.fillText(`\nWorkstation Name : ${section.name}`, 0, lineheight);
            lineheight = lineheight + 25;

            this.tooltipCtx.fillText(`\nOccupied By : NA`, 0, lineheight);
            lineheight = lineheight + 25;

            this.tooltipCtx.fillText(`\nBooking Dates : NA`, 0, lineheight);
            lineheight = lineheight + 25;

            this.tooltipCtx.textAlign = 'left';
            this.tooltipCtx.font = 'bold  15px Arial';
          }
        } else if (section.selected) {
          const odd: any = [];
          const even: any = [];
          this.setArrays(section, even, odd);
          const { xMin, xMax, yMin, yMax } = this.getMinMax(even, odd);

          if (this.isPointInsidePoly(x, y, xMin, xMax, yMin, yMax)) {
            this.hideTooltip = true;
            let lineheight = 15;
            this.canvas.nativeElement.style.cursor = 'pointer';
            this.tooltip.nativeElement.style.left = (section.sides[0] + 15) + 'px';
            this.tooltip.nativeElement.style.top = (section.sides[1] + 15) + 'px';

            this.tooltipCtx.clearRect(0, 0, section.sides[0] + 15, section.sides[1] + 15);
            this.tooltipCtx.fillText(`\nWorkstation Name : ${section.name}`, 0, lineheight);
            lineheight = lineheight + 25;

            this.tooltipCtx.fillText(`\nOccupied By : ${section.emp_name}`, 0, lineheight);
            lineheight = lineheight + 25;

            // this.tooltipCtx.fillText(`\nBooking Dates : ${section.date_range}`, 0, lineheight);
            // lineheight = lineheight + 25

            this.tooltipCtx.fillText(`\Status : ${section.status}`, 0, lineheight);
            lineheight = lineheight + 25;

            this.tooltipCtx.textAlign = 'left';
            this.tooltipCtx.font = 'bold  15px Arial';

          }
        }
      }
    }
  }

  isInputSelected() {
    if (this.f.wing.value === undefined
      || this.f.wing.value === '' || this.f.floor.value === undefined || this.f.floor.value === '') {
      return false;
    }
    return true;
  }

  convertDate(date) {
    const converdate = new Date(date.year, date.month - 1, date.day);
    return converdate;
  }

  private fitToCanvas(image) {
    this.floorPlanCtx.canvas.width = image.width;
    this.floorPlanCtx.canvas.height = image.height;
    this.floorPlanCtx.drawImage(image, 0, 0);
    this.loading = false;
    // this.highlightSelected();
  }

  highlightSelected() {
    this.coordinates.map((element) => {
      if (element.is_common) {
        if (element.type === 0) {
          this.floorPlanCtx.fillStyle = 'rgba(232,232,232, 0.8)';
          this.floorPlanCtx.fillRect(element.x, element.y, element.w, element.h);
          this.floorPlanCtx.strokeStyle = '#000000';
          this.floorPlanCtx.strokeRect(element.x, element.y, element.w, element.h);
        } else {
          this.floorPlanCtx.fillStyle = 'rgba(232,232,232, 0.8)';
          this.floorPlanCtx.strokeStyle = '#000000';
          this.plotPolygon(element);
        }

      } else if (element.selected) {
        if (element.type === 0) {
          this.floorPlanCtx.fillStyle = '#f50707';
          this.floorPlanCtx.fillRect(element.x, element.y, element.w, element.h);
          this.floorPlanCtx.strokeStyle = '#000000';
          this.floorPlanCtx.strokeRect(element.x, element.y, element.w, element.h);
        } else {
          this.floorPlanCtx.fillStyle = '#f50707';
          this.floorPlanCtx.strokeStyle = '#000000';
          this.plotPolygon(element);
        }
      }
    });
    this.loading = false;
  }

  private setArrays(element: Coordinate, even: any, odd: any) {
    let i = 0;
    let j = 0;
    element.sides.forEach((entry, index) => {
      if (index % 2 === 0) {
        even[i] = entry;
        i++;
      } else {
        odd[j] = entry;
        j++;
      }
    });
  }


  isPointInsidePoly(x, y, xmin, xmax, ymin, ymax) {
    if (x < xmin || x > xmax || y < ymin || y > ymax) {
      return false;
    } else {
      return true;
    }
  }

  private plotPolygon(element: Coordinate) {
    this.floorPlanCtx.beginPath();
    this.floorPlanCtx.moveTo(element.sides[0], element.sides[1]);
    this.floorPlanCtx.lineTo(element.sides[2], element.sides[3]);
    this.floorPlanCtx.lineTo(element.sides[4], element.sides[5]);
    this.floorPlanCtx.lineTo(element.sides[6], element.sides[7]);
    this.floorPlanCtx.lineTo(element.sides[8], element.sides[9]);
    this.floorPlanCtx.lineTo(element.sides[10], element.sides[11]);
    this.floorPlanCtx.closePath();
    this.floorPlanCtx.fill();
  }


  private getMinMax(even: any, odd: any) {
    const xMin = even.reduce((a, b) => Math.min(a, b));
    const xMax = even.reduce((a, b) => Math.max(a, b));
    const yMin = odd.reduce((a, b) => Math.min(a, b));
    const yMax = odd.reduce((a, b) => Math.max(a, b));
    return { xMin, xMax, yMin, yMax };
  }
  onStartTimeChange(event) {

  }
  onClick(event, bookingsModel) {

  }
}

