import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunicationService } from 'src/app/services/communication.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-edit-city',
  templateUrl: './add-edit-city.component.html',
  styleUrls: ['./add-edit-city.component.scss']
})
export class AddEditCityComponent implements OnInit {
  countries = [];
  cityId: any;
  detailsAddEditCityForm: FormGroup;
  cityDetails: any;
  cityName: any;
  isDisabled: boolean;
  submitted:boolean=false;

  constructor(
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    private _Activatedroute: ActivatedRoute,
    private router: Router,
    public  translate:  TranslateService,
  ) { }

  ngOnInit(): void {
    this.cityId = this._Activatedroute.snapshot.paramMap.get('cityid');
    this.getCountries();
    this.detailsAddEditCityForm = this.formBuilder.group(
      {
        country: ['', Validators.required],
        city: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9\-\/\(\)\,\. ]*')]),

      }
    );
    if (this.cityId != null) {
      this.getCityDetails(this.cityId);
    }
  }

  get city() {
    return this.detailsAddEditCityForm.get('city');
  }

  getCityDetails(cityId) {
    this.communicationService.getCityById(cityId).subscribe(res => {
      this.cityDetails = res;
      this.cityName = res.city_name;
      this.f.country.setValue(res.country_id);
  }, error => {
    console.log(error);
  });
  }

  getCountries() {
    this.communicationService.getCountries().subscribe(res => {
      this.countries = res;
      if (this.cityId == null) {
        this.f.country.setValue(res[0].country_id);
      }
    }, error => {
      console.error(error);
    });
  }

  onSubmit(event:any) {
    this.isDisabled = true;
    if (this.detailsAddEditCityForm.value.city) {
      const request = {
        country_id: this.detailsAddEditCityForm.value.country,
        city_name: this.detailsAddEditCityForm.value.city === '' ? this.cityName : this.detailsAddEditCityForm.value.city,
        status: '0',
        city_id: this.cityId != null ? this.cityId : null,
      };
      this.communicationService.addCity(request).subscribe(res => {
          this.toastr.success(this.translate.instant('toasterSuccessMessage.cityAdded'));
          this.router.navigate(['/view-city']);
      }, error => {
        this.isDisabled = false;
        this.toastr.error(error.error.message);
      });
    } else {
      this.isDisabled = false;
      this.toastr.error(this.translate.instant('addeditcity.cityNameErrorMsg'));
    }
  }

  get f() {
    return this.detailsAddEditCityForm.controls;
  }

  onCancel(event:any) {
    this.isDisabled = true;
    this.router.navigate(['/view-city']);
  }

}
