import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommunicationService } from 'src/app/services/communication.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {entityFeatures} from "../../helper/entityFeatures";


@Component({
  selector: 'app-update-user-role',
  templateUrl: './update-user-role.component.html',
  styleUrls: ['./update-user-role.component.scss']
})
export class UpdateUserRoleComponent implements OnInit {
  private responderList: any[];
  private countries: any[];
  private cities: any[];
  private enableSubmitButton: boolean;
  private dept: any;
  private features: any;
  responder = false;
  isDisabled: boolean;
    constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private _Activatedroute: ActivatedRoute,
    public communicationService: CommunicationService,
     public  translate:  TranslateService,
    ) {
  }

  userDetails: any;
  userRole: any;
  email: any;
  firstname: any;
  lastname: any;
  department: any;
  username: any;
  detailsUpdateUserRoleForm: FormGroup;
  role = [];
 /* limit = 100;
  startIndex = 0;*/
  selectedValue = 1;
  selectedDepartment = null;
  currentUser: any;
  isFirstAidResponder: boolean;
  isFireWarden: boolean;
  departments = [];
  buildings: any;
  private floors: any;
  private floor_name: any;
  private floorId: any;


  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.dept = this.currentUser.department;
    this.detailsUpdateUserRoleForm = this.formBuilder.group(
      {
        firstname: [''],
        lastname: [''],
        email: [''],
        department: [''],
        username: [''],
        role: [''],
        country: [''],
        firstAidResponder: [''],
        fireWarden: [''],
        city: [''],
        floor: ['', Validators.required],
        building: ['', Validators.required],
      }
    );
    this.isFirstAidResponder = false;
    this.isFireWarden = false;
    this.getRoles();
    this.getCountries();
    this.getDepartments();
    this.enableResponder();
    this.disableCurrentUser();
  }

  getRoles() {
    this.communicationService.getRoles().subscribe(res => {
      this.role = res;
    }, error => {
      console.log(error);
    });
  }

  getDepartments() {
    this.communicationService.getDepartments().subscribe(res => {
      this.departments = res;
      this.getUserDetails();
    }, error => {
      console.error(error);
    });
}
  getUserDetails() {
    this.username = this._Activatedroute.snapshot.paramMap.get('username');
    if (this.username) {
      this.communicationService.getUserDetails(this.username).subscribe(res => {
        this.userDetails = res;
        this.isFirstAidResponder = this.userDetails.isFirstAidResponder == null ? false : this.userDetails.isFirstAidResponder;
        this.isFireWarden = this.userDetails.isFireWarden == null ? false : this.userDetails.isFireWarden;
        this.setUserDetails();
      }, error => {
        console.error('error', error);
      });
    }
  }

  setUserDetails() {
    this.firstname = this.userDetails.firstName;
    this.lastname = this.userDetails.lastName;
    this.email = this.userDetails.email;
    this.userRole = this.userDetails.role;
    this.department = this.userDetails.department;
   /* this.f.floor.setValue(this.userDetails.floor_id);*/
    this.f.building.setValue(this.userDetails.building_id);
    if (this.userRole === 'Super User' && this.currentUser.role === 'Admin') {
      const objRole = { role_id: '6', role_name: 'Super User'};
      this.role.push(objRole);
      this.detailsUpdateUserRoleForm.controls['role'].disable();
    }
    this.role.forEach(r => {
      if (r.role_name === this.userRole) {
        this.selectedValue = r.role_id;
      }
    });
    this.departments.forEach(depart => {
      if (depart.department_name === this.department) {
        this.selectedDepartment = depart.department_id;
        this.detailsUpdateUserRoleForm.controls.department.setValue(depart.department_id);
      }
    });
  }

  isRoleSuperAdmin() {
    if (this.currentUser.role === 'Super User') {
      return true;
    } else {
      return false;
    }
  }

  disableCurrentUser() {
    if (this.username === this.currentUser.id) {
      this.detailsUpdateUserRoleForm.controls['role'].disable();
      this.detailsUpdateUserRoleForm.controls['department'].disable();
    }
  }

  disableSubmitButton() {
    let existingValue = 1 ;
    let existingDepartmentValue = 1;
    if (this.enableSubmitButton) {
      return false;
    }
    this.role.forEach(r => {
      if (r.role_name === this.userRole) {
        existingValue = r.role_id;
      }
    });
    this.departments.forEach(depart => {
      if (depart.department_name === this.department) {
        existingDepartmentValue = depart.department_id;
      }
    });
      if (((existingValue === this.detailsUpdateUserRoleForm.value.role &&  existingDepartmentValue === this.detailsUpdateUserRoleForm.value.department)
        || this.username === this.currentUser.id)
     ) {
      return true;
    }  else  if (this.userRole === 'Super User' && this.currentUser.role === 'Admin') {
        return true;
      }
  }

  deselectFirstAidResponder(event) {
    this.enableSubmitButton = true;
    if (event.target.checked) {
      this.isFirstAidResponder = true;
    } else {
      this.isFirstAidResponder = false;
    }
  }

  deselectFireWarden(event) {
    this.enableSubmitButton = true;
    if (event.target.checked) {
      this.isFireWarden = true;
    } else {
      this.isFireWarden = false;
    }
  }

  onCityChange(value) {
    this.getBuildings(value.target.value);
  }

  onCountryChange(value) {
    this.getCitiesByCountry(value.target.value);
  }

  onBuildingChange(value) {
    this.getFloors(value.target.value);
  }

  onFloorChange(value) {
    this.floorId = this.detailsUpdateUserRoleForm.value.floor;
  }

  onCancel() {
    this.router.navigate(['/users']);
  }

  onSubmit() {
    this.isDisabled = true;
    this.responderList = [];
    if (this.isFirstAidResponder) {
      this.responderList.push('First Aid Responder');
    }
    if (this.isFireWarden) {
      this.responderList.push('Fire Warden');
    }
    if (this.isFireWarden === false && this.isFirstAidResponder === false) {
      this.f.floor.setValue('');
      this.f.building.setValue('');
    }
    let departmentName = '';
    let userRole = null;
    this.departments.forEach(depart => {
      if (depart.department_id === this.selectedDepartment) {
        departmentName = depart.department_name;
      }
    });
    if (this.detailsUpdateUserRoleForm.value.role) {
      userRole = this.detailsUpdateUserRoleForm.value.role
    } else {
      userRole = this.selectedValue;
    }
    const request = {
      username: this.username,
      email: this.email,
      first_name: this.firstname,
      last_name: this.lastname,
      responder: this.responderList,
      department_name: departmentName === '' ? this.userDetails.department : departmentName,
      role: userRole,
      floor_id: this.detailsUpdateUserRoleForm.value.floor,
      building_id: this.detailsUpdateUserRoleForm.value.building
    };
    this.communicationService.addUser(request).subscribe(() => {
      this.toastr.success(this.translate.instant('toasterSuccessMessage.roleUpdated'));
      this.isDisabled = false;

      this.router.navigate(['/users']);
    }, error => {
      this.isDisabled = false;
      this.toastr.error(error.error.message);
    });
  }

  get f() {
    return this.detailsUpdateUserRoleForm.controls;
  }

  getCountries() {
    this.communicationService.getCountries().subscribe(res => {
      this.countries = [];
      for (const country of res) {
        if (country.status === '0' && country.country_name === 'Australia') {
          this.countries.push(country);
        }
      }
      if (this.countries.length > 0) {
        this.f.country.setValue(this.countries[0].country_id);
        this.getCitiesByCountry(this.countries[0].country_id);
      } else {
        this.countries = [];
        this.f.country.setValue('');
        this.cities = [];
        this.f.city.setValue('');
        this.buildings = [];
        this.f.building.setValue('');
        this.floors = [];
        this.f.floor.setValue('');
      }
    }, error => {
      this.toastr.error(error.error.message);
    });
  }

  getCitiesByCountry(country_id: string) {
    this.communicationService.getCitiesByCountry(country_id).subscribe(res => {
      if (res.length > 0) {
        this.cities = [];
        for (const city of res) {
          if (city.status === '0') {
            this.cities.push(city);
          }
        }
        if (this.cities.length > 0) {
          this.f.city.setValue(this.cities[0].city_id);
          this.getBuildings(this.cities[0].city_id);
        } else {
          this.cities = [];
          this.f.city.setValue('');
          this.buildings = [];
          this.f.building.setValue('');
          this.floors = [];
          this.f.floor.setValue('');
        }
      } else {
        this.cities = [];
        this.f.city.setValue('');
        this.buildings = [];
        this.f.building.setValue('');
        this.floors = [];
        this.f.floor.setValue('');
      }
    }, error => {
      console.error(error);
    });
  }

  getBuildings(value) {
    this.communicationService.getBuildings(value).subscribe(res => {
      this.buildings = res;
      if ((this.isFirstAidResponder === true || this.isFireWarden === true) &&  this.userDetails.building_id != null) {
        this.getFloors(this.userDetails.building_id);
      } else {
        this.getFloors(res[0].building_id);
        this.f.building.setValue(res[0].building_id);
      }
    }, error => {
      console.error(error);
    });
  }

  getFloors(building_id: string) {
    this.communicationService.getFloorsByBuilding(building_id).subscribe(res => {
      this.floors = res;
      this.f.floor.setValue(res[0].floor_id);
      this.floor_name = res[0].floor_name;

    }, error => {
      console.error(error);
    });
  }

  enableResponder() {
    this.communicationService.getFeatureByDept(this.dept).subscribe(res => {
      this.features = res;
      if (this.features.length) {
        for (const feature of this.features) {
          if (feature.feature_name === entityFeatures.FLOORRESPONDER && feature.status === '0') {
            this.responder = true;
          }
        }
      }
    });
  }
}
