import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { CommunicationService } from 'src/app/services/communication.service';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-approv-reject-booking',
  templateUrl: './approv-reject-booking.component.html',
  styleUrls: ['./approv-reject-booking.component.scss']
})

@Injectable({ providedIn: 'root' })
export class ApprovRejectBookingComponent implements OnInit {
  heroes$;
  superlatives$ = new BehaviorSubject<{ [superlativeName: string]: string }>({});
  tableDataSource$ = new BehaviorSubject<any[]>([]);
  displayedColumns$ = new BehaviorSubject<string[]>([
    'booking_id',
    'emp_name',
    'seat_number',
    'start_date',
    'end_date',
    'status',
    'id'
  ]);
  currentPage$ = new BehaviorSubject<number>(1);
  pageSize$ = new BehaviorSubject<number>(10);
  dataOnPage$ = new BehaviorSubject<any[]>([]);
  searchFormControl = new FormControl();
  sortKey$ = new BehaviorSubject<string>('booking_id');
  sortDirection$ = new BehaviorSubject<string>('asc');
  totalUserCount = 0;
  limit = 100;
  startIndex = 0;
  status = [];
  constructor(
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    public  translate:  TranslateService) { }

  ngOnInit() {
    this.getAllBookings(this.limit, this.startIndex);
    this.searchFormControl.setValue('');
  }

  adjustSort(key: string) {
    if (this.sortKey$.value === key) {
      if (this.sortDirection$.value === 'asc') {
        this.sortDirection$.next('desc');
      } else {
        this.sortDirection$.next('asc');
      }
      return;
    }

    this.sortKey$.next(key);
    this.sortDirection$.next('asc');
  }


  approveBooking(id: any) {
    const approveRequest = {
      booking_id: id,
      status: 1,
      comments: ''
    };
    this.communicationService.updateBookingSeat(approveRequest).subscribe(res => {
      this.toastr.success(this.translate.instant('toasterSuccessMessage.bookingApproved'));
      this.getAllBookings(this.limit, this.startIndex);
    }, error => {
      this.toastr.error('Unable to approve workstation booking', '');
      console.log('Getting error while user approving workstation');
    });
  }

  rejectBooking(id: any) {
    const rejectRequest = {
      booking_id: id,
      status: 2,
      comments: ''
    };
    this.communicationService.updateBookingSeat(rejectRequest).subscribe(res => {
      this.toastr.success(this.translate.instant('toasterSuccessMessage.bookingRejected'));
      this.getAllBookings(this.limit, this.startIndex);
    }, error => {
      this.toastr.error('Unable to reject workstation booking', '');
      console.log('Getting error while user rejecting workstation');
    });
  }


  getAllBookings(limit: number, index: number) {

    const userData = [];
    this.communicationService.getTasks().subscribe(res => {
      for (const user of res) {
        if (user.status === 0) {
          userData.push(user);
        }
      }
      this.totalUserCount = userData.length;
      this.heroes$ = new BehaviorSubject(userData);
      combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
        .subscribe(([allSources, currentPage, pageSize]) => {
          const startingIndex = (currentPage - 1) * pageSize;
          const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
          this.dataOnPage$.next(onPage);
        });

      this.heroes$.pipe(take(1)).subscribe(heroData => {
        this.tableDataSource$.next(Object.values(heroData));
      });

      combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          let filteredHeroes: any[];

          if (!searchTerm) {
            filteredHeroes = heroesArray;
          } else {
            const filteredResults = heroesArray.filter(hero => {
              return Object.values(hero)
                .reduce((prev, curr) => {
                  return prev || curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
                }, false);
            });
            filteredHeroes = filteredResults;
          }

          const sortedHeroes = filteredHeroes.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });

          this.tableDataSource$.next(sortedHeroes);
        });
    }, error => {
      console.log(error);
    });
  }

}
