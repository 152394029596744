import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {CommunicationService} from 'src/app/services/communication.service';

@Component({
  selector: 'app-add-edit-feature',
  templateUrl: './add-edit-feature.component.html',
  styleUrls: ['./add-edit-feature.component.css']
})
export class AddEditFeatureComponent implements OnInit {
  departments = [];
  featureId: any;
  detailsAddEditFeatureForm: FormGroup;
  featureName: any;
  isDisabled: boolean;
  departmentId: string;

  constructor(private formBuilder: FormBuilder,
              private communicationService: CommunicationService,
              private toastr: ToastrService,
              private _Activatedroute: ActivatedRoute,
              private router: Router,
              public translate: TranslateService,) {
  }

  ngOnInit(): void {
    this.featureId = this._Activatedroute.snapshot.paramMap.get('featureid');
    this.departmentId = this._Activatedroute.snapshot.paramMap.get('departmentid');

    this.detailsAddEditFeatureForm = this.formBuilder.group({
      department: ['', Validators.required],
      feature: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9\-\/\(\)\,\. ]*')]),
    });
    this.getDepartments();
    if (this.featureId != null) {
      this.getFeatureDetails(this.featureId);
    }
  }

  getFeatureDetails(featureId) {
    this.communicationService.getFeatureById(featureId).subscribe(res => {
      this.detailsAddEditFeatureForm.controls.feature.setValue(res.feature_name);
    }, error => {
      console.log(error);
    });
  }

  async getDepartments() {
    this.departments = await this.communicationService.getDepartments().toPromise();
    if (this.departments.length > 0) {
      if (this.featureId == null) {
        this.detailsAddEditFeatureForm.controls.department.setValue(this.departments[0].department_id);
      } else {
        this.departments.forEach(dept => {
          if (dept.department_id === this.departmentId) {
            this.detailsAddEditFeatureForm.controls.department.setValue(dept.department_id);
          }
        })
      }
    }
  }

  onSubmit() {
    this.isDisabled = true;
    if (this.detailsAddEditFeatureForm.value.feature) {
      const request = {
        department_id: this.detailsAddEditFeatureForm.value.department,
        status: '0',
        feature_name: this.detailsAddEditFeatureForm.value.feature,
        feature_id: this.featureId != null ? this.featureId : null,

      };
      this.communicationService.addFeature(request).subscribe(res => {
        this.toastr.success(this.translate.instant('toasterSuccessMessage.featureAdded'));
        this.router.navigate(['/entity-feature']);
      }, error => {
        this.isDisabled = false;
        this.toastr.error(error.error.message);
      });
    } else {
      this.isDisabled = false;
      this.toastr.error(this.translate.instant('addeditfeature.featureNameErrorMsg'));
    }
  }

  onCancel() {
    this.isDisabled = true;
    this.router.navigate(['/entity-feature']);
  }

}
