import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CommunicationService} from 'src/app/services/communication.service';
import {ConfirmationDialogService} from 'src/app/pages/confirmation-dialog/confirmation-dialog.service';
import {Coordinate} from 'src/app/model/coordinate';
import {SeatsResponse} from 'src/app/model/seatsresponse';
import {Statistics} from 'src/app/model/statistics';
import {AfterViewInit, Component, ElementRef, ViewChild, LOCALE_ID, Inject, TemplateRef} from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbModalRef, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {formatDate} from '@angular/common';
import {Router} from '@angular/router';
import {FloorPath} from 'src/app/model/floorPath';
import { TimeByHour } from 'src/app/constants/timeList';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';



@Component({
  selector: 'app-booking-by-date',
  templateUrl: './booking-by-date.component.html',
  styleUrls: ['./booking-by-date.component.scss']
})
export class BookingByDateComponent implements AfterViewInit {
  modalReference: NgbModalRef;
  showFloorPlanFlag = false;

  @ViewChild('canvas', {static: true})
  canvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('tooltip', {static: true})
  tooltip: ElementRef<HTMLCanvasElement>;

  @ViewChild('mymodal')
  private defaultTabButtonsTpl: TemplateRef<any>;

  hideTooltip = false;
  selectedSeat: any;
  imageCanvas: HTMLImageElement;

  floorPlanCtx: CanvasRenderingContext2D;
  tooltipCtx: CanvasRenderingContext2D;

  floorPlanImage: HTMLImageElement;
  detailsBookByDateForm: FormGroup;
  cities = [];
  floors = [];
  buildings = [];
  countries = [];
  wings = [];
  thumbnail: any;
  coordinates: Coordinate[] = [];
  seatsResponse: SeatsResponse;
  statistics: Statistics;
  floorPath: FloorPath;
  x_value: any;
  y_value: any;
  floor_id: any;
  wing_id: any;
  todayDate;
  limit = 30;
  startIndex = 0;
  employeeNo: any;
  loading = false;
  is_selected = false;
  is_map_shown = false;
  isFuturePreference = false;
  selectedSeats: any;
  startTimeValue = 0;
  bookingTimeList: any;
  bookingList: any[];
  tableDataSource$ = new BehaviorSubject<any[]>([]);
  displayedColumns$ = new BehaviorSubject<string[]>([
    'emp_name',
    'start_date',
    'end_date',
  ]);
  totalUserCount = 0;
  searchFormControl = new FormControl();
  currentPage$ = new BehaviorSubject<number>(1);
  pageSize$ = new BehaviorSubject<number>(10);
  dataOnPage$ = new BehaviorSubject<any[]>([]);
  sortKey$ = new BehaviorSubject<string>('start_date_time');
  sortDirection$ = new BehaviorSubject<string>('asc');
  heroes$: BehaviorSubject<any[]>;
  submitted:boolean;
  isDisabled:boolean=false;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private confirmationDialogService: ConfirmationDialogService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private router: Router) {
  }

  // tslint:disable-next-line:use-lifecycle-interface use-life-cycle-interface
  ngOnInit(): void {
    // this.getFloors();
    this.getCountries();
    this.detailsBookByDateForm = this.formBuilder.group({
      city: ['', Validators.required],
      country: ['', Validators.required],
      floor: ['', Validators.required],
      wing: ['', Validators.required],
      building: ['', Validators.required],
      startDate: ['', Validators.required],
      startTime:['', Validators.required],
      booked_workstations: ['', Validators.required],
      vacant_workstations: ['', Validators.required],
      common_areas: ['', Validators.required],
      workstationName:[''],

    });

    const currentDate = new Date();
    const objCurrentDate = {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate()
    };
    this.todayDate = objCurrentDate;
    this.detailsBookByDateForm.controls.startDate.setValue(this.todayDate);
    this.bookingTimeList = TimeByHour;
    this.f.startTime.setValue(0);
  }

  get f() {
    return this.detailsBookByDateForm.controls;
  }

  onDateChange(event) {
    this.detailsBookByDateForm.controls.endDate.setValue(this.detailsBookByDateForm.value.startDate);
  }

  onFloorChange(value) {
    this.getWings(value.target.value);
  }

  onCityChange(value) {
    this.getBuildings(value.target.value);
  }

  onCountryChange(value) {
    this.getCities(value.target.value);
  }

  onBuildingChange(value) {
    this.f.floor.setValue('');
    this.getFloors(value.target.value);
  }
  onStartTimeChange(value) {
    this.startTimeValue = value.target.value;
  }

  getCountries() {
    this.communicationService.getCountries().subscribe(res => {
      for (const country of res) {
        if (country.status === '0') {
          this.countries.push(country);
        }
      }
      if(this.countries.length > 0){
        this.f.country.setValue(this.countries[0].country_id);
        this.getCities(this.countries[0].country_id);
      }else{
        this.countries = [];
        this.f.country.setValue('');
        this.cities = [];
        this.f.city.setValue('');
        this.buildings = [];
        this.f.building.setValue('');
        this.floors = [];
        this.f.floor.setValue('');
        this.wings = [];
        this.f.wing.setValue('');
        this.modalReference.close();
      }
    }, error => {
      console.error(error);
      this.modalReference.close();
    });
  }

  getCities(value) {
    this.communicationService.getCitiesByCountry(value).subscribe(res => {
      if (res.length > 0) {
        this.cities = [];
        for (const city of res) {
          if (city.status === '0') {
            this.cities.push(city);
          }
        }
        if(this.cities.length > 0){
        this.f.city.setValue(this.cities[0].city_id);
        this.getBuildings(this.cities[0].city_id);
        }else {
          this.cities = [];
          this.f.city.setValue('');
          this.buildings = [];
          this.f.building.setValue('');
          this.floors = [];
          this.f.floor.setValue('');
          this.wings = [];
          this.f.wing.setValue('');
          this.modalReference.close();
        }
      } else {
        this.cities = [];
        this.f.city.setValue('');
        this.buildings = [];
        this.f.building.setValue('');
        this.floors = [];
        this.f.floor.setValue('');
        this.wings = [];
        this.f.wing.setValue('');
        this.modalReference.close();
      }
    }, error => {
      console.error(error);
      this.modalReference.close();
    });
  }

  getBuildings(value) {
    this.communicationService.getBuildings(value).subscribe(res => {
      if (res.length > 0) {
        this.buildings = [];
        for (const building of res) {
          if (building.status === '0') {
            this.buildings.push(building);
          }
        }
        if (this.buildings.length > 0) {
          this.f.building.setValue(this.buildings[0].building_id);
          this.getFloors(this.buildings[0].building_id);
        } else {
          this.f.building.setValue('');
          this.floors = [];
          this.f.floor.setValue('');
          this.wings = [];
          this.f.wing.setValue('');
          this.modalReference.close();
        }
      } else {
        this.buildings = [];
        this.f.building.setValue('');
        this.floors = [];
        this.f.floor.setValue('');
        this.wings = [];
        this.f.wing.setValue('');
        this.modalReference.close();
      }
    }, error => {
      console.error(error);
      this.modalReference.close();
    });
  }

  async getCoords() {
    const wing_id = this.f.wing.value;
    const floor_id = this.f.floor.value;
    const startDateVal = formatDate(this.convertDate(this.detailsBookByDateForm.value.startDate),
      'yyyy-MM-dd', this.locale);
    // tslint:disable-next-line:max-line-length
    const start_date = this.detailsBookByDateForm.value.startDate !== '0' ? startDateVal + this.bookingTimeList[this.detailsBookByDateForm.get('startTime').value].timeFormat :
    startDateVal + 'T00:00:00';
    this.communicationService.getSeatsByDate(floor_id, wing_id, start_date).subscribe(res => {
      this.coordinates = res.seats;
      this.statistics = res.details;
      this.detailsBookByDateForm.controls.booked_workstations.setValue(this.statistics.booked_workstations);
      this.detailsBookByDateForm.controls.common_areas.setValue(this.statistics.common_areas);
      this.detailsBookByDateForm.controls.vacant_workstations.setValue(this.statistics.vacant_workstations.length);

      this.showFloorPlan();
    }, error => {
      console.error(error);
    });
  }

  ngAfterViewInit(): void {
    this.floorPlanCtx = this.canvas.nativeElement.getContext('2d');
    this.tooltipCtx = this.tooltip.nativeElement.getContext('2d');

    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    this.modalReference = this.modalService.open(this.defaultTabButtonsTpl, ngbModalOptions);
  }

  getFloors(building_id: string) {
    this.communicationService.getFloorsByBuilding(building_id).subscribe(res => {
      this.floors = [];
      for (const floor of res) {
        if (floor.status === '0') {
          this.floors.push(floor);
        }
      }
      if(this.floors.length >0){
        this.f.floor.setValue(this.floors[0].floor_id);
        this.getWings(this.floors[0].floor_id);
      }else{
        this.f.floor.setValue('');
        this.wings = [];
        this.f.wing.setValue('');
        this.modalReference.close();
      }
    }, error => {
      console.error(error);
      this.modalReference.close();
    });
  }

  getWings(floor_id: string) {
    this.communicationService.getWings(floor_id).subscribe(res => {
      this.wings = [];
      for (const wing of res) {
        if (wing.status === '0') {
          this.wings.push(wing);
        }
      }
      if (this.wings.length > 0) {
        this.f.wing.setValue(this.wings[0].wing_id);
      } else {
        this.f.wing.setValue('');
      }
      this.modalReference.close();
    }, error => {
      console.error(error);
      this.modalReference.close();
    });
  }

  onSubmit() {
    this.is_map_shown = true;
    this.getCoords();
  }

  getFloorWings(floor_id: string, wing_id: string, date: string) {
    this.communicationService.getFloorWings(floor_id, wing_id).subscribe(res => {
      const objectURL = 'data:image/png;base64,' + res.image_path;
      const image = new Image();
      image.src = objectURL;
      this.floorPlanImage = image;
      image.onload = (() => this.fitToCanvas(image));
    }, error => {
      console.error(error);
    });
  }

  showFloorPlan() {
    this.is_selected = false;
    this.loading = true;
    const wing_id = this.f.wing.value;
    const floor_id = this.f.floor.value;
    const start_date = this.f.startDate.value;
    this.getFloorWings(floor_id, wing_id, start_date);
  }

  // onMouseMove(event) {
  //   this.hideTooltip = false;
  //   const rB = this.floorPlanCtx.canvas.getBoundingClientRect();
  //   const x = event.pageX - (rB.left + window.scrollX);
  //   const y = event.pageY - (rB.top + window.scrollY);
  //   // Put your mousemove stuff here
  //   let prevx: number;
  //   let prevy: number;
  //   // tslint:disable-next-line:prefer-for-of
  //   for (let i = 0; i < this.coordinates.length; i++) {
  //     const section = this.coordinates[i];
  //     if (section.type === 0) {
  //       if (y > section.y && y < section.y + section.h
  //         && x > section.x && x < section.x + section.w) {
  //         let lineheight = 15;
  //         this.canvas.nativeElement.style.cursor = 'pointer';
  //         this.tooltip.nativeElement.style.left = (section.x) + 'px';
  //         this.tooltip.nativeElement.style.top = (section.y) + 'px';
  //         this.tooltipCtx.clearRect(0, 0, this.tooltip.nativeElement.width, this.tooltip.nativeElement.height);
  //         this.tooltipCtx.fillStyle = '#FFFFFF';
  //         if (section.is_common) {
  //           this.hideTooltip = true;

  //           this.tooltipCtx.fillText(`\nWorkstation Name : ${section.name}`, 0, lineheight);
  //           lineheight = lineheight + 25;

  //           this.tooltipCtx.fillText(`\nOccupied By : NA`, 0, lineheight);
  //           lineheight = lineheight + 25;

  //           this.tooltipCtx.fillText(`\nBooking Dates : NA`, 0, lineheight);
  //           lineheight = lineheight + 25;

  //           this.tooltipCtx.textAlign = 'left';
  //           this.tooltipCtx.font = 'bold  15px Arial';

  //         } else if (section.selected) {
  //           this.hideTooltip = true;
  //           this.tooltipCtx.fillText(`\nWorkstation Name : ${section.name}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nOccupied By : ${section.emp_name}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nStart Date & Time : ${section.start_date_time}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nEnd Date & Time : ${section.end_date_time}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nStatus : ${section.status}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.textAlign = 'left';
  //           this.tooltipCtx.font = 'bold  15px Arial';

  //         } else {
  //           this.hideTooltip = false;
  //         }
  //       }
  //     } else {
  //       const odd: any = [];
  //       const even: any = [];
  //       this.setArrays(section, even, odd);
  //       const {xMin, xMax, yMin, yMax} = this.getMinMax(even, odd);
  //       if (section.is_common) {
  //         // tslint:disable-next-line:no-shadowed-variable
  //         const odd: any = [];
  //         // tslint:disable-next-line:no-shadowed-variable
  //         const even: any = [];
  //         this.setArrays(section, even, odd);
  //         // tslint:disable-next-line:no-shadowed-variable
  //         const {xMin, xMax, yMin, yMax} = this.getMinMax(even, odd);

  //         if (this.isPointInsidePoly(x, y, xMin, xMax, yMin, yMax)) {
  //           this.hideTooltip = true;
  //           let lineheight = 15;
  //           this.canvas.nativeElement.style.cursor = 'pointer';
  //           this.tooltip.nativeElement.style.left = (section.sides[0] + 15) + 'px';
  //           this.tooltip.nativeElement.style.top = (section.sides[1] + 15) + 'px';

  //           this.tooltipCtx.clearRect(0, 0, section.sides[0] + 15, section.sides[1] + 15);

  //           this.tooltipCtx.fillText(`\nWorkstation Name : ${section.name}`, 0, lineheight);
  //           lineheight = lineheight + 25;

  //           this.tooltipCtx.fillText(`\nOccupied By : NA`, 0, lineheight);
  //           lineheight = lineheight + 25;

  //           this.tooltipCtx.fillText(`\nBooking Dates : NA`, 0, lineheight);
  //           lineheight = lineheight + 25;

  //           this.tooltipCtx.textAlign = 'left';
  //           this.tooltipCtx.font = 'bold  15px Arial';
  //         }
  //       } else if (section.selected) {
  //         // tslint:disable-next-line:no-shadowed-variable
  //         const odd: any = [];
  //         // tslint:disable-next-line:no-shadowed-variable
  //         const even: any = [];
  //         this.setArrays(section, even, odd);
  //         // tslint:disable-next-line:no-shadowed-variable
  //         const {xMin, xMax, yMin, yMax} = this.getMinMax(even, odd);

  //         if (this.isPointInsidePoly(x, y, xMin, xMax, yMin, yMax)) {
  //           this.hideTooltip = true;
  //           let lineheight = 15;
  //           this.canvas.nativeElement.style.cursor = 'pointer';
  //           this.tooltip.nativeElement.style.left = (section.sides[0] + 15) + 'px';
  //           this.tooltip.nativeElement.style.top = (section.sides[1] + 15) + 'px';

  //           this.tooltipCtx.clearRect(0, 0, section.sides[0] + 15, section.sides[1] + 15);
  //           this.tooltipCtx.fillText(`\nWorkstation Name : ${section.name}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nOccupied By : ${section.emp_name}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nStart Date & Time : ${section.start_date_time}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nEnd Date & Time : ${section.end_date_time}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.fillText(`\nStatus : ${section.status}`, 0, lineheight);
  //           lineheight = lineheight + 20;

  //           this.tooltipCtx.textAlign = 'left';
  //           this.tooltipCtx.font = 'bold  15px Arial';

  //         }
  //       }
  //     }
  //   }
  // }

  setBookingsPopup(element,content){
    this.f.workstationName.setValue(element.name);
    element.bookings.forEach(booking =>{
      this.bookingList.push(booking);
    })
    this.sortBookings();
    this.modalReference = this.modalService.open(content);
  }

  onClick(event,content) {
    this.bookingList =[];
    const rB = this.floorPlanCtx.canvas.getBoundingClientRect();
    const x = event.pageX - (rB.left + window.scrollX);
    const y = event.pageY - (rB.top + window.scrollY);
    this.coordinates.map((element) => {
      if(element.selected ){
        if (y > element.y && y < element.y + element.h
            && x > element.x && x < element.x + element.w){
      this.setBookingsPopup(element,content);
       }
       else if(element.h === null || element.y === null
        ||element.x === null || element.w === null) {
        const odd: any = [];
        const even: any = [];
        this.setArrays(element, even, odd);
        const {xMin, xMax, yMin, yMax} = this.getMinMax(even, odd);
        if(this.isPointInsidePoly(x, y, xMin, xMax, yMin, yMax)){
        this.setBookingsPopup(element,content);
         }
         }
    }
  })
  }

  sortBookings() {
    const bookingData = [];
    bookingData.push(this.bookingList);
      this.totalUserCount = 10;
      this.heroes$ = new BehaviorSubject(bookingData);
      combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
        .subscribe(([allSources, currentPage, pageSize]) => {
          const startingIndex = (currentPage - 1) * pageSize;
          const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
          this.dataOnPage$.next(onPage);
        });

      this.heroes$.pipe(take(1)).subscribe(heroData => {
        this.tableDataSource$.next(Object.values(heroData));
      });
      combineLatest(this.heroes$, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          const sortedHeroes = heroesArray.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });

          this.tableDataSource$.next(sortedHeroes);
        });

      combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          let filteredHeroes: any[];

          if (!searchTerm) {
            filteredHeroes = heroesArray;
          } else {
            const filteredResults = heroesArray.filter(hero => {
              return Object.values(hero)
                .reduce((prev, curr) => {
                  return prev || curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
                }, false);
            });
            filteredHeroes = filteredResults;
          }

          const sortedHeroes = filteredHeroes.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });
          this.tableDataSource$.next(sortedHeroes);
        });
  }

  isInputSelected() {
    if (this.f.wing.value === undefined
      || this.f.wing.value === '' || this.f.floor.value === undefined || this.f.floor.value === '') {
      return false;
    }
    return true;
  }

  convertDate(date) {
    const converdate = new Date(date.year, date.month - 1, date.day);
    return converdate;
  }

  private fitToCanvas(image) {
    this.floorPlanCtx.canvas.width = image.width;
    this.floorPlanCtx.canvas.height = image.height;
    this.floorPlanCtx.drawImage(image, 0, 0);
    this.highlightSelected();
  }

  highlightSelected() {
    this.coordinates.map((element) => {
      if (element.is_common) {
        if (element.type === 0) {
          this.floorPlanCtx.fillStyle = '#A8ADB3';
          this.floorPlanCtx.fillRect(element.x, element.y, element.w, element.h);
          this.floorPlanCtx.strokeStyle = '#d4d6d9';
          this.floorPlanCtx.strokeRect(element.x, element.y, element.w, element.h);
        } else {
          this.floorPlanCtx.fillStyle = '#A8ADB3';
          this.floorPlanCtx.strokeStyle = '#d4d6d9';
          this.plotPolygon(element);
        }

      } else if (element.selected) {
        if (element.type === 0) {
          this.floorPlanCtx.fillStyle = '#A8ADB3';
          this.floorPlanCtx.fillRect(element.x, element.y, element.w, element.h);
          this.floorPlanCtx.strokeStyle = '#d4d6d9';
          this.floorPlanCtx.strokeRect(element.x, element.y, element.w, element.h);
        } else {
          this.floorPlanCtx.fillStyle = '#A8ADB3';
          this.floorPlanCtx.strokeStyle = '#d4d6d9';
          this.plotPolygon(element);
        }
      }
    });
    this.loading = false;
  }

  private setArrays(element: Coordinate, even: any, odd: any) {
    let i = 0;
    let j = 0;
    element.sides.forEach((entry, index) => {
      if (index % 2 === 0) {
        even[i] = entry;
        i++;
      } else {
        odd[j] = entry;
        j++;
      }
    });
  }

  private plotPolygon(element: Coordinate) {
    this.floorPlanCtx.beginPath();
    this.floorPlanCtx.moveTo(element.sides[0], element.sides[1]);
    this.floorPlanCtx.lineTo(element.sides[2], element.sides[3]);
    this.floorPlanCtx.lineTo(element.sides[4], element.sides[5]);
    this.floorPlanCtx.lineTo(element.sides[6], element.sides[7]);
    this.floorPlanCtx.lineTo(element.sides[8], element.sides[9]);
    this.floorPlanCtx.lineTo(element.sides[10], element.sides[11]);
    this.floorPlanCtx.closePath();
    this.floorPlanCtx.fill();
  }

  isPointInsidePoly(x, y, xmin, xmax, ymin, ymax) {
    if (x < xmin || x > xmax || y < ymin || y > ymax) {
      return false;
    } else {
      return true;
    }
  }

  private getMinMax(even: any, odd: any) {
    const xMin = even.reduce((a, b) => Math.min(a, b));
    const xMax = even.reduce((a, b) => Math.max(a, b));
    const yMin = odd.reduce((a, b) => Math.min(a, b));
    const yMax = odd.reduce((a, b) => Math.max(a, b));
    return {xMin, xMax, yMin, yMax};
  }

}
