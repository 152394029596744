import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { CommunicationService } from 'src/app/services/communication.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-view-parkings',
  templateUrl: './view-parkings.component.html',
  styleUrls: ['./view-parkings.component.css']
})
export class ViewParkingsComponent implements OnInit {

  heroes$;
  seat_number;
  emp_number;
  superlatives$ = new BehaviorSubject<{ [superlativeName: string]: string }>({});
  tableDataSource$ = new BehaviorSubject<any[]>([]);
  displayedColumns$ = new BehaviorSubject<string[]>([
    'name',
    'count',
    'status',
    'id'
  ]);
  start_Date: Date;
  end_Date: Date;
  currentPage$ = new BehaviorSubject<number>(1);
  pageSize$ = new BehaviorSubject<number>(10);
  dataOnPage$ = new BehaviorSubject<any[]>([]);
  searchFormControl = new FormControl();
  sortKey$ = new BehaviorSubject<string>('name');
  sortDirection$ = new BehaviorSubject<string>('asc');
  totalParkings = 0;
  limit = 10000;
  startIndex = 0;
  status = [];
  moreDetailsData = null;
  closeResult: string;
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getAllParkings(0, 100);
  }


  adjustSort(key: string) {
    if (this.sortKey$.value === key) {
      if (this.sortDirection$.value === 'asc') {
        this.sortDirection$.next('desc');
      } else {
        this.sortDirection$.next('asc');
      }
      return;
    }
    this.sortKey$.next(key);
    this.sortDirection$.next('asc');
  }

  activateParking(name: any) {

    this.communicationService.activateParking(name).subscribe(res => {
      this.getAllParkings(0, 100);
      this.toastr.success('Parking activated');
    }, error => {
      console.log(error);
      this.toastr.error('Unable to activate level');

    });
  }


  disableParking(name: any) {
    this.communicationService.disableParking(name).subscribe(res => {
      this.getAllParkings(0, 100);
      this.toastr.success('Parking disabled');

    }, error => {
      console.log(error);
      this.toastr.error('Unable to disable Level');
    });
  }

  editParking(parkingId: any) {
    this.router.navigate(['add-parking', parkingId]);
  }

  getAllParkings(limit: Number, index: Number) {
    const parkings = [];
    const searchObj = {
      limit,
      index
    };
    this.communicationService.getParkings(searchObj).subscribe(res => {
      for (const obj of res) {
        parkings.push(obj);
      }

      this.totalParkings = parkings.length;
      this.heroes$ = new BehaviorSubject(parkings);
      combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
        .subscribe(([allSources, currentPage, pageSize]) => {
          const startingIndex = (currentPage - 1) * pageSize;
          const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
          this.dataOnPage$.next(onPage);
        });

      this.heroes$.pipe(take(1)).subscribe(heroData => {
        this.tableDataSource$.next(Object.values(heroData));
      });
      combineLatest(this.heroes$, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          const sortedHeroes = heroesArray.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });

          this.tableDataSource$.next(sortedHeroes);
        });

      combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          let filteredHeroes: any[];

          if (!searchTerm) {
            filteredHeroes = heroesArray;
          } else {
            const filteredResults = heroesArray.filter(hero => {
              return Object.values(hero)
                .reduce((prev, curr) => {
                  return prev || curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
                }, false);
            });
            filteredHeroes = filteredResults;
          }

          const sortedHeroes = filteredHeroes.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });

          this.tableDataSource$.next(sortedHeroes);
        });
    }, error => {
      console.log(error);
    });
  }

  search() {
    const limit = this.limit;
    const index = this.startIndex;
    const parkings = [];

    const searchObj = {
      limit,
      index
    };
    this.communicationService.getParkings(searchObj).subscribe(res => {
      for (const obj of res) {
        // if (obj.status == 0) {
        parkings.push(obj);
        // }
      }
      this.totalParkings = parkings.length;
      this.heroes$ = new BehaviorSubject(parkings);
      combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
        .subscribe(([allSources, currentPage, pageSize]) => {
          const startingIndex = (currentPage - 1) * pageSize;
          const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
          this.dataOnPage$.next(onPage);
        });

      this.heroes$.pipe(take(1)).subscribe(heroData => {
        this.tableDataSource$.next(Object.values(heroData));
      });

      combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          let filteredHeroes: any[];

          if (!searchTerm) {
            filteredHeroes = heroesArray;
          } else {
            const filteredResults = heroesArray.filter(hero => {
              return Object.values(hero)
                .reduce((prev, curr) => {
                  return prev || curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
                }, false);
            });
            filteredHeroes = filteredResults;
          }

          const sortedHeroes = filteredHeroes.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });

          this.tableDataSource$.next(sortedHeroes);
        });
    }, error => {
      console.log(error);
    });
  }

}
