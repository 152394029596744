export const TimeByHour: any[] = [
  {time: '12:00 AM', timeFormat: 'T00:00'},
  {time: '12:30 AM', timeFormat: 'T00:30'},
  {time: '1:00 AM', timeFormat: 'T01:00'},
  {time: '1:30 AM', timeFormat: 'T01:30'},
  {time: '2:00 AM', timeFormat: 'T02:00'},
  {time: '2:30 AM', timeFormat: 'T02:30'},
  {time: '3:00 AM', timeFormat: 'T03:00'},
  {time: '3:30 AM', timeFormat: 'T03:30'},
  {time: '4:00 AM', timeFormat: 'T04:00'},
  {time: '4:30 AM', timeFormat: 'T04:30'},
  {time: '5:00 AM', timeFormat: 'T05:00'},
  {time: '5:30 AM', timeFormat: 'T05:30'},
  {time: '6:00 AM', timeFormat: 'T06:00'},
  {time: '6:30 AM', timeFormat: 'T06:30'},
  {time: '7:00 AM', timeFormat: 'T07:00'},
  {time: '7:30 AM', timeFormat: 'T07:30'},
  {time: '8:00 AM', timeFormat: 'T08:00'},
  {time: '8:30 AM', timeFormat: 'T08:30'},
  {time: '9:00 AM', timeFormat: 'T09:00'},
  {time: '9:30 AM', timeFormat: 'T09:30'},
  {time: '10:00 AM', timeFormat: 'T10:00'},
  {time: '10:30 AM', timeFormat: 'T10:30'},
  {time: '11:00 AM', timeFormat: 'T11:00'},
  {time: '11:30 AM', timeFormat: 'T11:30'},
  {time: '12:00 PM', timeFormat: 'T12:00'},
  {time: '12:30 PM', timeFormat: 'T12:30'},
  {time: '1:00 PM', timeFormat: 'T13:00'},
  {time: '1:30 PM', timeFormat: 'T13:30'},
  {time: '2:00 PM', timeFormat: 'T14:00'},
  {time: '2:30 PM', timeFormat: 'T14:30'},
  {time: '3:00 PM', timeFormat: 'T15:00'},
  {time: '3:30 PM', timeFormat: 'T15:30'},
  {time: '4:00 PM', timeFormat: 'T16:00'},
  {time: '4:30 PM', timeFormat: 'T16:30'},
  {time: '5:00 PM', timeFormat: 'T17:00'},
  {time: '5:30 PM', timeFormat: 'T17:30'},
  {time: '6:00 PM', timeFormat: 'T18:00'},
  {time: '6:30 PM', timeFormat: 'T18:30'},
  {time: '7:00 PM', timeFormat: 'T19:00'},
  {time: '7:30 PM', timeFormat: 'T19:30'},
  {time: '8:00 PM', timeFormat: 'T20:00'},
  {time: '8:30 PM', timeFormat: 'T20:30'},
  {time: '9:00 PM', timeFormat: 'T21:00'},
  {time: '9:30 PM', timeFormat: 'T21:30'},
  {time: '10:00 PM', timeFormat: 'T22:00'},
  {time: '10:30 PM', timeFormat: 'T22:30'},
  {time: '11:00 PM', timeFormat: 'T23:00'},
  {time: '11:30 PM', timeFormat: 'T23:30'},
];
