import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Coordinate } from '../model/coordinate';
import { Desk } from 'src/app/model/desk';
import { FloorPath } from '../model/floorPath';
import { SeatsResponse } from '../model/seatsresponse';
import { SeatAttributes } from '../model/seatattributes';
import { EventEmitter } from '@angular/core';

@Injectable()
export class CommunicationService {
  featureEmitter:EventEmitter<any>  = new EventEmitter();

  data = {};

  getData(key:string){
    return this.data[key];
  }

  setData(key:string,value:any){
    this.data[key] = value;
  }

  public showHideMenuBar = true;

  showDashboardPopUp = false;
  currentUser: any;

  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  getVersion() {
    return this.http.get<any>(`${environment.apiUrl}v1/user/version`)
      .pipe(map(res => {
        return res;
      }));
  }
  getAllUsersDetails(limit: number, index: number) {
    return this.http.get<any>(`${environment.apiUrl}v1/user/users?size=${limit}&page=${index}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getAllUsersFilter(searchObj: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/user/search_user?searchString=${searchObj}`)
      .pipe(map(res => {
        return res;
      }));
  }
  getParkings(searchObj: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/parkings?item_limit=${searchObj.limit}&item_start=${searchObj.index}`).pipe(map((res => {
      return res;
    })));
  }
  getZooPass(start_date,end_date) {
    let sDate = '' , eDate = '' ;
    if (start_date && end_date) {
      sDate = start_date;
      eDate = end_date;
    }
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/zoo_pass?start_date=${sDate}&end_date=${eDate}`).pipe(map((res => {
      return res;
    })));
  }

  bookZooPass(request: any) {
    return this.http.post<any>(`${environment.apiUrl}v1/booking/book/zoo_pass`, request).pipe(map((res => {
      return res;
    })));
  }

  getAllPasses() {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/zoo_passes`)
      .pipe(map(res => {
        return res;
      }));
  }

  getAllResponderBookings(limit: number, index: number, isReportDownload: any, responder: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/booking/responder/bookings?size=${limit}&page=${index}&isReportDownload=${isReportDownload}&responder=${responder}`)
      .pipe(map(res => {
        return res;
      }));
  }
  getFloorResponderUsers(limit: number, index: number) {
    return this.http.get<any>(`${environment.apiUrl}v1/user/floorResponder?size=${limit}&page=${index}`)
      .pipe(map(res => {
        return res;
      }));
  }
    getAllResponderBookingsFilter(limit: number, index: number, searchObj: any) {
    // tslint:disable-next-line:one-variable-per-declaration
    let sDate = '', eDate = '';
    if (searchObj.start_date) {
      sDate = searchObj.start_date + 'T00:00:00';
    }
    if (searchObj.end_date) {
      eDate = searchObj.end_date + 'T23:59:59';
    }

    return this.http.get<any>(`${environment.apiUrl}v1/booking/responder/bookings?size=${limit}&page=${index}&start_date=${sDate}&end_date=${eDate}&city=${searchObj.city_id}&isReportDownload=${searchObj.isReportDownload}&building=${searchObj.building_id}&floor=${searchObj.floor_id}&responder=${searchObj.responder}`)
      .pipe(map(res => {
        return res;
      }));
  }

  disablePass(passId: any) {
      return this.http.delete<any>(`${environment.apiUrl}v1/floor_wing/zoo_pass/${passId}/status/1`).pipe(map((res => {
        return res;
      })));
  }

  enablePass(passId: any) {
      return this.http.delete<any>(`${environment.apiUrl}v1/floor_wing/zoo_pass/${passId}/status/0`).pipe(map((res => {
        return res;
      })));
  }

  getAllBookingsFilter(limit: number, index: number, searchObj: any) {
    let sDate = '', eDate = '';
    if (searchObj.start_date) {
      sDate = searchObj.start_date;
    }
    if (searchObj.end_date) {
      eDate = searchObj.end_date;
    }

    return this.http.get<any>(`${environment.apiUrl}v1/booking/bookings?size=${limit}&page=${index}&start_date_time=${sDate}&end_date_time=${eDate}&status=${searchObj.status}&city=${searchObj.city_id}&building=${searchObj.building_id}&floor=${searchObj.floor_id}&employee=${searchObj.emp_id}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getAllBookings(limit: number, index: number) {
    return this.http.get<any>(`${environment.apiUrl}v1/booking/bookings?size=${limit}&page=${index}`)
      .pipe(map(res => {
        return res;
      }));
  }

  downloadAllBookings(booking_type: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/booking/getAllBookings/${booking_type}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getAllBookingsSelf(user_id: any) {
   return this.http.get<any>(`${environment.apiUrl}v1/booking/self/${user_id}`).pipe(map((res => {
        return res;
   })));
  }

  getBookingStatus() {
    return this.http.get<any>(`${environment.apiUrl}v1/booking/status`).pipe(map((res => {
      return res;
    })));
  }

  getDashboardDetails() {
    return this.http.get<any>(`${environment.apiUrl}v1/dashboard/details`).pipe(map((res => {
      return res;
    })));
  }

  bookSeat(request: any) {
    return this.http.post<any>(`${environment.apiUrl}v1/booking/book`, request).pipe(map((res => {
      return res;
    })));
  }

  getFoods() {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/foods`).pipe(map((res => {
      return res
    })))
 }

 getFood() {
  return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/food`).pipe(map((res => {
    return res;
  })));
}

  bookFood(request: any) {
    return this.http.post<any>(`${environment.apiUrl}v1/booking/book/food`, request).pipe(map((res => {
      return res
    })))
  }

  saveConfiguration(request: any) {
    return this.http.post<any>(`${environment.apiUrl}v1/configuration/configure`, request).pipe(map((res => {
      return res;
    })));
  }

  getConfiguration() {
    return this.http.get<any>(`${environment.apiUrl}v1/configuration/get`).pipe(map((res => {
      return res;
    })));
  }

  autoAssign(request: any) {
    return this.http.post<any>(`${environment.apiUrl}v1/booking/book/autoassign`, request).pipe(map((res => {
      return res;
    })));
  }

  updateBookingSeat(request: any) {
    return this.http.put<any>(`${environment.apiUrl}v1/booking/update`, request).pipe(map((res => {
      return res;
    })));
  }

  cancelSeatBooking(booking_id: any) {
    return this.http.delete<any>(`${environment.apiUrl}v1/booking/${booking_id}`).pipe(map((res => {
      return res;
    })));
  }

  getAllAuditLogs(size: number, index: number) {
    return this.http.get<any>(`${environment.apiUrl}v1/audit_log/audit_logs?size=${size}&page=${index}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getAuditLogDetails(id: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/audit_log/audit_log/${id}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getUserDetails(username: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/user/username/${username}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getUserByIds(id: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/user/users_id/${id}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getUserRequest() {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/register`)
      .pipe(map(res => {
        console.log(res);
        return res;
      }));
  }

  addUserDepartment(request) {
    return this.http.post<any>(`${environment.apiUrl}v1/floor_wing/register/user`, request).pipe(map((res => {
      return res;
    })));
  }

  rejectUserRequest(request) {
    return this.http.post<any>(`${environment.apiUrl}v1/floor_wing/register/user/reject`, request).pipe(map((res => {
      return res;
    })));
  }

  getFloors() {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/floors`).pipe(map((res => {
      return res;
    })));
  }

  getFloorsFilter(limit: number, index: number ) {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/floors?item_limit=${limit}&item_start=${index}`).pipe(map((res => {
      return res;
    })));
  }

  getWingsFilter(limit: number, index: number ) {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/wings?item_limit=${limit}&item_start=${index}`).pipe(map((res => {
      return res;
    })));
  }

  getBuildings(city_id: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/city/${city_id}/buildings`).pipe(map((res => {
      return res;
    })));
  }

  getCities() {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/cities`).pipe(map((res => {
      return res;
    })));
  }

  getCitiesFilter(limit: number, index: number) {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/cities?item_limit=${limit}&item_start=${index}`).pipe(map((res => {
      return res;
    })));
  }

  getBuildingsFilter(limit: number, index: number) {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/building?item_limit=${limit}&item_start=${index}`).pipe(map((res => {
      return res;
    })));
  }
  getDepartments() {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/departments`).pipe(map((res => {
      return res;
    })));
  }
  getCountries() {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/countries`).pipe(map((res => {
      return res;
    })));
  }

  getOrganizations() {
      return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/organizations`).pipe(map((res => {
        return res;
      })));
  }

  getSubDepartments() {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/subDepartments`).pipe(map((res => {
      return res;
    })));
  }

  getOrganization() {
    return this.http.get<any>(`${environment.apiUrl}v1/configuration/employee/organization`)
      .pipe(map(res => {
       return res;
    }));
  }
  getOrganizationByCountryId(countryId: any) {
      return this.http.get<any>(`${environment.apiUrl}v1/configuration/organization/country/${countryId}`)
        .pipe(map(res => {
          return res;
        }));
  }
  getDepartmentsByOrganizationId(organizationId: any) {
      return this.http.get<any>(`${environment.apiUrl}v1/configuration/departments/organizationId/${organizationId}`)
        .pipe(map(res => {
          return res;
        }));
  }
  getDepartmentsFilter(limit: number, index: number) {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/departments?item_limit=${limit}&item_start=${index}`).pipe(map((res => {
      return res;
    })));
  }
  getCountriesFilter(limit: number, index: number) {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/countries?item_limit=${limit}&item_start=${index}`).pipe(map((res => {
      return res;
    })));
  }

  getFlooWings() {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/floor_wings`).pipe(map((res => {
      return res;
    })));
  }

  getFloorsByBuilding(building_id: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/building/${building_id}/floors`).pipe(map((res => {
      return res;
    })));
  }

  getCitiesByCountry(country_id: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/country/${country_id}/cities`).pipe(map((res => {
      return res;
    })));
  }
  getRegistrationDetails(country_id: any,city_id:any,building_id) {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/registration/country/${country_id}/city/${city_id}/building/${building_id}`).pipe(map((res => {
      return res;
    })));
  }
  userRegisteration(request) {
    return this.http.post<any>(`${environment.apiUrl}v1/floor_wing/registration/user`,request).pipe(map((res => {
      return res;
    })));
  }

  getWings(floor_id: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/floor/${floor_id}/wing`).pipe(map((res => {
      return res;
    })));
  }

  getSeats(request: any): Observable<Coordinate[]> {
    return this.http.post<any>(`${environment.apiUrl}v1/floor_wing/floor/seats/all`, request).pipe(map((res => {
      return res;
    })));
  }

  getSeat(name: any): Observable<SeatAttributes> {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/seat/${name}`).pipe(map((res => {
      return res;
    })));
  }

  getSeatById(id: any): Observable<SeatAttributes> {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/seat/id/${id}`).pipe(map((res => {
      return res;
    })));
  }

  getSeatsByDate(floor_id, wing_id, date: any): Observable<SeatsResponse> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/seats/date?floor_id=${floor_id}&wing_id=${wing_id}&by_date=1&date=${date}`).pipe(map((res => {
      return res;
    })));
  }

  getFloorWings(floor_id: any, wing_id: any): Observable<FloorPath> {
    return this.http.get<FloorPath>(`${environment.apiUrl}v1/floor_wing/floor/${floor_id}/wing/${wing_id}`).pipe(map((res => {
      return res;
    })));
  }

  getParkingFloorWings(){
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/parkingslot`).pipe(map((res => {
      return res;
    })));
  }

  getFloorPlan(floor_id: any, wing_id: any): Observable<FloorPath> {
    return this.http.get<FloorPath>(`${environment.apiUrl}v1/floor_wing/floor/image/${floor_id}/wing/${wing_id}`).pipe(map((res => {
      return res;
    })));
  }

  getDesksList(floor_id: any, wing_id: any): Observable<FloorPath> {
    return this.http.get<FloorPath>(`${environment.apiUrl}v1/floor_wing/desks/floor/${floor_id}/wing/${wing_id}`).pipe(map((res => {
      return res;
    })));
  }

  getSeatsList(floor_id: any, wing_id: any): Observable<FloorPath> {
      return this.http.get<FloorPath>(`${environment.apiUrl}v1/floor_wing/seats/floor/${floor_id}/wing/${wing_id}`).pipe(map((res => {
        return res;
      })));
    }

  getSeatsFilter(limit: number, index: number, seat_name: string): Observable<Coordinate[]> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/seats?item_limit=${limit}&item_start=${index}&seat_name=${seat_name}`).pipe(map((res => {
      return res;
    })));
  }

  findDesk(limit: number, index: number, searchObject: any): Observable<Desk[]> {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/search?item_limit=${limit}&item_start=${index}&emp_id=${searchObject.emp_id}&seat_id=${searchObject.seat_id}&start_date_time=${searchObject.start_date_time}&building_id=${searchObject.building_id}`).pipe(map((res => {
      return res;
    })));
  }

  findBooking(searchObject: any): Observable<Desk> {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/search?booking_id=${searchObject.booking_id}`).pipe(map((res => {
      return res;
    })));
  }

  activateFloor(floor_id: any, wing_id: any) {
    return this.http.delete<any>(`${environment.apiUrl}v1/floor_wing/floor/${floor_id}/wing/${wing_id}/0`).pipe(map((res => {
      return res;
    })));
  }

  disableFloor(floor_id: any, wing_id: any) {
    return this.http.delete<any>(`${environment.apiUrl}v1/floor_wing/floor/${floor_id}/wing/${wing_id}/1`).pipe(map((res => {
      return res;
    })));
  }

  getFloorMap(floor_id: any, wing_id: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/floor/${floor_id}/wing/${wing_id}`).pipe(map((res => {
      return res;
    })));
  }

  updateFirstAidResponder(floor_id: any, username: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/floor/${floor_id}/username/${username}`).pipe(map((res => {
      return res;
    })));
  }

  getSearchResult(val: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/user/search_user?searchString=${val}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getTasks() {
    return this.http.get<any>(`${environment.apiUrl}v1/booking/tasks/`)
      .pipe(map(res => {
        return res;
      }));
  }

  getTasksCount() {
    return this.http.get<any>(`${environment.apiUrl}v1/booking/tasks/count`)
      .pipe(map(res => {
        return res;
      }));
  }

  getRoles() {
    return this.http.get<any>(`${environment.apiUrl}v1/user/roles`).pipe(map((res => {
      return res;
    })));
  }

  addRole(request) {
    return this.http.post<any>(`${environment.apiUrl}v1/user/role`, request).pipe(map((res => {
      return res;
    })));
  }

  addUser(request) {
    return this.http.post<any>(`${environment.apiUrl}v1/user/add`, request).pipe(map((res => {
      return res;
    })));
  }

  addDepartment(request) {
    return this.http.post<any>(`${environment.apiUrl}v1/configuration/department`, request).pipe(map((res => {
      return res;
    })));
  }
  getOganizationById(organizationId: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/configuration/organization/organizationId/${organizationId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getDepartmentById(departmentId: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/configuration/department/departmentId/${departmentId}`)
      .pipe(map(res => {
        return res;
      }));
  }
  addCountry(request) {
    return this.http.post<any>(`${environment.apiUrl}v1/configuration/country`, request).pipe(map((res => {
      return res;
    })));
  }


  getCountryById(countryId: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/configuration/country/countryId/${countryId}`)
      .pipe(map(res => {
        return res;
      }));
  }


  disableDepartment(departmentId: any) {
    return this.http.delete<any>(`${environment.apiUrl}v1/configuration/department/departmentId/${departmentId}/1`).pipe(map((res => {
      return res;
    })));
  }
  activateDepartment(departmentId: any) {
    return this.http.delete<any>(`${environment.apiUrl}v1/configuration/department/departmentId/${departmentId}/0`).pipe(map((res => {
      return res;
    })));
  }
  disableCountry(countryId: any) {
    return this.http.delete<any>(`${environment.apiUrl}v1/configuration/country/countryId/${countryId}/1`).pipe(map((res => {
      return res;
    })));
  }
  activateCountry(countryId: any) {
    return this.http.delete<any>(`${environment.apiUrl}v1/configuration/country/countryId/${countryId}/0`).pipe(map((res => {
      return res;
    })));
  }

  addCity(request) {
    return this.http.post<any>(`${environment.apiUrl}v1/configuration/city`, request).pipe(map((res => {
      return res;
    })));
  }

  addOrganization(request) {
      return this.http.post<any>(`${environment.apiUrl}v1/configuration/organization`, request).pipe(map((res => {
        return res;
      })));
  }

  addSubdepartment(request) {
    return this.http.post<any>(`${environment.apiUrl}v1/configuration/subDepartment`, request).pipe(map((res => {
      return res;
    })));
  }

  getCityById(cityId: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/configuration/city/cityId/${cityId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  disableCity(cityId: any) {
    return this.http.delete<any>(`${environment.apiUrl}v1/configuration/city/cityId/${cityId}/1`).pipe(map((res => {
      return res;
    })));
  }
  activateCity(cityId: any) {
    return this.http.delete<any>(`${environment.apiUrl}v1/configuration/city/cityId/${cityId}/0`).pipe(map((res => {
      return res;
    })));
  }

  addBuilding(request) {
    return this.http.post<any>(`${environment.apiUrl}v1/configuration/building`, request).pipe(map((res => {
      return res;
    })));
  }
  getBuildingById(buildingId: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/configuration/building/buildingId/${buildingId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  disableBuilding(buildingId: any) {
    return this.http.delete<any>(`${environment.apiUrl}v1/configuration/building/buildingId/${buildingId}/1`).pipe(map((res => {
      return res;
    })));
  }
  activateBuilding(buildingId: any) {
    return this.http.delete<any>(`${environment.apiUrl}v1/configuration/building/buildingId/${buildingId}/0`).pipe(map((res => {
      return res;
    })));
  }
  addFloor(request) {
    return this.http.post<any>(`${environment.apiUrl}v1/configuration/floor`, request).pipe(map((res => {
      return res;
    })));
  }
  getFloorById(floorId: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/configuration/floor/floorId/${floorId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getOrganizationById(organizationId: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/configuration/organization/organizationId/${organizationId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getSubDepartmentById(subDepartmentId: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/configuration/subDepartment/subDepartmentId/${subDepartmentId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  disableFloors(floorId: any) {
    return this.http.delete<any>(`${environment.apiUrl}v1/configuration/floor/floorId/${floorId}/1`).pipe(map((res => {
      return res;
    })));
  }

  disableOrganization(organizationId: any) {
    return this.http.delete<any>(`${environment.apiUrl}v1/configuration/organization/organizationId/${organizationId}/1`).pipe(map((res => {
      return res;
    })));
  }

  disableSubDepartment(subDepartmentId: any) {
    // tslint:disable-next-line:max-line-length
    return this.http.delete<any>(`${environment.apiUrl}v1/configuration/subDepartment/subDepartmentId/${subDepartmentId}/1`).pipe(map((res => {
      return res;
    })));
  }

  activateOrganization(organizationId: any) {
    return this.http.delete<any>(`${environment.apiUrl}v1/configuration/organization/organizationId/${organizationId}/0`).pipe(map((res => {
      return res;
    })));
  }

  activateSubDepartment(subDepartmentId: any) {
    // tslint:disable-next-line:max-line-length
    return this.http.delete<any>(`${environment.apiUrl}v1/configuration/subDepartment/subDepartmentId/${subDepartmentId}/0`).pipe(map((res => {
      return res;
    })));
  }
  activateFloors(floorId: any) {
    return this.http.delete<any>(`${environment.apiUrl}v1/configuration/floor/floorId/${floorId}/0`).pipe(map((res => {
      return res;
    })));
  }

  addWing(request) {
    return this.http.post<any>(`${environment.apiUrl}v1/configuration/wing`, request).pipe(map((res => {
      return res;
    })));
  }
  getWingById(wingId: any, buildingFloorId: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/configuration/wing/wingId/${wingId}/buildingFloorId/${buildingFloorId}`)
      .pipe(map(res => {
        return res;
      }));
  }
  /*disableWing(wingId: any) {
    return this.http.delete<any>(`${environment.apiUrl}v1/configuration/wing/wingId/${wingId}/1`).pipe(map((res => {
      return res
    })))
  }*/

  updateWing(wingId: any, buildingFloorId: any, status: any) {
    // tslint:disable-next-line:max-line-length
    return this.http.delete<any>(`${environment.apiUrl}v1/configuration/wing/wingId/${wingId}/${buildingFloorId}/${status}`).pipe(map((res => {
      return res;
    })));
  }

  disableUser(username: any, role: any) {
    return this.http.delete<any>(`${environment.apiUrl}v1/user/username/${username}/role/${role}/1`).pipe(map((res => {
      return res;
    })));
  }

  activateUser(username: any, role: any) {
    return this.http.delete<any>(`${environment.apiUrl}v1/user/username/${username}/role/${role}/0`).pipe(map((res => {
      return res;
    })));
  }

  enableDisableSeat(request: any) {
    return this.http.post<any>(`${environment.apiUrl}v1/floor_wing/seats/update`, request).pipe(map((res => {
      return res;
    })));
  }

  createSeats(request: any) {
    return this.http.post<any>(`${environment.apiUrl}v1/floor_wing/seat/create`, request).pipe(map((res => {
      return res;
    })));
  }

  deleteSeats(seat_id: any) {
    console.log('service');
    return this.http.delete<any>(`${environment.apiUrl}v1/floor_wing/seat/delete/id/${seat_id}`).pipe(map((res => {
      return res;
    })));
  }

  updateSeatAttribute(request: any) {
    return this.http.post<any>(`${environment.apiUrl}v1/floor_wing/seats/attribute/update`, request).pipe(map((res => {
      return res;
    })));
  }

  getPreferences() {
    return this.http.get<any>(`${environment.apiUrl}v1/booking/user_preference`).pipe(map((res => {
      return res;
    })));
  }

  bookCarParking(request: any) {
    return this.http.post<any>(`${environment.apiUrl}v1/booking/book/car`, request).pipe(map((res => {
      return res;
    })));
  }

  getZones(start_date, end_date) {
    let sDate = '';
    if (start_date) {
      sDate = start_date;
    }
    let eDate = '';
    if (end_date) {
      eDate = end_date;
    }
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/zones?start_date=${sDate}&end_date=${eDate}`).pipe(map((res => {
      return res;
    })));
  }

  uploadImage(request) {
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/json');
      return this.http.post<any>(`${environment.apiUrl}v1/configuration/image`, request, {headers}).pipe(map((res => {
        return res;
      })));
  }

  getSeatAttributes(request: any) {
    return this.http.post<any>(`${environment.apiUrl}v1/floor_wing/attributes`, request).pipe(map((res => {
      return res;
    })));
  }
  getFeatureByDept(department_name:any) {
    return this.http.get<any>(`${environment.apiUrl}v1/configuration/feature/departmentName/${department_name}`)
    .pipe(map((res => {
      return res;
    })));
  }

  activateParking(name: any) {
    return this.http.delete<any>(`${environment.apiUrl}v1/floor_wing/parkings/${name}/status/0`).pipe(map((res => {
      return res;
    })));
  }

  disableParking(name: any) {
    return this.http.delete<any>(`${environment.apiUrl}v1/floor_wing/parkings/${name}/status/1`).pipe(map((res => {
      return res;
    })));
  }

  updateParking(request) {
    return this.http.post<any>(`${environment.apiUrl}v1/floor_wing/parkings/update`, request).pipe(map((res => {
      return res;
    })));
  }

  getAllParkings() {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/parkings/`)
      .pipe(map(res => {
        return res;
      }));
  }

  updatePass(request) {
    return this.http.post<any>(`${environment.apiUrl}v1/floor_wing/zoo_pass/update`, request).pipe(map((res => {
      return res;
    })));
  }

  getParkingById(parkingId: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/parking/${parkingId}`).pipe(map((res => {
      return res;
    })));
  }

  getPassById(passId) {
    return this.http.get<any>(`${environment.apiUrl}v1/floor_wing/zoo_pass/${passId}`).pipe(map(res => {
      return res;
    }));
  }

  addFeature(request) {
    return this.http.post<any>(`${environment.apiUrl}v1/configuration/feature`, request).pipe(map((res => {
      return res;
    })));
  }

  activateFeature(featureId: any) {
    return this.http.delete<any>(`${environment.apiUrl}v1/configuration/feature/featureId/${featureId}/0`).pipe(map((res => {
      return res;
    })));
  }
  disableFeature(featureId: any) {
    return this.http.delete<any>(`${environment.apiUrl}v1/configuration/feature/featureId/${featureId}/1`).pipe(map((res => {
      return res;
    })));
  }

  getFeatureById(featureId: any) {
    return this.http.get<any>(`${environment.apiUrl}v1/configuration/feature/featureId/${featureId}`)
      .pipe(map(res => {
        return res;
      }));
  }
  getFeatures() {
    return this.http.get<any>(`${environment.apiUrl}v1/configuration/features`).pipe(map((res => {
      return res;
    })));
  }

}
