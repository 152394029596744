import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunicationService } from 'src/app/services/communication.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-edit-country',
  templateUrl: './add-edit-country.component.html',
  styleUrls: ['./add-edit-country.component.scss']
})
export class AddEditCountryComponent implements OnInit {
  detailsAddEditCountryForm: FormGroup;
  countryId: any;
  countryDetails: any = null;
  countryName: any;
  isDisabled: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    private _Activatedroute: ActivatedRoute,
    private router: Router,
    public  translate:  TranslateService,
  ) { }

  ngOnInit(): void {
    this.countryId = this._Activatedroute.snapshot.paramMap.get('countryid');
    this.detailsAddEditCountryForm = this.formBuilder.group(
      {
        country: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9\-\/\(\)\,\. ]*')])
      }
    );
    if (this.countryId != null) {
      this.getCountryDetails(this.countryId);
    }
  }

  get country() {
    return this.detailsAddEditCountryForm.get('country');
  }

  getCountryDetails(countryId) {
    this.communicationService.getCountryById(countryId).subscribe(res => {
      this.countryDetails = res;
     this.countryName = res.country_name;
  }, error => {
    console.log(error);
  });
  }
  onSubmit(event) {
    if (this.detailsAddEditCountryForm.value.country) {
      this.isDisabled = true;
      const request = {
        country_id: this.countryId != null ? this.countryId : null,
        country_name: this.detailsAddEditCountryForm.value.country == '' ? this.countryName : this.detailsAddEditCountryForm.value.country,
        status: '0'
      };
      this.communicationService.addCountry(request).subscribe(res => {
          this.toastr.success(this.translate.instant('toasterSuccessMessage.countryAdded'));
          this.router.navigate(['/view-country']);
      }, error => {
        this.toastr.error(error.error.message);
      });
    } else {
      this.isDisabled = false;
      this.toastr.error(this.translate.instant('addeditcountry.countryNameErrorMsg'));
    }
  }

  get f() {
    return this.detailsAddEditCountryForm.controls;
  }

  onCancel(event:any) {
    this.isDisabled = true;
    this.router.navigate(['/view-country']);
  }

}
