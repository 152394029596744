import {Component, OnInit, Input, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {CommunicationService} from 'src/app/services/communication.service';
import {userRoles} from '../../helper/userRoles';
import {TranslateService} from '@ngx-translate/core';
import {entityFeatures} from 'src/app/helper/entityFeatures';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {FirstAidBookingsComponent} from '../../pages/first-aid-bookings/first-aid-bookings.component';
import {title} from 'process';


declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  group?: {
    path: string;
    title: string;
    icon: string;
    class: string;
  }[];
}

export const ROUTES: RouteInfo[] = [
  {path: '/dashboard', title: 'Dashboard', icon: 'home-24', class: ''},
  {path: '/super-user-dashboard', title: 'Super User Dashboard', icon: 'home-24', class: ''},
  {path: '/admin-dashboard', title: 'Admin Dashboard', icon: 'home-24', class: ''},
  {
    path: '/view-bookings', title: 'Booking', icon: 'repair-24', class: '',
    group: [{path: '/book-seat', title: 'Book a Workstation', icon: 'book-closed-24', class: ''},
      /*{ path: '/book-seat-au', title: 'Book a Workstation AU',  icon: 'book-closed-24', class: '' },*/
      {path: '/auto-assign-booking', title: 'Auto Assign Booking', icon: 'forward-24', class: ''},
      {path: '/express-booking', title: 'Express Booking', icon: 'train-24', class: ''},
      /* { path: '/qr-code-booking', title: 'QR Code Booking',  icon:'success-filled-32', class: '' }, */
      /* {path: '/view-bookings', title: 'Bookings List', icon: 'calendar-24', class: ''},*/
      /*{ path: '/fire-warden-bookings', title: 'Fire Warden Bookings',  icon: 'success-24', class: '' },
      { path: '/first-aid-bookings', title: 'First Aid Bookings',  icon: 'success-24', class: '' }*/
    ]
  },
  {path: '/search-employee-desk', title: 'Locate', icon: 'search-24', class: ''},
  {path: '/booking-by-date', title: 'Check Availability', icon: 'success-24', class: ''},
  {path: '/users', title: 'Users', icon: 'user-group-24', class: ''},
  {
    path: '/disable-floor', title: 'Space Management', icon: 'gears-24', class: '',
    group: [{path: '/first-aid', title: 'First Aid Responders', icon: 'service-bell-24', class: ''},
      {path: '/disable-floor', title: 'Enable/Disable Wing', icon: 'elevator-24', class: ''},
      // { path: '/enable-disable-seat', title: 'Enable/Disable Workstation',  icon:'ni-active-40 text-blue', class: '' },
      {path: '/view-workstations', title: 'Workstations', icon: 'cast-24', class: ''},
      {path: '/rule-configuration', title: 'Capacity Management', icon: 'checklist-24', class: ''},
      /*{path: '/view-zoo-pass', title: 'View Zoo Pass', icon: 'sparkle-24', class: ''},
      {path: '/view-parkings', title: 'View Parking', icon: 'car-24', class: ''}*/]
  },
  {
    path: '/view-country', title: 'Configuration', icon: 'settings-24', class: '',
    group: [{path: '/view-country', title: 'View Country', icon: 'network-24', class: ''},
      {path: '/view-organisation', title: 'View Organisation', icon: 'grid-square-24', class: ''},
      {path: '/view-department', title: 'View Department', icon: 'clipboard-24', class: ''},
      {path: '/view-sub-department', title: 'View Sub-Department', icon: 'clipboard-24', class: ''},
      {path: '/add-edit-sub-department', title: 'Add Edit Sub-Department', icon: 'clipboard-24', class: ''},
      {path: '/view-city', title: 'View City', icon: 'global-24', class: ''},
      {path: '/view-building', title: 'View Building', icon: 'factory-24', class: ''},
      {path: '/view-floor', title: 'View Floor', icon: 'user-manual-24', class: ''},
      {path: '/view-wing', title: 'View Wing', icon: 'minus-24', class: ''},
      {path: '/add-workstation', title: 'Add Workstation', icon: 'add-24', class: ''},
      {path: '/create-qr-code', title: 'Create-Qr-Code', icon: 'add-24', class: ''},
    ]
  },
  {path: '/audit-logs', title: 'Audit logs', icon: 'diagram-bars-24', class: ''},
  {path: '/help', title: 'Help', icon: 'help-24', class: ''},
  {path: '/logout', title: 'Logout', icon: 'logout-24', class: ''}

];

@Component({
  selector: 'app-group-sidebar',
  templateUrl: './group-sidebar.component.html',
  styleUrls: ['./group-sidebar.component.scss']
})
export class GroupSidebarComponent implements OnInit {

  @Input() navPosition: boolean;

  public menuItems: any[];
  public isCollapsed = true;

  adminMenu = this.translate.instant('sidebar.spaceManagement');
  bookingMenu = this.translate.instant('sidebar.booking');
  configMenu = this.translate.instant('sidebar.configuration');

  showAdminMenus = false;
  showBookingMenus = false;
  showConfigMenus = false;

//  collapseNav = false;
  currentUser: any;
 // nestedMenuItems: any[];
  features: any[];

  constructor(private router: Router,
              public translate: TranslateService,
              public communicationService: CommunicationService) {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser') || '{}');
    let dept = this.currentUser.department;
    this.communicationService.getFeatureByDept(dept).subscribe(res => {
      this.features = res;
      if (this.features.length !== 0) {
        let right = this.checkRouting();
        if (!right) {
          this.features.forEach(feature => {
            if (entityFeatures.BOOKWORKSPACE.includes(feature.feature_name)) {
              ROUTES[3].group.push({
                path: '/book-seat-au',
                title: 'Book Workspace/Parking',
                icon: 'book-closed-24',
                class: ''
              });
              ROUTES[3].group = ROUTES[3].group.filter((route) => route.title !== 'Book a Workstation');
              ROUTES[3].group = ROUTES[3].group.filter((route) => route.title !== 'Auto Assign Booking');
              ROUTES[3].group = ROUTES[3].group.filter((route) => route.title !== 'Express Booking');
            } else if ([entityFeatures.ZOOPASS].includes(feature.feature_name)) {
              ROUTES[3].group.push({path: '/book-zoopass', title: 'Book Zoo Pass', icon: 'sparkle-24', class: ''});
              ROUTES[7].group.push({path: '/view-zoo-pass', title: 'View Zoo Pass', icon: 'sparkle-24', class: ''});
            } else if (entityFeatures.CARPARKING.includes(feature.feature_name)) {
              ROUTES[3].group.push({path: '/book-car-parking', title: 'Book Car Parking', icon: 'car-24', class: ''});
              ROUTES[7].group.push({path: '/view-parkings', title: 'View Parking', icon: 'car-24', class: ''});
            } else if (entityFeatures.FOODBOOKING.includes(feature.feature_name)) {
              ROUTES[3].group.push({path: '/book-food', title: 'Book a Food', icon: 'book-closed-24', class: ''},);
            } else if (entityFeatures.CHECKAVAILABILITYAU.includes(feature.feature_name)) {
              ROUTES[5] = {path: '/booking-by-date-au', title: 'Check Availability', icon: 'success-24', class: ''};
              ROUTES[4] = {path: '/search-employee-desk-au', title: 'Locate', icon: 'search-24', class: ''};
            } else if (entityFeatures.FLOORRESPONDER.includes(feature.feature_name)) {
              ROUTES[3].group.push({
                path: '/fire-warden-bookings',
                title: 'Fire Wardens Bookings',
                icon: 'security-attention-24',
                class: ''
              });
              ROUTES[3].group.push({
                path: '/first-aid-bookings',
                title: 'First Aid Bookings',
                icon: 'thermometer-24',
                class: ''
              });
              ROUTES[7].group.push({path: '/responders', title: 'Responders', icon: 'success-24', class: ''});
              ROUTES[7].group = ROUTES[7].group.filter((route) => route.title !== 'First Aid Responders');
            }
          });
         /* ROUTES[2].group.push({
            path: '/view-bookings',
            title: 'Bookings List',
            icon: 'book-closed-24',
            class: ''
          });*/
        }
      }
      let isPresent = false;
      ROUTES[3].group.forEach(ele => {
        if (ele.path == '/view-bookings') {
          isPresent = true;
        }
      });
      if (!isPresent) {
        ROUTES[3].group.push({
          path: '/view-bookings',
          title: 'Bookings List',
          icon: 'book-closed-24',
          class: ''
        });
      }

      this.handleRouteVar();
    }, error => {
      this.handleRouteVar();
    });
  }

  checkRouting() {
    let check = false;
    ROUTES[3].group.forEach(ele => {
      if (ele.path == '/book-zoopass' || ele.path == '/book-car-parking' || ele.path == '/book-food') {
        check = true;
      }
      if (ele.path == '/book-seat-au') {
        check = true;
      }
    });
    if (ROUTES[5].path == '/booking-by-date-au' || ROUTES[4].path == '/search-employee-desk-au') {
      check = true;
    }
    return check;
  }

  handleRouteVar() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser') || '{}');
    //this.getUserDepartent();

    if ([userRoles.ADMIN, userRoles.SUPERADMIN].includes(this.currentUser.role) === false) {
      this.menuItems = this.menuItems.filter((route) => route.title !== 'Configuration');
      this.menuItems = this.menuItems.filter((route) => route.title !== 'Audit logs');
    }

    /* if ([userRoles.ADMIN, userRoles.DEPARTMENTHEAD, userRoles.MANAGER, userRoles.HR, userRoles.EMPLOYEE].includes(this.currentUser.role) === false) {
       this.menuItems = this.menuItems.filter((route) => route.title !== 'Dashboard');
     }*/

    if ([userRoles.ADMIN, userRoles.DEPARTMENTHEAD, userRoles.MANAGER, userRoles.HR, userRoles.EMPLOYEE].includes(this.currentUser.role) === true) {
      this.menuItems = this.menuItems.filter((route) => route.title !== 'Super User Dashboard');
    }

    if ([userRoles.SUPERADMIN, userRoles.ADMIN, userRoles.DEPARTMENTHEAD,userRoles.HR].includes(this.currentUser.role) === false) {
      this.menuItems = this.menuItems.filter((route) => route.title !== 'Users');
      this.menuItems = this.menuItems.filter((route) => route.title !== 'Space Management');
      this.menuItems = this.menuItems.filter((route) => route.title !== 'Admin Dashboard');
    }

    if ([userRoles.ADMIN].includes(this.currentUser.role) === true) {
      this.menuItems = this.menuItems.filter((route) => route.title !== 'Super User Dashboard');
      this.menuItems[7].group = this.menuItems[7].group.filter((route) => route.title !== 'View Organisation');
      this.menuItems[7].group = this.menuItems[7].group.filter((route) => route.title !== 'View Department');
      this.menuItems[7].group = this.menuItems[7].group.filter((route) => route.title !== 'View Sub-Department');
      this.menuItems[7].group = this.menuItems[7].group.filter((route) => route.title !== 'View Country');
      this.menuItems[7].group = this.menuItems[7].group.filter((route) => route.title !== 'View City');
    }
    this.translateMenuList();
  }

  ngOnInit() {

  }

  /*getUserDepartent() {
    let dept = this.currentUser.department;
    // this.communicationService.getFeatureByDept(dept).subscribe(res => {
    //   this.features = res;

    //   if (this.features.length !== 0) {
    //     this.features.forEach(feature => {
    //       if ([entityFeatures.FOODBOOKING].includes(feature.feature_name) === false) {
    //         this.menuItems[1].group = this.menuItems[1].group.filter((route) => route.title !== 'Book a Food');
    //       }
    //       if ([entityFeatures.ZOOPASS].includes(feature.feature_name) === false) {
    //         this.menuItems[1].group = this.menuItems[1].group.filter((route) => route.title !== 'Book Zoo Pass');
    //       }
    //       if ([entityFeatures.CARPARKING].includes(feature.feature_name) === false) {
    //         this.menuItems[1].group = this.menuItems[1].group.filter((route) => route.title !== 'Book Car Parking');
    //       }
    //       if ([entityFeatures.VIEWZOOPASS].includes(feature.feature_name) === false) {
    //         this.menuItems[7].group = this.menuItems[7].group.filter((route) => route.title !== 'View Zoo Pass');
    //       }
    //       if ([entityFeatures.VIEWPARKING].includes(feature.feature_name) === false) {
    //         this.menuItems[7].group = this.menuItems[7].group.filter((route) => route.title !== 'View Parking');
    //       }
    //       if ([entityFeatures.CHECKAVAILABILITYAU].includes(feature.feature_name) === false) {
    //         this.menuItems[4].group = this.menuItems[4].group.filter((route) => route.title !== 'Check Availability');
    //       }
    //       else{
    //         this.menuItems[3].group = this.menuItems[3].group.filter((route) => route.title !== 'Check Availability');
    //       }

    //     })
    //   } else {
    //     this.menuItems[1].group = this.menuItems[1].group.filter((route) => route.title !== 'Book a Food');
    //     this.menuItems[1].group = this.menuItems[1].group.filter((route) => route.title !== 'Book Zoo Pass');
    //     this.menuItems[1].group = this.menuItems[1].group.filter((route) => route.title !== 'Book Car Parking');
    //     this.menuItems[7].group = this.menuItems[7].group.filter((route) => route.title !== 'View Zoo Pass');
    //     this.menuItems[7].group = this.menuItems[7].group.filter((route) => route.title !== 'View Parking');
    //     this.menuItems[4].group = this.menuItems[4].group.filter((route) => route.title !== 'Check Availability');

    //   }
    // });
  }*/

  translateMenuList() {

    this.menuItems.forEach(item => {
        switch (item.title) {
          case 'Dashboard':
            item.title = this.translate.instant('sidebar.dashBoard');
            break;
          case 'Super User Dashboard':
            item.title = this.translate.instant('Super User Dashboard');
            break;
          case 'Booking':
            item.title = this.translate.instant('sidebar.booking');
            item.group.forEach((res: { title: any; }) => {
              switch (res.title) {
                case 'Book a Workstation':
                  res.title = this.translate.instant('sidebar.bookingOptions.bookWorkstation');
                  break;
                case 'Book Workstation':
                  res.title = this.translate.instant('sidebar.bookingOptions.bookWorkstation');
                  break;
                case 'Express Booking':
                  res.title = this.translate.instant('sidebar.bookingOptions.expressBooking');
                  break;
                case 'Auto Assign Booking':
                  res.title = this.translate.instant('sidebar.bookingOptions.autoAssignBooking');
                  break;
                case 'Book a Food':
                  res.title = this.translate.instant('sidebar.bookingOptions.bookFood');
                  break;
                case 'Bookings List':
                  res.title = this.translate.instant('sidebar.bookingOptions.bookingList');
                  break;
              }
            });
            break;
          case 'Locate':
            item.title = this.translate.instant('sidebar.locate');
            break;
          /*case 'Locate-AU':
            item.title = this.translate.instant('sidebar.locate-au');
            break;*/
          case 'Check Availability':
            item.title = this.translate.instant('sidebar.availability');
            break;
          /*case 'Check Availability': item.title = this.translate.instant('sidebar.availabilityAU'); break;*/
          case 'Users':
            item.title = this.translate.instant('sidebar.users');
            break;
          case 'Space Management':
            item.title = this.translate.instant('sidebar.spaceManagement');
            item.group.forEach((res: { title: any; }) => {
              switch (res.title) {
                case 'Enable/Disable Wing':
                  res.title = this.translate.instant('sidebar.spaceManagementOptions.enableDisableWing');
                  break;
                case 'Enable/Disable Workstation':
                  res.title = this.translate.instant('sidebar.spaceManagementOptions.enableDisableWorkstation');
                  break;
                case 'Capacity Management':
                  res.title = this.translate.instant('sidebar.spaceManagementOptions.capacityManagement');
                  break;
                case 'Workstations':
                  res.title = this.translate.instant('sidebar.spaceManagementOptions.viewWorkstation');
                  break;
                case 'First Aid Responders':
                  res.title = this.translate.instant('sidebar.spaceManagementOptions.firstAidResponder');
                  break;
              }
            });
            break;
          case 'Configuration':
            item.title = this.translate.instant('sidebar.configuration');
            item.group.forEach((res: { title: any; }) => {
              switch (res.title) {
                case 'View Department':
                  res.title = this.translate.instant('sidebar.configurationOptions.viewDepartment');
                  break;
                case 'View Sub-Department':
                  res.title = this.translate.instant('sidebar.configurationOptions.viewSubDepartment');
                  break;
                case 'View Country':
                  res.title = this.translate.instant('sidebar.configurationOptions.viewcountry');
                  break;
                case 'View City':
                  res.title = this.translate.instant('sidebar.configurationOptions.viewcity');
                  break;
                case 'View Organisation':
                  res.title = this.translate.instant('sidebar.configurationOptions.viewOrganisation');
                  break;
                case 'View Building':
                  res.title = this.translate.instant('sidebar.configurationOptions.viewbuilding');
                  break;
                case 'View Floor':
                  res.title = this.translate.instant('sidebar.configurationOptions.viewFloor');
                  break;
                case 'View Wing':
                  res.title = this.translate.instant('sidebar.configurationOptions.viewWing');
                  break;
                case 'Add Workstation':
                  res.title = this.translate.instant('sidebar.configurationOptions.addWorkstation');
                  break;
              }
            });
            break;
          case 'Audit logs':
            item.title = this.translate.instant('sidebar.logs');
            break;
          case 'Help':
            item.title = this.translate.instant('sidebar.help');
            break;
          case 'Logout':
            item.title = this.translate.instant('sidebar.logout');
            break;
        }
      }
    );
  }

  showHideMenu() {
    this.communicationService.showHideMenuBar = false;
  }

}
