import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map, mapTo, tap} from 'rxjs/operators';

import { User } from '../model/user';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginComponent } from '../pages/login/login.component';
import {OidcSecurityService} from 'angular-auth-oidc-client';

@Injectable({ providedIn: 'root' })
export class AccountService {
    public currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    loginUser: any;

    constructor(private http: HttpClient, private router: Router,
                private oidcSecurityService: OidcSecurityService) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(data: any,token:any):Observable<any> {
        this.clearCreds();
        return this.http.post<any>(`${environment.apiUrl}v1/user/authenticate`, data, { headers: new HttpHeaders({
          Authorization: 'Bearer ' + token,
        })})
          .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user) {
                  this.loginUser = user;
                  sessionStorage.setItem('currentUser', JSON.stringify(user));
                  sessionStorage.setItem('token', token);
                  this.currentUserSubject.next(user);
                }
                return user;
            }))
            
    }

    clearCreds() {
        // remove user from local storage to log user out
        sessionStorage.clear();
        sessionStorage.removeItem('currentUser');
        // localStorage.removeItem('UserDeatils');
        // localStorage.removeItem('refreshToken');
        this.currentUserSubject.next(null);

    }

    storeTokens(user) {
      this.loginUser = user;
      sessionStorage.setItem('currentUser', JSON.stringify(user));
      // this.refreshToken = user.refreshToken;
    }

    /*setRefreshToken() {
        // let refrehToken = JSON.parse(localStorage.getItem('refreshToken'));
        /!*if(refrehToken ){
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${refrehToken}`
                }
            });
        }*!/

        return this.http.post<any>(`${environment.apiUrl}v1/user/refreshtoken`, {
        })
         .pipe(map(user => {
            if (user ) {
                this.storeTokens(user);
                this.reloadCurrentRoute();
            }
            return user;
        }, error => {
            this.logout();
          }
        ));
    }*/
   /* reloadCurrentRoute() {
        const currentUrl = this.router.url;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate([currentUrl]);
        });
    }*/

  refreshToken() {
    // return this.http.get(`${environment.apiUrl}v1/user/refreshtoken`);
    return this.oidcSecurityService.getRefreshToken();
  }

  logOff() {
    this.oidcSecurityService.logoff();
  }

  onLogOut() {
    this.currentUserSubject.next(null);
    sessionStorage.clear();
    sessionStorage.removeItem('currentUser');
    /*this.oidcSecurityService.logoff();*/
  }

  logout() {
    this.oidcSecurityService.logoffAndRevokeTokens();
    this.onLogOut();
    // return this.http.get<any>(`${environment.apiUrl}v1/user/logout`)
    //   .pipe(
    //     tap(() => this.onLogOut()),
    //     mapTo(true),
    //     catchError(_error => {
    //       return of(false);
    //     })
    //   )
  }
}


