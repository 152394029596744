import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunicationService } from 'src/app/services/communication.service';
import { ConfirmationDialogService } from 'src/app/pages/confirmation-dialog/confirmation-dialog.service';
import { Coordinate } from 'src/app/model/coordinate';
import {  Component,  OnInit, LOCALE_ID, Inject, ViewChild,TemplateRef, ElementRef } from '@angular/core';
import { formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import {take} from 'rxjs/operators';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FloorPath } from 'src/app/model/floorPath';
import * as CryptoJS from 'crypto-js';
import { User } from 'src/app/model/user';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-book-car-parking',
  templateUrl: './book-car-parking.component.html',
  styleUrls: ['./book-car-parking.component.css']
})
export class BookCarParkingComponent implements OnInit {

  @ViewChild('canvas', {static: true})
  canvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('tooltip', {static: true})
  tooltip: ElementRef<HTMLCanvasElement>;

  @ViewChild('mymodal')
  private defaultTabButtonsTpl: TemplateRef<any>;

  @ViewChild('modalComment')
  private modalComment: TemplateRef<any>;

  detailsCarParkingForm: FormGroup;
  zones = [];
  hideTooltip = false;
  zoneBooking: any =true;
  thumbnail: any;
  todayDate;
  employeeNo: any;
  isCarParking = false;
  corpId: any;
  user: User;
  searchResult = [];
  searchResultSeats = [];
  isSelfBooking = true;
  keySize = 256;
  iterations = 1000;
  limit = 30;
  startIndex = 0;
  hideElement = true;
  bookingTimeList: any;
  selectedZoneMessage: any;
  userName: any;
  submitted:boolean;
  isDisabled:boolean=false;
  mymodal:any;
  isBookByTime = false;
  loading:any;
  floorPlanImage: HTMLImageElement;
  isOnSubmit: boolean = false;
  floorPlanCtx: CanvasRenderingContext2D;
  tooltipCtx: CanvasRenderingContext2D;
  bookingList: Coordinate[] = [];
  selectedSeat: any;
  coordinates: Coordinate[] = [];
  isMeetingRoom: boolean;
  isMeetingRoomBook: boolean;
  userIdList: any[];
  isUserDataEmpty: boolean;
  modalReference: any;
  totalUserCount: number;
  heroes$: BehaviorSubject<any[]>;
  tableDataSource$ = new BehaviorSubject<any[]>([]);
  currentPage$ = new BehaviorSubject<number>(1);
  pageSize$ = new BehaviorSubject<number>(10);
  dataOnPage$ = new BehaviorSubject<any[]>([]);
  sortKey$ = new BehaviorSubject<string>('start_date_time');
  sortDirection$ = new BehaviorSubject<string>('asc');
  searchFormControl = new FormControl();
  displayedColumns$ = new BehaviorSubject<string[]>([
    'emp_name',
    'start_date',
    'end_date',
    'details',
    'id',
  ]);
  is_selected: boolean = false;
  selectedSeats: any;
  isFuturePreference: boolean = false;
  parkingWings=[];
  wing: string;
  floor: string;
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private confirmationDialogService: ConfirmationDialogService,
    private toastr: ToastrService,
    public translate: TranslateService,
    private modalService: NgbModal,
    private router: Router) {
  }

  ngOnInit(): void {
    this.detailsCarParkingForm = this.formBuilder.group({
      zone: ['', Validators.required],
      employee_id: ['', Validators.required],
      wing: ['', Validators.required],
      seat_id: [''],
      workstationName : [''],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      comments: new FormControl('', [Validators.pattern('[a-zA-Z0-9 ]*'), Validators.maxLength(250)]),
      selfBooking: ['', Validators.required],
      zoneBooking: ['', Validators.required],
      type: ['', Validators.required],
      employee_name: [''],
      empName:['']

    });

    const currentDate = new Date();
    const objCurrentDate = { year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate() };
    this.todayDate = objCurrentDate;
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser.id) {
      this.communicationService.getUserDetails(currentUser.id).subscribe(res => {
        this.detailsCarParkingForm.controls.employee_name.setValue(res.firstName + ' ' + res.lastName);
        this.detailsCarParkingForm.controls.employee_id.patchValue(res.username);
        this.userName = res.username;
      }, error => {
        console.log('error', error);
      });
    }
    this.floorPlanCtx = this.canvas.nativeElement.getContext('2d');

    this.detailsCarParkingForm.controls.startDate.setValue(this.todayDate);
    this.detailsCarParkingForm.controls.endDate.setValue(this.todayDate);
    this.getZones(this.detailsCarParkingForm.value.startDate,
      this.detailsCarParkingForm.value.endDate);
    this.getParkingSlots();
  }

  get comments() {
    return this.detailsCarParkingForm.get('comments');
  }

  get f() {
    return this.detailsCarParkingForm.controls;
  }

  onDateChange(event) {
    this.detailsCarParkingForm.controls.endDate.setValue(this.detailsCarParkingForm.value.startDate);
    this.getZones(this.detailsCarParkingForm.value.startDate, this.detailsCarParkingForm.value.endDate);
    this.getCoords();
    this.onClear()
  }

  onEndDateChange(event) {
    this.getZones(this.detailsCarParkingForm.value.startDate, this.detailsCarParkingForm.value.endDate);
  }

  async getCoords() {
    const startDateVal = formatDate(this.convertDate(this.detailsCarParkingForm.value.startDate),
      'yyyy-MM-dd', this.locale);
    const endDateVal = formatDate(this.convertDate(this.detailsCarParkingForm.value.endDate),
      'yyyy-MM-dd', this.locale);
    const coordinates = {
      start_date_time: this.isBookByTime ? startDateVal + this.bookingTimeList[this.detailsCarParkingForm.get('startTime').value].timeFormat :
        startDateVal + 'T00:00:00',
      end_date_time: this.isBookByTime ? endDateVal + this.bookingTimeList[this.detailsCarParkingForm.get('endTime').value].timeFormat :
        endDateVal + 'T23:59:59',
      floor_id: this.floor,
      wing_id:  this.wing,
    };
    this.communicationService.getSeats(coordinates).subscribe(res => {
      // let workstations = [];
      // for (let obj of res) {
      //   // if (obj.status === '0') {
      //   //   workstations.push(obj);
      //   // }
      // }
      this.coordinates = res;
      if (res != null) {
        this.loadMapBorder();
      }
    }, error => {
      console.error(error);
    });
  }

  getZones(date, endDate) {
    let sDate;
    if (date) {
      sDate = this.convertDate(date);
    }
    let sDateFormatted;
    if (sDate) {
      sDateFormatted = formatDate(sDate, 'yyyy-MM-dd', this.locale);
    }

    let eDate;
    if (endDate) {
      eDate = this.convertDate(endDate);
    }
    let eDateFormatted;
    if (eDate) {
      eDateFormatted = formatDate(sDate, 'yyyy-MM-dd', this.locale);
    }

    this.communicationService.getZones(sDateFormatted, eDateFormatted).subscribe(res => {
      this.zones = res;
      this.f.zone.setValue(res[0].zone_id);
      this.selectedZoneMessage = 'Please only park in the White colour coded spaces';
    }, error => {
      console.error(error);
    });
  }

  onCarParkingChange(event) {
    if (event.target.checked) {
      this.isCarParking = true;
    } else {
      this.isCarParking = false;
    }
  }

  getSearchUserData(data: any) {
    let myForm = this.detailsCarParkingForm.value.employee_name;
    if (typeof (data) === 'object') {
      myForm = data.displayName;
      this.corpId = data.corpId;
      this.detailsCarParkingForm.controls['employee_name'].setValue(myForm);
      this.searchResult.length = 0;
      this.user = new User();
      this.user.email = data.email;
      this.user.firstName = data.firstName;
      this.user.lastName = data.lastName;
      this.user.username = data.username;
      this.user.department = data.department;
    }
  }

  deselectZoneBooking(event){
    this.getFloorWings(this.parkingWings[0].floor_id , this.parkingWings[0].wing_id);
    this.onClear();
    if (event.target.checked) {
      this.zoneBooking = false;
    } else {
      this.zoneBooking = true;
    }
  }

  onWingChange(value) {
    let wing_id = value.target.value , floor_id;
    for(let i = 0; i < this.parkingWings.length; i++) {
      if(this.parkingWings[i].wing_id == wing_id) {
              wing_id= this.parkingWings[i].wing_id,
              floor_id= this.parkingWings[i].floor_id
      }
    }
    this.getFloorWings(floor_id, wing_id);
  }

  getParkingSlots(){
    let floorId, wingId;
    this.communicationService.getParkingFloorWings().subscribe(res => {
      this.parkingWings = []
      for (const wing of res) {
        if (wing.status === '0') {
          this.parkingWings.push(wing);
        }
      }
      if (this.parkingWings.length > 0) {
        this.parkingWings.sort( (a, b) => {
          if ( a.wing_name < b.wing_name) { return -1; }
          if (a.wing_name > b.wing_name) { return 1 ; }
          return 0;
        });
        this.parkingWings.sort( (a, b) => a.wing_name - b.wing_name);
        console.log(this.parkingWings);
        this.f.wing.setValue(this.parkingWings[0].wing_id);
      } else {
        this.f.wing.setValue('');
      }
    }, error => {
      console.log('error', error);
    });
    if(this.parkingWings.length >0){
      this.getFloorWings(this.parkingWings[0].floor_id , this.parkingWings[0].wing_id);
    }
  }

  deselectSelfBooking(event) {
    if (event.target.checked) {
      this.isSelfBooking = false;
    } else {
      this.isSelfBooking = true;
    }
  }

  onSubmitBook(confirmed: any, content) {
    let is_self = true;
    let zone_id_value;
    if (confirmed) {
      zone_id_value =  this.detailsCarParkingForm.value.zone;

      let userRequestObj = {};
      if (this.user) {
        userRequestObj = {
          username: this.user.username,
          first_name: this.user.firstName,
          last_name: this.user.lastName,
          role: '5',
          email: this.user.email,
          sub_department_name: this.user.department
        };
        is_self = false;
      }
      let startDateVal = formatDate(this.convertDate(this.detailsCarParkingForm.value.startDate), 'yyyy-MM-dd', this.locale);
      let endDateVal = formatDate(this.convertDate(this.detailsCarParkingForm.value.endDate), 'yyyy-MM-dd', this.locale);
      if (startDateVal <= endDateVal) {
        const bookSeatObj = {
          start_date_time: startDateVal + 'T00:00:00',
          end_date_time: endDateVal + 'T23:59:59',
          emp_id: this.userName,
          status: 0,
          comments: this.detailsCarParkingForm.value.comments,
          user_request: userRequestObj,
          is_self,
          zone_id : zone_id_value,
          email_notification : true
        };
        this.communicationService.bookCarParking(bookSeatObj).subscribe(res => {
            this.toastr.success('Car Parking booked successfully');
            this.router.navigate(['/view-bookings']);
          },
          error => {
            console.log('error', error);
            this.toastr.error(error.error.message, 'Failed to Book Car Parking');
          });
      } else {
        this.toastr.error('Invalid Booking Date Selection');
      }
    }
  }


  onZoneChange(event) {
    switch (event.target.value) {
      case '1':
        this.selectedZoneMessage = 'Please only park in the White color coded spaces.';
        break;
      case '2' :
        this.selectedZoneMessage = 'Please only park in the Blue color coded spaces.';
        break;
      case '4':
        this.selectedZoneMessage = 'Please only park on level (P2)';
        break;
      default:
        this.selectedZoneMessage = '';
    }
  }

  public openConfirmationDialog(mymodal:any) {
    if (this.detailsCarParkingForm.value.zone) {
      const sDate = formatDate(this.convertDate(this.detailsCarParkingForm.value.startDate),
        'yyyy-MM-dd', this.locale);
      const eDate = formatDate(this.convertDate(this.detailsCarParkingForm.value.endDate),
        'yyyy-MM-dd', this.locale);
      this.confirmationDialogService.confirm('Book Car Parking Confirmation',
        'Are you sure you want to book Car Parking  from ' + sDate + ' to ' + eDate + '?')
        .then((confirmed) => this.onSubmitBook(confirmed, mymodal));
    } else {
      this.toastr.error('Zone is not selected');
    }
  }

  convertDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  }

  getSearchResult = (val) => {
    let queryData = val.target.value;
    const inp = String.fromCharCode(val.keyCode);
    if (/[a-zA-Z0-9-_ @.]/.test(inp)) {
      queryData = val.target.value + val.key;
    }
      if (queryData.length >= 4 && queryData.length <= 15) {
            this.communicationService.getSearchResult(queryData).subscribe(res => {
              if (res.employeeList.users.length === 0 && queryData.length <= 4) {
                this.toastr.error('Search User not found.');
              }
              this.searchResult = res.employeeList.users;
            }, error => {
              this.searchResult.length = 0;
              if (queryData.length <= 4) {
                this.toastr.error('Search User not found.');
              }
            });
      } else {
            this.searchResult.length = 0;
      }
  }

  getSearchResultSeats = (val) => {
    const queryData = val.target.value;
    if (queryData.length >= 4) {
      this.communicationService.getSeatsFilter(this.limit, this.startIndex, queryData).subscribe(res => {
        this.searchResultSeats = res;
      }, error => {
        this.searchResultSeats.length = 0;
        console.log('Getting error while searching result for seats');
      });
    } else {
      this.searchResultSeats.length = 0;
    }
  }


  decrypt(transitmessage, pass) {

    const hexResult = this.base64ToHex(transitmessage);

    const salt = CryptoJS.enc.Hex.parse(hexResult.substr(0, 64));
    const iv = CryptoJS.enc.Hex.parse(hexResult.substr(64, 32));
    const encrypted = this.hexToBase64(hexResult.substring(96));

    const key = CryptoJS.PBKDF2(pass, salt, {
      keySize: this.keySize / 32,
      iterations: this.iterations
    });

    const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC

    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  hexToBase64(str) {
    return btoa(String.fromCharCode.apply(null,
      str.replace(/\r|\n/g, '').replace(/([\da-fA-F]{2}) ?/g, '0x$1 ').replace(/ +$/, '').split(' '))
    );
  }


  base64ToHex(str) {
    const hex = [];
    const bin = atob(str.replace(/[ \r\n]+$/, ''));
    let i = 0;
    for (; i < bin.length; ++i) {
      let tmp = bin.charCodeAt(i).toString(16);
      if (tmp.length === 1) { tmp = '0' + tmp; }
      hex[hex.length] = tmp;
    }
    return hex.join('');
  }

  getFloorWings(floor_id: string, wing_id: string) {
    this.floor = floor_id , this.wing = wing_id;
    this.getCoords();
    this.communicationService.getFloorWings(floor_id, wing_id).subscribe(res => {
      const objectURL = 'data:image/png;base64,' + res.image_path;
      const image = new Image();
      image.src = objectURL;
      this.floorPlanImage = image;
      image.onload = (() => this.fitToCanvas(image));
    }, error => {
      this.loading = false;
      if (this.isOnSubmit == true) {
        this.toastr.error(error.error.message, 'Failed to load map');
      }
    });
  }

  fitToCanvas(image) {
    this.floorPlanCtx.canvas.width = image.width;
    this.floorPlanCtx.canvas.height = image.height;
    this.floorPlanCtx.drawImage(image, 0, 0);
    this.highlightSelected();
    this.loadMapBorder();
  }

  highlightSelected() {
    this.coordinates.map((element) => {
      if (element.is_common) {
        if (element.shape === 0) {
          this.floorPlanCtx.fillStyle = 'rgba(232,232,232, 0.8)';
          this.floorPlanCtx.fillRect(element.x, element.y, element.w, element.h);
          this.floorPlanCtx.strokeStyle = '#000000';
          this.floorPlanCtx.strokeRect(element.x, element.y, element.w, element.h);
        } else {
          this.floorPlanCtx.fillStyle = 'rgba(232,232,232, 0.8)';
          this.floorPlanCtx.strokeStyle = '#000000';
          this.plotPolygon(element);
        }

      } else if (element.selected) {
        if (element.shape === 0) {
          this.floorPlanCtx.fillStyle = '#A8ADB3';
          this.floorPlanCtx.fillRect(element.x, element.y, element.w, element.h);
          this.floorPlanCtx.strokeStyle = '#d4d6d9';
          this.floorPlanCtx.strokeRect(element.x, element.y, element.w, element.h);
        } else {
          this.floorPlanCtx.fillStyle = '#A8ADB3';
          this.floorPlanCtx.strokeStyle = '#d4d6d9';
          this.plotPolygon(element);
        }
      }else if(element.status=='1'){
        if (element.shape === 0) {
          this.floorPlanCtx.fillStyle = '#e5e3e7';
          this.floorPlanCtx.fillRect(element.x, element.y, element.w, element.h);
          this.floorPlanCtx.strokeStyle = '#e5e3e7';
          this.floorPlanCtx.strokeRect(element.x, element.y, element.w, element.h);
        } else {
          this.floorPlanCtx.fillStyle = '#e5e3e7';
          this.floorPlanCtx.strokeStyle = '#e5e3e7';
          this.plotPolygon(element);
        }
      }
    });
    this.loading = false;
  }

  loadMapBorder() {
    this.bookingList = [];
    const rB = this.floorPlanCtx.canvas.getBoundingClientRect();
    this.coordinates.map((element) => {
      this.floorPlanCtx.strokeStyle = '#006400';
      this.floorPlanCtx.strokeRect(element.x, element.y, element.w, element.h);
      this.selectedSeat = element;
      if (element.h === null || element.w === null) {
        this.floorPlanCtx.fillStyle ='#006400';
        this.plotPolygonBorder(element);
      }
    });
  }

  private plotPolygonBorder(element: Coordinate) {
    this.floorPlanCtx.beginPath();
    this.floorPlanCtx.moveTo(element.sides[0], element.sides[1]);
    this.floorPlanCtx.lineTo(element.sides[2], element.sides[3]);
    this.floorPlanCtx.lineTo(element.sides[4], element.sides[5]);
    this.floorPlanCtx.lineTo(element.sides[6], element.sides[7]);
    this.floorPlanCtx.lineTo(element.sides[8], element.sides[9]);
    this.floorPlanCtx.lineTo(element.sides[10], element.sides[11]);
    this.floorPlanCtx.closePath();
    this.floorPlanCtx.strokeStyle = element.type===1?'#641f00':'#006400';
    this.floorPlanCtx.stroke();
  }

  private plotPolygon(element: Coordinate) {
    this.floorPlanCtx.beginPath();
    this.floorPlanCtx.moveTo(element.sides[0], element.sides[1]);
    this.floorPlanCtx.lineTo(element.sides[2], element.sides[3]);
    this.floorPlanCtx.lineTo(element.sides[4], element.sides[5]);
    this.floorPlanCtx.lineTo(element.sides[6], element.sides[7]);
    this.floorPlanCtx.lineTo(element.sides[8], element.sides[9]);
    this.floorPlanCtx.lineTo(element.sides[10], element.sides[11]);
    this.floorPlanCtx.closePath();
    this.floorPlanCtx.fill();
  }

  setBookingsPopup(element, content) {
    this.userIdList =[];
    this.bookingList =[];
    this.selectedSeat = element;
    if(this.selectedSeat.type == '1'){
      this.isMeetingRoom = this.selectedSeat.selected? false:true;
      this.isMeetingRoomBook =true;
    }
    this.f.workstationName.setValue(element.name);
    element.bookings.forEach(booking => {
      this.bookingList.push(booking);
      if(booking.users_id != null){
        this.userIdList.push(booking.users_id)
      }
    });
    this.isUserDataEmpty = this.userIdList.toString()==''?true:false;
    this.sortBookings();
    this.modalReference = this.modalService.open(content);
  }

  sortBookings() {
    const bookingData = [];
    bookingData.push(this.bookingList);
    this.totalUserCount = 10;
    this.heroes$ = new BehaviorSubject(bookingData);
    combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
      .subscribe(([allSources, currentPage, pageSize]) => {
        const startingIndex = (currentPage - 1) * pageSize;
        const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
        this.dataOnPage$.next(onPage);
      });

    this.heroes$.pipe(take(1)).subscribe(heroData => {
      this.tableDataSource$.next(Object.values(heroData));
    });
    combineLatest(this.heroes$, this.sortKey$, this.sortDirection$)
      .subscribe(([changedHeroData, sortKey, sortDirection]) => {
        const heroesArray = Object.values(changedHeroData);
        const sortedHeroes = heroesArray.sort((a, b) => {
          if (a[sortKey] > b[sortKey]) {
            return sortDirection === 'asc' ? 1 : -1;
          }
          if (a[sortKey] < b[sortKey]) {
            return sortDirection === 'asc' ? -1 : 1;
          }
          return 0;
        });

        this.tableDataSource$.next(sortedHeroes);
      });

    combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
      .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
        const heroesArray = Object.values(changedHeroData);
        let filteredHeroes: any[];

        if (!searchTerm) {
          filteredHeroes = heroesArray;
        } else {
          const filteredResults = heroesArray.filter(hero => {
            return Object.values(hero)
              .reduce((prev, curr) => {
                return prev || curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
              }, false);
          });
          filteredHeroes = filteredResults;
        }

        const sortedHeroes = filteredHeroes.sort((a, b) => {
          if (a[sortKey] > b[sortKey]) {
            return sortDirection === 'asc' ? 1 : -1;
          }
          if (a[sortKey] < b[sortKey]) {
            return sortDirection === 'asc' ? -1 : 1;
          }
          return 0;
        });
        this.tableDataSource$.next(sortedHeroes);
      });
  }

  private setArrays(element: Coordinate, even: any, odd: any) {
    let i = 0;
    let j = 0;
    element.sides.forEach((entry, index) => {
      if (index % 2 === 0) {
        even[i] = entry;
        i++;
      } else {
        odd[j] = entry;
        j++;
      }
    });
  }

  private getMinMax(even: any, odd: any) {
    const xMin = even.reduce((a, b) => Math.min(a, b));
    const xMax = even.reduce((a, b) => Math.max(a, b));
    const yMin = odd.reduce((a, b) => Math.min(a, b));
    const yMax = odd.reduce((a, b) => Math.max(a, b));
    return {xMin, xMax, yMin, yMax};
  }

  isPointInsidePoly(x, y, xmin, xmax, ymin, ymax) {
    if (x < xmin || x > xmax || y < ymin || y > ymax) {
      return false;
    } else {
      return true;
    }
  }

  setReservedComment(element , content){
    this.detailsCarParkingForm.controls.name.setValue(element.name);
    this.communicationService.getSeat(element.name).subscribe(res => {
      this.detailsCarParkingForm.controls.comments.setValue(res.comments);
    }, error => {
        console.error(error);
      }
      );
    this.modalReference = this.modalService.open(this.modalComment , content);
}


onClick(event, content) {
  this.isMeetingRoom =  false;
  this.isMeetingRoomBook =false;
  this.userIdList =[];
  const rB = this.floorPlanCtx.canvas.getBoundingClientRect();
  const x = event.pageX - rB.left;
  const y = event.pageY - (rB.top + window.scrollY);
  this.coordinates.map((element) => {
    if (element.selected && element.status=='0' ){
      if (y > element.y && y < element.y + element.h
        && x > element.x && x < element.x + element.w) {
        this.setBookingsPopup(element, content);
      } else if (element.h === null || element.y === null
        || element.x === null || element.w === null) {
        const odd: any = [];
        const even: any = [];
        this.setArrays(element, even, odd);
        const {xMin, xMax, yMin, yMax} = this.getMinMax(even, odd);
        if (this.isPointInsidePoly(x, y, xMin, xMax, yMin, yMax)) {
          this.setBookingsPopup(element, content);
        }
      }
    } else if ( !element.selected && element.reserved=='Yes' && element.status == '0' ){
      if (y > element.y && y < element.y + element.h
        && x > element.x && x < element.x + element.w) {
          this.setReservedComment(element , content);
      } else if (element.h === null || element.y === null
        || element.x === null || element.w === null) {
        const odd: any = [];
        const even: any = [];
        this.setArrays(element, even, odd);
        const {xMin, xMax, yMin, yMax} = this.getMinMax(even, odd);
        if (this.isPointInsidePoly(x, y, xMin, xMax, yMin, yMax)) {
          this.setReservedComment(element , content);
        }
      }
    } else if (!element.is_common && !element.selected && element.status ==='0') {
      if (element.shape === 0) {
        let noOfSeats: any = [];
        if (y > element.y && y < element.y + element.h
          && x > element.x && x < element.x + element.w) {
          if (!this.is_selected) {
            this.floorPlanCtx.fillStyle = '#64a844';
            this.floorPlanCtx.fillRect(element.x, element.y, element.w, element.h);
            this.floorPlanCtx.strokeStyle = '#c2cca6';
            this.floorPlanCtx.strokeRect(element.x, element.y, element.w, element.h);
            if (this.selectedSeats != null) {
              const array = this.selectedSeats.split(',');
              if (array.indexOf(element.name) > -1) {
              } else {
                this.selectedSeats = this.selectedSeats + ',' + element.name;
              }
            } else {
              this.selectedSeats = element.name;
            }
            this.f.seat_id.setValue(this.selectedSeats);
            noOfSeats = this.selectedSeats.split(',');
          }

        }
      } else {
        const odd: any = [];
        const even: any = [];
        let noOfSeats: any = [];
        this.setArrays(element, even, odd);
        const {xMin, xMax, yMin, yMax} = this.getMinMax(even, odd);
        if (this.isPointInsidePoly(x, y, xMin, xMax, yMin, yMax)
          && !element.is_common && !element.selected) {
          if (!this.is_selected) {
            this.floorPlanCtx.fillStyle = '#64a844';
            this.plotPolygon(element);
            this.selectedSeat = element;
            this.isMeetingRoom = this.selectedSeat.type == '1'? true:false;
            if (this.selectedSeats != null) {
              const array = this.selectedSeats.split(',');
              if (array.indexOf(element.name) > -1) {
              } else {
                this.selectedSeats = this.selectedSeats + ',' + element.name;
              }
            } else {
              this.selectedSeats = element.name;
            }
            this.f.seat_id.setValue(this.selectedSeats);
            noOfSeats = this.selectedSeats.split(',');
            if (noOfSeats.length > 0) {
               this.is_selected = true;
             }
          }

        }
      }
    }
  });
}

onClear() {
  this.getFloorWings(this.parkingWings[0].floor_id , this.parkingWings[0].wing_id);
  this.f.seat_id.setValue('');
  this.isOnSubmit = false;
  this.is_selected = false;
  this.selectedSeats = null;
}

public openDialog(content) {
  const startDate = formatDate(this.convertDate(this.detailsCarParkingForm.value.startDate),
    'yyyy-MM-dd', this.locale);
  const endDate = formatDate(this.convertDate(this.detailsCarParkingForm.value.endDate),
    'yyyy-MM-dd', this.locale);

  if (this.detailsCarParkingForm.value.seat_id) {
    const sDate = this.isBookByTime ? startDate + this.bookingTimeList[this.detailsCarParkingForm.get('startTime').value].timeFormat :
      startDate + 'T00:00:00';

    const eDate = this.isBookByTime ? endDate + this.bookingTimeList[this.detailsCarParkingForm.get('endTime').value].timeFormat :
      endDate + 'T23:59:59';
    this.confirmationDialogService.confirm('Book Car Parking Confirmation',
      'Are you sure you want to this Parking slot ' + this.detailsCarParkingForm.value.seat_id + ' from ' + sDate + ' to ' + eDate + '?')
      .then((confirmed) => this.submitBook(confirmed, content));
  } else {
    this.toastr.error('Parking Slot is not selected');
  }
}

submitBook(confirmed: any, content) {
  this.isDisabled = true;
  let is_self = true;
  if (confirmed) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    this.modalReference = this.modalService.open(content, ngbModalOptions);
    let userRequestObj = {};
    if (this.user) {
      userRequestObj = {
        username: this.user.username,
        first_name: this.user.firstName,
        last_name: this.user.lastName,
        role: '5',
        email: this.user.email,
        sub_department_name: this.user.department,
      };
      is_self = false;
    }
    const startDateVal =
      formatDate(this.convertDate(this.detailsCarParkingForm.value.startDate), 'yyyy-MM-dd', this.locale);
    const endDateVal = formatDate(this.convertDate(this.detailsCarParkingForm.value.endDate), 'yyyy-MM-dd', this.locale);
    if (startDateVal <= endDateVal) {
      const bookSeatObj = {
        // start_date: startDateVal,
        // end_date: endDateVal,
        start_date_time: this.isBookByTime ? startDateVal + this.bookingTimeList[this.detailsCarParkingForm.get('startTime').value].timeFormat :
          startDateVal + 'T00:00:00',
        end_date_time: this.isBookByTime ? endDateVal + this.bookingTimeList[this.detailsCarParkingForm.get('endTime').value].timeFormat :
          endDateVal + 'T23:59:59',
        emp_id: this.detailsCarParkingForm.value.employee_id,
        seat_id: this.detailsCarParkingForm.value.seat_id,
        status: 0,
        email_notification: true,
        comments: this.detailsCarParkingForm.value.comments,
        floor_id: this.floor,
        wing_id:  this.wing,
        futureReference: this.isFuturePreference,
        user_request: userRequestObj,
        is_self,
      };
      this.communicationService.bookSeat(bookSeatObj).subscribe(res => {
          this.modalReference.close();
          this.toastr.success(this.translate.instant('toasterSuccessMessage.workStationBooked'));
          this.isDisabled = false;
          this.router.navigate(['/view-bookings']);
        },
        error => {
          this.modalReference.close();
          console.log('error', error);
          this.toastr.error(error.error.message, 'Failed to book Workstation');
          this.isDisabled = false;
        });
    } else {
      this.modalReference.close();
      this.toastr.error('Invalid Booking Date Selection');
      this.isDisabled = false;
    }
  }
}

}
