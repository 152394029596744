import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import Chart from 'chart.js';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfirmationDialogCovidService } from 'src/app/pages/confirmation-dialog-covid/confirmation-dialog-covid.service';
import { CommunicationService } from 'src/app/services/communication.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { bookingChart, chartOptions, parseOptions, spaceUtilizationChart } from '../../variables/charts';
import { entityFeatures } from 'src/app/helper/entityFeatures';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  heroes$;
  superlatives$ = new BehaviorSubject<{ [superlativeName: string]: string }>({});
  tableDataSource$ = new BehaviorSubject<any[]>([]);
  displayedColumns$ = new BehaviorSubject<any[]>([]);
  currentPage$ = new BehaviorSubject<number>(1);
  pageSize$ = new BehaviorSubject<number>(10);
  dataOnPage$ = new BehaviorSubject<any[]>([]);
  searchFormControl = new FormControl();
  sortKey$ = new BehaviorSubject<string>('emp_id');
  sortDirection$ = new BehaviorSubject<string>('asc');
  totalUserCount = 0;
  limit = 10000;
  startIndex = 0;
  status = [];
  dashboardDetails: any;
  salesChartData: any;

  chartType = 'space_count_month';

  isMonthView = true;
  weekButton = false;
  monthButton = true;
  currentUserRole: string;

  salesChart: Chart;
  ordersChart: Chart;

  cardsList: any;

  constructor(
    private communicationService: CommunicationService,
    public translate: TranslateService,
    private confirmationDialogCovidService: ConfirmationDialogCovidService) {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser) {
      const dept = currentUser.department;
      this.communicationService.getFeatureByDept(dept).subscribe(features => {
        let arr = ['username',
          'seat_number',
          'end_date']
        features.forEach(element => {
          if (entityFeatures.CARPARKING.includes(element.feature_name)) {
            arr.push('parking');
          } else if ([entityFeatures.ZOOPASS].includes(element.feature_name)) {
            arr.push('zoo_pass');
          }
        });
        arr.push('status_msg');
        this.displayedColumns$ = new BehaviorSubject<string[]>(arr);
      });
    } else {
      let arr = ['username',
        'seat_number',
        'end_date', 'status_msg'];
      this.displayedColumns$ = new BehaviorSubject<string[]>(arr);
    }

    this.currentUserRole = this.communicationService.currentUser.role;

  }

  ngOnInit() {
    localStorage.removeItem('qrId');
    this.getDashBoardDetails();
    this.getAllBookings(this.limit, this.startIndex);
    this.getBookingStatus();
    if (this.communicationService.showDashboardPopUp) {
      setTimeout(() => {
        this.openConfirmationDialog();
      }, 2000);

      this.communicationService.showDashboardPopUp = false;
    }
  }

  loadCardsDetails() {
    // Cards List
    this.cardsList = [
      { title: this.translate.instant('dashBoard.card.floor'), value: this.dashboardDetails.floors_count, bottomValue: '', class: 'fas fa-chart-bar', bottomClass: '', textClass: 'text-warning', icon: 'list-view-24' },
      { title: this.translate.instant('dashBoard.card.totalWorkstation'), value: this.dashboardDetails.total_seats, bottomValue: '', class: 'fas fa-chart-bar', bottomClass: '', textClass: 'text-success', icon: 'network-24' },
      // tslint:disable-next-line:max-line-length
      { title: this.translate.instant('dashBoard.card.availableWorkstations'), value: this.dashboardDetails.available_seats, bottomValue: '', class: 'fas fa-chart-pie', bottomClass: '', textClass: 'text-danger', icon: 'alarm-24' },
      // tslint:disable-next-line:max-line-length
      { title: this.translate.instant('dashBoard.card.todayBookings'), subTitle: 'Bookings', value: this.dashboardDetails.booked_seats, bottomValue: '', class: 'fas fa-percent', bottomClass: '', textClass: 'text-success', icon: 'diagram-curve-24' },
      { title: this.translate.instant('dashBoard.card.totalUsers'), value: this.dashboardDetails.user_counts, bottomValue: '', class: 'fas fa-users', bottomClass: '', textClass: 'text-warning', icon: 'user-group-24' },
      // tslint:disable-next-line:max-line-length
      { title: this.translate.instant('dashBoard.card.activeBookings'), value: this.dashboardDetails.upcoming_bookings, bottomValue: '', class: 'fas fa-users', bottomClass: '', textClass: 'text-warning', icon: 'calendar-24' }
    ];
  }

  getBookingStatus() {
    this.communicationService.getBookingStatus().subscribe(res => {
      this.status = res;
    }, error => {
      console.error(error);
    });
  }

  getDashBoardDetails() {
    this.communicationService.getDashboardDetails().subscribe(res => {
      this.dashboardDetails = res;
      this.formatSalesChartData();
      this.renderBookingsChart();
      this.renderSpaceUtilizationChart();
      this.loadCardsDetails();
    }, error => {
      console.log(error);
    });
  }

  renderBookingsChart() {
    const chartOrders = document.getElementById('chart-orders');
    parseOptions(Chart, chartOptions());
    if (this.dashboardDetails && this.dashboardDetails.booking_count) {
      bookingChart.data.labels = Object.keys(this.dashboardDetails.booking_count);
      bookingChart.data.datasets[0].data = Object.values(this.dashboardDetails.booking_count);
    }
    if (chartOrders) {
      this.ordersChart = new Chart(chartOrders, {
        type: 'bar',
        options: bookingChart.options,
        data: bookingChart.data
      });
    }
  }

  renderSpaceUtilizationChart() {
    const chartSales = document.getElementById('chart-sales');
    if (this.salesChartData && this.salesChartData.space_count_month) {
      spaceUtilizationChart.data.labels = this.salesChartData.space_count_month.keys;
      spaceUtilizationChart.data.datasets[0].data = this.salesChartData.space_count_month.values;
    }
    if (chartSales) {
      this.salesChart = new Chart(chartSales, {
        type: 'line',
        options: spaceUtilizationChart.options,
        data: spaceUtilizationChart.data
      });
    }
  }

  formatSalesChartData() {
    if (this.dashboardDetails && this.dashboardDetails.space_count_month && this.dashboardDetails.space_count_week) {
      this.salesChartData = {
        space_count_month: {
          keys: Object.keys(this.dashboardDetails.space_count_month),
          values: Object.values(this.dashboardDetails.space_count_month)
        },
        space_count_week: {
          keys: Object.keys(this.dashboardDetails.space_count_week),
          values: Object.values(this.dashboardDetails.space_count_week)
        }
      };
    }
  }

  public updateOptions() {
    this.salesChart.data.labels = this.salesChartData[this.chartType].keys;
    this.salesChart.data.datasets[0].data = this.salesChartData[this.chartType].values;
    this.salesChart.update();
  }

  adjustSort(key: string) {
    if (this.sortKey$.value === key) {
      if (this.sortDirection$.value === 'asc') {
        this.sortDirection$.next('desc');
      } else {
        this.sortDirection$.next('asc');
      }
      return;
    }

    this.sortKey$.next(key);
    this.sortDirection$.next('asc');
  }

  public openConfirmationDialog() {
    this.confirmationDialogCovidService.confirm('Covid Notification for employees', 'Are you sure you want to book seat ?');
  }

  getAllBookings(limit: number, index: number) {

    const bookingData = [];
    const user_id = this.communicationService.currentUser.id;
    this.communicationService.getAllBookingsSelf(user_id).subscribe(res => {

      for (const user of res.bookingResponseList) {
        if (user.status === '0' || user.status === '1') {
          bookingData.push(user);
        }
      }
      this.totalUserCount = bookingData.length;
      this.heroes$ = new BehaviorSubject(bookingData);
      combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
        .subscribe(([allSources, currentPage, pageSize]) => {
          const startingIndex = (currentPage - 1) * pageSize;
          const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
          this.dataOnPage$.next(onPage);
        });

      this.heroes$.pipe(take(1)).subscribe(heroData => {
        this.tableDataSource$.next(Object.values(heroData));
      });

      combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          let filteredHeroes: any[];

          if (!searchTerm) {
            filteredHeroes = heroesArray;
          } else {
            const filteredResults = heroesArray.filter(hero => {
              return Object.values(hero)
                .reduce((prev, curr) => {
                  return prev || curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
                }, false);
            });
            filteredHeroes = filteredResults;
          }

          const sortedHeroes = filteredHeroes.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });

          this.tableDataSource$.next(sortedHeroes);
        });
    }, error => {
      console.error(error);
    });
  }

}
