export enum entityFeatures {
  FOODBOOKING = 'Book a Food',
  ZOOPASS = 'Book Zoo Pass',
  CARPARKING = 'Book Car Parking',
  VIEWZOOPASS = 'View Zoo Pass',
  VIEWPARKING = 'View Parking',
  CHECKAVAILABILITYAU = 'Check Availability',
  FLOORRESPONDER = 'Floor Responder Bookings',
  BOOKWORKSPACE = 'Book Workstation',
  USERPREFERENCE = 'User Preference'

}
