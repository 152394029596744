import { formatDate } from '@angular/common';
import { Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild , AfterViewInit, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbModalRef, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Coordinate } from 'src/app/model/coordinate';
import { Desk } from 'src/app/model/desk';
import { FloorPath } from 'src/app/model/floorPath';
import { User } from 'src/app/model/user';
import { CommunicationService } from 'src/app/services/communication.service';
import { ValidationService } from 'src/app/services/validation.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-add-workstation',
  templateUrl: './add-workstation.component.html',
  styleUrls: ['./add-workstation.component.css']
})
export class AddWorkstationComponent implements OnInit {
  modalReference: NgbModalRef;
  showFloorPlanFlag = false;

  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('tooltip', { static: true })
  tooltip: ElementRef<HTMLCanvasElement>;
  title = 'floor-plan-poc';
  rect: Coordinate[] = [];
  coordsValue = new Array();
  managerName = 'Abhijit D.Thorat';
  imageCanvas: HTMLImageElement;
  floorPlanCtx: CanvasRenderingContext2D;
  tooltipCtx: CanvasRenderingContext2D;
  floorPlanImage: HTMLImageElement;

  @ViewChild('mymodal')
  private defaultTabButtonsTpl: TemplateRef<any>;

  hideTooltip = false;
  selectedSeat: any;

  detailsAddWorkstationForm: FormGroup;
  cities = [];
  countries = [];
  floors = [];
  buildings = [];
  wings = [];
  thumbnail: any;
  floorPath: FloorPath;
  x_value: any;
  y_value: any;
  floor_id: any;
  wing_id: any;
  todayDate;
  employeeNo: any;
  loading = false;
  is_selected = false;
  is_map_shown = false;
  isFuturePreference = false;
  selectedSeats: any;
  corpId: any;
  user: User;
  searchResult = [];
  searchResultSeats = [];
  type = false;
  reserved = false;
  noOfCoordinates = [4, 6, 8, 10, 12, 14, 16, 18, 20];
  keySize = 256;
  iterations = 1000;
  limit = 30;
  startIndex = 0;
  validatated: any;
  seats: any;
  desk: Desk;
  coordinates: Coordinate[] = [];
  showAttribute = 0;
  viewAttribute: string;
  metaJsonObject = {};
  displayMap = new Map<string, string>();
  seatId: any = null;
  seatName: string;
  departments = [];
  submitted:boolean;
  isDisabled: boolean;


  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private formBuilder: FormBuilder,
    private validationService: ValidationService,
    private communicationService: CommunicationService,
    private confirmationDialogService: ConfirmationDialogService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.getCountries();
    this.detailsAddWorkstationForm = this.formBuilder.group({
      city: [''],
      country: [''],
      floor: [''],
      wing: [''],
      building: [''],
      seat_name: new FormControl('', [Validators.pattern('[a-zA-Z0-9\-\/\(\)\,\. ]*'), Validators.maxLength(250), Validators.required]),
      no_of_coordinates: [''],
      coordinate: new FormControl('', [Validators.pattern('[0-9,]*'), Validators.maxLength(250), Validators.required]),
      reserved: [''],
      type: [''],
      department: [''],
      capacity:['']
    });

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.getDepartments();
    /* if (currentUser.id) {
      this.communicationService.getUserDetails(currentUser.id).subscribe(res => {
      }, error => {
        console.log("error", error)
      })
    } */
    this.f.no_of_coordinates.setValue('8');
  }

  get f() {
    return this.detailsAddWorkstationForm.controls;
  }

  get seat_name() {
    return this.detailsAddWorkstationForm.get('seat_name');
  }

  get coordinate() {
    return this.detailsAddWorkstationForm.get('coordinate');
  }
  onChange(event) {
    if (event.target.checked) {
      this.isFuturePreference = true;
    } else {
      this.isFuturePreference = false;
    }
  }

  getDepartments() {
    this.communicationService.getDepartments().subscribe(res => {
      this.departments = res;
      if (this.departments !== null) {
      this.detailsAddWorkstationForm.controls.department.setValue(res[0].department_id);
      }
    }, error => {
      console.error(error);
    });
}
  validatingWorkstationName() {
    this.validatated = this.validationService.validationWorkstationName(this.detailsAddWorkstationForm.value.seat_name);
   }
  selectReserved(value) {
    this.reserved = value;
    this.detailsAddWorkstationForm.controls.reserved.setValue(value);

  }
  deselectType(event) {
    if (event.target.checked) {
      this.type = true;
    } else {
      this.type = false;
    }
  }

  deselectReserved(event) {
    if (event.target.checked) {
      this.reserved = true;
    } else {
      this.reserved = false;
    }
  }

  clearAttributes() {
    this.showAttribute = 0;
    this.viewAttribute = '';
    this.metaJsonObject = {};
    this.f.seat_name.setValue('');
    this.f.coordinate.setValue('');
    this.f.capacity.setValue('');
    this.displayMap.clear();
    this.coordinates = [];
    this.seatId = null;
    this.detailsAddWorkstationForm.controls.reserved.setValue(false);
    this.detailsAddWorkstationForm.controls.type.setValue(false);
  }

  onFloorChange(value) {
    if (value) {
      this.f.wing.setValue('');
      this.getWings(value.target.value);
    }
  }

  onCityChange(value) {
    this.getBuildings(value.target.value);
  }

  onBuildingChange(value) {
    this.getFloors(value.target.value);
  }

  onCountryChange(value) {
    this.getCitiesByCountry(value.target.value);
  }


  getCities() {
    this.communicationService.getCities().subscribe(res => {
      this.cities = res;
    }, error => {
      console.error(error);
    });
  }

  getCountries() {
    this.communicationService.getCountries().subscribe(res => {
      for (const country of res) {
        if (country.status === '0') {
          this.countries.push(country);
        }
      }
      this.f.country.setValue(this.countries[0].country_id);
      this.getCitiesByCountry(this.countries[0].country_id);
    }, error => {
      console.error(error);
    });
  }


  getBuildings(value) {
    this.communicationService.getBuildings(value).subscribe(res => {
      if (res.length > 0) {
        this.buildings = [];
        for (const building of res) {
          if (building.status === '0') {
            this.buildings.push(building);
          }
        }
        if (this.buildings.length > 0) {
          this.f.building.setValue(this.buildings[0].building_id);
          this.getFloors(this.buildings[0].building_id);
        } else {
          this.f.building.setValue('');
          this.floors = [];
          this.f.floor.setValue('');
          this.wings = [];
          this.f.wing.setValue('');
        }
      } else {
        this.buildings = [];
        this.f.building.setValue('');
        this.floors = [];
        this.f.floor.setValue('');
        this.wings = [];
        this.f.wing.setValue('');
      }
    }, error => {
      console.error(error);
    });
  }

  // tslint:disable-next-line:use-lifecycle-interface use-life-cycle-interface
  ngAfterViewInit(): void {
    const ngbModalOptions: NgbModalOptions = {
              backdrop : 'static',
              keyboard : false
          };
        this.modalReference = this.modalService.open(this.defaultTabButtonsTpl, ngbModalOptions);
        this.floorPlanCtx = this.canvas.nativeElement.getContext('2d');
         this.tooltipCtx = this.tooltip.nativeElement.getContext('2d');
  }

  getFloors(building_id: string) {
    this.communicationService.getFloorsByBuilding(building_id).subscribe(res => {
      this.floors = [];
      for (const floor of res) {
        if (floor.status === '0') {
          this.floors.push(floor);
        }
      }
      this.f.floor.setValue(this.floors[0].floor_id);
      this.getWings(this.floors[0].floor_id);
    }, error => {
      console.error(error);
    });
  }

  getCitiesByCountry(country_id: string) {
    this.communicationService.getCitiesByCountry(country_id).subscribe(res => {
      if (res.length > 0) {
        this.cities = [];
        for (const city of res) {
          if (city.status === '0') {
            this.cities.push(city);
          }
        }
        this.f.city.setValue(this.cities[0].city_id);
        this.getBuildings(this.cities[0].city_id);
      } else {
        this.cities = [];
        this.f.city.setValue('');
        this.buildings = [];
        this.f.building.setValue('');
        this.floors = [];
        this.f.floor.setValue('');
        this.wings = [];
        this.f.wing.setValue('');
      }
    }, error => {
      console.error(error);
    });
  }

  getWings(floor_id: string) {
    this.communicationService.getWings(floor_id).subscribe(res => {
      this.wings = [];
      for (const wing of res) {
        if (wing.status === '0') {
          this.wings.push(wing);
        }
      }
      this.f.wing.setValue(this.wings[0].wing_id);
      this.modalReference.close();
    }, error => {
      console.error(error);
      this.modalReference.close();
    });
  }

  clearData() {
    this.f.seat_name.setValue('');
    this.f.no_of_coordinates.setValue('8');
    this.f.coordinate.setValue('');
    this.f.capacity.setValue('');
    this.reserved = false;
    this.type = false;
    this.coordinates = [];
    this.detailsAddWorkstationForm.controls.department.setValue(1);
    this.detailsAddWorkstationForm.controls.reserved.setValue(false);
    const wing_id = this.f.wing.value;
    const floor_id = this.f.floor.value;
    this.getFloorWings(floor_id, wing_id);
    this.detailsAddWorkstationForm.controls['no_of_coordinates'].enable();
     this.detailsAddWorkstationForm.controls.type.setValue(false);
  }

  public openConfirmationDialog(content) {
    if (this.detailsAddWorkstationForm.value.seat_name) {
      this.confirmationDialogService.confirm('Delete Workstation Confirmation',
        'Are you sure you want to delete Workstation ' + this.detailsAddWorkstationForm.value.seat_name + '?')
        .then((confirmed) => this.delete(confirmed, content));
    } else {
      this.toastr.error('Workstation is not selected');
    }
  }

  getFloorWings(floor_id: string, wing_id: string) {
    this.communicationService.getFloorPlan(floor_id, wing_id).subscribe(res => {
      const objectURL = 'data:image/png;base64,' + res.image_path;
      const image = new Image();
      image.src = objectURL;
      this.floorPlanImage = image;
      image.onload = (() => this.fitToCanvas(image));
    }, error => {
      console.error(error);
    });
    this.getDesksList(floor_id, wing_id);
  }

  getDesksList(floor_id: string, wing_id: string) {
    this.communicationService.getDesksList(floor_id, wing_id).subscribe(res => {
     this.seats = res;
        if (res != null) {
          this.seats.forEach(desk => {
            this.desk = desk;
            this.highlightSelected();
          });

        }
    }, error => {
      console.error(error);
    });
  }
  highlightSelected() {
    if (this.desk.type === 0) {
      this.floorPlanCtx.fillStyle = '#A8ADB3';
      this.floorPlanCtx.fillRect(this.desk.x, this.desk.y, this.desk.w, this.desk.h);
      this.floorPlanCtx.strokeStyle = '#d4d6d9';
      this.floorPlanCtx.strokeRect(this.desk.x, this.desk.y, this.desk.w, this.desk.h);
    } else if (this.desk.type === 1) {
      this.floorPlanCtx.fillStyle = '#A8ADB3';
      this.plotPolygon(this.desk);
    }
  }

  private plotPolygon(element: Desk) {
    this.floorPlanCtx.beginPath();
    this.floorPlanCtx.moveTo(element.sides[0], element.sides[1]);
    this.floorPlanCtx.lineTo(element.sides[2], element.sides[3]);
    this.floorPlanCtx.lineTo(element.sides[4], element.sides[5]);
    this.floorPlanCtx.lineTo(element.sides[6], element.sides[7]);
    this.floorPlanCtx.lineTo(element.sides[8], element.sides[9]);
    this.floorPlanCtx.lineTo(element.sides[10], element.sides[11]);
    this.floorPlanCtx.closePath();
    this.floorPlanCtx.fill();
  }

  save() {
    this.isDisabled = true;
    const coordinateValue = this.coordsValue.toString();
    this.f.coordinate.setValue(coordinateValue);
    const bookSeatObj = {
      floor_id: this.detailsAddWorkstationForm.value.floor,
      wing_id: this.detailsAddWorkstationForm.value.wing,
      city_id: this.detailsAddWorkstationForm.value.city,
      seat_name: this.detailsAddWorkstationForm.value.seat_name,
      capacity:this.detailsAddWorkstationForm.value.capacity,
      status: 0,
      is_reserved: this.reserved === true ? 1 : 0,
      seat_coordinates: this.coordinates.length === 0 ? this.detailsAddWorkstationForm.value.coordinate : this.coordinates,
      type: this.type === true ? 1 : 0,
      shape: 1,
      seat_id: this.seatId != null ? this.seatId : null,
      department_id: this.detailsAddWorkstationForm.value.department
    };
    this.communicationService.createSeats(bookSeatObj).subscribe(res => {
      this.toastr.success(this.translate.instant('toasterSuccessMessage.addedWorkstation'));
      this.isDisabled = false;
         this.getDesksList( this.detailsAddWorkstationForm.value.floor, this.detailsAddWorkstationForm.value.wing);
      this.clearData();
    },
      error => {
        this.modalReference.close();
        console.log('error', error);
        this.toastr.error(error.error.message, this.translate.instant('toasterSuccessMessage.workstationError'));
        this.isDisabled = false;
      });

  }

  delete(confirmed, content) {
    this.isDisabled = true;
    if (confirmed) {
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false
      };
      this.modalReference = this.modalService.open(content, ngbModalOptions);
    if (this.seatId != null) {
      this.communicationService.deleteSeats(this.seatId).subscribe(res => {
        this.toastr.success(this.translate.instant('toasterSuccessMessage.workstationDeleted'));
        this.isDisabled = false;
           this.getDesksList( this.detailsAddWorkstationForm.value.floor, this.detailsAddWorkstationForm.value.wing);
         this.showFloorMap(event);
        this.clearData();
      },
        error => {
          this.modalReference.close();
          console.log('error', error);
          this.toastr.error(error.error.message,  'Failed to delete Workstation');
          this.isDisabled = false;
        });
    }
    this.modalReference.close();

  }
  }

  disableDeleteButon() {
    if ((this.detailsAddWorkstationForm.value.seat_name === '') || (this.detailsAddWorkstationForm.value.coordinate === '') || (this.seatId == null)) {
      return true;
    } else {
      return false;
    }
  }

  isInputSelected() {
    if (this.f.wing.value === undefined
      || this.f.wing.value === '' || this.f.floor.value === undefined || this.f.floor.value === '') {
      return false;
    }
    return true;
  }

  private fitToCanvas(image) {
    this.floorPlanCtx.canvas.width = image.width;
    this.floorPlanCtx.canvas.height = image.height;
    this.floorPlanCtx.drawImage(image, 0, 0);
  }

  disableSaveButon() {
    if ((this.detailsAddWorkstationForm.value.seat_name === '') || (this.detailsAddWorkstationForm.value.coordinate === '')
         || (this.detailsAddWorkstationForm.value.capacity === '' && this.type == true ) ){
      return true;
    } else {
      return false;
    }
  }
  onChangingCoordinateNo() {
    this.coordsValue = [];
    this.f.seat_name.setValue('');
    this.f.coordinate.setValue('');
  }
  showFloorMap(event) {
    this.f.seat_name.setValue('');
    this.f.no_of_coordinates.setValue('8');
    this.f.coordinate.setValue('');
    this.reserved = false;
    this.type = false;
    this.coordinates = [];
    this.detailsAddWorkstationForm.controls.department.setValue(1);
    this.detailsAddWorkstationForm.controls.reserved.setValue(false);
    const wing_id = this.f.wing.value;
    const floor_id = this.f.floor.value;
    this.getFloorWings(floor_id, wing_id);
    const _this = this;
    _this.floorPlanCtx = _this.canvas.nativeElement.getContext('2d');
    _this.tooltipCtx = _this.tooltip.nativeElement.getContext('2d');
    this.detailsAddWorkstationForm.controls['no_of_coordinates'].enable();
  }

  onClick(event) {
    if (this.detailsAddWorkstationForm.controls['no_of_coordinates'].disabled) {
        return;
    }
    this.clearAttributes();
    const rB = this.floorPlanCtx.canvas.getBoundingClientRect();
    const x = Math.floor(event.pageX - rB.left);
    const y = Math.floor(event.pageY - (rB.top + window.scrollY));
    this.seats.map((element) => {
        if ((y > element.y && y < element.y + element.h
          && x > element.x && x < element.x + element.w)) {
          this.seatId = element.seat_id;
          this.seatName = element.name;
          this.f.seat_name.setValue(this.seatName);
          this.f.no_of_coordinates.setValue(4);
          this.getCoordinates(element.seat_id);
          this.detailsAddWorkstationForm.controls['no_of_coordinates'].disable();

        } else if (element.shape === 1 && this.seatId == null) {
          const odd: any = [];
          const even: any = [];
          this.setArrays(element, even, odd);
          const {xMin, xMax, yMin, yMax} = this.getMinMax(even, odd);
          if (this.isPointInsidePoly(x, y, xMin, xMax, yMin, yMax)) {
            this.plotPolygon(element);
            this.seatId = element.seat_id;
            this.seatName = element.name;
            this.f.seat_name.setValue(this.seatName);
            this.f.no_of_coordinates.setValue(element.sides.length);
            this.detailsAddWorkstationForm.controls['no_of_coordinates'].disable();
            this.getCoordinates(element.seat_id);
          }
        }
    });
    if (this.detailsAddWorkstationForm.value.seat_name === '' || this.detailsAddWorkstationForm.value.seat_name == null) {
      this.detailsAddWorkstationForm.controls['no_of_coordinates'].enable();
      const p = this.detailsAddWorkstationForm.value.no_of_coordinates;
      // tslint:disable-next-line:no-shadowed-variable
      const rB = this.floorPlanCtx.canvas.getBoundingClientRect();
      // tslint:disable-next-line:no-shadowed-variable
      const x = Math.floor(event.pageX - rB.left);
      // tslint:disable-next-line:no-shadowed-variable
      const y = Math.floor(event.pageY - (rB.top + window.scrollY));
      this.f.coordinate.setValue('');
      if (this.coordsValue.length >= p) {
        this.coordsValue = [];
        this.coordsValue.push(x);
        this.coordsValue.push(y);
      } else {
        this.coordsValue.push(x);
        this.coordsValue.push(y);
      }
      this.f.coordinate.setValue(this.coordsValue);
      this.plotPoint(x, y);
      if (this.coordsValue.length == p) {
        this.fillGreen();
        this.detailsAddWorkstationForm.controls['no_of_coordinates'].disable();
      }
    }
  }


plotPoint(x, y) {
    this.floorPlanCtx.beginPath();
    this.floorPlanCtx.arc(x, y, 3, 0, 2 * Math.PI);
    this.floorPlanCtx.fillStyle = '#009900';
    this.floorPlanCtx.fill();
    this.floorPlanCtx.stroke();
}

fillGreen() {
    this.floorPlanCtx.beginPath();
    this.floorPlanCtx.moveTo(this.coordsValue[0], this.coordsValue[1]);
    for(let i = 2; i < this.coordsValue.length; i += 2) {
        this.floorPlanCtx.lineTo(this.coordsValue[i], this.coordsValue[i + 1]);
    }
    this.floorPlanCtx.closePath();
    this.floorPlanCtx.fillStyle = '#99ff66';
    this.floorPlanCtx.fill();
}

  getCoordinates(seatId) {
    this.communicationService.getSeatById(seatId).subscribe(res => {
      this.coordinates = res.seat_coordinates;
      this.detailsAddWorkstationForm.controls.coordinate.setValue(res.seat_coordinates);
      this.f.coordinate.setValue(res.seat_coordinates);
      this.f.department.setValue(res.department_id);
      this.reserved = res.is_reserved === '1' ? true : false;
      this.type = res.type === 1 ? true : false;
      this.detailsAddWorkstationForm.controls.reserved.setValue(this.reserved);
      this.detailsAddWorkstationForm.controls.type.setValue(this.type);
      this.f.capacity.setValue(res.capacity);
    }, error => {
      console.error(error);
    });
  }

  private setArrays(element: Coordinate, even: any, odd: any) {
    let i = 0;
    let j = 0;
    element.sides.forEach((entry, index) => {
      if (index % 2 === 0) {
        even[i] = entry;
        i++;
      } else {
        odd[j] = entry;
        j++;
      }
    });
  }

  private getMinMax(even: any, odd: any) {
    const xMin = even.reduce((a, b) => Math.min(a, b));
    const xMax = even.reduce((a, b) => Math.max(a, b));
    const yMin = odd.reduce((a, b) => Math.min(a, b));
    const yMax = odd.reduce((a, b) => Math.max(a, b));
    return {xMin, xMax, yMin, yMax};
  }

  isPointInsidePoly(x, y, xmin, xmax, ymin, ymax) {
    if (x < xmin || x > xmax || y < ymin || y > ymax) {
      return false;
    } else {
      return true;
    }
  }

}

