import {Component,ViewChild, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from "@angular/router";
import {entityFeatures} from "../../helper/entityFeatures";
import {CommunicationService} from "../../services/communication.service";
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-group-navbar',
  templateUrl: './group-navbar.component.html',
  styleUrls: ['./group-navbar.component.scss']
})
export class GroupNavbarComponent implements OnInit {

  constructor(public translate: TranslateService,
              private router: Router,
              private observer: BreakpointObserver,
              public communicationService: CommunicationService) {
  }

  selectedLanguage = 'en';
  allLanguages = ['en', 'de'];
  currentUser;
  isFeatureActive = false;
  auBookingActive = false;
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  isMobile= true;
  isCollapsed = true;

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.selectLanguage();
    this.getActiveFeature();
    this.observer.observe(['(max-width: 800px)']).subscribe((screenSize) => {
      if(screenSize.matches){
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
  }
  
  toggleMenu() {
    if(this.isMobile){
      this.sidenav.toggle();
      this.isCollapsed = false;
    } else {
      this.sidenav.open();
      this.isCollapsed = !this.isCollapsed;
    }
  }

  onLogoClick() {
    this.currentUser.role === 'Super User' ? this.router.navigate(['/super-user-dashboard']) : this.router.navigate(['/dashboard']);
  }

  selectLanguage() {
    const self = this;
    self.translate.use(this.selectedLanguage);
  }

  getActiveFeature() {
    this.communicationService.getFeatureByDept(this.currentUser.department).subscribe(res => {
      if (res.length) {
        for (const feature of res) {
          if (feature.feature_name === entityFeatures.USERPREFERENCE && feature.status === '0') {
            this.isFeatureActive = true;
          }
          if (feature.feature_name === entityFeatures.BOOKWORKSPACE && feature.status === '0') {
            this.auBookingActive = true;
          }
        }
      }
    });
  }

}
