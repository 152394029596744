import {Component, OnInit, Input, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {CommunicationService} from 'src/app/services/communication.service';
import {userRoles} from '../../helper/userRoles';
import {TranslateService} from '@ngx-translate/core';
import {entityFeatures} from 'src/app/helper/entityFeatures';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {FirstAidBookingsComponent} from '../../pages/first-aid-bookings/first-aid-bookings.component';
import {title} from 'process';


declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  group?: {
    path: string;
    title: string;
    icon: string;
    class: string;
  }[];
}

export const ROUTES: RouteInfo[] = [
  {path: '/dashboard', title: 'Dashboard', icon: 'home-24', class: ''},
  {path: '/super-user-dashboard', title: 'Super User Dashboard', icon: 'home-24', class: ''},
  {path: '/admin-dashboard', title: 'Admin Dashboard', icon: 'home-24', class: ''},
  {
    path: '/view-bookings', title: 'Booking', icon: 'repair-24', class: '',
    group: [{path: '/book-seat', title: 'Book Workspace', icon: 'book-closed-24', class: ''},
      /*{ path: '/book-seat-au', title: 'Book a Workstation AU',  icon: 'book-closed-24', class: '' },*/
      {path: '/auto-assign-booking', title: 'Auto Assign', icon: 'forward-24', class: ''},
      {path: '/express-booking', title: 'Express Booking', icon: 'train-24', class: ''},
      /* { path: '/qr-code-booking', title: 'QR Code Booking',  icon:'success-filled-32', class: '' }, */
      /* {path: '/view-bookings', title: 'Bookings List', icon: 'calendar-24', class: ''},*/
      /*{ path: '/fire-warden-bookings', title: 'Fire Warden Bookings',  icon: 'success-24', class: '' },
      { path: '/first-aid-bookings', title: 'First Aid Bookings',  icon: 'success-24', class: '' }*/
    ]
  },
  {path: '/search-employee-desk', title: 'Locate', icon: 'search-24', class: ''},
  {path: '/booking-by-date', title: 'Check Availability', icon: 'success-24', class: ''},
  {path: '/users', title: 'Users', icon: 'user-group-24', class: ''},
  {
    path: '/disable-floor', title: 'Space Management', icon: 'gears-24', class: '',
    group: [{path: '/first-aid', title: 'First Aid Responders', icon: 'service-bell-24', class: ''},
      {path: '/disable-floor', title: 'Enable/Disable Wing', icon: 'elevator-24', class: ''},
      // { path: '/enable-disable-seat', title: 'Enable/Disable Workstation',  icon:'ni-active-40 text-blue', class: '' },
      {path: '/view-workstations', title: 'Workstations', icon: 'cast-24', class: ''},
      {path: '/rule-configuration', title: 'Capacity Planning', icon: 'checklist-24', class: ''},
      /*{path: '/view-zoo-pass', title: 'View Zoo Pass', icon: 'sparkle-24', class: ''},
      {path: '/view-parkings', title: 'View Parking', icon: 'car-24', class: ''}*/]
  },
  {
    path: '/view-country', title: 'Configuration', icon: 'settings-24', class: '',
    group: [{path: '/view-country', title: 'Countries', icon: 'network-24', class: ''},
      {path: '/view-organisation', title: 'Organisations', icon: 'grid-square-24', class: ''},
      {path: '/view-department', title: 'Departments', icon: 'clipboard-24', class: ''},
      {path: '/view-sub-department', title: 'Sub-Departments', icon: 'clipboard-24', class: ''},
      {path: '/add-edit-sub-department', title: 'Add Edit Sub-Department', icon: 'clipboard-24', class: ''},
      {path: '/view-city', title: 'Cities', icon: 'global-24', class: ''},
      {path: '/view-building', title: 'Buildings', icon: 'factory-24', class: ''},
      {path: '/view-floor', title: 'Floors', icon: 'user-manual-24', class: ''},
      {path: '/view-wing', title: 'Wings', icon: 'minus-24', class: ''},
      {path: '/add-workstation', title: 'Add Workspace', icon: 'add-24', class: ''},
      {path: '/create-qr-code', title: 'Create-Qr-Code', icon: 'add-24', class: ''},
    ]
  },
  {path: '/audit-logs', title: 'Audit logs', icon: 'diagram-bars-24', class: ''},
  {path: '/help', title: 'Help', icon: 'help-24', class: ''},
  {path: '/logout', title: 'Logout', icon: 'logout-24', class: ''}

];

@Component({
  selector: 'app-group-sidebar',
  templateUrl: './group-sidebar.component.html',
  styleUrls: ['./group-sidebar.component.scss']
})
export class GroupSidebarComponent implements OnInit {

  @Input() navPosition: boolean;

  public menuItems: any[];
  public isCollapsed = true;

  adminMenu = this.translate.instant('sidebar.spaceManagement');
  bookingMenu = this.translate.instant('sidebar.booking');
  configMenu = this.translate.instant('sidebar.configuration');

  showAdminMenus = false;
  showBookingMenus = false;
  showConfigMenus = false;

//  collapseNav = false;
  currentUser: any;
 // nestedMenuItems: any[];
  features: any[];

  private translationMapDe = {
    'Dashboard': 'sidebar.dashBoard',
    'Super User Dashboard': 'Super User Dashboard',
    'Booking': 'sidebar.booking',
    'Locate': 'sidebar.locate',
    'Check Availability': 'sidebar.availability',
    'Users': 'sidebar.users',
    'Space Management': 'sidebar.spaceManagement',
    'Configuration': 'sidebar.configuration',
    'Audit logs': 'sidebar.logs',
    'Help': 'sidebar.help',
    'Logout': 'sidebar.logout'
  };

  private translationMapEn = {
    'Armaturenbrett': 'sidebar.dashBoard',
    'Super User Dashboard': 'Super User Dashboard',
    'Buchung': 'sidebar.booking',
    'Lokalisieren': 'sidebar.locate',
    'Verfügbarkeit prüfen': 'sidebar.availability',
    'Benutzer': 'sidebar.users',
    'Raum-Management': 'sidebar.spaceManagement',
    'Aufbau': 'sidebar.configuration',
    'Audit-Protokolle': 'sidebar.logs',
    'Hilfe': 'sidebar.help',
    'Ausloggen': 'sidebar.logout'
  };

  private groupTranslationMapDe = {
    'Book Workspace': 'sidebar.bookingOptions.bookWorkstation',
    'Express Booking': 'sidebar.bookingOptions.expressBooking',
    'Auto Assign': 'sidebar.bookingOptions.autoAssignBooking',
    'Book a Food': 'sidebar.bookingOptions.bookFood',
    'Bookings': 'sidebar.bookingOptions.bookingList',
    'Enable/Disable Wing': 'sidebar.spaceManagementOptions.enableDisableWing',
    'Enable/Disable Workstation': 'sidebar.spaceManagementOptions.enableDisableWorkstation',
    'Capacity Planning': 'sidebar.spaceManagementOptions.capacityManagement',
    'Workstations': 'sidebar.spaceManagementOptions.viewWorkstation',
    'First Aid Responders': 'sidebar.spaceManagementOptions.firstAidResponder',
    'Departments': 'sidebar.configurationOptions.viewDepartment',
    'Sub-Departments': 'sidebar.configurationOptions.viewSubDepartment',
    'Countries': 'sidebar.configurationOptions.viewcountry',
    'Cities': 'sidebar.configurationOptions.viewcity',
    'Organisations': 'sidebar.configurationOptions.viewOrganisation',
    'Buildings': 'sidebar.configurationOptions.viewbuilding',
    'Floors': 'sidebar.configurationOptions.viewFloor',
    'Wings': 'sidebar.configurationOptions.viewWing',
    'Add Workspace': 'sidebar.configurationOptions.addWorkstation'
  };

  private groupTranslationMapEn = {
    'Einen Arbeitsplatz buchen': 'sidebar.bookingOptions.bookWorkstation',
    'Express-Buchung': 'sidebar.bookingOptions.expressBooking',
    'Buchung automatisch zuweisen': 'sidebar.bookingOptions.autoAssignBooking',
    'Book a Food': 'sidebar.bookingOptions.bookFood',
    'Buchungen': 'sidebar.bookingOptions.bookingList',
    'Flügel aktivieren/deaktivieren': 'sidebar.spaceManagementOptions.enableDisableWing',
    'Arbeitsplatz aktivieren/deaktivieren': 'sidebar.spaceManagementOptions.enableDisableWorkstation',
    'Kapazitätsmanagement': 'sidebar.spaceManagementOptions.capacityManagement',
    'Arbeitsplätze': 'sidebar.spaceManagementOptions.viewWorkstation',
    'Ersthelfer': 'sidebar.spaceManagementOptions.firstAidResponder',
    'Abteilung': 'sidebar.configurationOptions.viewDepartment',
    'Unterabteilungen': 'sidebar.configurationOptions.viewSubDepartment',
    'Länder': 'sidebar.configurationOptions.viewcountry',
    'Städte': 'sidebar.configurationOptions.viewcity',
    'Organisations': 'sidebar.configurationOptions.viewOrganisation',
    'Gebäude': 'sidebar.configurationOptions.viewbuilding',
    'Etagen': 'sidebar.configurationOptions.viewFloor',
    'Flügel': 'sidebar.configurationOptions.viewWing',
    'Arbeitsstation hinzufügen': 'sidebar.configurationOptions.addWorkstation'
  };

  constructor(private router: Router,
              public translate: TranslateService,
              public communicationService: CommunicationService) {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser') || '{}');
    let dept = this.currentUser.department;
    this.communicationService.getFeatureByDept(dept).subscribe(res => {
      this.features = res;
      if (this.features.length !== 0) {
        let right = this.checkRouting();
        if (!right) {
          this.features.forEach(feature => {
            if (entityFeatures.BOOKWORKSPACE.includes(feature.feature_name)) {
              ROUTES[3].group.push({
                path: '/book-seat-au',
                title: 'Book Workspace/Parking',
                icon: 'book-closed-24',
                class: ''
              });
              ROUTES[3].group = ROUTES[3].group.filter((route) => route.title !== 'Book WorkSpace');
              ROUTES[3].group = ROUTES[3].group.filter((route) => route.title !== 'Auto Assign');
              ROUTES[3].group = ROUTES[3].group.filter((route) => route.title !== 'Express Booking');
            } else if ([entityFeatures.ZOOPASS].includes(feature.feature_name)) {
              ROUTES[3].group.push({path: '/book-zoopass', title: 'Book Zoo Pass', icon: 'sparkle-24', class: ''});
              ROUTES[7].group.push({path: '/view-zoo-pass', title: 'View Zoo Pass', icon: 'sparkle-24', class: ''});
            } else if (entityFeatures.CARPARKING.includes(feature.feature_name)) {
              ROUTES[3].group.push({path: '/book-car-parking', title: 'Book Car Parking', icon: 'car-24', class: ''});
              ROUTES[7].group.push({path: '/view-parkings', title: 'View Parking', icon: 'car-24', class: ''});
            } else if (entityFeatures.FOODBOOKING.includes(feature.feature_name)) {
              ROUTES[3].group.push({path: '/book-food', title: 'Book a Food', icon: 'book-closed-24', class: ''},);
            } else if (entityFeatures.CHECKAVAILABILITYAU.includes(feature.feature_name)) {
              ROUTES[5] = {path: '/booking-by-date-au', title: 'Check Availability', icon: 'success-24', class: ''};
              ROUTES[4] = {path: '/search-employee-desk-au', title: 'Locate', icon: 'search-24', class: ''};
            } else if (entityFeatures.FLOORRESPONDER.includes(feature.feature_name)) {
              ROUTES[3].group.push({
                path: '/fire-warden-bookings',
                title: 'Fire Wardens Bookings',
                icon: 'security-attention-24',
                class: ''
              });
              ROUTES[3].group.push({
                path: '/first-aid-bookings',
                title: 'First Aid Bookings',
                icon: 'thermometer-24',
                class: ''
              });
              ROUTES[7].group.push({path: '/responders', title: 'Responders', icon: 'success-24', class: ''});
              ROUTES[7].group = ROUTES[7].group.filter((route) => route.title !== 'First Aid Responders');
            }
          });
         /* ROUTES[2].group.push({
            path: '/view-bookings',
            title: 'Bookings List',
            icon: 'book-closed-24',
            class: ''
          });*/
        }
      }
      let isPresent = false;
      ROUTES[3].group.forEach(ele => {
        if (ele.path == '/view-bookings') {
          isPresent = true;
        }
      });
      if (!isPresent) {
        ROUTES[3].group.push({
          path: '/view-bookings',
          title: 'Bookings',
          icon: 'book-closed-24',
          class: ''
        });
      }

      this.handleRouteVar();
    }, error => {
      this.handleRouteVar();
    });
  }

  checkRouting() {
    let check = false;
    ROUTES[3].group.forEach(ele => {
      if (ele.path == '/book-zoopass' || ele.path == '/book-car-parking' || ele.path == '/book-food') {
        check = true;
      }
      if (ele.path == '/book-seat-au') {
        check = true;
      }
    });
    if (ROUTES[5].path == '/booking-by-date-au' || ROUTES[4].path == '/search-employee-desk-au') {
      check = true;
    }
    return check;
  }

  handleRouteVar() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser') || '{}');
    //this.getUserDepartent();

    if ([userRoles.ADMIN, userRoles.SUPERADMIN].includes(this.currentUser.role) === false) {
      this.menuItems = this.menuItems.filter((route) => route.title !== 'Configuration');
      this.menuItems = this.menuItems.filter((route) => route.title !== 'Audit logs');
    }

    /* if ([userRoles.ADMIN, userRoles.DEPARTMENTHEAD, userRoles.MANAGER, userRoles.HR, userRoles.EMPLOYEE].includes(this.currentUser.role) === false) {
       this.menuItems = this.menuItems.filter((route) => route.title !== 'Dashboard');
     }*/

    if ([userRoles.ADMIN, userRoles.DEPARTMENTHEAD, userRoles.MANAGER, userRoles.HR, userRoles.EMPLOYEE].includes(this.currentUser.role) === true) {
      this.menuItems = this.menuItems.filter((route) => route.title !== 'Super User Dashboard');
    }

    if ([userRoles.SUPERADMIN, userRoles.ADMIN, userRoles.DEPARTMENTHEAD,userRoles.HR].includes(this.currentUser.role) === false) {
      this.menuItems = this.menuItems.filter((route) => route.title !== 'Users');
      this.menuItems = this.menuItems.filter((route) => route.title !== 'Space Management');
      this.menuItems = this.menuItems.filter((route) => route.title !== 'Admin Dashboard');
    }

    if ([userRoles.ADMIN].includes(this.currentUser.role) === true) {
      this.menuItems = this.menuItems.filter((route) => route.title !== 'Super User Dashboard');
      this.menuItems[7].group = this.menuItems[7].group.filter((route) => route.title !== 'Organisations');
      this.menuItems[7].group = this.menuItems[7].group.filter((route) => route.title !== 'Departments');
      this.menuItems[7].group = this.menuItems[7].group.filter((route) => route.title !== 'Sub-Departments');
      this.menuItems[7].group = this.menuItems[7].group.filter((route) => route.title !== 'Countries');
      this.menuItems[7].group = this.menuItems[7].group.filter((route) => route.title !== 'Cities');
    }
    this.translateMenuList();
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe(() => {
      this.handleRouteVar();
      this.translateMenuList();
    });
  }

  /*getUserDepartent() {
    let dept = this.currentUser.department;
    // this.communicationService.getFeatureByDept(dept).subscribe(res => {
    //   this.features = res;

    //   if (this.features.length !== 0) {
    //     this.features.forEach(feature => {
    //       if ([entityFeatures.FOODBOOKING].includes(feature.feature_name) === false) {
    //         this.menuItems[1].group = this.menuItems[1].group.filter((route) => route.title !== 'Book a Food');
    //       }
    //       if ([entityFeatures.ZOOPASS].includes(feature.feature_name) === false) {
    //         this.menuItems[1].group = this.menuItems[1].group.filter((route) => route.title !== 'Book Zoo Pass');
    //       }
    //       if ([entityFeatures.CARPARKING].includes(feature.feature_name) === false) {
    //         this.menuItems[1].group = this.menuItems[1].group.filter((route) => route.title !== 'Book Car Parking');
    //       }
    //       if ([entityFeatures.VIEWZOOPASS].includes(feature.feature_name) === false) {
    //         this.menuItems[7].group = this.menuItems[7].group.filter((route) => route.title !== 'View Zoo Pass');
    //       }
    //       if ([entityFeatures.VIEWPARKING].includes(feature.feature_name) === false) {
    //         this.menuItems[7].group = this.menuItems[7].group.filter((route) => route.title !== 'View Parking');
    //       }
    //       if ([entityFeatures.CHECKAVAILABILITYAU].includes(feature.feature_name) === false) {
    //         this.menuItems[4].group = this.menuItems[4].group.filter((route) => route.title !== 'Check Availability');
    //       }
    //       else{
    //         this.menuItems[3].group = this.menuItems[3].group.filter((route) => route.title !== 'Check Availability');
    //       }

    //     })
    //   } else {
    //     this.menuItems[1].group = this.menuItems[1].group.filter((route) => route.title !== 'Book a Food');
    //     this.menuItems[1].group = this.menuItems[1].group.filter((route) => route.title !== 'Book Zoo Pass');
    //     this.menuItems[1].group = this.menuItems[1].group.filter((route) => route.title !== 'Book Car Parking');
    //     this.menuItems[7].group = this.menuItems[7].group.filter((route) => route.title !== 'View Zoo Pass');
    //     this.menuItems[7].group = this.menuItems[7].group.filter((route) => route.title !== 'View Parking');
    //     this.menuItems[4].group = this.menuItems[4].group.filter((route) => route.title !== 'Check Availability');

    //   }
    // });
  }*/

  translateMenuList() {
    const currentLang = this.translate.currentLang;
    this.translate.get('sidebar.booking').subscribe((translatedValue: string) => {
      this.bookingMenu = translatedValue;
    });
    this.translate.get('sidebar.configuration').subscribe((translatedValue: string) => {
      this.configMenu = translatedValue;
    });
    this.translate.get('sidebar.spaceManagement').subscribe((translatedValue: string) => {
      this.adminMenu = translatedValue;
    });

    const translationMap = currentLang === 'de' ? this.translationMapDe : this.translationMapEn;
    const groupTranslationMap = currentLang === 'de' ? this.groupTranslationMapDe : this.groupTranslationMapEn;

    this.menuItems.forEach(item => {
      const translatedTitleKey = translationMap[item.title] || item.title;
      this.translate.get(translatedTitleKey).subscribe((translatedTitle: string) => {
        item.title = translatedTitle;

        if (item.group) {
          item.group.forEach(subItem => {
            const translatedSubTitleKey = groupTranslationMap[subItem.title] || subItem.title;
            this.translate.get(translatedSubTitleKey).subscribe((translatedSubTitle: string) => {
              subItem.title = translatedSubTitle;
            });
          });
        }
      });
    });
  }

  showHideMenu() {
    this.communicationService.showHideMenuBar = false;
  }

}
