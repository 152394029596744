import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'app-entity-feature',
  templateUrl: './entity-feature.component.html',
  styleUrls: ['./entity-feature.component.scss']
})
export class EntityFeatureComponent implements OnInit {
  heroes$;
  superlatives$ = new BehaviorSubject<{ [superlativeName: string]: string }>({});
  tableDataSource$ = new BehaviorSubject<any[]>([]);
  displayedColumns$ = new BehaviorSubject<string[]>([
    // 'department_name',
    'feature_name',
    'status',
    'action'

  ]);

currentPage$ = new BehaviorSubject<number>(1);
pageSize$ = new BehaviorSubject<number>(10);
dataOnPage$ = new BehaviorSubject<any[]>([]);
searchFormControl = new FormControl();
sortKey$ = new BehaviorSubject<string>('organization_name');
sortDirection$ = new BehaviorSubject<string>('asc');
totalUserCount = 0;
selectedDepartment: string;
departments: any;
departmentId:any;


  constructor(private communicationService: CommunicationService,
    private toastr: ToastrService,
    private router: Router,
    public  translate:  TranslateService) { }

  ngOnInit(): void {
    this.getDepartments();
    this.getAllFeatures();
  }

  updateFeature(featureId: string,department_id:string) {
    this.departments.forEach(dept=>{
      if(dept.department_name === this.selectedDepartment){
          this.departmentId = dept.department_id
      }
    })
      let deptId = department_id == null ?  this.departmentId:department_id;
      this.router.navigate(['add-edit-feature', featureId,deptId]);
  }


  getDepartments() {
    this.communicationService.getDepartments().subscribe(res => {
      this.departments = res;
      this.selectedDepartment="all";
    }, error => {
      console.error(error);
    });

}

  activateFeature(featureId) {
      this.communicationService.activateFeature(featureId).subscribe(() => {
        this.selectedDepartmentChange();
        this.toastr.success(this.translate.instant('toasterSuccessMessage.featureActivated'));
      }, error => {
        console.log(error);
        this.toastr.error('Unable to activate feature');
      });
  }

  disableFeature(featureId) {
    this.communicationService.disableFeature(featureId).subscribe(() => {
      this.selectedDepartmentChange();
      this.toastr.success(this.translate.instant('toasterSuccessMessage.featureDisabled'));

    }, error => {
      console.error('error', error);
      this.toastr.error('Unable to disable feature');
    });
}


  selectedDepartmentChange() {
    if(this.selectedDepartment === 'all'){
      this.getAllFeatures();
    }else{
    const userData = [];
      this.communicationService.getFeatureByDept(this.selectedDepartment).subscribe(res => {
        for (const obj of res) {
          userData.push(obj);
        }
        this.totalUserCount = userData.length;
        this.heroes$ = new BehaviorSubject(userData);
        combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
          .subscribe(([allSources, currentPage, pageSize]) => {
            const startingIndex = (currentPage - 1) * pageSize;
            const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
            this.dataOnPage$.next(onPage);
          });

        this.heroes$.pipe(take(1)).subscribe(heroData => {
          this.tableDataSource$.next(Object.values(heroData));
        });

        combineLatest(this.heroes$, this.sortKey$, this.sortDirection$)
          .subscribe(([changedHeroData, sortKey, sortDirection]) => {
            const heroesArray = Object.values(changedHeroData);

            const sortedHeroes = heroesArray.sort((a, b) => {
              if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
              if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
              return 0;
            });

            this.tableDataSource$.next(sortedHeroes);
          });

        combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
          .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
            const heroesArray = Object.values(changedHeroData);
            let filteredHeroes: any[];

            if (!searchTerm) {
              filteredHeroes = heroesArray;
            } else {
              const filteredResults = heroesArray.filter(hero => {
                return Object.values(hero)
                  .reduce((prev, curr) => {
                    if (curr != null) {
                      curr = curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
                    }
                    return prev || curr;
                  }, false);
              });
              filteredHeroes = filteredResults;
            }

            const sortedHeroes = filteredHeroes.sort((a, b) => {
              if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
              if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
              return 0;
            });

            this.tableDataSource$.next(sortedHeroes);
          });
      }, error => {
        console.log(error);
      });
    }
  }

  getAllFeatures() {
    const userData = [];
    this.communicationService.getFeatures().subscribe(res => {
      for (const obj of res) {
        userData.push(obj);
      }
      this.totalUserCount = userData.length;
      this.heroes$ = new BehaviorSubject(userData);
      combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
        .subscribe(([allSources, currentPage, pageSize]) => {
          const startingIndex = (currentPage - 1) * pageSize;
          const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
          this.dataOnPage$.next(onPage);
        });

      this.heroes$.pipe(take(1)).subscribe(heroData => {
        this.tableDataSource$.next(Object.values(heroData));
      });

      combineLatest(this.heroes$, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);

          const sortedHeroes = heroesArray.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });

          this.tableDataSource$.next(sortedHeroes);
        });

      combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          let filteredHeroes: any[];

          if (!searchTerm) {
            filteredHeroes = heroesArray;
          } else {
            const filteredResults = heroesArray.filter(hero => {
              return Object.values(hero)
                .reduce((prev, curr) => {
                  if (curr != null) {
                    curr = curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
                  }
                  return prev || curr;
                }, false);
            });
            filteredHeroes = filteredResults;
          }

          const sortedHeroes = filteredHeroes.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });

          this.tableDataSource$.next(sortedHeroes);
        });
    }, error => {
      console.log(error);
    });
  }

}
