import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Coordinate } from '../model/coordinate';
import { Desk } from 'src/app/model/desk';
import { FloorPath } from '../model/floorPath';
import { SeatsResponse } from '../model/seatsresponse';
import { TypeFlags } from 'typescript';


@Injectable({ providedIn: 'root' })
export class ValidationService {
   constructor() {
  }

   validationComment(params: any) {
    const specialChars = '$#@%^=;:[](){}*+-//|\\?<>`~';
    for (let i = 0; i < params.length; i++) {
        if (specialChars.includes(params.substring(i, i + 1))) {
              return true;
          }
      }
    return false;
  }

  validationWorkstationName(params: any) {
    const specialChars = '$#@%^=;:[]()*+|{}\\?<>`~';
    for (let i = 0; i < params.length; i++) {
        if (specialChars.includes(params.substring(i, i + 1))) {
              return true;
          }
      }
    return false;
  }

  validationSearchResult(params: any) {
    const specialChars = '$#@%^=;[]*+-//|{}\\?<>`~';
    for (let i = 0; i < params.length; i++) {
        if (specialChars.includes(params.substring(i, i + 1))) {
              return true;
          }
      }
    return false;
  }
}
