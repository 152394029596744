import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunicationService } from 'src/app/services/communication.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-edit-building',
  templateUrl: './add-edit-building.component.html',
  styleUrls: ['./add-edit-building.component.css']
})
export class AddEditBuildingComponent implements OnInit {
  countries = [];
  countriesNew: any[];
  cities = [];
  departments = [];
  buildingId: any;
  buildingDetails: any;
  buildingName: any;
  departmentName: any;
  detailsAddEditBuildingForm: FormGroup;
  isSuperUser = false;
  isDisabled: boolean;
  submitted:boolean;
  constructor(
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    private _Activatedroute: ActivatedRoute,
    private router: Router,
    public  translate:  TranslateService,
  ) { }

  ngOnInit(): void {
    this.buildingId = this._Activatedroute.snapshot.paramMap.get('buildingid');
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser.role === 'Super User') {
      this.isSuperUser = true;
      this.getDepartment();
    }
    this.getCountries();
    this.getCities();
    this.detailsAddEditBuildingForm = this.formBuilder.group(
      {
        country: ['', Validators.required],
        countryName: ['', Validators.required],
        city: ['', Validators.required],
        building: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9\-\/\(\)\,\. ]*')]),
        cityName : ['', Validators.required],
        department: ['', Validators.required],
        departmentName: ['', Validators.required]
      }
    );
    if (this.buildingId != null) {
      this.getBuildingDetails(this.buildingId);
    } else {
       this.f.department.setValue( currentUser.department);
    }
  }

  get building() {
    return this.detailsAddEditBuildingForm.get('building');
  }

  getBuildingDetails(buildingId) {
    this.communicationService.getBuildingById(buildingId).subscribe(res => {
      this.buildingDetails = res;
      this.buildingName = res.building_name;
      this.departmentName = res.department_name;
      this.f.departmentName.setValue(this.departmentName);
      this.f.cityName.patchValue(res.city_name);
      this.f.countryName.patchValue(res.country_name);
      this.f.country.setValue(res.country_id);
      this.getCitiesByCountry(res.country_id);
      this.f.city.setValue(res.city_id);
      if (this.isSuperUser) {
        for (const department of this.departments) {
          if (department.department_name === this.departmentName) {
            this.f.department.setValue(department.department_id);
            this.f.departmentName.setValue(this.departmentName);
          }
        }
      }
    }, error => {
      console.log(error);
    });
  }

 getCountries() {
    this.communicationService.getCountries().subscribe(res => {
      this.countries = res;
      if (this.buildingId == null) {
        this.f.country.setValue(res[0].country_id);
      }
    }, error => {
      console.error(error);
    });
  }

  getCities() {
    this.communicationService.getCities().subscribe(res => {
      this.cities = res;
      if (this.buildingId == null) {
        this.f.city.setValue(res[0].city_id);
      }
    }, error => {
      console.error(error);
    });
  }
  onCountryChange(value) {
    this.getCitiesByCountry(value.target.value);
  }
  getCitiesByCountry(country_id: string) {
    this.communicationService.getCitiesByCountry(country_id).subscribe(res => {
       this.cities = res;
       this.f.city.setValue(res[0].city_id);
    }, error => {
      console.error(error);
    });
  }

  getDepartment() {
    this.communicationService.getDepartments().subscribe(res => {
      this.departments = res;
      if (this.buildingId == null) {
      this.f.department.setValue(res[0].department_id);
      }
    }, error => {
      console.error(error);
      this.toastr.error(this.translate.instant('toasterSuccessMessage.departmentError'));
    });
  }

  onSubmit(event:any) {
    this.isDisabled = true;
    if (this.detailsAddEditBuildingForm.value.building) {
       const request = {
        country_id: this.detailsAddEditBuildingForm.value.country,
        city_id: this.detailsAddEditBuildingForm.value.city,
        building_name: this.detailsAddEditBuildingForm.value.building === '' ? this.buildingName : this.detailsAddEditBuildingForm.value.building,
        building_id: this.buildingId != null ? this.buildingId : null,
        department_id: this.detailsAddEditBuildingForm.value.department,
        status: '0'
      };
      this.communicationService.addBuilding(request).subscribe(res => {
          this.toastr.success(this.translate.instant('toasterSuccessMessage.buildingAdded'));
          this.router.navigate(['/view-building']);
      }, error => {
        this.isDisabled = false;
        this.toastr.error(error.error.message);
      });
    } else {
      this.isDisabled = false;
       this.toastr.error(this.translate.instant('addeditbuilding.buildingNameErrorMsg'));
    }
  }

  get f() {
    return this.detailsAddEditBuildingForm.controls;
  }

  onCancel(event:any) {
    this.isDisabled = true;
    this.router.navigate(['/view-building']);
  }

}
