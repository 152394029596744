import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule,APP_INITIALIZER} from '@angular/core';
import { GroupUiModule } from '@sdc-wob-type-3/group-ui-angular';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { LoginComponent } from './pages/login/login.component';
import { JwtInterceptor } from './helper/jwt.interceptor';
import { AuditLogsComponent } from './pages/audit-logs/audit-logs.component';
import { CdkTableModule } from '@angular/cdk/table';
import { UsersComponent } from './pages/users/users.component';
import { ViewBookingsComponent } from './pages/view-bookings/view-bookings.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { ConfirmationDialogService } from './pages/confirmation-dialog/confirmation-dialog.service';
import { ConfirmationDialogCovidService } from './pages/confirmation-dialog-covid/confirmation-dialog-covid.service';
import { RuleConfigurationComponent } from './pages/rule-configuration/rule-configuration.component';
import { SearchEmployeeDeskAuComponent } from './pages/search-employee-desk-au/search-employee-desk-au.component';
import { SearchEmployeeDeskComponent } from './pages/search-employee-desk/search-employee-desk.component';
import { ApprovRejectBookingComponent } from './pages/approv-reject-booking/approv-reject-booking.component';
import { DisableFloorComponent } from './pages/disable-floor/disable-floor.component';
import { ViewOnMapComponent } from './pages/view-on-map/view-on-map.component';
import { ToastrModule } from 'ngx-toastr';
import { MatIconModule } from '@angular/material/icon';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ExpressBookingComponent } from './pages/express-booking/express-booking.component';
import { AddUserComponent } from './pages/add-user/add-user.component';
import {
  AuthModule,
  OidcSecurityService,
  /*OpenIDImplicitFlowConfiguration,
  AuthWellKnownEndpoints,*/
  EventTypes, OidcConfigService, PublicEventsService
} from 'angular-auth-oidc-client';
import { EnableDisableSeatComponent } from './pages/enable-disable-seat/enable-disable-seat.component';
import { UserPreferenceComponent } from './pages/user-preference/user-preference.component';
import { QrCodeBookingComponent } from './pages/qr-code-booking/qr-code-booking.component';
import { BookingByDateComponent } from './pages/booking-by-date/booking-by-date.component';
import { UpdateUserRoleComponent } from './pages/update-user-role/update-user-role.component';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AddEditCountryComponent } from './pages/add-edit-country/add-edit-country.component';
import { AddEditCityComponent } from './pages/add-edit-city/add-edit-city.component';
import { AddEditBuildingComponent } from './pages/add-edit-building/add-edit-building.component';
import { ViewCountryComponent } from './pages/view-country/view-country.component';
import { ViewCityComponent } from './pages/view-city/view-city.component';
import { ViewBuildingComponent } from './pages/view-building/view-building.component';
import { ViewFloorComponent } from './pages/view-floor/view-floor.component';
import { AddEditFloorComponent } from './pages/add-edit-floor/add-edit-floor.component';
import { ViewWingComponent } from './pages/view-wing/view-wing.component';
import { AddEditWingComponent } from './pages/add-edit-wing/add-edit-wing.component';
import { FirstAidComponent } from './pages/first-aid/first-aid.component';
import { ViewWorkstationsComponent } from './pages/view-workstations/view-workstations.component';
import { AddFirstAidComponent } from './pages/add-first-aid/add-first-aid.component';
import { AutoAssignBookingComponent } from './pages/auto-assign-booking/auto-assign-booking.component';
import { HelpComponent } from './pages/help/help.component';
import { ViewDepartmentComponent } from './pages/view-department/view-department.component';
import { AddEditDepartmentComponent } from './pages/add-edit-department/add-edit-department.component';
import { AddWorkstationComponent } from './pages/add-workstation/add-workstation.component';
import { ViewOrganisationComponent } from './pages/view-organisation/view-organisation.component';
import { AddEditOrganisationComponent } from './pages/add-edit-organisation/add-edit-organisation.component';
import { ViewSubDepartmentComponent } from './pages/view-sub-department/view-sub-department.component';
import { AddEditSubDepartmentComponent } from './pages/add-edit-sub-department/add-edit-sub-department.component';
import {GroupNavbarComponent} from './components/group-navbar/group-navbar.component';
//import {GroupCardsComponent} from './pages/group-dashboard/group-cards/group-cards.component';
import { SuperUserDashboardComponent } from './pages/super-user-dashboard/super-user-dashboard.component';
import {AdminLayoutModule, initializeApp} from './layouts/admin-layout/admin-layout.module';
import {NgIdleModule} from '@ng-idle/core';
import {BookSeatComponent} from './pages/book-seat/book-seat.component';
import { environment } from 'src/environments/environment';
import { CommonModule } from '@angular/common';
import { BookFoodComponent } from './pages/book-food/book-food.component';
import { BookZoopassComponent } from './pages/book-zoopass/book-zoopass.component';
import { BookCarParkingComponent } from './pages/book-car-parking/book-car-parking.component';
import { ViewParkingsComponent } from './pages/view-parkings/view-parkings.component';
import { AddEditZooPassComponent } from './pages/add-edit-zoo-pass/add-edit-zoo-pass.component';
import { AddParkingComponent } from './pages/add-parking/add-parking.component';
import {ViewZooPassComponent} from './pages/view-zoo-pass/view-zoo-pass.component';
import { BookingByDateAuComponent } from './pages/booking-by-date-au/booking-by-date-au.component';
import { FirstAidBookingsComponent } from './pages/first-aid-bookings/first-aid-bookings.component';
import { FireWardenBookingsComponent } from './pages/fire-warden-bookings/fire-warden-bookings.component';
import { RespondersComponent } from './pages/responders/responders.component';
import { AppInitService } from './helper/app-init.service';
import { BookSeatAuComponent } from './pages/book-seat-au/book-seat-au.component';
import { EntityFeatureComponent } from './pages/entity-feature/entity-feature.component';
import { AddEditFeatureComponent } from './pages/add-edit-feature/add-edit-feature.component';
import {filter} from 'rxjs/operators';
import { CreateQrCodeComponent } from './pages/create-qr-code/create-qr-code.component';
import { AdminDashboardComponent } from './pages/admin-dashboard/admin-dashboard.component';

export  function  HttpLoaderFactory(http:  HttpClient) {
  return  new  TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function CurrentLanguageFactory(translate: TranslateService) {
  return translate.currentLang;
}
export function configureAuth(oidcConfigService: OidcConfigService) {
  return () =>
    oidcConfigService.withConfig({
      stsServer: 'https://idp.cloud.vwgroup.com/auth/realms/kums',
      redirectUrl: window.location.origin,
      // postLoginRoute: '/book-seat',
      postLogoutRedirectUri: window.location.origin,
      clientId: 'idp-e6d127c7-0b11-40a5-bdeb-168a10b7748b-Digital-Products-BookMySpace-Prod',
      scope: 'openid email ',
      responseType: 'id_token token',
      silentRenew: false,
      useRefreshToken: true,
      // logLevel: LogLevel.Debug,
    });
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        GroupUiModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        ComponentsModule,
        NgbModule,
        RouterModule,
        AppRoutingModule,
        ReactiveFormsModule,
        CdkTableModule,
        CommonModule,
        ToastrModule.forRoot(), // ToastrModule added
        AuthModule.forRoot(),
        NgIdleModule.forRoot(),
        MatIconModule,
        NgxSliderModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),


  ],
  declarations: [
    LoginComponent,
    AppComponent,
    AdminLayoutComponent,
    AuditLogsComponent,
    UsersComponent,
    ViewBookingsComponent,
    SearchEmployeeDeskAuComponent,
    SearchEmployeeDeskComponent,
    ApprovRejectBookingComponent,
    DisableFloorComponent,
    LogoutComponent,
    RuleConfigurationComponent,
    ViewOnMapComponent,
    ExpressBookingComponent,
    AddUserComponent,
    EnableDisableSeatComponent,
    UserPreferenceComponent,
    QrCodeBookingComponent,
    BookingByDateComponent,
    UpdateUserRoleComponent,
    AddEditCountryComponent,
    AddEditCityComponent,
    AddEditBuildingComponent,
    ViewCountryComponent,
    ViewCityComponent,
    ViewBuildingComponent,
    ViewFloorComponent,
    ViewWingComponent,
    AddEditFloorComponent,
    AddEditWingComponent,
    BookZoopassComponent,
    FirstAidComponent,
    ViewWorkstationsComponent,
    AddFirstAidComponent,
    AutoAssignBookingComponent,
    HelpComponent,
    AddWorkstationComponent,
    ViewOrganisationComponent,
    AddEditOrganisationComponent,
    ViewDepartmentComponent,
    AddEditDepartmentComponent,
    ViewSubDepartmentComponent,
    AddEditSubDepartmentComponent,
    GroupNavbarComponent,
    BookSeatComponent,
    SuperUserDashboardComponent,
    BookFoodComponent,
    BookCarParkingComponent,
    ViewParkingsComponent,
    AddEditZooPassComponent,
    AddParkingComponent,
    ViewZooPassComponent,
    BookingByDateAuComponent,
    FirstAidBookingsComponent,
    FireWardenBookingsComponent,
    RespondersComponent,
    BookSeatAuComponent,
    EntityFeatureComponent,
    AddEditFeatureComponent,
    CreateQrCodeComponent,
    AdminDashboardComponent,
  ],
  providers: [
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [AppInitService]
  },

    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    ConfirmationDialogService, ConfirmationDialogCovidService,
    OidcSecurityService, LoginComponent], bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private readonly eventService: PublicEventsService,
              translate: TranslateService) {
    translate.addLangs(['en', 'de']);
    translate.setDefaultLang('en');
    this.eventService
      .registerForEvents()
      .pipe(filter((notification) => notification.type === EventTypes.ConfigLoaded))
      .subscribe((config) => {
        console.log('Welcome');
      });
  }
}
