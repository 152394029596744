import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { userRoles } from 'src/app/helper/userRoles';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'app-add-edit-department',
  templateUrl: './add-edit-department.component.html',
  styleUrls: ['./add-edit-department.component.css']
})
export class AddEditDepartmentComponent implements OnInit {
  detailsAddEditDepartmentForm: FormGroup;
  organizations = [];
  departmentId: any;
  organizationDetails: any = null;
  departmentName: any;
  organizationName: any;
  currentUser: any;
  isDisabled: boolean;
  adminUserCount= 3;
  activeBookingCount =3;
  bookingAllowedDays= 13;
  bookingFutureDays= 13;
  parkingBookingFutureDays=0;
  parkingBookingCount= 0;
  foodBookingDays= 0;
  zooPassCount= 0;
  zooPassFutureDays= 0;
  departmentConfigStatus= 0;
  emailStatus=0;

  constructor(
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    private _Activatedroute: ActivatedRoute,
    private router: Router,
    public  translate:  TranslateService,
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser') || '{}');
    this.departmentId = this._Activatedroute.snapshot.paramMap.get('departmentid');
    this.detailsAddEditDepartmentForm = this.formBuilder.group(
      {
        organization: ['', Validators.required],
        department: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9\-\/\(\)\,\. ]*')]),
        country: ['', Validators.required],
        adminUserCount:['', Validators.required],
        activeBookingCount:['', Validators.required],
        bookingAllowedDays:['', Validators.required],
        bookingFutureDays:['', Validators.required],
        parkingBookingCount:['', Validators.required],
        parkingBookingFutureDays:['',Validators.required],
        foodBookingDays:['', Validators.required],
        zooPassCount:['', Validators.required],
        zooPassFutureDays:['', Validators.required],
        departmentConfigStatus:['', Validators.required],
        emailStatus:['', Validators.required]
      }
    );
    this.getOrganizationList();
    if (this.departmentId != null) {
      this.getDepartmentDetails(this.departmentId);
    }else{
      this.setDefaultDepartmentConfigDetails();
    }
  }
  setDefaultDepartmentConfigDetails() {
    this.f.adminUserCount.setValue(this.adminUserCount);
    this.f.activeBookingCount.setValue(this.activeBookingCount);
    this.f.bookingAllowedDays.setValue(this.bookingAllowedDays);
    this.f.bookingFutureDays.setValue(this.bookingFutureDays);
    this.f.parkingBookingCount.setValue(this.parkingBookingCount);
    this.f.parkingBookingFutureDays.setValue(this.parkingBookingFutureDays)
    this.f.foodBookingDays.setValue(this.foodBookingDays);
    this.f.zooPassCount.setValue(this.zooPassCount);
    this.f.zooPassFutureDays.setValue(this.zooPassFutureDays);
    this.f.departmentConfigStatus.setValue(this.departmentConfigStatus);
    this.f.emailStatus.setValue(this.emailStatus);

  }


  get department() {
    return this.detailsAddEditDepartmentForm.get('department');
  }

  getOrganizationList() {
    this.communicationService.getOrganizations().subscribe(res => {
      this.organizations = res;
      if (this.departmentId == null) {
        this.f.organization.setValue(res[0].organization_id);
      }
    }, error => {
      console.error(error);
    });
  }
  showOrganizations() {
    return this.currentUser.role === userRoles.SUPERADMIN ? true : false;
   }
  getDepartmentDetails(departmentId: any) {
    this.communicationService.getDepartmentById(departmentId).subscribe(res => {
      if(res.admin_user_count== null){
        this.setDefaultDepartmentConfigDetails();
      }
     this.f.department.setValue(res.department_name);
     this.f.organization.setValue(res.organization_id);
     this.f.country.setValue(res.country_name);
     this.f.adminUserCount.setValue(res.admin_user_count);
     this.f.activeBookingCount.setValue(res.active_booking_count);
     this.f.bookingAllowedDays.setValue(res.booking_allowed_days);
     this.f.bookingFutureDays.setValue(res.booking_future_days);
     this.f.parkingBookingCount.setValue(res.parking_booking_count);
     this.f.parkingBookingFutureDays.setValue(res.parking_booking_future_days);
     this.f.foodBookingDays.setValue(res.food_booking_days);
     this.f.zooPassCount.setValue(res.zoopass_count);
     this.f.zooPassFutureDays.setValue(res.zoopass_future_days);
     this.f.departmentConfigStatus.setValue(res.config_status);
     this.f.emailStatus.setValue(res.email_status);
  }, error => {
    console.log(error);
  });
  }

  getOrganizationDetails() {
    this.communicationService.getOrganization().subscribe(res => {
      this.organizationDetails = res;
     this.organizationName = res.organization_name;
     this.f.organization.setValue(this.organizationName);
  }, error => {
    console.log(error);
  });
  }
  disableConfigStatus(){

  }
  onSubmit(event:any) {
    this.isDisabled = true;
    if (this.detailsAddEditDepartmentForm.value.department) {
      const request = {
        department_id: this.departmentId != null ? this.departmentId : null,
        department_name: this.detailsAddEditDepartmentForm.value.department === '' ? this.departmentName : this.detailsAddEditDepartmentForm.value.department,
        status: '0',
        organization_id: this.organizationDetails == null ? this.detailsAddEditDepartmentForm.value.organization : this.organizationDetails.organization_id,
        admin_user_count:this.detailsAddEditDepartmentForm.value.adminUserCount == '' ? this.adminUserCount: this.detailsAddEditDepartmentForm.value.adminUserCount,
        active_booking_count:this.detailsAddEditDepartmentForm.value.activeBookingCount == '' ?this.activeBookingCount:this.detailsAddEditDepartmentForm.value.activeBookingCount,
        booking_allowed_days:this.detailsAddEditDepartmentForm.value.bookingAllowedDays == '' ?this.bookingAllowedDays:this.detailsAddEditDepartmentForm.value.bookingAllowedDays,
        booking_future_days:this.detailsAddEditDepartmentForm.value.bookingFutureDays == '' ?this.bookingFutureDays:this.detailsAddEditDepartmentForm.value.bookingFutureDays,
        parking_booking_count:this.detailsAddEditDepartmentForm.value.parkingBookingCount == '' ?this.parkingBookingCount:this.detailsAddEditDepartmentForm.value.parkingBookingCount,
        parking_booking_future_days:this.detailsAddEditDepartmentForm.value.parkingBookingFutureDays=''?this.parkingBookingFutureDays:this.detailsAddEditDepartmentForm.value.parkingBookingFutureDays,
        food_booking_days:this.detailsAddEditDepartmentForm.value.foodBookingDays == '' ?this.foodBookingDays:this.detailsAddEditDepartmentForm.value.foodBookingDays,
        zoopass_count:this.detailsAddEditDepartmentForm.value.zooPassCount =='' ?this.zooPassCount:this.detailsAddEditDepartmentForm.value.zooPassCount,
        zoopass_future_days:this.detailsAddEditDepartmentForm.value.zooPassFutureDays == '' ?this.zooPassFutureDays:this.detailsAddEditDepartmentForm.value.zooPassFutureDays,
        config_status:this.detailsAddEditDepartmentForm.value.departmentConfigStatus == '' ?this.departmentConfigStatus:this.detailsAddEditDepartmentForm.value.departmentConfigStatus,
        email_status:this.detailsAddEditDepartmentForm.value.emailStatus == '' ?this.emailStatus:this.detailsAddEditDepartmentForm.value.emailStatus
      };
      this.communicationService.addDepartment(request).subscribe(res => {
          this.toastr.success(this.translate.instant('toasterSuccessMessage.departmentAdded'));
          this.router.navigate(['/view-department']);
      }, error => {
        this.isDisabled = false;
        this.toastr.error(error.error.message);
      });
    } else {
      this.isDisabled = false;
      this.toastr.error(this.translate.instant('addeditdepartment.departmentNameErrorMsg'));
    }
  }

  get f() {
    return this.detailsAddEditDepartmentForm.controls;
  }

  onCancel(event) {
    this.isDisabled = true;
    this.router.navigate(['/view-department']);
  }

}

