import { Injectable } from '@angular/core';
import { GuardsCheckStart, Router } from '@angular/router';
import { AddEditZooPassComponent } from '../pages/add-edit-zoo-pass/add-edit-zoo-pass.component';
import { AddParkingComponent } from '../pages/add-parking/add-parking.component';
import { BookCarParkingComponent } from '../pages/book-car-parking/book-car-parking.component';
import { BookFoodComponent } from '../pages/book-food/book-food.component';
import { BookZoopassComponent } from '../pages/book-zoopass/book-zoopass.component';
import { BookingByDateAuComponent } from '../pages/booking-by-date-au/booking-by-date-au.component';
import { SearchEmployeeDeskAuComponent } from '../pages/search-employee-desk-au/search-employee-desk-au.component';
import { ViewParkingsComponent } from '../pages/view-parkings/view-parkings.component';
import { ViewZooPassComponent } from '../pages/view-zoo-pass/view-zoo-pass.component';
import { CommunicationService } from '../services/communication.service';
import { entityFeatures } from './entityFeatures';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  
  flag: boolean = false;
  constructor(private Router: Router, private communicationService: CommunicationService) {
    this.Router.events.subscribe(ele => {
      if (ele instanceof GuardsCheckStart) {
        const routes = this.Router.config;

        let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        if (currentUser && !this.flag) {
          let dept = currentUser.department;
          this.communicationService.getFeatureByDept(dept).subscribe(res => {
            let features = res;
            this.flag = true;
            this.handleRes(features,routes);
            this.Router.resetConfig(routes);
          })
        }
      }
    })
  }

  isExecuted : boolean = false;  
  handleRes(features,routes){
    if (features.length !== 0 && !this.isExecuted) {
      this.communicationService.featureEmitter.emit(features);
      this.communicationService.setData("features",features);
      features.forEach(feature => {
        this.isExecuted= true;
        if ([entityFeatures.ZOOPASS].includes(feature.feature_name)) {
          routes[1]['children'].push(
            { path: 'book-zoopass', component: BookZoopassComponent },
            { path: 'view-zoo-pass', component: ViewZooPassComponent },
            { path: 'add-edit-zoo-pass', component: AddEditZooPassComponent },
            { path: 'add-edit-zoo-pass/:zoopassid', component: AddEditZooPassComponent }
          );
        } else if (entityFeatures.VIEWPARKING.includes(feature.feature_name)) {
          routes[1]['children'].push(
            { path: 'book-car-parking', component: BookCarParkingComponent },
            { path: 'add-parking/:parkingid', component: AddParkingComponent },
            { path: 'view-parkings', component: ViewParkingsComponent },
            { path: 'add-parking', component: AddParkingComponent },
          );
        } else if (entityFeatures.CHECKAVAILABILITYAU.includes(feature.feature_name)) {
          routes[1]['children'].push(
            { path: 'booking-by-date-au', component: BookingByDateAuComponent },
            { path: 'search-employee-desk-au', component: SearchEmployeeDeskAuComponent },
          );
        } else if (entityFeatures.FOODBOOKING.includes(feature.feature_name)) {
          routes[1]['children'].push(
            { path: 'book-food', component: BookFoodComponent }
          );
        }
      });
    }
  }

  init() {
    return new Promise<void>((resolve, reject) => {
      const routes = this.Router.config;
      let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
      if (currentUser) {
        let dept = currentUser.department;
        this.communicationService.getFeatureByDept(dept).subscribe(res => {
          let features = res;
          this.handleRes(features,routes);
          this.Router.resetConfig(routes);
          resolve();
        })
      } else {
        resolve();
      }
    });
  }

}
