import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { FormControl, Validators , ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { CommunicationService } from 'src/app/services/communication.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Coordinate } from 'src/app/model/coordinate';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SeatAttributes } from 'src/app/model/seatattributes';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-view-workstations',
  templateUrl: './view-workstations.component.html',
  styleUrls: ['./view-workstations.component.scss']
})
export class ViewWorkstationsComponent implements OnInit {


  modalReference: NgbModalRef;
  detailsViewWorkstationForm: FormGroup;
  detailsViewWorkstationFormNew: FormGroup;
  detailsViewComment:FormGroup;
  heroes$;
  seat_number;
  emp_number;
  superlatives$ = new BehaviorSubject<{ [superlativeName: string]: string }>({});
  tableDataSource$ = new BehaviorSubject<any[]>([]);
  displayedColumns$ = new BehaviorSubject<string[]>([
    'name',
    'status',
    'reserved',
    'id'
  ]);
  start_Date: Date;
  end_Date: Date;
  wings = [];
  floors = [];
  cities = [];
  countries = [];
  buildings = [];
  JSONattribute: SeatAttributes;
  attribute: SeatAttributes;
  attributeMap = new Map<string, string>();
  attributeValue: any;
  attributeMapObject = {};
  currentPage$ = new BehaviorSubject<number>(1);
  pageSize$ = new BehaviorSubject<number>(10);
  dataOnPage$ = new BehaviorSubject<any[]>([]);
  searchFormControl = new FormControl();
  sortKey$ = new BehaviorSubject<string>('name');
  sortDirection$ = new BehaviorSubject<string>('asc');
  totalWorkstations = 0;
  limit = 10000;
  startIndex = 0;
  status = [];
  closeResult: string;
  seats: Coordinate[] = [];
  JSONdata = 0;
  JsonAtt: {};
  submitted:boolean;
  comments: SeatAttributes;
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private router: Router,
    public  translate:  TranslateService
  ) { }

  ngOnInit() {
    this.getCountries();
    this.detailsViewWorkstationForm = this.formBuilder.group(
      {
        city: ['', Validators.required],
        country: ['', Validators.required],
        floor: ['', Validators.required],
        wing: ['', Validators.required],
        building: ['', Validators.required]
      }
    );
    this.detailsViewWorkstationFormNew = this.formBuilder.group(
        {
          name: ['', Validators.required],
          keyData: ['', Validators.required],
          valueData: ['', Validators.required],
          JSON_attribute: ['', Validators.required]
        }
    );
    this.detailsViewComment = this.formBuilder.group({
      name: ['', Validators.required],
      comments: ['', Validators.required]
    });
  }

  getCountries() {
    this.communicationService.getCountries().subscribe(res => {
      for (const country of res) {
          if (country.status === '0') {
             this.countries.push(country);
          }
      }
      this.f.country.setValue(this.countries[0].country_id);
      this.getCitiesByCountry(this.countries[0].country_id);
    }, error => {
      console.error(error);
    });
  }

   getCitiesByCountry(country_id: String) {
     this.communicationService.getCitiesByCountry(country_id).subscribe(res => {
        if (res.length > 0) {
          this.cities = [];
          for (const city of res) {
              if (city.status === '0') {
                 this.cities.push(city);
              }
          }
          this.f.city.setValue(this.cities[0].city_id);
          this.getBuildings(this.cities[0].city_id);
        } else {
          this.cities = [];
          this.f.city.setValue('');
          this.buildings = [];
          this.f.building.setValue('');
          this.floors = [];
          this.f.floor.setValue('');
          this.wings = [];
          this.f.wing.setValue('');
        }
     }, error => {
       console.error(error);
     });
   }

  get f() {
    return this.detailsViewWorkstationForm.controls;
  }

  onWingChange(event:any) {
    const limit = this.limit;
    const index = this.startIndex;
    this.getAllSeats(limit, index);
  }
  onFloorChange(value) {
    if (value) {
      this.f.wing.setValue('');
      this.getWings(value.target.value);
    }
  }

  onCityChange(value) {
     this.getBuildings(value.target.value);
  }

  onBuildingChange(value) {
    this.getFloors(value.target.value);
  }

  onCountryChange(value) {
    this.getCitiesByCountry(value.target.value);
  }

  getBuildings(value) {
    this.communicationService.getBuildings(value).subscribe(res => {
      if (res.length > 0) {
        this.buildings = [];
        for (const building of res) {
            if (building.status === '0') {
               this.buildings.push(building);
            }
        }
        if (this.buildings.length > 0) {
          this.f.building.setValue(this.buildings[0].building_id);
          this.getFloors(this.buildings[0].building_id);
        } else {
          this.f.building.setValue('');
          this.floors = [];
          this.f.floor.setValue('');
          this.wings = [];
          this.f.wing.setValue('');
        }
      } else {
        this.buildings = [];
        this.f.building.setValue('');
        this.floors = [];
        this.f.floor.setValue('');
        this.wings = [];
        this.f.wing.setValue('');
        this.dataOnPage$ = new BehaviorSubject<any[]>([]);
      }
    }, error => {
      console.error(error);
    });
  }

  getFloors(building_id: string) {
    this.communicationService.getFloorsByBuilding(building_id).subscribe(res => {
      if (res.length > 0) {
        this.floors = [];
        for (const floor of res) {
            if (floor.status === '0') {
               this.floors.push(floor);
            }
        }
        this.f.floor.setValue(this.floors[0].floor_id);
        this.getWings(this.floors[0].floor_id);
      } else {
        this.floors = [];
        this.f.floor.setValue('');
        this.wings = [];
        this.f.wing.setValue('');
        this.dataOnPage$ = new BehaviorSubject<any[]>([]);
      }
    }, error => {
      console.error(error);
    });
  }

  getWings(floor_id: string) {
    this.communicationService.getWings(floor_id).subscribe(res => {
      if (res.length > 0) {
        this.wings = [];
        for (const wing of res) {
            if (wing.status === '0') {
               this.wings.push(wing);
            }
        }
        this.f.wing.setValue(this.wings[0].wing_id);
        this.getAllSeats( this.limit, this.startIndex);
      } else {
         this.wings = [];
         this.f.wing.setValue('');
         this.dataOnPage$ = new BehaviorSubject<any[]>([]);
      }

    }, error => {
      console.error(error);
    });
  }

  adjustSort(key: string) {
    if (this.sortKey$.value === key) {
      if (this.sortDirection$.value === 'asc') {
        this.sortDirection$.next('desc');
      } else {
        this.sortDirection$.next('asc');
      }
      return;
    }
    this.sortKey$.next(key);
    this.sortDirection$.next('asc');
  }
   updateWorkstationStatus(seat_name, status, isReserved,floor, wing) {
    const seats: string[] = [];
    seats.push(seat_name);
     const searchObj = {
       seats: seats,
       status: status,
       reserved: isReserved,
       floor: floor,
       wing: wing,
     };
    this.communicationService.enableDisableSeat(searchObj).subscribe(res => {
      this.getAllSeats(0, 100);
      this.toastr.success('Workstation status updated successfully');

    }, error => {
      console.log(error);
      this.toastr.error('Failed to update workstation status');
    });
   }

  getAllSeats(limit: number, index: number) {
    const workstations = [];
    const searchObj = {
      limit: limit,
      index: index,
      floor_id: this.detailsViewWorkstationForm.value.floor,
      wing_id: this.detailsViewWorkstationForm.value.wing
    };
    this.communicationService.getSeats(searchObj).subscribe(res => {
      for (const obj of res) {
        workstations.push(obj);
      }

      this.totalWorkstations = workstations.length;
      this.heroes$ = new BehaviorSubject(workstations);
      combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
        .subscribe(([allSources, currentPage, pageSize]) => {
          const startingIndex = (currentPage - 1) * pageSize;
          const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
          this.dataOnPage$.next(onPage);
        });

      this.heroes$.pipe(take(1)).subscribe(heroData => {
        this.tableDataSource$.next(Object.values(heroData));
      });
      combineLatest(this.heroes$, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          const sortedHeroes = heroesArray.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });

          this.tableDataSource$.next(sortedHeroes);
        });

      combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          let filteredHeroes: any[];

          if (!searchTerm) {
            filteredHeroes = heroesArray;
          } else {
            const filteredResults = heroesArray.filter(hero => {
              return Object.values(hero)
                .reduce((prev, curr) => {
                  return prev || curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
                }, false);
            });
            filteredHeroes = filteredResults;
          }

          const sortedHeroes = filteredHeroes.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });

          this.tableDataSource$.next(sortedHeroes);
        });
    }, error => {
      console.log(error);
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  addComments(value, content){
    this.detailsViewComment.controls.comments.setValue("");  
    this.modalReference = this.modalService.open(content);
    this.detailsViewComment.controls.name.setValue(value);
    this.seat_number =  value;
    this.communicationService.getSeat(value).subscribe(res => {
      this.detailsViewComment.controls.comments.setValue(res.comments);      
    }, error => {
        console.error(error);
      }
    );
  }

  getWorkstationDetails(value, content) {
    this.JSONdata = 0;
    this.detailsViewWorkstationFormNew.controls.keyData.setValue('');
    this.detailsViewWorkstationFormNew.controls.valueData.setValue('');
    this.modalReference = this.modalService.open(content);
    this.detailsViewWorkstationFormNew.controls.name.setValue(value);
    this.seat_number = value;
    this.communicationService.getSeat(value).subscribe(res => {
      this.attribute = res;
      this.attributeValue = this.attribute.attributes === 'null' ? '{}' : this.attribute.attributes;
      this.attributeMapObject = JSON.parse(this.attributeValue);
      const keys = Object.keys(this.attributeMapObject);
      this.attributeMap.clear();
        // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < keys.length; i++) {
          this.attributeMap.set(keys[i], this.attributeMapObject[keys[i]]);
      }
      this.attributeMapObject = {};
    }, error => {
        console.error(error);
      }
    );
  }

  IsJsonFormat() {
    try {
        JSON.parse(this.detailsViewWorkstationFormNew.value.JSON_attribute);
    } catch (e) {
        return false;
    }
    return true;
  }

  deleteRecord(value) {
  this.attributeMap.delete(value);
  }

  editRecord(value) {
  this.detailsViewWorkstationFormNew.controls.keyData.setValue(value);
  this.detailsViewWorkstationFormNew.controls.valueData.setValue(this.attributeMap.get(value));
  this.attributeMap.delete(value);
  }

  onSubmitComment(){
    let comments = this.detailsViewComment.value.comments;
    const request = {
      name: this.seat_number,
      comments:this.detailsViewComment.value.comments
    }
    this.communicationService.updateSeatAttribute(request).subscribe(res => {
      this.toastr.success(this.translate.instant('toasterSuccessMessage.attributeUpdated'));
      this.modalReference.close();
    }, error => {
      console.log(error);
      this.toastr.error(this.translate.instant('toasterSuccessMessage.attributeError'));
      this.modalReference.close();
    });

  }

  onSubmit() {

    if (this.JSONdata === 1) {
      if (this.detailsViewWorkstationFormNew.value.JSON_attribute === '') {
        this.JsonAtt = '{}';
      } else {
        this.JsonAtt = this.detailsViewWorkstationFormNew.value.JSON_attribute;
      }
      const request = {
        name: this.seat_number,
        attribute: this.JsonAtt
      };
      this.communicationService.updateSeatAttribute(request).subscribe(res => {
        this.toastr.success(this.translate.instant('toasterSuccessMessage.attributeUpdated'));
        this.modalReference.close();
        this.JSONdata = 0;
      }, error => {
        console.log(error);
        this.toastr.error(this.translate.instant('toasterSuccessMessage.attributeError'));
        this.modalReference.close();
        this.JSONdata = 0;
      });
    } else {
      this.attributeMap.forEach((value, key) => {
        this.attributeMapObject[key] = value;
      });
      this.attributeMap.clear();
      const request = {
         name: this.seat_number,
         attribute: JSON.stringify(this.attributeMapObject)
      };
      this.attributeMapObject = {};
      this.communicationService.updateSeatAttribute(request).subscribe(res => {
        this.toastr.success(this.translate.instant('toasterSuccessMessage.attributeUpdated'));
        this.modalReference.close();
      }, error => {
        console.log(error);
        this.attributeMapObject = {};
        this.toastr.error(this.translate.instant('toasterSuccessMessage.attributeError'));
      });
    }
  }

  disableAddButton() {
      if ((this.detailsViewWorkstationFormNew.value.keyData === '') ||
        (this.detailsViewWorkstationFormNew.value.valueData === '') || (this.detailsViewWorkstationFormNew.value.name === '') ) {
        return true;
      } else {
        return false;
      }
  }

  valueRequire() {
      if (this.detailsViewWorkstationFormNew.value.valueData === '') {
      return true;
      } else {
      return false;
      }
  }

  keyRequire() {
        if (this.detailsViewWorkstationFormNew.value.keyData === '') {
        return true;
        } else {
        return false;
        }
  }

  addAttribute() {
    let keyAtt: string;
    let valueAtt:  any;
    keyAtt = this.detailsViewWorkstationFormNew.value.keyData;
    valueAtt = this.detailsViewWorkstationFormNew.value.valueData;
    this.attributeMap.set(keyAtt, valueAtt);
    this.attributeMap.get(keyAtt);
    this.detailsViewWorkstationFormNew.controls.keyData.setValue('');
    this.detailsViewWorkstationFormNew.controls.valueData.setValue('');

  }

  changeToJson(value: number) {
    this.JSONdata = value;
    const workstationName = this.detailsViewWorkstationFormNew.value.name;
    if (this.JSONdata === 1) {
      this.communicationService.getSeat(workstationName).subscribe(res => {
        this.JSONattribute = res;
        const JsonAttData = this.JSONattribute.attributes === 'null' ? '{}' : this.attribute.attributes;
        this.detailsViewWorkstationFormNew.controls.JSON_attribute.patchValue(JsonAttData);
      }, error => {
        console.error(error);
      });
    }
  }
}
