import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunicationService } from 'src/app/services/communication.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { userRoles } from 'src/app/helper/userRoles';

@Component({
  selector: 'app-add-edit-wing',
  templateUrl: './add-edit-wing.component.html',
  styleUrls: ['./add-edit-wing.component.css']
})
export class AddEditWingComponent implements OnInit {
  countries = [];
  organizations = [];
  cities = [];
  buildings = [];
  floors = [];
  departments = [];
  wingId: any;
  buildingFloorId: any;
  image_path;
  wingDetails: any;
  wingName: any;
  departmentName: any;
  departmentId: any;
  selectedFile: File;
  currentUser: any;
  isSuperUser = false;
  detailsAddEditWingForm: FormGroup;
  isDisabled: boolean;
 imageName: any;
 submitted:boolean;
  constructor(
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    private _Activatedroute: ActivatedRoute,
    private router: Router,
    public  translate:  TranslateService,
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser') || '{}');
    if (this.currentUser.role === 'Super User') {
      this.isSuperUser = true;
    }
    this.getDepartment();
    this.wingId = this._Activatedroute.snapshot.paramMap.get('wingid');
    this.buildingFloorId = this._Activatedroute.snapshot.paramMap.get('buildingfloorid');
    this.getCountries();

    this.detailsAddEditWingForm = this.formBuilder.group(
      {
        organization: ['', Validators.required],
        department: ['', Validators.required],
        country: ['', Validators.required],
        countryName: ['', Validators.required],
        city: ['', Validators.required],
        cityName: ['', Validators.required],
        building: ['', Validators.required],
        buildingName: ['', Validators.required],
        floor: ['', Validators.required],
        wing: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9\-\/\(\)\,\. ]*')]),
        floorName: ['', Validators.required],
        imageFile: ['', Validators.required]
      }
    );
    if (this.wingId != null) {
      this.getWingDetails(this.wingId, this.buildingFloorId);
    }
  }

  get wing() {
    return this.detailsAddEditWingForm.get('wing');
  }
  getDepartment() {
    this.communicationService.getDepartments().subscribe(res => {
      this.departments = res;
      if (this.wingId == null) {
        this.f.department.setValue(res[0].department_id);
      }
    }, error => {
      console.error(error);
      this.toastr.error(this.translate.instant('toasterSuccessMessage.departmentError'));
    });
  }

  getWingDetails(wingId, floorWingId) {
    this.communicationService.getWingById(wingId, floorWingId).subscribe(res => {
       this.wingDetails = res;
        this.wingName = res.wing_name;
        this.departmentName = res.department_name;
        this.f.country.setValue(res.country_id);
        this.getCitiesByCountry(res.country_id);
        this.getOrganizationsByCountry(res.country_id);
        this.f.city.setValue(res.city_id);
        this.getBuildings(res.city_id);
        this.f.building.setValue(res.building_id);
        this.getFloors(res.building_id);
        // this.getDepartmentsByOrganization(res.organization_id);
        this.f.organization.setValue(res.organization_id);
        this.f.floor.setValue(res.floor_id);
        this.f.floorName.patchValue(res.floor_name);
        this.f.cityName.patchValue(res.city_name);
        this.f.countryName.patchValue(res.country_name);
        this.f.buildingName.patchValue(res.building_name);
        this.imageName = res.imagePath;
        if (this.imageName) {
          this.detailsAddEditWingForm.get('imageFile')?.clearValidators();
        }
        if (this.isSuperUser) {
          for (const department of this.departments) {
            if (department.department_name === this.departmentName) {
              this.f.department.setValue(department.department_id);
            }
          }
        } else {
          this.f.department.setValue(res.department_id);
        }
    }, error => {
      console.log(error);
    });
  }
  getBuildings(value) {
    this.communicationService.getBuildings(value).subscribe(res => {
      if (res.length > 0) {
                this.buildings = [];
                for (const building of res) {
                  if (building.status === '0') {
                    this.buildings.push(building);
                  }
                }
        if (this.buildingFloorId == null) {
          this.f.building.setValue(res[0].building_id);
          this.getFloors(res[0].building_id);
        }
      } else {
        this.f.building.setValue('');
        this.floors = [];
        this.f.floor.setValue('');
      }

    }, error => {
      console.error(error);
    });
  }
  showOrganizations() {
   return this.currentUser.role === userRoles.SUPERADMIN ? true : false;
  }

  getFloors(building_id: string) {
    this.communicationService.getFloorsByBuilding(building_id).subscribe(res => {
    if (res.length > 0) {
       this.floors = res;
       if (this.buildingFloorId == null) {
       this.f.floor.setValue(res[0].floor_id); }
    } else {
       this.floors = [];
       this.f.floor.setValue('');
    }
    }, error => {
      console.error(error);
    });
  }

  getCitiesByCountry(country_id: string) {
    this.communicationService.getCitiesByCountry(country_id).subscribe(res => {
       this.cities = res;
       if (this.buildingFloorId == null) {
       this.f.city.setValue(res[0].city_id);
       this.getBuildings(res[0].city_id); }
    }, error => {
      console.error(error);
    });
  }

  getCountries() {
    this.communicationService.getCountries().subscribe(res => {
      this.countries = res;
      if (this.buildingFloorId == null) {
      this.f.country.setValue(res[0].country_id);
      this.getCitiesByCountry(res[0].country_id);
      this.getOrganizationsByCountry(res[0].country_id); }
    }, error => {
      console.error(error);
    });
  }

  onCountryChange(value) {
    this.getOrganizationsByCountry(value.target.value);
    this.getCitiesByCountry(value.target.value);
  }
  getOrganizationsByCountry(value: any) {
    this.communicationService.getOrganizationByCountryId(value).subscribe(res => {
      this.organizations = res;
      if (this.buildingFloorId == null) {
        this.f.organization.setValue(res[0].organization_id);
        // this.getDepartmentsByOrganization(res[0].organization_id);
      }
    }, error => {
      console.error(error);
    });
  }
  getDepartmentsByOrganization(organization_id: any) {
    this.communicationService.getDepartmentsByOrganizationId(organization_id).subscribe(res => {
      this.departments = res;
      if (this.buildingFloorId == null) {
       this.f.department.setValue(res[0].department_id);
      }
    }, error => {
      console.error(error);
    });
  }

  onCityChange(value) {
     this.getBuildings(value.target.value);
  }
  onOrganizationChange(value) {
    this.getDepartmentsByOrganization(value.target.value);
 }

  onBuildingChange(value) {
    this.getFloors(value.target.value);
  }

  get f() {
    return this.detailsAddEditWingForm.controls;
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.selectedFile = event.target.files[0];
    }
  }

  onSubmit(event) {
    this.isDisabled = true;
    if (this.imageName) {
      this.submitForm();
    } else {
      if (this.selectedFile) {
        if (!this.validateFile(this.selectedFile.name)) {
            this.toastr.error(this.translate.instant('addeditwing.uploadFileErrorMsg'));
            return false;
        }
        const request = {
          floor_id: this.detailsAddEditWingForm.value.floor,
          wing_name: this.detailsAddEditWingForm.value.wing === '' ? this.wingName : this.detailsAddEditWingForm.value.wing,
          image_path: this.image_path,
          status: '0',
          wing_id: this.wingId != null ? this.wingId : null,
          department_id: this.detailsAddEditWingForm.value.department == null ? this.departmentId :
          this.detailsAddEditWingForm.value.department
        };
        this.communicationService.addWing(request).subscribe(res => {
            this.onUpload(res.floor_wing_id);
        }, error => {
          this.isDisabled = false;
          this.toastr.error(error.error.message);
          console.log(error);
        });
      } else {
        this.isDisabled = false;
        this.toastr.error(this.translate.instant('addeditwing.imageErrorMsg'));
      }
    }
  }

  onUpload(floor_wing_id) {
    const uploadImageData = new FormData();
    uploadImageData.append('file', this.selectedFile, this.selectedFile.name);
    uploadImageData.append('floor_wing_id', floor_wing_id);
    this.communicationService.uploadImage(uploadImageData).subscribe(res => {
      this.toastr.success(this.translate.instant('toasterSuccessMessage.wingAdded'));
      this.router.navigate(['/view-wing']);
    }, error => {
      console.error(error);
    });
  }
  validateFile(name: string) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
        return true;
    } else {
        return false;
    }
  }
  onCancel(event) {
    this.isDisabled = true;
    this.router.navigate(['/view-wing']);
  }

  submitForm(): void {
    const request = {
      floor_id: this.detailsAddEditWingForm.value.floor,
      wing_name: this.detailsAddEditWingForm.value.wing === '' ? this.wingName : this.detailsAddEditWingForm.value.wing,
      image_path: this.image_path,
      status: '0',
      wing_id: this.wingId != null ? this.wingId : null,
      department_id: this.detailsAddEditWingForm.value.department == null ? this.departmentId :
      this.detailsAddEditWingForm.value.department
    };
    this.communicationService.addWing(request).subscribe(res => {
        this.onUpload(res.floor_wing_id);
    }, error => {
      this.isDisabled = false;
      this.toastr.error(error.error.message);
      console.log(error);
    });
  }
}
