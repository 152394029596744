import { Routes } from '@angular/router';
import { DashboardComponent } from '../../pages/dashboard/dashboard.component';
import { HelpComponent } from '../../pages/help/help.component';
import { UserProfileComponent } from '../../pages/user-profile/user-profile.component';
import { AuditLogsComponent } from '../../pages/audit-logs/audit-logs.component';
import { UsersComponent } from '../../pages/users/users.component';
import { ViewBookingsComponent } from '../../pages/view-bookings/view-bookings.component';
import { BookSeatComponent } from '../../pages/book-seat/book-seat.component';
import { SearchEmployeeDeskComponent } from '../../pages/search-employee-desk/search-employee-desk.component';
import { SearchEmployeeDeskAuComponent } from '../../pages/search-employee-desk-au/search-employee-desk-au.component';
import { ApprovRejectBookingComponent } from '../../pages/approv-reject-booking/approv-reject-booking.component';
import { DisableFloorComponent } from '../../pages/disable-floor/disable-floor.component';
import { LogoutComponent } from '../../pages/logout/logout.component';
import { RuleConfigurationComponent } from '../../pages/rule-configuration/rule-configuration.component';
import { ViewOnMapComponent } from '../../pages/view-on-map/view-on-map.component';
import { ExpressBookingComponent } from '../../pages/express-booking/express-booking.component';
import { AddUserComponent } from '../../pages/add-user/add-user.component';
import { RouteGuardService } from '../../services/route-guard.service ';
import { EnableDisableSeatComponent } from '../../pages/enable-disable-seat/enable-disable-seat.component';
import { UserPreferenceComponent} from '../../pages/user-preference/user-preference.component';
import { QrCodeBookingComponent} from '../../pages/qr-code-booking/qr-code-booking.component';
import { BookingByDateComponent} from '../../pages/booking-by-date/booking-by-date.component';
import { UpdateUserRoleComponent } from 'src/app/pages/update-user-role/update-user-role.component';
import { AddEditCountryComponent } from 'src/app/pages/add-edit-country/add-edit-country.component';
import { AddEditCityComponent } from 'src/app/pages/add-edit-city/add-edit-city.component';
import { AddEditBuildingComponent } from 'src/app/pages/add-edit-building/add-edit-building.component';
import { ViewCountryComponent } from 'src/app/pages/view-country/view-country.component';
import { ViewCityComponent } from 'src/app/pages/view-city/view-city.component';
import { ViewBuildingComponent } from 'src/app/pages/view-building/view-building.component';
import { ViewFloorComponent } from 'src/app/pages/view-floor/view-floor.component';
import { ViewWingComponent } from 'src/app/pages/view-wing/view-wing.component';
import { AddEditFloorComponent } from 'src/app/pages/add-edit-floor/add-edit-floor.component';
import { AddEditWingComponent } from 'src/app/pages/add-edit-wing/add-edit-wing.component';
import { FirstAidComponent } from 'src/app/pages/first-aid/first-aid.component';
import { ViewWorkstationsComponent } from 'src/app/pages/view-workstations/view-workstations.component';
import { AddFirstAidComponent } from 'src/app/pages/add-first-aid/add-first-aid.component';
import { AutoAssignBookingComponent } from '../../pages/auto-assign-booking/auto-assign-booking.component';
import { ViewDepartmentComponent } from 'src/app/pages/view-department/view-department.component';
import { AddEditDepartmentComponent } from 'src/app/pages/add-edit-department/add-edit-department.component';
import { AddWorkstationComponent } from 'src/app/pages/add-workstation/add-workstation.component';
import { ViewOrganisationComponent } from 'src/app/pages/view-organisation/view-organisation.component';
import { AddEditOrganisationComponent } from 'src/app/pages/add-edit-organisation/add-edit-organisation.component';
import { ViewSubDepartmentComponent } from 'src/app/pages/view-sub-department/view-sub-department.component';
import { AddEditSubDepartmentComponent } from 'src/app/pages/add-edit-sub-department/add-edit-sub-department.component';
import { SuperUserDashboardComponent } from '../../pages/super-user-dashboard/super-user-dashboard.component';
import { BookZoopassComponent } from 'src/app/pages/book-zoopass/book-zoopass.component';
import { ViewZooPassComponent } from 'src/app/pages/view-zoo-pass/view-zoo-pass.component';
import {BookCarParkingComponent} from '../../pages/book-car-parking/book-car-parking.component';
import {AddParkingComponent} from '../../pages/add-parking/add-parking.component';
import {AddEditZooPassComponent} from '../../pages/add-edit-zoo-pass/add-edit-zoo-pass.component';
import {ViewParkingsComponent} from '../../pages/view-parkings/view-parkings.component';
import { BookingByDateAuComponent } from 'src/app/pages/booking-by-date-au/booking-by-date-au.component';
import {FirstAidBookingsComponent} from "../../pages/first-aid-bookings/first-aid-bookings.component";
import {FireWardenBookingsComponent} from "../../pages/fire-warden-bookings/fire-warden-bookings.component";
import {RespondersComponent} from "../../pages/responders/responders.component";
import {BookSeatAuComponent} from "../../pages/book-seat-au/book-seat-au.component";
import { EntityFeatureComponent } from '../../pages/entity-feature/entity-feature.component';
import { AddEditFeatureComponent } from 'src/app/pages/add-edit-feature/add-edit-feature.component';
import { CreateQrCodeComponent } from  '../../pages/create-qr-code/create-qr-code.component';
import { AdminDashboardComponent } from 'src/app/pages/admin-dashboard/admin-dashboard.component';


export const AdminLayoutRoutes: Routes = [
    { path: '', component: BookSeatComponent },
    { path: 'dashboard',      component: DashboardComponent, canActivate: [RouteGuardService], data: {role: ['Admin', 'Employee','Super User','Manager']}  },
    { path: 'super-user-dashboard',      component: SuperUserDashboardComponent, canActivate: [RouteGuardService], data: {role: ['Super User']}  },
    { path: 'user-profile',   component: UserProfileComponent },
    { path: 'entity-feature',   component: EntityFeatureComponent },
    { path: 'audit-logs',     component: AuditLogsComponent, canActivate: [RouteGuardService], data: {role: ['Admin', 'Super User']}},
    { path: 'users',          component: UsersComponent, canActivate: [RouteGuardService], data: {role: ['Admin', 'Super User', 'Department Head', 'Manager', 'Human Resource']} },
    { path: 'view-bookings',  component: ViewBookingsComponent },
    { path: 'book-seat',      component: BookSeatComponent },
    { path: 'book-seat-au',      component: BookSeatAuComponent },
    { path: 'search-employee-desk-au', component: SearchEmployeeDeskAuComponent },
    { path: 'search-employee-desk', component: SearchEmployeeDeskComponent },
    /*{ path: 'approv-reject-booking', component: ApprovRejectBookingComponent, canActivate: [RouteGuardService], data: {role: ['Admin', 'Department Head', 'Manager', 'Human Resource', 'Super User']} },*/
    { path: 'disable-floor',  component: DisableFloorComponent, canActivate: [RouteGuardService], data: {role: ['Admin', 'Super User', 'Department Head', 'Manager', 'Human Resource']} },
    { path: 'rule-configuration', component: RuleConfigurationComponent, canActivate: [RouteGuardService], data: {role: ['Admin', 'Super User', 'Department Head', 'Manager', 'Human Resource']} },
    { path: 'express-booking', component: ExpressBookingComponent },
    { path: 'logout',      component: LogoutComponent },
    { path: 'view-on-map',   component: ViewOnMapComponent },
    { path: 'add-user',      component: AddUserComponent, canActivate: [RouteGuardService], data: {role: ['Admin', 'Department Head', 'Manager', 'Human Resource', 'Super User']} },
    { path: 'update-user-role/:username',      component: UpdateUserRoleComponent, canActivate: [RouteGuardService], data: {role: ['Admin', 'Department Head', 'Manager', 'Human Resource', 'Super User']} },
   /*  { path: 'enable-disable-seat',      component: EnableDisableSeatComponent }, */
    { path: 'user-preference', component: UserPreferenceComponent},
    { path: 'booking-by-date', component: BookingByDateComponent},
    { path: 'add-edit-department', component: AddEditDepartmentComponent, canActivate: [RouteGuardService], data: {role: ['Super User']}},
    { path: 'add-edit-department/:departmentid', component: AddEditDepartmentComponent, canActivate: [RouteGuardService], data: {role: ['Super User']}},
    { path: 'add-edit-country', component: AddEditCountryComponent, canActivate: [RouteGuardService], data: {role: ['Super User']}},
    { path: 'add-edit-country/:countryid',      component: AddEditCountryComponent, canActivate: [RouteGuardService], data: {role: ['Super User']} },
    { path: 'add-edit-city', component: AddEditCityComponent, canActivate: [RouteGuardService], data: {role: ['Super User']}},
    { path: 'add-edit-organisation', component: AddEditOrganisationComponent, canActivate: [RouteGuardService], data: {role: ['Super User']}},
    { path: 'add-edit-city/:cityid', component: AddEditCityComponent, canActivate: [RouteGuardService], data: {role: ['Super User']}},
    { path: 'add-edit-building', component: AddEditBuildingComponent, canActivate: [RouteGuardService], data: {role: ['Admin', 'Super User']}},
    { path: 'add-edit-building/:buildingid', component: AddEditBuildingComponent, canActivate: [RouteGuardService], data: {role: ['Admin', 'Super User']}},
    { path: 'view-department', component: ViewDepartmentComponent, canActivate: [RouteGuardService], data: {role: ['Super User']}},
    { path: 'view-country',  component: ViewCountryComponent, canActivate: [RouteGuardService], data: {role: ['Super User']} },
    { path: 'view-city', component: ViewCityComponent , canActivate: [RouteGuardService], data: {role: ['Super User']} },
    { path: 'view-building', component: ViewBuildingComponent, canActivate: [RouteGuardService], data: {role: ['Admin', 'Super User']}},
    { path: 'view-organisation', component: ViewOrganisationComponent , canActivate: [RouteGuardService], data: {role: ['Super User']}},
    { path: 'view-floor', component: ViewFloorComponent , canActivate: [RouteGuardService], data: {role: ['Admin', 'Super User']}},
    { path: 'view-wing', component: ViewWingComponent , canActivate: [RouteGuardService], data: {role: ['Admin', 'Super User']}},
    { path: 'add-workstation', component: AddWorkstationComponent , canActivate: [RouteGuardService], data: {role: ['Admin', 'Super User']}},
    { path: 'add-edit-floor', component: AddEditFloorComponent , canActivate: [RouteGuardService], data: {role: ['Admin', 'Super User']}},
    { path: 'add-edit-floor/:floorid', component: AddEditFloorComponent , canActivate: [RouteGuardService], data: {role: ['Admin', 'Super User']}},
    { path: 'add-edit-wing', component: AddEditWingComponent , canActivate: [RouteGuardService], data: {role: ['Admin', 'Super User']}},
    { path: 'first-aid', component: FirstAidComponent, canActivate: [RouteGuardService], data: {role: ['Admin', 'Super User']}},
    { path: 'view-workstations', component: ViewWorkstationsComponent , canActivate: [RouteGuardService], data: {role: ['Admin', 'Super User','Manager']}},
    { path: 'add-edit-wing/:wingid/:buildingfloorid', component: AddEditWingComponent , canActivate: [RouteGuardService], data: {role: ['Admin', 'Super User']}},
    { path: 'add-first-aid/:workstation_id', component: AddFirstAidComponent, canActivate: [RouteGuardService], data: {role: ['Admin', 'Super User']} },
    { path: 'auto-assign-booking', component: AutoAssignBookingComponent},
    { path: 'help', component: HelpComponent },
    { path: 'add-edit-organisation/:organizationid', component: AddEditOrganisationComponent , canActivate: [RouteGuardService], data: {role: ['Super User']}},
    { path: 'add-edit-organisation' , component: AddEditOrganisationComponent , canActivate: [RouteGuardService], data: {role: ['Super User']}},
    { path: 'add-edit-feature/:featureid/:departmentid', component: AddEditFeatureComponent , canActivate: [RouteGuardService], data: {role: ['Super User']}},
    { path: 'add-edit-feature' , component: AddEditFeatureComponent , canActivate: [RouteGuardService], data: {role: ['Super User']}},
    { path: 'view-sub-department' , component: ViewSubDepartmentComponent , canActivate: [RouteGuardService], data: {role: ['Super User']}},
    { path: 'add-edit-sub-department' , component: AddEditSubDepartmentComponent, canActivate: [RouteGuardService], data: {role: ['Super User', 'Admin']}},
    { path: 'add-edit-sub-department/:subdepartmentid', component: AddEditSubDepartmentComponent , canActivate: [RouteGuardService], data: {role: ['Super User','Admin']}},
    { path: 'book-zoopass' , component: BookZoopassComponent},
    { path: 'book-car-parking' , component: BookCarParkingComponent},
    { path: 'add-parking/:parkingid' , component: AddParkingComponent },
    { path: 'view-zoo-pass' , component: ViewZooPassComponent},
    { path: 'view-parkings', component: ViewParkingsComponent},
    { path: 'add-parking' , component: AddParkingComponent },
    { path: 'add-edit-zoo-pass' , component: AddEditZooPassComponent},
    { path: 'add-edit-zoo-pass/:zoopassid' , component: AddEditZooPassComponent },
    { path: 'first-aid-bookings' , component: FirstAidBookingsComponent },
    { path: 'fire-warden-bookings' , component: FireWardenBookingsComponent},
    { path: 'responders' , component: RespondersComponent },
    { path: 'qr-code-booking' , component: QrCodeBookingComponent },
    { path: 'create-qr-code' , component: CreateQrCodeComponent },
    { path: 'admin-dashboard' , component: AdminDashboardComponent , canActivate: [RouteGuardService], data: {role: ['Super User','Admin','Manager']}},
];
