import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'app-add-edit-zoo-pass',
  templateUrl: './add-edit-zoo-pass.component.html',
  styleUrls: ['./add-edit-zoo-pass.component.css']
})
export class AddEditZooPassComponent implements OnInit {
  detailsAddEditZooPassForm: FormGroup;
  passes = [];
  passId: any;
  passStatus: any;
  passesName: any;
  isDisabled: boolean;



  constructor(
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private toastr: ToastrService,
    private _Activatedroute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.passId = this._Activatedroute.snapshot.paramMap.get('zoopassid');
    this.detailsAddEditZooPassForm = this.formBuilder.group(
      {
        passName: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9 ]*'), Validators.maxLength(50)]),
        passCount: new FormControl('', [Validators.required, Validators.pattern('[0-9 ]*'), Validators.maxLength(8)]),
      }
    );
    this.PassList();
    if (this.passId != null) {
      this.getPassDetails(this.passId);
    }
  }

  get passName() {
    return this.detailsAddEditZooPassForm.get('passName');
  }

  get passCount() {
    return this.detailsAddEditZooPassForm.get('passCount');
  }

  PassList() {
    this.communicationService.getAllPasses().subscribe(res => {
      this.passes = res;
    }, error => {
      console.error(error);
    });
  }

  getPassDetails(passId: any) {
    this.communicationService.getPassById(passId).subscribe(res => {
      this.f.passName.setValue(res.zoo_pass_name);
      this.f.passCount.setValue(res.count);
      this.passStatus = res.status;
    }, error => {
      console.log(error);
    });
  }

  get f() {
    return this.detailsAddEditZooPassForm.controls;
  }

  onCancel(event:any) {
    this.isDisabled = true;
    this.router.navigate(['/view-zoo-pass']);
  }

  onSubmit(event:any) {
    this.isDisabled = true;
    if (this.detailsAddEditZooPassForm.value.passCount > 0) {
      const request = {
        zoo_pass_id: this.passId != null ? this.passId : null,
        zoo_pass_name: this.detailsAddEditZooPassForm.value.passName === '' ? this.passesName : this.detailsAddEditZooPassForm.value.passName,
        count: this.detailsAddEditZooPassForm.value.passCount,
        status: this.passStatus != null ? this.passStatus : '0'
      };
      this.communicationService.updatePass(request).subscribe(res => {
        this.toastr.success('Pass Updated Successfully');
        this.isDisabled = false;
        this.router.navigate(['/view-zoo-pass']);
      }, error => {
        this.isDisabled = false;
        this.toastr.error(error.error.message);
      });
    } else {
      this.isDisabled = false;
      this.toastr.error('Pass Updatation Failed');
    }
  }

}

