import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunicationService } from 'src/app/services/communication.service';
import { formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AfterViewInit, Component, LOCALE_ID, Inject, TemplateRef, ViewChild } from '@angular/core';
import { ConfirmationDialogService } from 'src/app/pages/confirmation-dialog/confirmation-dialog.service';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ValidationService } from 'src/app/services/validation.service';
import { TimeByHour } from 'src/app/constants/timeList';

@Component({
  selector: 'app-express-booking',
  templateUrl: './express-booking.component.html',
  styleUrls: ['./express-booking.component.scss']
})
export class ExpressBookingComponent implements AfterViewInit {
  modalReference: NgbModalRef;
  searchResultSeats = [];
  detailsExpressBookingForm: FormGroup;
  thumbnail: any;
  todayDate;
  employeeId: any;
  loading = false;
  limit = 20;
  startIndex = 0;
  countries = [];
  cities = [];
  buildings = [];
  loader = false;
  bookingTimeList: any;
  startTimeValue = 0;
  isBookByTime = false;
  isDisabled:boolean;
  submitted:boolean;
  floor_id;
  wing_id;
  emailNotification:boolean=false;

  @ViewChild('mymodal')
private defaultTabButtonsTpl: TemplateRef<any>;
  validatated: boolean;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    public _DomSanitizationService: DomSanitizer,
    private toastr: ToastrService,
    private router: Router,
    private validationService: ValidationService,
    private modalService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService,
    public  translate:  TranslateService) {
  }

  // tslint:disable-next-line:use-lifecycle-interface use-life-cycle-interface
  ngOnInit(): void {
    this.detailsExpressBookingForm = this.formBuilder.group(
      {
        seatName: ['', Validators.required],
        employee_id: ['', Validators.required],
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
        comments: new FormControl('', [Validators.pattern('[a-zA-Z0-9 ]*'), Validators.maxLength(250)]),
        country: ['', Validators.required],
        city: ['', Validators.required],
        building: ['', Validators.required],
        startTime : ['', Validators.required],
        endTime : ['', Validators.required],
        email_notification: ['', Validators.required]
      }
    );

    const currentDate = new Date();
    const objCurrentDate = { year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate() };
    this.todayDate = objCurrentDate;

    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (currentUser.id) {
      this.communicationService.getUserDetails(currentUser.id).subscribe(res => {
        this.detailsExpressBookingForm.controls.employee_id.setValue(res.firstName + ' ' + res.lastName);
        this.employeeId = res.username;
      }, error => {
        console.log('error', error);
      });
    }
    this.detailsExpressBookingForm.controls.startDate.setValue(this.todayDate);
    this.detailsExpressBookingForm.controls.endDate.setValue(this.todayDate);

    this.getCountries();
    this.setStartEndTime();

  }

  validatingComment() {
    this.validatated = this.validationService.validationComment(this.detailsExpressBookingForm.value.comments);
   }

  onDateChange(event) {
    this.detailsExpressBookingForm.controls.endDate.patchValue(this.detailsExpressBookingForm.value.startDate);
  }

  get f() {
    return this.detailsExpressBookingForm.controls;
  }

  getSearchResultSeats = (val) => {
    const queryData = val.target.value;
    if (queryData.length >= 2) {
      this.communicationService.getSeatsFilter(this.limit, this.startIndex, queryData).subscribe(res => {
        this.searchResultSeats = res;
      }, error => {
        this.searchResultSeats.length = 0;
        console.log('Getting error while searching result for seats');
      });
    } else {
      this.searchResultSeats.length = 0;
    }
  }

  onEmailChange(event) {
        if (event.target.checked) {
          this.emailNotification = true;
        } else {
          this.emailNotification = false;
        }
  }

  getSearchSeatsData(data: any) {
    let myForm = this.detailsExpressBookingForm.value.seatName;
    if (typeof (data) === 'object') {
      myForm = data.name;
      this.detailsExpressBookingForm.controls['seatName'].setValue(myForm);
      this.floor_id = data.floor_id;
      this.wing_id = data.wing_id;
      this.searchResultSeats.length = 0;
    }
  }

  setStartEndTime() {
    this.bookingTimeList = TimeByHour;
    this.f.startTime.setValue(0);
    this.f.endTime.setValue(24);
    this.detailsExpressBookingForm.get('startTime').disable();
    this.detailsExpressBookingForm.get('endTime').disable();
  }

  ngAfterViewInit(): void {

    const ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false
  };
  this.modalReference = this.modalService.open(this.defaultTabButtonsTpl, ngbModalOptions);

  }

  onCountryChange(value) {
    this.getCitiesByCountry(value);
  }

  getCitiesByCountry(country_id: string) {
    this.communicationService.getCitiesByCountry(country_id).subscribe(res => {
      this.modalReference.close();
      this.cities = res;
      this.f.city.setValue(res[0].city_id);
      this.getBuildings(res[0].city_id);
    }, error => {
      console.error(error);
    });
  }

  getCities() {
    this.communicationService.getCities().subscribe(res => {
      this.cities = res;
      this.loader = true ;
      this.modalReference.close();
    }, error => {
      this.modalReference.close();
      console.error(error);
    });
  }

  getCountries() {
    this.communicationService.getCountries().subscribe(res => {
      this.countries = res;
      this.f.country.setValue(res[0].country_id);
      this.getCitiesByCountry(res[0].country_id);
    }, error => {
      console.error(error);
    });
  }


  getBuildings(value) {
    this.communicationService.getBuildings(value).subscribe(res => {
      this.buildings = res;
      this.f.building.setValue(res[0].building_id);
    }, error => {
      console.error(error);
    });
  }

  deselectTimeBooking(event) {
    if (event.target.checked) {
      this.detailsExpressBookingForm.get('startTime').enable();
      this.detailsExpressBookingForm.get('endTime').enable();
      this.isBookByTime = true;
    } else {
      this.isBookByTime = false;
      this.f.startTime.setValue(0);
      this.f.endTime.setValue(24);
      this.detailsExpressBookingForm.get('startTime').disable();
      this.detailsExpressBookingForm.get('endTime').disable();
    }
  }

  onStartTimeChange(value) {
    this.startTimeValue =  value.target.value;
    let val = this.startTimeValue;
    this.setEndTime(++val);
  }

  setEndTime(val) {
    this.f.endTime.setValue(val);
  }

  onEndTimeChange(value) {
    let endTimeValue =  value.target.value;
    // tslint:disable-next-line:radix
    if (parseInt(endTimeValue) > this.startTimeValue) {
      this.setEndTime(endTimeValue);
    } else {
      endTimeValue = this.startTimeValue;
      this.setEndTime(++endTimeValue);
    }
  }

  onSubmitBook(confirmed: any, content) {
    if (confirmed) {
      const startDateVal = formatDate(this.convertDate(this.detailsExpressBookingForm.value.startDate), 'yyyy-MM-dd', this.locale);
      const endDateVal = formatDate(this.convertDate(this.detailsExpressBookingForm.value.endDate), 'yyyy-MM-dd', this.locale);
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false
      };
      this.modalReference = this.modalService.open(content, ngbModalOptions);
      if (startDateVal <= endDateVal) {
        const bookSeatObj = {
          // start_date: startDateVal,
          // end_date: endDateVal,
          start_date_time: this.isBookByTime ? startDateVal + this.bookingTimeList[this.detailsExpressBookingForm.get('startTime').value].timeFormat :
          startDateVal + 'T00:00:00'   ,
          end_date_time: this.isBookByTime ? endDateVal + this.bookingTimeList[this.detailsExpressBookingForm.get('endTime').value].timeFormat :
          endDateVal + 'T23:59:59',
          emp_id: this.employeeId,
          seat_id: this.detailsExpressBookingForm.value.seatName,
          status: 0,
          email_notification : this.emailNotification,
          comments: this.detailsExpressBookingForm.value.comments,
          futureReference: false,
          is_self: true,
          floor_id: this.floor_id,
          wing_id: this.wing_id
        };
        this.communicationService.bookSeat(bookSeatObj).subscribe(res => {
          this.modalReference.close();
          this.toastr.success(this.translate.instant('toasterSuccessMessage.workStationBooked'));
          this.router.navigate(['/view-bookings']);
        },
          error => {
            this.modalReference.close();
            console.error(error);
            this.toastr.error(error.error.message, 'Failed to book workstation');
          });
      } else {
        this.modalReference.close();
        this.toastr.error('Invalid Booking Date Selection');
      }
    }
  }

  get comments() {
    return this.detailsExpressBookingForm.get('comments');
  }

  public openConfirmationDialog(content) {
    const startDate = formatDate(this.convertDate(this.detailsExpressBookingForm.value.startDate),
    'yyyy-MM-dd', this.locale);
    const endDate = formatDate(this.convertDate(this.detailsExpressBookingForm.value.endDate),
    'yyyy-MM-dd', this.locale);
    if (this.detailsExpressBookingForm.value.seatName ) {
      const sDate = this.isBookByTime ? startDate + this.bookingTimeList[this.detailsExpressBookingForm.get('startTime').value].timeFormat :
      startDate + 'T00:00:00';
      const eDate = this.isBookByTime ? endDate + this.bookingTimeList[this.detailsExpressBookingForm.get('endTime').value].timeFormat :
       endDate + 'T23:59:59';
      this.confirmationDialogService.confirm('Book Workstation Confirmation',
        'Are you sure you want to book Workstation ' + this.detailsExpressBookingForm.value.seatName + ' from ' + sDate + ' to ' + eDate + '?')
        .then((confirmed) => this.onSubmitBook(confirmed, content));
    } else {
      this.toastr.error('Workstation is not selected');
    }
  }

  isInputSelected() {
    if (this.f.wing.value === undefined
      || this.f.wing.value === '' || this.f.floor.value === undefined || this.f.floor.value === '') {
      return false;
    }
    return true;
  }

  convertDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  }
}
