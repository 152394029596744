import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Component,  OnInit } from '@angular/core';
import { CommunicationService } from 'src/app/services/communication.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Seat } from 'src/app/model/seat';
import { take } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { ConfirmationDialogService } from 'src/app/pages/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-enable-disable-seat',
  templateUrl: './enable-disable-seat.component.html',
  styleUrls: ['./enable-disable-seat.component.scss']
})
export class EnableDisableSeatComponent implements OnInit {
  detailsDisableSeatForm: FormGroup;
  hideTooltip = false;
  keySize = 256;
  iterations = 1000;
  searchResult = [];
  searchResultSeats = [];
  limit = 20;
  startIndex = 0;
  corpId: any;
  seat: Seat;
  isWorkstationActive = false;
  isAddWorkstationActive = false;
  workstationDetails = [];
  tableDataSource$ = new BehaviorSubject<any[]>([]);
  dataOnPage$ = new BehaviorSubject<any[]>([]);
  pageSize$ = new BehaviorSubject<number>(10);
  currentPage$ = new BehaviorSubject<number>(1);
  displayedColumns$ = new BehaviorSubject<string[]>([
      'name',
      'status',
      'reserved'
  ]);
  heroes$;
  totalUserCount = 0;
  searchFormControl = new FormControl();
  sortKey$ = new BehaviorSubject<string>('');
  sortDirection$ = new BehaviorSubject<string>('asc');
  selectedType;

  constructor(
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private confirmationDialogService: ConfirmationDialogService,
    private toastr: ToastrService,
    private router: Router) { }

  ngOnInit(): void {
    this.detailsDisableSeatForm = this.formBuilder.group(
      {
        seatName: [''],
        type: ['']
      }
    );
    this.detailsDisableSeatForm.controls.type.setValue('Active');
  }

  get f() {
    return this.detailsDisableSeatForm.controls;
  }

  getSearchSeatsData(data: any) {
    let myForm = this.detailsDisableSeatForm.value.seatName;
    if (typeof (data) === 'object') {
      myForm = data.name;
      this.detailsDisableSeatForm.controls['seatName'].setValue(myForm);
      this.searchResultSeats.length = 0;
      const index = this.workstationDetails.findIndex(x => x.name === data.name);
      this.selectedType = this.detailsDisableSeatForm.value.type;
      if (this.selectedType !== undefined) {
        if (data.status === this.selectedType) {
          this.isAddWorkstationActive = false;
          this.toastr.error('Workstation already ' + data.status);
        } else if (data.reserved === 'Yes' && this.selectedType === 'Reserved') {
          this.isAddWorkstationActive = false;
          this.toastr.error('Workstation already ' + this.selectedType);
        } else if (data.reserved === 'No' && this.selectedType === 'Open') {
          this.isAddWorkstationActive = false;
          this.toastr.error('Workstation already ' + this.selectedType);
        } else if (index > -1) {
          this.isAddWorkstationActive = false;
          this.toastr.error('Workstation already added');
        } else {
           this.isAddWorkstationActive = true;
           this.seat = data;
        }
      } else {
        this.toastr.error('Select Type');
      }
    }
  }
  onClickType(event) {
    this.selectedType = event;
    this.workstationDetails = [];
    this.detailsDisableSeatForm.controls['seatName'].setValue('');
    this.dataOnPage$.next([]);
  }

  cancelEnableDisableSeat() {
    this.workstationDetails = [];
    this.dataOnPage$.next([]);
    this.detailsDisableSeatForm.controls['seatName'].setValue('');
    this.detailsDisableSeatForm.controls['type'].setValue('Active');
    this.isWorkstationActive = false;
    this.isAddWorkstationActive = false;
  }

  addWorkstation() {
    if (this.workstationDetails.length <= 5 ) {
      this.workstationDetails.push(this.seat);
      this.totalUserCount = this.workstationDetails.length;
      this.heroes$ = new BehaviorSubject(this.workstationDetails);
      this.detailsDisableSeatForm.controls['seatName'].setValue('');
      this.isWorkstationActive = true;
      this.isAddWorkstationActive = false;
      combineLatest(this.tableDataSource$, this.currentPage$, this.pageSize$)
        .subscribe(([allSources, currentPage, pageSize]) => {
          const startingIndex = (currentPage - 1) * pageSize;
          const onPage = allSources.slice(startingIndex, startingIndex + pageSize);
          this.dataOnPage$.next(onPage);
        });

      this.heroes$.pipe(take(1)).subscribe(heroData => {
        this.tableDataSource$.next(Object.values(heroData));
      });
      combineLatest(this.heroes$, this.searchFormControl.valueChanges, this.sortKey$, this.sortDirection$)
        .subscribe(([changedHeroData, searchTerm, sortKey, sortDirection]) => {
          const heroesArray = Object.values(changedHeroData);
          let filteredHeroes: any[];

          if (!searchTerm) {
            filteredHeroes = heroesArray;
          } else {
            const filteredResults = heroesArray.filter(hero => {
              return Object.values(hero)
                .reduce((prev, curr) => {
                  return prev || curr.toString().toLowerCase().includes(searchTerm.toLowerCase());
                }, false);
            });
            filteredHeroes = filteredResults;
          }

          const sortedHeroes = filteredHeroes.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) { return sortDirection === 'asc' ? 1 : -1; }
            if (a[sortKey] < b[sortKey]) { return sortDirection === 'asc' ? -1 : 1; }
            return 0;
          });

          this.tableDataSource$.next(sortedHeroes);
        });
    } else {
       this.toastr.error('Only 5 Workstation can be added');
    }
  }
  public openConfirmationDialog() {
    if (this.workstationDetails.length > 0) {
      this.confirmationDialogService.confirm('Enable/Disable Workstation Confirmation',
        'Do you want to save the changes?')
        .then((confirmed) => this.saveEnableDisableWorkstation(confirmed));
    } else {
      this.toastr.error('Workstation is not selected');
    }
  }
  saveEnableDisableWorkstation(confirmed: any) {
    if (confirmed) {
      const seats: string[] = [];
      let type = null;
      let isReserved = null;

      switch (this.selectedType) {
        case 'Active':
            type = 0;
            break;
        case 'Inactive' :
            type = 1;
            break;
        case 'Reserved':
            isReserved = 1;
            break;
        case 'Open':
            isReserved = 0;
        default:
      }

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.workstationDetails.length; i++) {
        seats.push(this.workstationDetails[i].name);
      }
      const searchObj = {
          seats,
          status: type,
          reserved: isReserved
      };
      this.communicationService.enableDisableSeat(searchObj).subscribe(res => {
        this.toastr.success('Workstation ' + this.selectedType + ' successfully');
        this.isWorkstationActive = false;
        this.router.navigate(['/dashboard']);
      }, error => {
        this.toastr.error('Workstation ' + this.selectedType + ' failed');
        console.log('Error in disable floor', error);
      });
    }
  }

  getSearchResultSeats = (val) => {
    const queryData = val.target.value;
    if (queryData.length >= 2) {
      this.communicationService.getSeatsFilter(this.limit, this.startIndex, queryData).subscribe(res => {
        this.searchResultSeats = res;
      }, error => {
        this.searchResultSeats.length = 0;
        console.log('Getting error while searching result for workstations');
      });
    } else {
      this.searchResultSeats.length = 0;
    }
  }
}
